<template>
    <div class="text-h4 q-mb-md">Concept Clarity Analysis</div>
    <div class="tab-content-container">
        <div class="sliders-container">
            <div class="slider-container">
                <p>Clarity Score:</p>
                <div class="q-pa-md">
                    <q-slider 
                    v-model="ratingExplanationData.stabScore" 
                    :min="stabMin" 
                    :max="stabMax" 
                    readonly 
                    label-always
                    label
                    :inner-min="ratingExplanationData.stabScore" 
                    :inner-max="ratingExplanationData.stabScore"
                    />
                    <div class="flex justify-between q-mt-md">
                        <div style="display: flex; flex-direction: column; align-items: center;">
                            <span>{{ stabMin }} <font-awesome-icon :icon="['fas', 'thumbs-down']" style="color: red; margin-left: 5px;" /></span>
                            <span>(Abstract)</span>
                        </div>
                        <div style="display: flex; flex-direction: column; align-items: center;">
                            <span>{{ stabMax }} <font-awesome-icon :icon="['fas', 'thumbs-up']" style="color: green; margin-left: 5px;" /></span>
                            <span>(Concrete)</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slider-container">
                <p>No Idea Percentage:</p>
                <div class="q-pa-md">
                    <q-slider 
                    v-model="ratingExplanationData.noIdeaScore" 
                    :min="noIdeaMin" 
                    :max="noIdeaMax" 
                    readonly 
                    label-always
                    label
                    :inner-min="ratingExplanationData.noIdeaScore" 
                    :inner-max="ratingExplanationData.noIdeaScore"
                    :label-value="ratingExplanationData.noIdeaScore + ' %'"
                    />
                    <div class="flex justify-between q-mt-md">
                        <div class="slider-footer-container">
                            <span>
                                {{ noIdeaMin }}% <font-awesome-icon :icon="['fas', 'thumbs-up']" style="color: green; margin-left: 5px;" />
                            </span>
                            <span>
                                (Recognisable)
                            </span>
                        </div>
                    <div class="slider-footer-container">
                        <span>
                            {{ noIdeaMax }}% <font-awesome-icon :icon="['fas', 'thumbs-down']" style="color: red; margin-left: 5px;" />
                        </span>
                        <span>
                            (Unrecognisable)
                        </span>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bell-curve-container">
            <RatingBellCurve
                containerName="bellCurveContainer"
                :min="0"
                :max="10"
                :stdDeviation="ratingExplanationData.std_dev"
                :mean="ratingExplanationData.mean"
                :dataPoint="ratingExplanationData.clarityScore"
            />
        </div>
    </div>
</template>

<script>
import RatingBellCurve from './RatingBellCurve.vue';
export default {
    name: "ClarityRatingExplanation",
    props: {
        ratingExplanationData: {
            default: {},
            type: Object,
        },
    },
    components: {RatingBellCurve},
    data(){
        return{
        stabMin: 0,
        stabMax: 10,
        noIdeaMin: 0,
        noIdeaMax: 100
        }
    }

}
</script>

<style scoped>
.bell-curve-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:15px;
    width: 50%;
    height: 100%;
}
.slider-footer-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.slider-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 45%;
}
.sliders-container{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    justify-content: space-around;
    align-content: center;
}
.tab-content-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 95%;
    height: 90%;
    overflow: hidden;
}
</style>