<template>
<div>
    <span class="step-label" >
        <!-- <p>{{ $t('message.add_assets') }}:</p> -->
        <!-- <q-select dense outlined v-model="dummy" /> -->
        <div class="btn-div"><a class="btn3" @click="openAssetPicker()">Go to Library</a></div>
    </span>
    <div class="divdr-bx"> or </div>

    <span class="step-label">
        <p>{{ $t('message.select_a_word_instead') }}:</p>
        <word-picker class="data-selector" @wordSelected="onWordSelected" />
    </span>

    <asset-picker ref="asset-picker" :show_positionings="show_positionings" :allowed_types="allowed_types" @addAsset="onAddAsset">
    </asset-picker>

    <div class="aw-assets" v-for="ap in selected_assets_and_positionings" v-bind:key="ap">
        <asset-checkbox :asset_and_positioning="ap" :show_positionings="show_positionings" @removeAsset="onRemoveAsset">
        </asset-checkbox>
    </div>
</div>
</template>

<script>
import AssetPicker from '@/components/asset_picker/AssetPicker.vue';
import AssetCheckbox from '@/components/target_pickers/assets/AssetCheckbox.vue';
import WordPicker from '@/components/target_pickers/ias/WordPicker.vue';
import {
    toast_success,
    alert_error
} from '@/helpers/alert_helper.js';

/*
This component is similar to the AssetCheckboxPicker.vue, the main difference
being that this one allows selecting words in addition to assets. This is done
by mocking words for assets (see onWordSelect()).
*/
export default {
    components: {
        AssetCheckbox,
        AssetPicker,
        WordPicker
    },
    emits: ['assetAdded', 'assetRemoved', 'onTotalAssetsCount'],
    props: {
        // whether we should show positioning dates (and allow choosing them)
        show_positionings: {
            default: false,
            type: Boolean,
        },
        allowed_types: {
            default: ['images', 'words', 'multimedia'],
            type: Array,
        },
    },
    data: function () {
        return {
            selected_assets_and_positionings: new Set(),
            dummy: null,
        };
    },

    methods: {
        reset: function () {
            this.selected_assets_and_positionings.clear();
        },
        onRemoveAsset: function (asset_and_positioning) {
            this.selected_assets_and_positionings.delete(asset_and_positioning);
            this.$emit('assetRemoved', asset_and_positioning);
            this.$emit('onTotalAssetsCount', this.selected_assets_and_positionings);
        },
        openAssetPicker: function () {
            this.$refs['asset-picker'].openAssetPicker();
        },
        onAddAsset: function (payload) {
            // payload = {asset: xxx, positioning: xxx}
            this.selected_assets_and_positionings.add(payload);
            this.$emit('assetAdded', payload);
            const msg = this.$t('message.added', {
                words: payload.asset.display_name,
            });
            toast_success(msg);
        },
        /*
            Since the rest of the component (and it's children) only expect assets,
            we have to create a "mock" asset before adding it to the list.
            */
        onWordSelected: function (word) {
            // we look through the mocked objects in selected_assets_and_positionings
            // to see whether the mock of this word has already been added to the
            // list
            const match = Array.from(this.selected_assets_and_positionings).find(
                (ap) => ap.mock && ap.asset.word.word_id == word.word_id
            );
            if (typeof match === 'undefined') {
                // we now mock an asset based on the selected word
                const word_type = {
                    id: 1,
                    title: 'word',
                    isMultimedia: false,
                    isImage: false,
                    isAudio: false,
                    isVideo: false,
                };
                const mock_asset = {
                    id: word.word_id,
                    state: 2,
                    user: {},
                    display_name: word.word,
                    word: {
                        word: word.word,
                        brand: 0,
                        asset_mime_type: '',
                        file_identifier: '',
                        external: 0,
                        word_id: word.word_id,
                        type_: word_type,
                        image_url: '',
                    },
                    candidate_word: [],
                    asset_mime_type: '',
                    file_identifier: '',
                    type_: word_type,
                    urls: {},
                };
                const payload = {
                    mock: true, // we mark this object as a mock
                    asset: mock_asset,
                    positioning: {},
                };
                this.onAddAsset(payload);
            } else {
                alert_error(this.$t('message.duplicate_ia'));
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.aw-assets {
    margin-top: 10px;
}

.rightbox-sec span.step-label {
    text-align: center;
}

.rightbox-sec span.step-label p {
    text-align: center;
}
</style>
