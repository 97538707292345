<template>
    <div >
      <!-- <q-select dense outlined v-model="dummy" class=" form-select-sm" /> -->
      <div class="btn-div"><a class="btn3" @click="openAssetPicker">Go to Library</a></div>
    </div>
    <asset-picker
      ref="asset-picker"
      :show_positionings="show_positionings"
      :allowed_types="allowed_types"
      @addAsset="onAddAsset">
    </asset-picker>
    <asset-checkbox
      v-for="ap in selected_assets_and_positionings" v-bind:key="ap"
      :asset_and_positioning="ap"
      :show_positionings="show_positionings"
      @removeAsset="onRemoveAsset">
    </asset-checkbox>
</template>

<script>
import AssetPicker from "@/components/asset_picker/AssetPicker.vue";
import AssetCheckbox from '@/components/target_pickers/assets/AssetCheckbox.vue';
import { toast_success } from '@/helpers/alert_helper.js';

export default {
  components: { AssetCheckbox, AssetPicker },
  emits: ['assetAdded', 'assetRemoved'],
  props: {
    // whether we should show positioning dates (and allow choosing them)
    show_positionings: { default: false, type: Boolean },
    allowed_types: { default: ['images', 'words', 'multimedia'], type: Array },
  },
  data: function() {
    return {
      selected_assets_and_positionings: new Set(), 
      dummy: null,
    }
  },
  methods: {
    reset: function() {
      this.selected_assets_and_positionings.clear();
    },
    onRemoveAsset: function(asset_and_positioning) {
      this.selected_assets_and_positionings.delete(asset_and_positioning);
      this.$emit('assetRemoved', asset_and_positioning);
    },
    openAssetPicker: function() {
      this.$refs["asset-picker"].openAssetPicker();
    },
    onAddAsset: function(payload) {
      // payload = {asset: xxx, positioning: xxx, key: xxx}
      this.selected_assets_and_positionings.add(payload);
      this.$emit('assetAdded', payload);
      const msg = this.$t("message.added", {words: payload.asset.display_name});
      toast_success(msg);
    },
  },
}
</script>

<style scoped>

.upload-img-col {
    align-items: flex-start;
}

.img-name-date {
    line-break: anywhere;
}
</style>