<template>
  <div>
    <div class="assets" v-if="asset_is_selected">
      <div class="lib_image" v-if="selected_asset.type_.isImage">
        <img class="assetImg" :src="selected_asset.urls.thumb_medium" />
      </div>
      <h3 v-if="!selected_asset.type_.isMultimedia">
        {{ selected_asset.display_name }}
      </h3>
      <div class="cp-icons">
        <img
          class="image"
          src="@/assets/icons/edit.svg"
          @click="openAssetPicker"
        />
        <img
          class="image"
          src="@/assets/icons/zoom-in-blue.svg"
          @click="openPreview"
        />
      </div>
    </div>
    <div class="assetBlnk" v-if="!asset_is_selected">
      <div class="picker-opener" >
        <!-- <q-select dense outlined v-model="dummy" /> -->
         <div class="btn-div"><a class="btn3" @click="openAssetPicker">Go to Library</a></div>
      </div>
    </div>
    <asset-picker
      ref="asset-picker"
      :allowed_types="allowed_types"
      :show_positionings="show_positionings"
      @addAsset="onAddAsset"
    >
    </asset-picker>
    <full-screen-preview ref="preview" :asset="selected_asset" />
  </div>
</template>

<script>
import AssetPicker from '@/components/asset_picker/AssetPicker.vue';
import FullScreenPreview from '@/components/common/FullScreenPreview.vue';

export default {
  components: { AssetPicker, FullScreenPreview },
  emits: ['selectedAsset'],
  props: {
    show_positionings: { default: false, type: Boolean },
    allowed_types: { default: ['images', 'words', 'multimedia'], type: Array },
  },
  data: function() {
    return {
      selected_asset: {},
      dummy: null,
    };
  },
  computed: {
    asset_is_selected: function() {
      return Object.keys(this.selected_asset).length > 0;
    },
  },
  methods: {
    reset: function() {
      this.selected_asset = {};
    },
    onAddAsset: function(payload) {
      this.$refs['asset-picker'].closeAssetPicker();
      this.$emit('selectedAsset', payload.asset);
    },
    openAssetPicker: function(payload) {
      this.$refs['asset-picker'].openAssetPicker();
    },
    openPreview: function() {
      this.$refs['preview'].open();
    },
    /*
    SingleAsset doesn't set its own selected asset because the parent
    should decide whether the asset is acceptable or not (e.g. if it's a
    duplicate, it shouldn't be accepted).
    */
    setAsset: function(asset) {
      this.selected_asset = asset;
    },
  },
};
</script>
<style scoped lang="scss">
.logo {
  width: 17vw;
  text-align: center;
}
.logo img {
  width: 100%;
  height: 150px;
  padding-top: 10px;
  justify-content: center;
  display: flex;
  object-fit: contain;
}
.cp-icons {
  padding-top: 15px;
  display: flex;
  justify-content: space-evenly;
}
.cp-icons img {
  cursor: pointer;
}

.image {
  width: 2vw;
}

.lib_image {
    display: block;
    width: 100%;
}

// .lib_image img {
//   width: auto;
//   height: 160px;
// }
</style>
