<template>
  <section class="middle-section">
    <div class="container">
      <div class="result-pg">
        <div class="col-md-12 result-text">

          <div class="button-container">
            <btn class="buy-credits" @click="handleClick">Start Exploring</btn>
            <!-- <div class="brd-crm">Your Brand Profile Summary</div> -->
          </div>


          <div class="blue-text">
            Congratulations on finishing your brand profile! Now, you can position your brand or test your creatives by
            adding concepts to your library.
          </div>
          <div class="line2div">
            Dependent on the type of Mindspeller Asset you choose to map in this
            trial, various mindcoins will be deduced: 10 mindcoins for a word,
            20 for an image or 30 for media
          </div>
        </div>
        <div class="result-wrap">
          <div class="brand-prof-total">
            <h6>
              Your Brand Profile <span>Total mindcoins: 90 (30+30+30)</span>
            </h6>
            <div class="border-box">
              <div class="">
                <div class="rank-div">
                  <div class="rank-bar">
                    <div class="green-colo">&#10003;</div>
                    Rank Archetypes <span>(30 mindcoins)</span>
                    <div class="edit-icon-col cursor-p" title="Edit archetypes" @click="handleEdit('archetypes')">
                      <img src="../../assets/edit-icon.png" />
                    </div>
                  </div>
                  <div class="rank-border-box">
                    <div class="archetype-box">
                      <span class="no-bx">02</span>
                      <img v-if="archetypes.second_archetype" v-bind:src="archetypes.second_archetype.urls.big_large" />
                    </div>
                    <div class="archetype-box">
                      <span class="no-bx">01</span>
                      <img v-if="archetypes.first_archetype" v-bind:src="archetypes.first_archetype.urls.big_large" />
                    </div>
                    <div class="archetype-box">
                      <span class="no-bx">03</span>
                      <img v-if="archetypes.third_archetype" v-bind:src="archetypes.third_archetype.urls.big_large" />
                    </div>
                  </div>
                </div>

                <div class="rank-div">
                  <div class="rank-bar">
                    <div class="green-colo">&#10003;</div>
                    Rank Passions <span>(30 mindcoins)</span>
                    <div class="edit-icon-col cursor-p" title="Edit passions" @click="handleEdit('passions')">
                      <img src="../../assets/edit-icon.png" />
                    </div>
                  </div>
                  <div class="rank-border-box">
                    <div class="archetype-box">
                      <span class="no-bx">02</span>
                      <img v-if="passions.second_passion" v-bind:src="passions.second_passion.urls.thumb_small" />
                    </div>
                    <div class="archetype-box">
                      <span class="no-bx">01</span>
                      <img v-if="passions.first_passion" v-bind:src="passions.first_passion.urls.thumb_small" />
                    </div>
                    <div class="archetype-box">
                      <span class="no-bx">03</span>
                      <img v-if="passions.third_passion" v-bind:src="passions.third_passion.urls.thumb_small" />
                    </div>
                  </div>
                </div>

                <div class="rank-div">
                  <div class="rank-bar">
                    <div class="green-colo">&#10003;</div>
                    Brand Value <span>(30 mindcoins)</span>
                    <div class="edit-icon-col cursor-p" title="Edit brand value" @click="handleEdit('brand')">
                      <img src="../../assets/edit-icon.png" />
                    </div>
                  </div>
                  <div class="rank-border-box blockdiv">
                    <div class="startupbx">
                      Start Up -<span>{{
                        brandValue[1] && brandValue[1].investor === 0
                        ? 'No'
                        : ''
                      }}
                        Influencial Investor</span>
                    </div>
                    <p>
                      {{
                        brandValue[0] && (brandValue[0].brand_description.length > 25) ?
                        brandValue[0].brand_description.slice(0, 25) +
                        '...' : ''
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="option-continue">
            <h6>Choose one option to continue</h6>
            <div class="border-box">
              <div class="blue-box">
                <h5>Continue with free trial</h5>
                <p>
                  Explore Mindspeller using your free mindcoins to load already
                  mapped assets from the Mindspeller library for inspiration
                  and/or benchmarking
                </p>

                <router-link :to="{ name: 'Content Tuner' }" class="btn1">free trial</router-link>
              </div>
              <div class="blue-box">
                <h5>Upgrade to subscription</h5>
                <p>
                  Upload and share your own brand assets to the Mindspeller
                  Library to decode (research), develop (create) and/or drive
                  (monitor) your brand
                </p>

                <router-link :to="{ name: 'Subscription Plans' }" class="btn1">Upgrade</router-link>
              </div>
            </div>
          </div>
          {{ brandValue['investor'] }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Result',
  data() {
    return {
      archetypes: {
        first_archetype: '',
        second_archetype: '',
        third_archetype: '',
      },
      passions: {
        first_passion: '',
        second_passion: '',
        third_passion: '',
      },
      brandValue: [],
    };
  },
  mounted: async function () {
    document.getElementById('mindspeller').style.overflow = 'auto';
    localStorage.removeItem('pageText');
    localStorage.removeItem('pageName');
    try {
      try {
        const archetypes = await axios.get('/api/cas/users/get_archetypes');
        if (archetypes.status === 200) {
          this.archetypes.first_archetype = archetypes.data.first_archetype;
          this.archetypes.second_archetype = archetypes.data.second_archetype;
          this.archetypes.third_archetype = archetypes.data.third_archetype;
        }
      } catch (error) {
        console.log(error);
      }

      try {
        const passions = await axios.get('/api/cas/users/get_passions');
        if (passions.status === 200) {
          this.passions.first_passion = passions.data.first_passion;
          this.passions.second_passion = passions.data.second_passion;
          this.passions.third_passion = passions.data.third_passion;
        }
      } catch (error) {
        console.log(error);
      }

      const brandData = await axios.get('/api/cas/users/get_brand_description');
      if (brandData.status === 200) {
        this.brandValue.push(brandData.data);
      }

      const investData = await axios.get('/api/cas/users/get_investor');
      if (investData.status === 200) {
        this.brandValue.push(investData.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    handleClick() {
      this.$router.push('/content_tuner');
    },

    handleEdit(value) {
      localStorage.setItem('pageText', 'result');
      if (value === 'passions') {
        this.$router.push({
          name: 'Passions',
          // params: {
          //   pageText: 'result',
          // },
        });
      } else if (value === 'archetypes') {
        this.$router.push({
          name: 'Archetypes',
          // params: {
          //   pageText: 'result',
          // },
        });
      } else if (value === 'brand') {
        this.$router.push({
          name: 'Brand Value',
          // params: {
          //   pageText: 'result',
          // },
        });
      }
    },
  },
};
</script>

<style scoped>
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.buy-credits {
  align-items: center;
  padding: 0.6vw 1.1vw;
  background: #0A00FF;
  border-radius: 2vw;
  /* font-family: 'Inter Regular'; */
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 1.5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #ffffff !important;
  margin-right: 1.6vw;
  cursor: pointer;
  text-transform: capitalize;
  transition: all 1s;
}

.buy-credits a {
  color: #ffffff !important;
}

.buy-credits:hover {
  background-color: #92a0b3;
}
</style>