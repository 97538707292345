<template>
   <div class="form-check form-check-inline">
      <q-checkbox v-model="checked" @click="toggle" :label="word" />
    </div>
</template>

<script>
export default {
  props: {
    word: { required: true, type: String },
    initiallyChecked: { default: false, type: Boolean },
  },
  emits: ['wordSelected', 'wordUnselected'],
  data: function() {
    return {
      checked: this.initiallyChecked,
    }
  },
  methods: {
    toggle: function(event) {
      if (this.checked) {
        this.$emit('wordSelected', this.word);
      } else {
        this.$emit('wordUnselected', this.word);
      }
    },
    externalSelect: function() {
      this.checked = true;
    },
    externalUnselect: function() {
      this.checked = false;
    },
  },
}
</script>

