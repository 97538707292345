import * as d3 from "d3";
import * as imageSVG from "../../assets/icons/image-thick.svg"
import * as textSVG from "../../assets/icons/file-text.svg"

export default class CopyScoreChart {

    constructor(dom_element, data, options) {
        this.data;
        this.dom_element;
        this.width;
        this.height;
        this.margin = {top: 20, right: 40, bottom: 20, left: 20};
        this.options = {"show_scores": false};
        this.svg;
        this.x;
        this.y;
        this.xAxis;
        this.yAxis;
        this.tick_duration = 500;
        this.dom_element = $("#" + dom_element)[0];
        this.data = this.loadData(data);
        this.height = this.dom_element.clientHeight - this.margin.bottom - this.margin.top;
        this.width = this.dom_element.clientWidth - this.margin.left - this.margin.right;
        this.options = Object.assign(this.options, options);
        this.initGraph();
    }

    update(data, options) {
        this.options = Object.assign(this.options, options);
        this.data = this.loadData(data);
        this.render();
    }

    initGraph() {
        let self = this;
        let colors = this.getColors();

        // Creating SVG space for graph generation
        self.svg = d3.select(self.dom_element).append("svg")
            .attr("width", self.width + self.margin.left + self.margin.right)
            .attr("height", self.height + self.margin.top + self.margin.bottom)
            .append("g")
            .attr("transform", "translate(" + self.margin.left + "," + self.margin.top + ")");

        // Creating X scale
        self.x = d3.scaleBand()
            .domain(self.data.map(d => {
                return d.name;
            }))
            .range([0, self.width])
            .padding(0.4);


        // Creating Y scale
        self.y = d3.scaleLinear()
            .domain([-10, 10])
            .range([self.height, 0]);

        // Creating X axis
        self.xAxis = d3.axisBottom(self.x)
            .scale(self.x)
            .tickSize([])
            .tickPadding(10);

        // Creating Y axis
        self.yAxis = d3.axisLeft(self.y)
            .ticks(5);

        self.svg.append('g')
            .attr('class', 'x axis')
            .attr('transform', `translate(0, ${self.height})`)
            .call(self.xAxis);
        self.svg.append("g")
            .attr("class", "y axis")
            .call(self.yAxis);

        self.tooltip = d3.select("body").append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);


        // Adding right side chart icons
        // image icon
        self.svg.append("svg:image")
            .attr('x', self.width - 20)
            .attr('y', self.height / 2 + 24)
            .attr('width', 20)
            .attr('height', 24)
            .attr("href", `${textSVG}`)

        // text icon
        self.svg.append("svg:image")
            .attr('x', self.width - 20)
            .attr('y', self.height / 2 - 48)
            .attr('width', 20)
            .attr('height', 24)
            .attr("href", `${imageSVG}`)

        // Horizontal line in the middle of Y axis
        self.svg.append('line')
            .style("stroke", "#000000")
            .style("fill", "#000000")
            .style("stroke-width", 0.2)
            .attr("x1", 0)
            .attr("y1", (this.height / 2 + 1))
            .attr("x2", this.width)
            .attr("y2", (this.height / 2 + 1));

        // Changing the bottom -5 to 5 on Y axis, this could not be done automatically
        // because a range can only go in one way, we can't do [10, 5, 0, 5, 10]
        // and are forced to do [-10, -5, 0, 5, 10]
        $("g .tick text").get(3).textContent = "5"

        // Initial generating of the image rectangle, mainly to bind mouseover
        // tooltips.
        self.svg.selectAll('rect.bar')
            .data(self.data)
            .enter()
            .append('rect')
            .attr('class', 'image-bar')
            .style("display", d => {
                return d.image_score === null ? "none" : null;
            })
            .style("fill", d => "#1f1fff")
            .attr("x", d => self.x(d.name) + self.x.bandwidth()/4.0)
            .attr("width", self.x.bandwidth()/2.0)
            .attr("y", self.height)
            .attr("rx",10)
            .attr("ry",10)
            .attr("height", 0)
            .on("mouseover", function (d) {
                self.tooltip.transition()
                    .duration(200)
                    .style("opacity", .9);
                self.tooltip.html("Image score: " + d.image_score.toFixed(2))
                    .style("left", (d3.event.pageX) + "px")
                    .style("top", (d3.event.pageY - 28) + "px");
            })
            .on("mouseout", function (d) {
                self.tooltip.transition()
                    .duration(500)
                    .style("opacity", 0);
            })
            .transition()
            .duration(self.tick_duration)
            .delay(function (d, i) {
                return i * 150
            })
            .attr("y", d => (self.height / 2))
            .attr('height', d => (self.height / 2) - self.y(d.image_score))

        // Initial generating of the text rectangle, mainly to bind mouseover
        // tooltips.
        self.svg.selectAll('rect.bar')
            .data(self.data)
            .enter()
            .append('rect')
            .attr('class', 'text-bar')
            .style("fill", d => "#34eb8f")
            .attr("x", d => self.x(d.name) + (self.x.bandwidth() / 4.0))
            .attr("width", self.x.bandwidth() / 2.0)
            .attr("y", d => self.height - self.y(d.image_score))
            .attr("rx", 10)
            .attr("ry", 10)
            .attr("height", 0)
            .on("mouseover", function (d) {
                self.tooltip.transition()
                    .duration(200)
                    .style("opacity", .9);
                self.tooltip.html("Text score: " + d.text_score.toFixed(2))
                    .style("left", (d3.event.pageX) + "px")
                    .style("top", (d3.event.pageY - 28) + "px");
            })
            .on("mouseout", function (d) {
                self.tooltip.transition()
                    .duration(500)
                    .style("opacity", 0);uy7888
            })
            .transition()
            .duration(self.tick_duration)
            .delay(function (d, i) {
                return i * 150
            })
            .attr("y", (self.height / 2))
            .attr('height', d => (self.height / 2) - self.y(d.text_score))


        // Optional score display in rectangles
        self.svg.selectAll("text.label")
            .data(self.data)
            .enter()
            .append("text")
            .attr("class", "label")
            .style("display", d => {
                return d.score === null ? "none" : null;
            })
            .attr("x", (d => {
                return self.x(d.name) + (self.x.bandwidth() / 2) - 16;
            }))
            .attr("y", d => self.height)
            .attr("height", 0)
            .transition()
            .duration(self.tick_duration)
            .delay((d, i) => {
                return i * 150;
            })
            .text(d => d3.format(',.2f')(d.score))
            .attr("y", d => self.y(d.score) + .1)
            .attr("dy", "-.5em")
            .style("display", self.options.show_scores ? "block" : "none");
    }

    render() {
        let self = this;
        let colors = this.getColors();

        // update xAxis with new domain
        self.x = d3.scaleBand()
            .domain(self.data.map(d => {
                return d.name;
            }))
            .range([0, self.width])
            .padding(0.4);
        self.xAxis = d3.axisBottom(self.x)
            .scale(self.x)
            .tickSize([])
            .tickPadding(10);
        self.svg.select('g.x.axis')
            .transition()
            .duration(self.tick_duration)
            .ease(d3.easeLinear)
            .call(self.xAxis);

        // Update rectangle bars
        let image_bars = self.svg.selectAll('rect.image-bar').data(self.data);

        // Updating image bar
        image_bars.enter()
            .append('rect')
            .attr('class', 'image-bar')
            // If there's no data, we don't show the rectangle
            .style("display", d => {
                if (d.image_score == null || d.image_score == 0) {
                    return "none"
                } else {
                    return "initial"
                }
            })
            .style("fill", d => "#CCDDFC")
            .attr("x", d => self.x(d.name))
            .attr("width", self.x.bandwidth())
            .attr("y", (self.height / 2))
            .attr("height", 0)
            .transition()
            .duration(self.tick_duration)
            .delay(function (d, i) {
                return i * 150
            })
            // "y" is where rectangle starts, "height" is it's height
            // the graph extrudes downwards from Y offset.
            .attr("y", d => (self.height / 2) - self.y(d.image_score))
            .attr("height", d => {
                return self.y(d.image_score);
            })

        image_bars.transition()
            // If there's no data, we don't show the rectangle
            .style("display", d => {
                if (d.image_score == null) {
                    return "none"
                } else {
                    return "initial"
                }
            })
            .duration(self.tick_duration)
            .ease(d3.easeLinear)
            .attr("y", d => self.y(d.image_score))
            .attr('height', d => (self.height / 2) - self.y(d.image_score))

        // Not sure what this does, did not have any apparent effect on the graph
        image_bars.exit()
            .transition()
            .duration(self.tick_duration)
            .ease(d3.easeLinear)
            .attr('height', d => self.y(d.image_score) - self.y(0))
            .remove();


        let text_bars = self.svg.selectAll('rect.text-bar').data(self.data);

        text_bars.enter()
            .append('rect')
            .attr('class', 'text-bar')
            .style("fill", d => "#FCCCE4")
            .attr("x", d => self.x(d.name))
            .attr("width", self.x.bandwidth())
            .attr("y", d => (self.height / 2))
            .attr("height", 0)
            .transition()
            .duration(self.tick_duration)
            .delay(function (d, i) {
                return i * 150
            })
            .attr("y", d => (self.height / 2))
            .attr("height", d => {
                return self.y(d.text_score);
            })

        text_bars.transition()
            .style("display", d => {
                if (d.text_score == null || d.text_score == 0) {
                    return "none"
                } else {
                    return "initial"
                }
            })
            .duration(self.tick_duration)
            .ease(d3.easeLinear)
            .attr("y", d => (self.height / 2))
            .attr('height', d => (self.height / 2) - self.y(d.text_score))

        text_bars.exit()
            .transition()
            .duration(self.tick_duration)
            .ease(d3.easeLinear)
            .attr('height', d => self.height - self.y(d.text_score) - self.y(0))
            .remove();


        // update value labels
        let valueLabels = self.svg.selectAll('text.label').data(self.data);

        valueLabels
            .enter()
            .append("text")
            .attr("class", "label")
            .style("display", d => {
                return d.score === null ? "none" : null;
            })
            .attr("x", (d => {
                return self.x(d.name) + (self.x.bandwidth() / 2) - 16;
            }))
            .attr("y", d => self.height)
            .attr("height", 0)
            .transition()
            .duration(self.tick_duration)
            .delay((d, i) => {
                return i * 150;
            })
            .text(d => d3.format(',.2f')(d.score))
            .attr("y", d => self.y(d.score) + .1)
            .attr("dy", "-.5em")
            .style("display", self.options.show_scores ? "block" : "none");

        valueLabels
            .transition()
            .duration(self.tick_duration)
            .ease(d3.easeLinear)
            .attr("y", d => self.y(d.score) + .1)
            .attr("dy", "-.5em")
            .tween("text", function (d) {
                const prev = this.textContent || "0";
                let i = d3.interpolateNumber(prev, d.score);
                return function (t) {
                    this.textContent = d3.format(',.2f')(i(t));
                };
            })
            .style("display", self.options.show_scores ? "block" : "none");


        valueLabels
            .exit()
            .transition()
            .duration(self.tick_duration)
            .ease(d3.easeLinear)
            .remove();
    }

    getColors() {
        const colors = {};
        // const color_scale = d3.scaleOrdinal(d3.schemeSet3);
        let color_palette = ["#CCDDFC", "#FCCCE4", "#FCEDCC", "#D9FCD9"];

        this.data.forEach((text, i) => {
            colors[text.name] = color_palette[i]
        });
        return colors;
    }

    loadData(data) {
        data.forEach((d, i) => {
            d.rank = i;
            d.name = d.name.toUpperCase();
        });
        return data;
    }


}
