<template>
<div>
  <strong>{{ $t("message.inspiration_for_alternative_ias") }}</strong>
  <div class="word-list scroll">
    <ul>
      <word-checkbox
          v-for="d in data"
          :ref="refFn"
          :word="d.word"
          @wordSelected="onWordSelected"
          @wordUnselected="onWordUnselected">
      </word-checkbox>
    </ul>
  </div>
</div>
</template>

<script>
import WordCheckbox from "@/components/common/WordCheckbox.vue";

export default {
  components: { WordCheckbox },
  props: {
    data: { required: true, type: Object }
  },
  data: function() {
    return {
      wordCheckboxes: new Array,
    }
  },
  emits: ['wordSelected', 'wordUnselected'],
  methods: {
    onWordSelected: function(word) {
      this.$emit('wordSelected', word);
    },
    onWordUnselected: function(word) {
      this.$emit('wordUnselected', word);
    },
    externalSelectWord: function(word) {
      const wordCheckbox = this.wordCheckboxes.find(w => w.word === word);
      wordCheckbox.externalSelect();
    },
    externalUnselectWord: function(word) {
      const wordCheckbox = this.wordCheckboxes.find(w => w.word === word);
      wordCheckbox.externalUnselect();
    },
    refFn: function(wordCheckbox) {
      this.wordCheckboxes.push(wordCheckbox);
    },
  },
}
</script>
<style scoped lang="scss">
.word-list {
  display: flex;
  flex-wrap: wrap;
  max-height: 50vh;
  overflow-y: scroll;
  padding: 5px;
      // overflow-x: hidden;
}
.word-list label {
  flex-basis: 100%;
  margin-top: 5px;
}
.word-list ul {
  padding: 0;
  list-style: none;
}

.q-checkbox__label.q-anchor--skip {
    padding-left: 0;
}


.form-check {
    padding-left: 0;
}
.form-check-inline {
    padding-left: 2px;
    display: block;
}
</style>
