import * as d3 from 'd3-4';
import $ from 'jquery';

var self = null;

export default class PathFinderChart {

    constructor(data, mas1, mas2, el, options, controls_el, vue_vm) {
        self = this;
        this.originalData = data;
        this.vue_vm = vue_vm;
        this.colors = d3.scaleOrdinal().range(d3.quantize(d3.interpolateRainbow, 25));
        this.data = {};
        this.selected_words = {};
        this.cfg = {
            margin_top: 0,
            margin_right: 0,
            margin_bottom: 0,
            margin_left: 0,
            inner_margin: 40,
        }

        this.data = {"data": data, "mas1": mas1, "mas2": mas2, "el": el, "options": options};
        //Put all of the options into a variable called cfg
        if ('undefined' !== typeof options) {
            for (let i in options) {
                if ('undefined' !== typeof options[i]) {
                    this.cfg[i] = options[i];
                }
            }
        }

        // init container
        let container = $("#" + el);
        // clear container
        container.empty();

        // set the dimensions and margins of the graph
        this.margin = {
            top: this.cfg.margin_top,
            right: this.cfg.margin_right,
            bottom: this.cfg.margin_bottom,
            left: this.cfg.margin_left
        };
        this.width = container.width() - this.margin.left - this.margin.right;
        this.height = container.height() - this.margin.top - this.margin.bottom;

        const chart_width = this.width;

        const svg = d3.select('#' + el).append("svg")
            .attr("height", this.height)
            .attr("width", this.width)
            .attr("viewBox", "0 0 " + this.width + " " + this.height)
            .attr('preserveAspectRatio', 'none')
            .style("font", this.cfg.font_size + "px sans-serif");

        const g = svg.append("g")
            .attr("transform", `translate(${this.width / 2},${this.height / 2})`);

        var getColor = this.getColor;

        //Read the data
        data.forEach(d => {
            if (d[5] === undefined) {
                d.push(false)
            }
        });
        let words = data.map(x => x[0]);
        let deltas = data.map(x => x[4]);
        let is_highlighted = false;


        // ---------------------------//
        //       AXIS  AND SCALE      //
        // ---------------------------//

        let axis_width = this.width - (2 * 22);
        let axis_height = this.height - (2 * this.cfg.inner_margin);


        // GRADIENT
        // Create the svg:defs element and the main gradient definition.
        var svgDefs = svg.append('defs');

        var mainGradient = svgDefs.append('radialGradient')
            .attr('id', 'mainGradient')
            .attr('fx', '100%')
            .attr('fy', '0%')
            .attr('cx', '100%')
            .attr('cy', '0%')
            .attr('r', '100%')


        // Create the stops of the main gradient. Each stop will be assigned
        // a class to style the stop using CSS.
        mainGradient.append('stop')
            .attr('class', 'stop-left')
            .attr('offset', '0');

        mainGradient.append('stop')
            .attr('class', 'stop-right')
            .attr('offset', '1');

        // Use the gradient to set the shape fill, via CSS.
        svg.append('rect')
            .classed('filled', true)
            .attr('x', 22)
            .attr('y', this.cfg.inner_margin)
            .attr('width', axis_width)
            .attr('height', axis_height);

        // QUADRANTS
        svg.append("line")
            .attr("x1", 22)
            .attr("y1", (axis_height / 2) + this.cfg.inner_margin)
            .attr("x2", axis_width + 22)
            .attr("y2", (axis_height / 2) + this.cfg.inner_margin)
            .attr("stroke-width", 1)
            .attr("stroke", "black");

        svg.append("line")
            .attr("x1", (axis_width / 2) + 22)
            .attr("y1", this.cfg.inner_margin)
            .attr("x2", (axis_width / 2) + 22)
            .attr("y2", axis_height + this.cfg.inner_margin)
            .attr("stroke-width", 1)
            .attr("stroke", "black");


        // Add X axis
        var x = d3.scaleLinear()
            .domain([0, 10])
            .range([0, axis_width]);

        // Add Y axis
        var y = d3.scaleLinear()
            .domain([0, 10])
            .range([axis_height, 0]);

        // Add a scale for bubble size
        var z = d3.scaleSqrt()
            .domain([0, Math.max(...deltas)])
            .range([2, 15]);


        var toggle = this.toggleWord;
        var toggleCheckbox = this.toggleCheckbox;

        // ---------------------------//
        //      TOOLTIP               //
        // ---------------------------//
        var text = svg.selectAll("text")
            .data(data)
            .enter()
            .append("text")
            .attr("id", function (d, i) {
                return "tooltip-" + (i + 1);
            })

        //Add SVG Text Element Attributes
        var textLabels = text
            .attr("x", function (d) {
                let x_loc = x(d[2]);
                if (chart_width < x_loc + 100) {
                    x_loc = x_loc - 50
                }
                return x_loc + 40;
            })
            .attr("y", function (d) {
                return y(d[3]) + 45;
            })
            .text(function (d) {
                return d[1];
            })
            .attr("text-anchor", function (d) {
                let anchor = "start";
                let x_loc = x(d[2]);
                if (chart_width < x_loc + 100) {
                    anchor = "end"
                }
                return anchor;
            })
            .attr("font-size", "20px")
            .attr("font-weight", "bold")
            .style("opacity", 0)
            .style("fill", function (d) {
                return getColor(d[1]);
            })

        // ---------------------------//
        //       CIRCLES              //
        // ---------------------------//

        // Add dots
        svg.append('g')
            .selectAll("dot")
            .data(data)
            .enter()
            .append("circle")
            .attr("id", function (d, i) {
                return "circle-" + (i + 1);
            })
            .attr("class", function (d) {
                return "bubbles " + d[1]
            })
            .attr("cx", function (d) {
                return x(d[2]) + 20;
            })
            .attr("cy", function (d) {
                return y(d[3]) + 40;
            })
            .attr("r", function (d) {
                return z(d[4]);
            })
            .style("fill", function (d) {
                let abc = getColor(d[1]);
                return abc;
            })
            .style("opacity", 0.18)
            // .on("mouseover", tipMouseover)
            // .on("mouseout", tipMouseout)
            .on("click", function (d, i) {
                toggle(i + 1);
                toggleCheckbox(d[1]);
            })


        // Add X axis
        svg.append("g")
            .attr("transform", "translate(" + 22 + "," + ((axis_height) + this.cfg.inner_margin) + ")")
            .call(d3.axisBottom(x).ticks(10));

        // Add X axis label:
        svg.append("text")
            .attr("text-anchor", "end")
            .attr("x", this.width - 22)
            .attr("y", this.height - 10)
            .text(mas1)
            .attr("transform", "translate(5, 5)");

        // Add Y axis
        svg.append("g")
            .attr("transform", "translate(" + ((axis_width * 0) + 22) + "," + this.cfg.inner_margin + ")")
            .call(d3.axisLeft(y));

        // Add Y axis label:
        svg.append("text")
            .attr("x", 0)
            .attr("y", 12)
            .text(mas2)
            .attr("text-anchor", "start")
            .attr("transform", "translate(0, 5)");



        data.forEach((d, index) => {
            if (d[5]) {
                d3.select("#circle-" + (index + 1)).style("opacity", .9);
                d3.select("#tooltip-" + (index + 1)).style("opacity", .9);
            }
        });

        // hide tick labels
        $(".tick text").hide();
        
        self.initControls(data, controls_el);
    }


    initControls(data, controls_el) {
        let pathfinder_controls = $(controls_el);
        pathfinder_controls.empty();
        let idx = 1;
        data.forEach(function (word) {
            let control_item = self.controlItem(word[0], 'control-list', idx);
            pathfinder_controls.append(control_item);
            idx++;
        })
    }
    
    controlItem(keyword, list, idx) {
        keyword = self.htmlspecialchars(keyword);
        return "<label id='" + keyword + "-control' class='checkbox keyword-list-item control-" + idx + "' data-index='" + idx + "'>" +
            "<input type='checkbox' value='" + keyword + "' name='" + list + "-options' id='" + keyword + "-checkbox'> " +
            "<span>" + keyword + "</span> " +
            "</label>"
    }
    
    htmlspecialchars(str) {
        return str.replace('&', '&amp;').replace('"', '&quot;').replace("'", '&#039;').replace('<', '&lt;').replace('>', '&gt;');
    }

    getColor(word) {
        return self.colors(word);
    }

    toggleWord(index) {
        d3.select("#circle-" + index)
            .transition()
            .duration(220) // ms
            .style("opacity", function (d) {
                d[5] = !d[5];
                return d[5] ? "1" : "0.18";
            })
        self.toggleTooltip(index);
    }

    toggleCheckbox(word) {
        self.vue_vm.toggleWord(word);
    }
    
    externalWordToggle(word) {
        let wordIndex = -1;
        for (const [index, d] of self.originalData.entries()) {
            if (d[0] === word) {
                wordIndex = index;
                break;
            }
        }
        if (wordIndex > -1) {
            self.toggleWord(wordIndex + 1);
        }
    }

    toggleTooltip(index) {
        let d = d3.select("#circle-" + index).data()[0];
        let tooltip = d3.select("#tooltip-" + index);
        if (d[5]) {
            tooltip.transition()
                .duration(220) // ms
                .style("opacity", .9) // started as 0!
        } else {
            tooltip.transition()
                .duration(220) // ms
                .style("opacity", 0);
        }
    }
}
