<template>
  <h4>Competitors</h4>
  <div class="wt-box-col">
    <cp-asset-picker
      ref="asset-picker"
      @bothAssetsSelected="onBothAssetsSelected"
    >
    </cp-asset-picker>
   </div>
  <RightSideBar path="association-wheel" />
</template>

<script>
import RightSideBar from '../common/RightSideBar.vue';
import CpAssetPicker from './CpAssetPicker.vue';

export default {
  components: { CpAssetPicker, RightSideBar },
  emits: ['generate'],
  data: function() {
    return {
      assets_selected: false,
      first_asset: {},
      second_asset: {},
    };
  },
  methods: {
    reset: function() {
      this.$refs['asset-picker'].reset();
    },
    manuallyAddAssetAndPositioning: function(asset_and_positioning) {
      this.$refs['asset-picker'].onSelectedAsset1(asset_and_positioning);
    },
    onBothAssetsSelected: function(first_asset, second_asset) {
      this.first_asset = first_asset;
      this.second_asset = second_asset;
      // this.assets_selected = true;
      this.$emit('generate', this.first_asset, this.second_asset);
    },
    generateChart: function() {
      if (this.assets_selected) {
        this.$emit('generate', this.first_asset, this.second_asset);
      }
    },
    getClass(){
        return {
            'btn3': this.assets_selected,  
            'disabled-btn': !this.assets_selected
            }
    }
  },
};
</script>
<style scoped style="scss">
.cp-sidebar {
  background-color: #f7f7f7;
  border-radius: 0 0 46px 0;
  height: 100%;
  width: 280px;
  -moz-transition: 400ms transform ease;
  -o-transition: 400ms transform ease;
  -webkit-transition: 400ms transform ease;
  transition: 400ms transform ease;
}
.cp-sidebar .inner {
  padding: 40px 10px 20px 30px;
  margin-top: 50px;
}

.cp-sidebar .inner .steps {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cp-sidebar .inner .steps li {
  margin: 0 0 40px 0;
}
.step-label {
  font-size: 0.75rem;
  line-height: 1.0625rem;
  display: block;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.sidebar .steps .link {
  font-size: 0.875rem;
  line-height: 1.0625rem;
  cursor: pointer;
  color: #1f1fff;
  position: relative;
  display: inline-block;
}

.wt-box-col {
  padding: 1vw 0vw 2vw ;
}
</style>
