<template>
  <!-- select your assets -->
  <h4>stories</h4>
  <div class="wt-box-col">
    <div class="col-md-12 choose-libra story-selec-drop">
      <!-- <label>{{ $t('message.select_an_asset') }}</label> -->
      <single-asset @selectedAsset="onSelectedAsset" ref="single-asset">
      </single-asset>
    </div>
    <div class="col-md-12 choose-libra story-selec-drop">
      <label>Enter the memory, feeling or keyword you want to evoke with your concepts</label>
      <single-ia-picker ref="ia-picker" @iaSelected="onIaSelected">
      </single-ia-picker>
    </div>
  </div>


  <div class="assets-col-div">
    <RightSideBar />
  </div>

  <!-- generate button -->
  <!-- <div class="btn-col">
    <button v-bind:class="getClass()" @click="generateStory"
      :title="!generateAllowed ? $t('message.select_asset_and_ia') : null" :disabled="!generateAllowed">
      {{ $t('message.generate') }}
    </button>
  </div> -->
</template>

<script>
import SingleIaPicker from '@/components/target_pickers/sidebar_pickers/SingleIaPicker.vue';
import SingleAsset from '@/components/target_pickers/assets/SingleAsset.vue';
import RightSideBar from '../common/RightSideBar.vue';

export default {
  components: { SingleIaPicker, SingleAsset, RightSideBar },
  emits: ['generate', 'generateStory'],
  data: function () {
    return {
      selected_asset: {},
      ia_is_selected: false,
      asset_is_selected: false,
      current_select: null,
      current_asset: null,
    };
  },
  computed: {
    generateAllowed: function () {
      return this.ia_is_selected && this.asset_is_selected;
    },
  },
  methods: {
    reset: function () {
      this.selected_asset = {};
      this.$refs['single-asset'].reset();
      this.$refs['ia-picker'].reset();
      this.ia_is_selected = false;
      this.asset_is_selected = false;
    },

    generateStory: function () {
      this.$emit('generateStory');
    },

    generate: function () {
      const selected_ia = this.$refs['ia-picker'].selected_intended_association;
      this.$emit('generate', this.selected_asset, selected_ia);
    },

    onSelectedAsset: function (asset) {
      this.selected_asset = asset;
      this.$refs['single-asset'].setAsset(asset);
      this.asset_is_selected = true;
      this.current_asset = asset.id;
      if (this.current_asset) {
        this.current_asset = asset.id;
        if (this.ia_is_selected && this.asset_is_selected) {
          this.generate();
        }
      } else {
        if (this.current_asset !== asset.id) {
          this.current_asset = asset.id;
          if (this.ia_is_selected && this.asset_is_selected) {
            this.generate();
          }
        }
      }
    },
    /*
    Whenever IAs change in some way the sidebar needs to figure out if
    clicking the generate button is allowed.
    */
    onIaSelected: function (selected_ia) {
      this.ia_is_selected = Object.keys(selected_ia).length > 0;
      if (!this.current_select) {
        this.current_select = selected_ia.word_id;
        if (this.ia_is_selected && this.asset_is_selected) {
          this.generate();
        }
      } else {
        if (this.current_select !== selected_ia.word_id) {
          this.current_select = selected_ia.word_id;
          if (this.ia_is_selected && this.asset_is_selected) {
            this.generate();
          }
        }
      }
    },

    getClass() {
      return {
        btn3: this.generateAllowed,
        btn5: !this.generateAllowed,
      };
    },
  },
};
</script>

<style scoped>
.col-md-12.choose-libra {
  margin-top: 1vw;
  margin-bottom: 1.5vw;
}
</style>

