<template>
  <section class="middle-section">
    <div class="container">
      <payment-form v-if="paymentMethodListEmpty" :elements="elements" :price="price"
        :paymentMethodListEmpty="paymentMethodListEmpty" :selectedPaymentMethod="selectedPaymentMethod" :stripe="stripe"
        :selectedLicense="JSON.parse(this.$props.selectedLicense)" :newCustomer="new_customer"></payment-form>
      <saved-cards v-else-if="paymentMethodListEmpty === false" @paymentMethodListEmpty="onPaymentMethodListEmpty"
        @paymentMethodChange="onPaymentMethodChange" :paymentMethodListEmpty="paymentMethodListEmpty"
        :selectedPaymentMethod="selectedPaymentMethod" :selectedLicense="JSON.parse(this.$props.selectedLicense)"
        :price="price"></saved-cards>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import SavedCards from './saved_cards.vue';
import PaymentForm from './payment_form.vue';

export default {
  name: 'PaymentMethod',
  components: {
    SavedCards,
    PaymentForm,
  },
  data: function () {
    return {
      price: null,
      selectedLicense: null,
      selectedPaymentMethod: null,
      paymentMethodListEmpty: null,
      elements: {},
      stripe: {},
      isButtonDisabled: false,
      new_customer: null
    };
  },
  props: {
    selectedLicense: {
      required: true,
      type: String,
    },
  },
  mounted: async function () {
    if (!this.$props.selectedLicense) {
      if (this.$store.state.current_license.monthly_fee > 0) {
        this.$router.push({
          name: 'Upgrade Screen',
        });
      } else {
        this.$router.push({
          name: 'Subscription Plans',
        });
      }
    }
    document.getElementById('mindspeller').style.overflow = 'auto';
    this.stripe = Stripe(
      // "pk_test_aFEbGFfORcuEnEuR5BWynEYx"
      this.$store.getters.getEnvVar('VUE_APP_STRIPE_PUBLISHABLE_KEY')
    );
    this.selectedLicense = JSON.parse(this.$props.selectedLicense);
    try {
      if (!this.$store.state.current_org.stripe_customer_id) {
        const new_user = await axios.post('/payment/create_registration_setup', {
          email: this.$store.state.current_user.email,
          name: this.$store.state.current_user.organisation.name,
        });

        if (new_user.status === 200) {
          this.new_customer = new_user.data;
        }

        await axios.post('/api/cas/org/update', {
          payment_type: this.new_customer.client_secret.payment_method_types[0],
          stripe_customer_id: this.new_customer.customer.id,
        });

        await this.$store.dispatch('load_user_data');

      }
      // here we don't distinguish the different payment options, we do it in the backend but we need add three more fields
      const items = [
      {
        id: this.selectedLicense,
        coupon: '',
        org_id: this.$store.state.current_org.id,
        license_id: this.selectedLicense.id,
        customer_id: this.$store.state.current_org.stripe_customer_id,
        amount:this.selectedLicense.amount,
        mindcoins:this.selectedLicense.mindcoins,
        pay_as_you_go:this.selectedLicense.pay_as_you_go,
      },
    ];

      var clientSecret = '';
      var price = '';

      // here we don't distinguish different payment options either, we do it in the backend
      try {
          const payment_intent = await axios.post('/payment/create_payment_intent_new', items);
          clientSecret = payment_intent.data.clientSecret;
          price = payment_intent.data.oldPrice;
        } catch (error) {
          console.error(error);
        }

      this.price = price;
      var options = {
        clientSecret: clientSecret,
        appearance: {
          theme: 'stripe',
          rules: {
            '.Label': {
              color: '#405261',
              fontWeight: '400',
              fontSize: '3.8vw',
              marginBottom: '15px',
              lineHeight: '20px',
              opacity: '0.9',
              transition: 'none',
            },
            '.Input': {
              marginBottom: '2.5vw',
            },
          },
        },
      };

      this.elements = this.stripe.elements(options);
      const payment_result = await axios.post('/payment_methods/list', {
        customer_id: this.$store.state.current_org.stripe_customer_id,
      });

      if (payment_result.status === 200) {
        if (payment_result.data.data.length > 0) {
          return (this.paymentMethodListEmpty = false);
        } else {
          this.paymentMethodListEmpty = true;
        }
      } else {
        this.paymentMethodListEmpty = true;
      }
    } catch (error) {
      this.paymentMethodListEmpty = true;
    }
  },
  methods: {
    onPaymentMethodChange: function (payment_method_id) {
      this.selectedPaymentMethod = payment_method_id;
    },

    onPaymentMethodListEmpty: async function () {
      this.paymentMethodListEmpty = true;
      this.license_extension_active = false;
    },
  },
  watch: {
    clientSecret: function (value) {
      this.$emit('clientSecretChange', value);
    },
    customerID: function (value) {
      this.$emit('customerIDChange', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.pull-left {
  margin: auto auto auto 0 !important;
}

.settings-workspace {
  display: flex;
  flex-flow: column;
}

.settings-content-block {
  display: flex;
  flex-flow: column;
  align-items: baseline;
  margin-top: 15px;
  width: 100%;
  position: relative;
}

.settings-content-block p {
  margin: 0;
}

.settings-title {
  font-size: 1.3rem;
  color: $mdspblue_ui;
}

.settings-secondary {
  font-size: 1rem;
  color: $mdspblue_ui;
}

.purchase-summary {
  display: flex;
  margin-top: 15px;
  width: 100%;
  justify-content: center;
  flex: 1;
}

.purchase-summary div,
form {
  margin: 0px 5px;
}

.purchase-summary .dialog {
  justify-content: flex-end;
  display: flex;
  flex-flow: column;
  align-items: center;
  flex: 1;
}

.purchase-summary .dialog div {
  width: 100%;
}

.payment-ui {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.license-upgrade-ui {
  display: flex;
  width: 100%;
}

.payment-form {
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}

.payment-form #payment-element {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  flex: 1;
}

.main-block {
  flex: 1;
}

.override-top-margin {
  margin-top: 0;
}
</style>
