<template>
  <figure class="highcharts-figure">
    <div id="container"></div>
  </figure>
</template>

<script>
export default {
  name: 'DashboardChart',
  props: {
    dataSet: {
      type: String,
      required: true,
    },
  },
  mounted: function () {
    Highcharts.chart('container', {
      chart: {
        polar: true,
      },

      title: {
        text: '',
      },

      pane: {
        startAngle: 0,
        endAngle: 360,
      },

      plotOptions: {
        column: {
          pointPadding: 0,
          groupPadding: 0,
        },
      },
      tooltip: {
        outside: true,
      },

      xAxis: {
        tickmarkPlacement: 'off',
        lineWidth: 1,
        type: 'category',
        labels: {
          y: -75,
          x: -20,
          distance: -40,
          useHTML: true,
          outside: false,
          align: 'center',
          allowOverlap: true,
        },
        values: {
          min: 0,
          max: 10,
          useHTML: true,
          align: 'center',
        },
      },
      yAxis: {
        tickInterval: 0,
        min: 0,
        max: 10,
        offset: 10,
      },

      legend: {
        layout: 'vertical',
        align: 'center',
        verticalAlign: 'bottom',
        floating: true,
      },

      series: [
        {
          keys: ['y', 'name'],
          type: 'column',
          name: 'Your target brand image',
          data: this.$props.dataSet,
          pointPlacement: 'between',
          color: '#d6e4b0',
          grouping: false, 
          events: {
            legendItemClick: function () {
                return false; 
            }
        }
        },
      
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: false
          },
        },
      },
    }
    );
  },
};
</script>

<style scoped>
.highcharts-figure,
.highcharts-data-table table {
  min-width: 320px;
  max-width: 660px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: ce nter;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

#container {
  overflow: visible !important;
  height: 568px;
  width: 568px;
  margin: 0 auto;
}
</style>
