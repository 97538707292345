<template>
  <div class="wt-box-col rightBar">
    <div class="col-md-12 choose-libra">
      <h6>Load Brand Concepts</h6>
      <div class="divider"></div>
      <asset-checkbox-picker ref="asset-picker" :show_positionings="true" @assetAdded="onAssetChanged"
        @assetRemoved="onAssetChanged">
      </asset-checkbox-picker>
    </div>
  </div>

  <div class="wt-box-col rightBar" style="margin-top: 20px;">
    <div class="col-md-12 choose-libra">
      <h6>Load Target Association(s)</h6>
      <div class="divider"></div>
      <multiple-ia-picker ref="ia-picker" @wordSelected="onIaChanged" @wordUnselected="onIaChanged"
        @wordRemoved="onIaChanged" :slogan-selector="true"> 
      </multiple-ia-picker>
    </div>
  </div>
</template>

<script>
import MultipleIaPicker from '@/components/target_pickers/sidebar_pickers/MultipleIaPicker.vue';
import AssetCheckboxPicker from '@/components/target_pickers/sidebar_pickers/AssetCheckboxPicker_CC.vue';

export default {
  components: { MultipleIaPicker, AssetCheckboxPicker },
  emits: ['generate', 'reset'],
  data: function () {
    return {
      ia_is_selected: false,
      asset_is_selected: false,
      selected_ias: [],
      selected_assets: [],
    };
  },
  computed: {
    generateAllowed: function () {
      return this.ia_is_selected && this.asset_is_selected;
    },
  },
  watch: {
    selected_ias() {
      this.checkGenerate();
    },
    selected_assets() {
      this.checkGenerate();
    }
  },
  methods: {
    reset: function () {
      this.$refs['asset-picker'].reset();
      this.$refs['ia-picker'].reset();
      this.ia_is_selected = false;
      this.asset_is_selected = false;
    },
    manuallyAddAssetAndPositioning: function(asset_and_positioning) {
      this.$refs['asset-picker'].onAddAsset(asset_and_positioning);
    },
    checkGenerate() {
      if (this.ia_is_selected && this.asset_is_selected) {
        this.generate(this.selected_ias, this.selected_assets);
      }
      if (!this.ia_is_selected && !this.asset_is_selected){
        this.$emit('reset')
      }
    },
    generate: function (selected_ias, selected_assets) {
      this.$emit('generate', selected_ias, selected_assets);
    },
    /*
    Whenever the list of selected IAs changes the sidebar needs to figure
    out if clicking the generate button is allowed.
    */
    onIaChanged: function () {
      this.selected_ias = [...this.$refs['ia-picker'].intended_associations];
      this.ia_is_selected =
        this.$refs['ia-picker'].intended_associations.size > 0;
    },
    /*
    Whenever the list of selected assetss changes the sidebar needs to
    figure out if clicking the generate button is allowed.
    */
    onAssetChanged: function () {
      this.selected_assets = [...this.$refs['asset-picker'].selected_assets_and_positionings];
      this.asset_is_selected =
        this.$refs['asset-picker'].selected_assets_and_positionings.size > 0;
    },
  },
};
</script>

<style scoped>
.divider {
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
  margin-bottom: 20px;
}

.rightBar .wt-box-col {
  text-align: center !important;
}
</style>
