const settings_translation = {
    en: {
        message: {
            profile: 'Profile',
            team: 'Team',
            mindcoins_and_license: 'Mindcoins & License',
            receive_asset_updates: 'Receive e-mail notifications about your asset mapping status',
            receive_product_updates: 'Receive communication about product updates and news',
            user_updated: 'User successfully updated!',
            error_user_update: 'An error occured while updating the user.',
            password_updated: 'Password successfully changed!',
            old_password_incorrect: 'The old password is incorrect.',
            change_password: 'Change password',
            old_password: 'Old password',
            new_password: 'New password',
            verify_password: 'Verify password',
            passwords_dont_match: 'Passwords do not match',
            change_your_password: 'Change your password',
            team_overview: 'Team overview',
            invite_a_team_member: 'Invite a team member',
            provide_user_details: 'Provide user details to send an invitation e-mail.',
            user_type: 'User type',
            send_invitation: 'Send invitation',
            username_less_than_3_chars: 'Username must be at least 3 characters long.',
            select_user_category: 'Please select a user category.',
            invitation_sent: 'Invitation sent!',
            invitation_error: 'There was an error while sending the invite.',
            edit_a_team_member: 'Edit a team member',
            mindcoin_use: 'You can use your mindcoins to map concepts and add Mindspeller Library concepts to your library.',
            your_current_plan: 'Your current plan',
            change_plan: 'Change plan',
            preview_all_ias: 'Preview all intended associations',
            all_ias: 'All intended associations',
            download_all_ias: 'Download all intended associations',
            next_renewal: "Next renewal at {renewal_date}.",
            license_will_expire_on: "License will expire on {renewal_date}.",
            your_license_will_extend_into: "Your license will be extended to {license_name} license.",
            plan_name: 'Name',
            plan_credits: 'mindcoins',
            plan_monthly_fee: 'Monthly fee (EUR)',
            automatically_extend_license: 'Automatically extend license',
            pay_now: 'Pay now',
            payment_succeeded: 'Payment succeeded, new license will be assigned briefly.',
            payment_failed: 'An unexpected error occurred. Error type: ',
            payment_unexpected_fail: 'There was an unexpected problem with your transaction.',
            payment_method_deleted: 'Payment method successfully deleted.',
            payment_method_favourited: 'Default payment method succesfully updated.',
        },
    },
    nl: {
        message: {
            profile: 'Profiel',
            team: 'Team',
            mindcoins_and_license: 'Mindcoins & Licentie',
            receive_asset_updates: 'Ontvang e-mail notificaties m.b.t. je asset mapping status', 
            receive_product_updates: 'Ontvang communicatie m.b.t. product updates en nieuws', 
            user_updated: 'Gebruiker succesvol bijgewerkt!',
            error_user_update: 'Een fout werd vastgesteld tijdens het updated van de gebruiker.',
            password_updated: 'Wachtwoord veranderd!',
            old_password_incorrect: 'Het oude paswoord is incorrect.',
            change_password: 'Verander wachtwoord',
            old_password: 'Oud wachtwoord',
            new_password: 'Nieuw wachtwoord',
            verify_password: 'Verifieer wachtwoord',
            passwords_dont_match: 'Wachtwoorden komen niet overeen',
            change_your_password: 'Wijzig je wachtwoord',
            team_overview: 'Team overzicht',
            invite_a_team_member: 'Nodig een teamlid uit',
            provide_user_details: 'Geef gebruikersgegevens op om een uitnodigingsmail te verzenden.',
            user_type: 'Gebruikerstype',
            send_invitation: 'Verstuur',
            username_less_than_3_chars: 'Gebruikersnaam moet minstens 3 karakters lang zijn.', 
            select_user_category: 'Gelieve een gebruiks categorie te selecteren.', 
            invitation_sent: 'Uitnodiging verstuurd!', 
            invitation_error: 'Er trad een fout op bij het verzenden van de uitnodiging. Probeer opnieuw of contacteer uw customer success manager.',
            edit_a_team_member: 'Bewerk een teamlid',
            mindcoin_use: 'Je kan je Mindcoins gebruiken om concepten te mappen en Store concepten toe te voegen aan jouw Library.',
            your_current_plan: 'Jouw huidige plan',
            change_plan: 'Wijzig plan',
            preview_all_ias: 'Preview alle Gewenste Associaties', 
            all_ias: 'Alle Gewenste Associaties', 
            download_all_ias: 'Download alle Gewenste Associaties',
            next_renewal: "Volgende verlenging op {renewal_date}.",
            license_will_expire_on: "Licentie verloopt op {renewal_date}.",
            your_license_will_extend_into: "Uw licentie wordt verlengd tot {license_name} licentie.",
            plan_name: 'Naam',
            plan_credits: 'Credits',
            plan_monthly_fee: 'Maandelijkse kosten (EUR)',
            automatically_extend_license: 'Licentie automatisch verlengen',
            pay_now: 'Nu betalen',
            payment_succeeded: 'Betaling geslaagd, nieuwe licentie wordt kortstondig toegewezen.',
            payment_failed: 'Er is een onverwachte fout opgetreden. Fouttype: ',
            payment_unexpected_fail: 'Er was een onverwacht probleem met uw transactie.',
            payment_method_deleted: 'Betaalmethode succesvol verwijderd.',
            payment_method_favourited: 'Standaard betaalmethode succesvol geüpdatet.',
        },
    },
};

export { settings_translation };
