<template>
  <q-dialog v-model="visible">
    <q-card>
      <q-card-section class="row">
        <div class="text-h5">{{ $t('message.select_a_color') }}</div>
        <q-space />
        <q-btn icon="close" size="16px" flat round dense v-close-popup />
      </q-card-section>
      <q-card-section>
        <q-color
          v-model="color"
          no-header no-footer
          default-view="palette"
          @click="select">
        </q-color>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  emits: ['colorSelected'],
  data() {
    return {
      color: '#59c7f9',
      visible: false,
      promiseResolved: false,
      promiseRejected: false,
    }
  },
  methods: {
    pickColor: function() {
      this.visible = true;
      const self = this;
      // TODO resolve the promise after a color is selected w/o timeouts;
      // we reset the polling flags first
      this.promiseResolved = false;
      this.promiseRejected = false;
      return new Promise(function(resolve, reject) {
        const interval = 300;
        // reject or resolve; if neither should happen yet, execute
        // again in 'interval' milliseconds
        const poll = function() {
          if (self.promiseResolved) resolve(self.color);
          else if (self.promiseRejected) reject('nothing selected');
          else setTimeout(poll, interval);
        };
        // start polling
        setTimeout(poll, interval);
      });
    },
    close: function() {
      this.promiseRejected = true;
      this.visible = false;
    },
    select() {
      this.promiseResolved = true;
      this.close();
    },
  },
}
</script>
