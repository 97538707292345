import { ref, watch } from 'vue';

export function useDialogDismiss(title) {
  // data
  const stopShowing = ref(false);
  
  // watchers
  watch(stopShowing, (value) => {
    if (value) {
      // if the user checks the checkbox that says "don't show again", we write
      // a record to local storage for that dialog with the value false
      // (indicating that this dialog shouldn't be shown again
      localStorage.setItem(title, false);
    } else {
      // if the uncheck "don't show again" we simply remove the record from the
      // local storage
      localStorage.removeItem(title);
    }
  }); 
  
  return { stopShowing };
}

