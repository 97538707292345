<template>
<section class="middle-section">
    <div class="container">
        <div class="inspiration-pg association-wheel-pg connect-check-pg">
            <div class="inspir-lft-sec scroll">
                <workspace ref="workspace" @reset="reset"></workspace>
            </div>
            <div class="rightbox-sec scroll">
                <div>
                    <left-sidebar ref="left-sidebar" @generate="onGenerate"></left-sidebar>
                </div>
            </div>
        </div>
    </div>
</section>
<loading-app-wide v-if="display_loading_overlay" />
</template>

<script>
import LeftSidebar from './LeftSidebar.vue';
import Workspace from './Workspace.vue';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';

export default {
    name: 'BrandTracker',
    components: {
        LeftSidebar,
        Workspace,
        LoadingAppWide,
    },
    props: {
        jumpstart_asset_id: {
            default: -1,
            type: Number,
        },
    },
    data: function () {
        return {
            display_loading_overlay: false,
        };
    },
    methods: {
        reset: function () {
            this.$refs['workspace'].reset();
            this.$refs['left-sidebar'].reset();
        },
        onGenerate: async function (assets, selected_ia, campaigns) {
            this.$refs['workspace'].reset();
            this.display_loading_overlay = true;
            await this.$refs['workspace'].generate(assets, selected_ia, campaigns);
            this.display_loading_overlay = false;
        },
        jumpstart: async function () {
            const jumpstartEmpty = await this.$store.dispatch('jumpstart_empty');
            if (!jumpstartEmpty) {
                const assetAndPositioning = await this.$store.dispatch('pop_jumpstart');
                this.$refs['left-sidebar'].manuallyAddAsset(assetAndPositioning);
            }
        },
    },
    mounted: async function () {
        this.jumpstart();
    },
};
</script>
