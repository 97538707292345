<template>
  <div class="asset" :class="{ selected: isSelected, 'store-asset': storeAsset }" @click="select">
    <div class="thumb-container">
      <div class="thumb" v-if="!asset.type_.isMultimedia || asset.type_.isAudio">
        <span class="asset-icon-text">
          {{ asset.display_name }}
        </span>
        <div class="ms-asset-types">
          <font-awesome-icon v-if="asset.type_.isAudio" icon="volume-up" />
        </div>
      </div>
      <div class="thumb" :style="thumbStyle" v-else>
        <div class="ms-asset-types">
          <font-awesome-icon v-if="asset.type_.isVideo" icon="video" />
        </div>
      </div>
      <div v-if="hasMultiplePositionings" class="backdrop"></div>
      <button v-if="isMyLibrary" class="open-tools" @click.stop="toggleMenu" :disabled="this.asset.lifecycle !=4">
        <font-awesome-icon :icon="['fas', 'bars']" style="color: #0a00ff;" />
      </button>
      <jumpstart v-if="menu" v-model="menu"
        :asset="asset"
        :selectedPositioning="selectedPositioning">
      </jumpstart>
    </div>
  </div>
</template>

<script>
import Jumpstart from './sidebar/Jumpstart.vue';
import axios from 'axios';  

export default {
  components: {Jumpstart},
  props: {
    asset: { required: true, type: Object },
    isModal: { type: String },
    storeAsset: { required: false, default: false, type: Boolean },
    isMyLibrary: { default: false, type: Boolean }
  },
  emits: ['assetSelected'],
  data: function () {
    return {
      isSelected: false,
      // object-fit attribute for images will be either cover or contains based
      // on the aspect ratio of the image
      objectFit: 'cover',
      price: 0,
      menu: false,
      selectedPositioning: {},
    }
  },
  computed: {
    hasMultiplePositionings: function () {
      return this.asset.positioning_count > 1
    },
    thumbStyle: function () {
      let url = '';
      if (this.asset.type_.isImage) {
        url = this.asset.urls.thumb_medium
      } else if (this.asset.type_.isVideo) {
        url = this.asset.urls.gif;
      };
      let style_obj = {
        'background-image': `url("${url}"`,
        'background-size': `${this.objectFit}`,
        'background-repeat': 'no-repeat',
        'background-position': 'center'
      }
      return style_obj;
    }
  },
  methods: {
    _determinePrice: function () {
            const type = this.asset.type_;
            let priceType = '';
            if (type.isImage) {
                priceType = 'private_image_asset';
            } else if (type.isAudio || type.isVideo) {
                priceType = 'private_av_asset';
            } else {
                priceType = 'private_word_asset';
            }
            this.price = this.$store.getters.get_price(priceType);
        },

    select: function () {
      // the handler for assetSelected in the parent of this component
      // will ask all tiles (including this one) to unselect themselves;
      // after that is done, this tile makes itself selected again
      this.$emit('assetSelected', this.asset);
      // at this point all asset tiles have been unselected, so we have to
      // re-select this one
      this.isSelected = true;
    },
    unselect: function () {
      this.isSelected = false;
    },
    /*
    Image assets should have their object-fit set to either cover (for
    square-ish images) or contain (for horizontally rectangular ones).
    */
    determineObjectFit: function () {
      if (this.asset.type_.isImage) {
        const image = new Image();
        image.src = this.asset.urls.thumb_medium;
        image.onload = () => {
          const ratio = image.width / image.height;
          const threshold = 1.5;
          const shareish = ratio <= threshold && ratio >= (1 / threshold);
          this.objectFit = shareish ? 'cover' : 'contain';
        }
      }
    },
    toggleMenu(event) {
      this.menu = !this.menu;
    }
  },
  mounted: function () {
    this.determineObjectFit();
  },
  watch: {
        // whenever the asset changes we need to ask the API if it's monitored
        asset: {
            deep: true,
            immediate: true,
            handler(val, oldVal) {
                this._determinePrice();
            },
            handler: function (asset) {
              if (Object.keys(asset).length === 0) {
                return;
              }
              axios
                .get(`/api/cas/assets/positionings/${asset.id}`)
                .then((response) => {
                  this.positioning_surveys = response.data;
                  this.selectedPositioning = this.positioning_surveys[0];
                });
            },
        },
    },
}
</script>

<style scoped lang="scss">
.asset {
  position: relative;
  width: 125px;
  height: 125px;
}
.thumb-container {
  position: relative;
  width: 125px;
  height: 125px;
}
.thumb {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 125px;
  height: 125px;
}
.open-tools {
  position: absolute;
  top: 5px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.open-tools:hover {
  color: #0731ee;
  transform: scale(1.1);
}
</style>
