<template>
  <section class="middle-section">
    <div class="container">
      <div class="inspiration-pg association-wheel-pg">
        <div class="inspir-lft-sec scroll">
          <div class="leftBar">
            <!-- <div class="brd-crm">Develop</div> -->
            <workspace ref="workspace" @reset="reset"></workspace>
          </div>
        </div>
        <div class="rightbox-sec scroll">
          <div class="rightBar">
            <left-sidebar ref="sidebar" @generate="onGenerate" @generateStory="onGenerateStory"></left-sidebar>
          </div>
        </div>
      </div>
    </div>
  </section>
  <loading-app-wide v-if="display_loading_overlay"></loading-app-wide>
</template>

<script>
import LeftSidebar from './LeftSidebar.vue';
import Workspace from './Workspace.vue';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';

export default {
  name: 'Story Creator',
  components: {
    LeftSidebar,
    Workspace,
    LoadingAppWide,
  },
  data: function () {
    return {
      display_loading_overlay: false,
    };
  },
  methods: {
    reset: function () {
      this.$refs['workspace'].reset();
      // this.$refs['sidebar'].reset();
    },
    onGenerate: async function (asset, ia) {
      this.display_loading_overlay = true;
      await this.$refs['workspace'].generate(asset, ia);
      this.display_loading_overlay = false;
    },

    onGenerateStory: async function (asset, ia) {
      await this.$refs['workspace'].generateStory();
    },
    /*
            Perform the jumpstart action if a jumpstart object had been pushed to
            the store.
            */
    jumpstart: async function () {
      // if there's no jumpstart object we don't need to do anything
      const jumpstartEmpty = await this.$store.dispatch('jumpstart_empty');
      if (!jumpstartEmpty) {
        const assetAndPositioning = await this.$store.dispatch('pop_jumpstart');
        this.$refs.sidebar.onSelectedAsset(assetAndPositioning.asset);
      }
    },
  },
  mounted: function () {
    this.jumpstart();
  },
};
</script>

<style scoped>
.left-checkbx-div {
  max-height: 37vw;
  padding: none;
}

/* .leftBar {
    height: 100%;
} */
</style>
