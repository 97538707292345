<template>
  <div class="asset-title" v-if="!isStoreAsset">
    <transition name="quickfade" mode="out-in">
      <div class="display-name-container" v-if="!editAssetName" key=1 @click="editAssetName = !editAssetName">
        <span class="display-name">
          {{ asset.display_name }}
          <q-icon size="20px" class="edit-icon" name="eva-edit-outline" color="primary"></q-icon>
        </span>
      </div>
      <asset-name-edit v-else key=2 ref="asset-name-edit" @closeAssetNameEdit="editAssetName = !editAssetName"
        :asset="asset">
      </asset-name-edit>
    </transition>
  </div>

  <div class="asset-image">
    <img v-if="asset.type_.isImage" v-bind:src="asset.urls.thumb_medium" @click="$refs.fullscreen_preview.open">
    <img v-if="asset.type_.isVideo" v-bind:src="asset.urls.gif">
    <span v-if="!asset.type_.isMultimedia || asset.type_.isAudio" class="asset-icon-text">{{ asset.display_name }}
    </span>
  </div>
  <img v-if="asset.type_.isImage || asset.type_.isVideo" class="magnifier-icon" src="@/assets/icons/zoom-in-blue.svg"
      @click="$refs.fullscreen_preview.open">
  <div style="display: flex; justify-content: center;">
    <div v-if="isLoading" class="spinner"></div>
    <div v-else-if="hasError" class="placeholder">Concept not ready yet!</div>
    <div v-else class="rating-tooltip-container">
      <concept-rating v-model:rating="star_rating"></concept-rating>
      <div class="rating-tooltip-text" @click="emitRatingExplanation">Clarity Rating: {{star_rating.toFixed(2)}} / 5 <br>Click to find out more...</div>
    </div>
  </div>
  <div class="asset-data">
    <div>
      <div class="inner">
        <div style="height:3vw; display: flex; align-items: center;">
          <label>{{ $t('message.status').toUpperCase() }}</label>
          <p v-if="!fromAssetPicker" @click="emitLifecycle" style=" margin: auto;">{{state_name}}
            <font-awesome-icon :icon="['fas', 'info-circle']" style="color: #0A00FF; cursor: pointer;" />
          </p>
          <p v-if="fromAssetPicker" style=" margin: auto;">{{state_name}}</p>
        </div>

        <div style="height:3vw;display: flex; align-items: center;">
          <label>{{ $t('message.type').toUpperCase() }}</label>
          <p style="margin: auto;">{{ type_name }}</p>
        </div>

        <div v-if="show_positionings && !positioningsEmpty" style="height:3vw;display: flex; align-items: center;">
          <label class="mapping" style="display: flex; gap: 1px;">
            {{ $t('message.mapped').toUpperCase() }}
            <div v-if="multiple_positionings">
              {{ $t('message.positioned_n_times', { n: positioning_surveys.length }) }}
            </div>
          </label>
          <p style="margin:auto; display:flex; align-items: center; gap: 10px;">
            <select v-if="show_positionings && !positioningsEmpty" class="date-picker" @change="onPositioningChange"
              v-model="selected_positioning">
              <option v-for="s in positioning_surveys" :value="s">
                {{ s.to }}
              </option>
            </select>
            <div class="tooltip-container">
              <div v-if="!isAddToNextSurvey(this.asset)" @click="reposition(this.asset)">
                <font-awesome-icon :icon="['fas', 'sync-alt']" style="color: #0a00ff;" />
              </div>
              <div class="tooltip-text">
                This concept was positioned before. To reposition it, add it to the next survey.
              </div>
            </div>
          </p>
        </div>

        <div style="height:3vw;display: flex; align-items: center;" v-if="Array.from(urls).length > 0 && Array.from(urls)[0] !== 'null'">
          <label v-if="Array.from(urls).length > 0">URL</label>
          <p style=" margin: auto;" class="url" v-if="Array.from(urls).length > 0">
            <a :href="url" target="_blank" class="url" v-for="url in Array.from(urls)" v-bind:key="url">&zwnj;{{ url }}
            </a>
          </p>
        </div>

        <div style="height:3vw;display: flex; align-items: center;">
          <label>TRACK CONCEPT</label>
          <p style="margin:auto; display:flex; align-items: center; gap: 10px;">
            <monitoring-toggle :asset="asset"></monitoring-toggle>
          </p>
        </div>
      </div>
    </div>
  </div>
  <full-screen-preview :asset="asset" :isStoreAsset="isStoreAsset" ref="fullscreen_preview">
  </full-screen-preview>
</template>

<script>
import axios from "axios";
import FullScreenPreview from "@/components/common/FullScreenPreview";
import MonitoringToggle from './MonitoringToggle.vue';
import AssetNameEdit from "@/components/asset_library/sidebar/AssetNameEdit";
import ConceptRating from "./ConceptRating.vue";
import {
  alert_error,
  toast_success,
  repositionDialog
} from '@/helpers/alert_helper.js';
/*
This component contains the information that is common to both owned and store
assets. 
*/
export default {
  components: { FullScreenPreview, AssetNameEdit, MonitoringToggle, ConceptRating },
  emits: ['positioningSelected','showLifecycle', 'showRatingExplanation', 'ratingExplanationData'],
  props: {
    asset: { required: true, type: Object },
    show_positionings: { default: false, type: Boolean },
    positioning_surveys: { required: true, type: Array },
    isMyLibrary: { default: false, type: Boolean },
    AssetMonitoringQueue: { type: Array },
    fromAssetPicker: {type: Boolean}
  },
  data: function () {
    return {
      urls: new Set,
      selected_positioning: {},
      editAssetName: false,
      isStoreAsset: false,
      price: 0,
      star_rating: 0,
      rating_score: 0,
      stabScore: 0,
      noIdeaScore: 0,
      mean: 0,
      stdDev: 0,
      isLoading: true,
      hasError: false,
      ratingExplanationPopup: false
    }
  },
  computed: {

    asset_empty: function () {
      return Object.keys(this.asset).length === 0;
    },
    state_name: function () {
      if (this.asset_empty) { return ''; }
      return this.getStateName(this.asset.lifecycle);
    },
    type_name: function () {
      if (this.asset_empty) { return ''; }
      return this.getTypeName(this.asset.type_.id);
    },
    multiple_positionings: function () {
      return this.positioning_surveys.length > 1;
    },
    positioningsEmpty: function () {
      return this.positioning_surveys.length === 0;
    },
  },

  methods: {
    isAddToNextSurvey: function (asset) {
      return this.AssetMonitoringQueue.includes(asset.id)
    },
    toggleRatingExplanationPopup() {
      this.ratingExplanationPopup = !this.ratingExplanationPopup;
    },
    _determinePrice: function () {
      const type = this.asset.type_;
      let priceType = '';
      if (type.isImage) {
        priceType = 'private_image_asset';
      } else if (type.isAudio || type.isVideo) {
        priceType = 'private_av_asset';
      } else {
        priceType = 'private_word_asset';
      }
      this.price = this.$store.getters.get_price(priceType);
    },
    onPositioningChange: function () {
      this.$emit('positioningSelected', this.selected_positioning);
    },
    getStateName: function (state) {
      switch (state) {
        case 0: return this.$t("message.queued");
        case 1: return this.$t("message.in_survey");
        case 2: return this.$t("message.in_cleaning");
        case 3: return this.$t("message.in_ingestion");
        case 4: return this.$t("message.done");
      }
    },
    getTypeName: function (type) {
      switch (type) {
        case 1: return this.$t("message.word").toLowerCase();
        case 2: return this.$t("message.image").toLowerCase();
        case 5: return this.$t("message.audio").toLowerCase();
        case 7: return this.$t("message.video").toLowerCase();
      }
    },
    emitLifecycle: function(){
      this.$emit('showLifecycle', true);
    },
    emitRatingExplanation: function(){
      this.$emit('showRatingExplanation', true);
      this.$emit('ratingExplanationData', {
        'mean': this.mean,
        'std_dev': this.stdDev,
        'stabScore': this.stabScore,
        'noIdeaScore': this.noIdeaScore,
        'clarityScore': this.rating_score,
      })
    },
    reposition: async function (asset) {
      // open a pop up window
      let dialog_result = null;
      const dialog_options = {
        title: this.$t("message.tracking_once"),
        // text: this.$t("message.reposition")+"\n total \n"+100+" mindcoins",
        line1: this.$t("message.reposition"),
        line2: "mindcoins",
        price: this.price,
        confirmButtonText: this.$t("message.track"),
        cancelButtonText: this.$t("message.cancel"),
      };
      dialog_result = await repositionDialog(dialog_options);
      if (dialog_result.isConfirmed) {
        try {
          // TODO: call add this asset to the automatic survey queue
          let payload = {
            'asset_id': asset.id
          }
          const response = await axios.post('/api/cas/assets/reposition', payload);
          if (response.status == 200) {
            toast_success('Remapping order completed successfully!')
            this.AssetMonitoringQueue.push(this.asset.id);
            this.$store.dispatch('get_mindcoin_balance');
          } else {
            if (response.data == 'no access') {
              alert_error("You don't have access to this asset")
            }
            else if (response.data == 'insufficient_credits') {
              alert_error("You don't have enough mindcoins")
            }
            else {
              alert_error("The asset is still being mapped now")
            }
          }
        } catch (error) {
          await alert_error(this.$t('message.insufficient_credits_for_asset'));
        }
      }
    },
    fetchRating: async function (asset) {
      this.isLoading = true;
      this.hasError = false;
      const params = { 'concept_id': asset.id }
      const response = await axios.get('/api/cas/concept_rating/get_scores', {params});
      if (response.status == 200) {
        this.star_rating = response.data["star_count"]
        this.rating_score = response.data["overall_score"]
        this.mean = response.data["mean"]
        this.stdDev = response.data["std_dev"]
        this.noIdeaScore = response.data["no_idea"].toFixed(2)
        this.stabScore = response.data["stability_score"].toFixed(2)
      } else {
        this.hasError = true;
      }
      this.isLoading = false;
    }
    },
  watch: {
    // whenever the asset changes we need to fetch URLs
    asset: {
      deep: true,
      immediate: true,
      handler: function (asset) {
        this.isStoreAsset = true;
        this._determinePrice();
        if (asset.type_.id === 1) {
          this.isStoreAsset = true;
        } else {
          axios.post(`/api/cas/assets/owned_by_org`, { 'id': asset.id }).then((response) => {
            this.isStoreAsset = !response.data.has_ownership;
          });
        }
        axios.get(`/api/cas/assets/urls/${asset.id}`).then((response) => {
          this.urls.clear();
          for (const url of response.data) {
            this.urls.add(url);
          }
        });
        this.fetchRating(asset);
      }
    },
    positioning_surveys: function () {
      if (this.positioning_surveys.length === 0) {
        this.selected_positioning = {};
      } else {
        this.selected_positioning = this.positioning_surveys[0];
      }
      this.onPositioningChange();
    },
  },
}
</script>
<style scoped lang="scss">

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-icon {
  // display: inline-block;
  // position: relative;
  font-size: 1vw;
  cursor: pointer;
  align-items: center;
  margin-bottom: 3px;
}

.tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #726EE1;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  right: 10%;
  text-transform: none;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
}

.tooltip-text::before {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #726EE1 transparent transparent;
}


.asset-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.asset-title div {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (min-width: 1024px) {
    width: calc(#{$sidebar-small} - 50px);
  }

  @media (min-width: 1600px) {
    width: calc(#{$sidebar-medium} - 50px);
  }

  @media (min-width: 1920px) {
    width: calc(#{$sidebar-big} - 50px);
  }
}

.asset-title div {
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 1rem;
}

.asset-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.asset-image img {
  border-radius: 0px;
  max-width: 200px;
  max-height: 165px;
  object-fit: contain;
  cursor: pointer;
  flex-basis: 100%;
  margin-top: 10px;
}

.magnifier-icon {
  width: 24px;
  height: 24px;
}

.asset-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.disabled {
  pointer-events: none;
}

.asset-data .mapping {
  @media (max-width: 1600px) {
    margin-right: 5px;
  }

  @media (min-width: 1601px) {
    margin-right: 15px;
  }
}

.asset-data .inner {
  display: flex;
  flex-flow: column;
}

.inner div label {
  text-align: right;
  width: 50%;
  font-size: 0.8vw;

  @media (max-width: 1600px) {
    margin-right: 5px;
  }

  @media (min-width: 1601px) {
    margin-right: 30px;
  }
}

.dynamic-font {
  font-size: 11px !important;
}

@media screen and (max-width: 600px) {
  .dynamic-font {
    font-size: 7px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .dynamic-font {
    font-size: 20px !important;
  }
}

.inner div p {
  text-align: left;
  width: 50%;
  text-transform: capitalize;
  color: #000 !important;
  opacity: 1 !important;
}

.asset-data .labels p {
  display: flex;
  justify-content: flex-end;
  font-family: 'Open Sans';
  font-weight: 500;

  @media (min-width: 1024px) {
    font-size: 0.725rem;
  }

  @media (min-width: 1600px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1920px) {
    font-size: 0.875rem;
    ;
  }
}

.asset-data .data p {
  font-family: 'Open Sans';
  font-weight: 600;

  @media (min-width: 1024px) {
    font-size: 0.725rem;
  }

  @media (min-width: 1600px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1920px) {
    font-size: 0.875rem;
  }
}

.asset-data .data a {
  font-family: 'Open Sans';
  font-weight: 600;
  margin-bottom: 16px;

  @media (min-width: 1024px) {
    font-size: 0.725rem;
  }

  @media (min-width: 1600px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1920px) {
    font-size: 0.875rem;
  }
}

.asset-data .url {
  color: blue;
  cursor: pointer;
}

.asset-data .positionings {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  margin-bottom: 10px;
}

.asset-data .positionings img {
  width: 16px;
  height: 16px;
}

.url {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 0.875rem;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 16px;
}

.date-picker {
  display: block;
  line-height: 38px;
  border-radius: 6px;
  border: solid 1px #adb5bd;
  //padding: 0 15px;
  cursor: pointer;
  padding: 0 5px;
  color: #212529;

  //width: 100%;
  @media (min-width: 1024px) {
    font-size: 0.725rem;
  }

  @media (min-width: 1600px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1920px) {
    font-size: 0.875rem;
    ;
  }
}

.display-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.5s;

  &:hover {
    cursor: pointer;
    border-bottom: 2px solid $mdspblue_ui;
  }
}

.display-name-container .display-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.display-name-container i {
  padding-left: 5px;
}

.quickfade-enter-active,
.quickfade-leave-active {
  transition: all .35s
}

.quickfade-enter-from {
  opacity: 0;
}

.quickfade-enter-to {
  opacity: 1;
}

.quickfade-enter,
.quickfade-leave-to {
  opacity: 0;
}

.refresh-button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 23px;
  margin: auto;
}
@keyframes spin {
  0% { transform: rotate(0deg);}
  100% { transform: rotate(360deg);}
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

.placeholder {
  font-size: 0.875rem;
  color: #999;
  text-align: center;
}

.rating-tooltip-container {
  position: relative;
  display: inline-block;
}

.rating-tooltip-text {
  position: absolute;
  top: 100%;
  left: 30%;
  transform: translateY(0px);
  background-color: #726EE1;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 10;
  white-space: nowrap;
  display: none;
  cursor: pointer;
  font-weight: bold;
}

.rating-tooltip-container:hover .rating-tooltip-text {
  display: block;
}
</style>
