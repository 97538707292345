<template>
    <q-dialog v-model="visible">
        <q-card class="upload-item-card">
            <q-card-section class="row items-center q-pb-none">
                <q-space />
                <q-btn icon="close" flat round dense @click="close" />
            </q-card-section>

            <q-card-section class="upload-info">
                <div class="top-text">{{ $t('new_asset.add_new_item') }}</div>
                <div class="bottom-text">{{ $t('new_asset.upload_from_computer') }}</div>
            </q-card-section>

            <upload-area @fileAdd="onFileAdd"
                :extensions="fileType === 'img' ? ['png', 'jpg', 'jpeg'] : ['mp3', 'mp4']"></upload-area>

            <q-list bordered class="rounded-borders upload-list scroll">
                <candidate-asset v-for="file in files" :key="file.uuid" :file="file" :ref="setCandidateAssetRef"
                    @remove="onFileRemove" :fullSize="true">
                </candidate-asset>
            </q-list>

            <div class="loaders" v-if="loading">
                <span>
                    <loading-spinner></loading-spinner>
                </span>
            </div>

            <div class="dialogs" v-if="files.size > 0">
                <p v-if="!allQuestionsAnswered">
                    {{ $t('new_asset.answer_image_questions') }}
                </p>
                <p v-if="insufficientMindcoins">
                    {{ $t('new_asset.insufficient_credits') }}
                </p>
                <p class="font-weight-bold">
                    {{ $t("new_asset.total_mindcoins", { total: mindcoinTotal }) }}
                </p>
            </div>
            <div class="buttons" v-if="files.size > 0">
                <button class="btngrey" @click="close">
                    {{ $t("message.cancel") }}
                </button>
                <button class="btn3" @click="upload">
                    {{ $t("message.asset_creator_add") }}
                </button>
            </div>
        </q-card>
    </q-dialog>




    <q-dialog v-model="isModalVisible">
    <q-modal @hide="closeModal">

        <div class="modal-content">
            <!-- Close Button (Top Right) -->
            <q-card-section class="row items-center q-pb-none" style="position: absolute; ">
                <q-space />
                <q-btn icon="close" flat round dense @click="closeModal" />
            </q-card-section>

            <!-- Header Text (Centered) -->
            <div class="header-text">
                <p>As we survey your concept order <br>
                    Please explore these immediate testing options</p>
            </div>

            <!-- If no images are found, display a message -->
            <div v-if="imageList.length === 0">
                <p>No similar concepts found in Mindspeller Library. You will receive an email when your concept has been surveyed and is ready for testing. You can monitor the survey status in the right window pane of My Library when clicking on your concept.</p>
            </div>

            <!-- List of images with frames, display_name at left, image in the middle, and button at right -->
            <div v-else>
                <div v-for="(image, index) in imageList" :key="index">
                    <div class="image-container">
                        <div class="image-frame"> <!-- Add a div for the frame -->
                            <img :src="image.url" alt="Image" class="image">
                        </div>
                        <button class="button_pay" @click="addAsset(image.id, image.display_name)" 
                            :disabled="disabledButtons.includes(image.id)">Add to my library</button>
                    </div>
                </div>
            </div>
        </div>
    </q-modal>
</q-dialog>

</template>

<script>
import axios from "axios";
import {
    v4 as uuidv4
} from 'uuid';
import {
    alert_error,
    genericDialog,
    toast_success
} from '@/helpers/alert_helper.js';
import UploadArea from "./UploadArea.vue";
import CandidateAsset from "./CandidateAsset.vue";
import LoadingSpinner from '../common/LoadingSpinner.vue';


export default {
    components: {
        CandidateAsset,
        UploadArea,
        LoadingSpinner,
    },
    emits: ['resetSearch'],
    data: function () {
        return {
            loading: false,
            visible: false,
            // files contains a list of Files objects obtained from UploadArea
            files: new Set(),
            // contains CandidateAsset.vue components
            candidateAssets: new Set(),
            isModalVisible: false,
            imageList: [],
            disabledButtons: [],
        }
    },
    props: {
        fileType: {
            type: String,
            required: true,
            default: 'img'
        }
    },
    computed: {
        mindcoinTotal: function () {
            // the total only includes acceptable files (i.e. it excludes the
            // files with an unacceptable extension)
            return this.acceptableAssets
                .map(ca => ca.info.price)
                .reduce((a, b) => a + b, 0);
        },
        uploadAllowed: function () {
            return !this.insufficientMindcoins &&
                this.allQuestionsAnswered &&
                this.acceptableAssets.length > 0;
        },
        insufficientMindcoins: function () {
            return this.mindcoinTotal > this.$store.state.mindcoins;
        },
        allQuestionsAnswered: function () {
            return this.acceptableAssets
                .filter(ca => ca.info.type === 'image')
                .every(ca => ca.questionsAnswered);
        },
        acceptableAssets: function () {
            return Array.from(this.candidateAssets).filter(ca => ca.info.acceptable);
        },
    },
    methods: {
        async addAsset(id, display_name) {
            const dialog_options = {
                title: this.$t("message.buy_asset", {
                    'asset': display_name
                }),
                text: this.$t("message.asset_cost", {
                    'price': 20
                }),
                confirmButtonText: this.$t("message.add"),
                cancelButtonText: this.$t("message.cancel"),
            };
            const dialog_result = await genericDialog(dialog_options);
            if (dialog_result.isConfirmed) {
                try {
                    const response = await axios.post('/api/cas/assets/store/purchase', { 'id': id });
                    this.$store.dispatch('get_mindcoin_balance');
                    toast_success(this.$t('message.purchased_asset'));
                    this.disabledButtons.push(id);
                    // trigger the re-render (search function)
                    this.$emit("resetSearch");
                } catch (error) {
                    if (error.response.status === 422) {
                        alert_error(this.$t('message.insufficient_credits_for_asset'));
                    } else {
                        alert_error(this.$t('message.general_server_error'));
                    }
                }
            }
        },
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
        open: function () {
            this.visible = true;
        },
        close: function () {
            this.candidateAssets.clear();
            this.files.clear();
            this.visible = false;
        },
        setCandidateAssetRef: function (ca) {
            if (ca) {
                this.candidateAssets.add(ca);
            }
        },
        onFileAdd: async function (file) {
            file.uuid = uuidv4();
            this.files.add(file);
        },
        onFileRemove: function (file) {
            this.candidateAssets.clear();
            this.files.delete(file);
        },
        upload: async function () {
            // first, we ask the user to confirm the upload
            const dialog_options = {
                title: this.$t('new_asset.upload_selected_asset'),
                text: this.$t('new_asset.this_will_cost', {
                    price: this.mindcoinTotal
                }),
                confirmButtonText: this.$t("message.asset_creator_add"),
                cancelButtonText: this.$t("message.cancel"),
            };
            const dialog_result = await genericDialog(dialog_options);
            if (!dialog_result.isConfirmed) {
                return;
            }

            // next, check if the user has enough credits
            await this.$store.dispatch('get_mindcoin_balance');
            if (this.$store.state.mindcoins < this.mindcoinTotal) {
                alert_error(this.$t('new_asset.insufficient_credits'));
                return;
            }

            // if the user has enough credits, we upload the files one by one
            for (const ca of this.acceptableAssets) {
                const formData = new FormData();
                formData.append('file', ca.file);
                formData.append('display_name', ca.info.displayName);
                formData.append('confidential', ca.questions.isConfidential);
                // we add answers to image questions to the form if this is an image
                if (ca.info.type === 'image') {
                    formData.append('is_brand', ca.questions.isBrand);
                    formData.append('tag', ca.questions.type);
                }
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                }
                ca.uploading = true;
                try {
                    let sorted_data = null;
                    // currently the mindrop cas use another api for uploading than the nl
                    await axios.post(
                        '/api/cas/assets/upload_new', formData, axiosConfig
                    ).then(response => {
                        if (response.status === 200) {
                            sorted_data = response.data;
                        }
                    })
                    toast_success(`Concept(s) ${ca.info.displayName} uploaded, you will be notified when your concept(s) is analysed`);
                    this.$store.dispatch('get_mindcoin_balance');
                    this.$emit("resetSearch");

                    this.files.delete(ca.file);
                    this.candidateAssets.delete(ca);
                    ca.uploading = true;

                    // here we redirect to the library page to show
                    this.visible = false;
                    // This should be changed according to different server
                    let url_string = "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_";
                    for (const item of sorted_data) {
                        // Here we should display this information in the mindspeller library
                        this.imageList.push({ "url": url_string + item['file_identifier'], "display_name": item['display_name'], "id": item['id'] });
                    }
                    this.isModalVisible = true;

                } catch (error) {
                    const er = error.response;
                    if (er.status === 403 && er.data.insufficient_credits) {
                        alert_error(this.$t('new_asset.insufficient_credits'));
                        ca.uploading = false;
                        // since the user no longer has enough credits, we shouldn't try
                        // to upload the remaining files either
                        break;
                    } else {
                        alert_error(this.$t('message.general_server_error'));
                    }
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.upload-item-card {
    width: 1000px;
    max-width: 80vw;
    height: 800px;
    overflow: hidden;
    display: flex;
    flex-flow: column;
}

.upload-info .bottom-text {
    font-size: 1rem;
    font-family: 'Open Sans';
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
}

.upload-info .top-text {
    font-size: 1.675rem;
    font-family: 'Open Sans';
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
}

.dialogs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

.dialogs p {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 5px;
    font-weight: bolder;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.buttons button {
    margin: 20px 5px !important;
}

.upload-list {
    flex: 1;
    overflow-x: hidden;
}

.q-card__section.q-card__section--vert.row.items-center.q-pb-none {
    position: absolute;
    right: 0;
    z-index: 1;
    top: -11px;
    display: block;
    text-align: revert;
}

.upload-info .top-text {
    font-weight: bold;
    text-transform: capitalize;
}

// The design of the modal
.modal-content {
    max-height: 80vh;
    overflow-y: auto;
    background-color: #f0f0f0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.close-icon {
    font-size: 1.2rem;
    font-weight: bold;
}

.header-text {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-right: 50px;
}

.header-text p {
    margin: 0;
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.image-label {
    flex: 1;
    text-align: left;
    font-weight: bold;
    margin-right: 10px;
}

.image-frame {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-button {
    width: 200px;
    flex-shrink: 0;
    margin-top: 10px;
}

.button_pay {
    padding: 0.5vw 1.0vw;
    background: #0A00FF;
    border-radius: 2vw;
    font-style: normal;
    font-weight: 1000;
    font-size: 0.8vw;
    color: #ffffff !important;
    cursor: pointer;
    text-transform: capitalize;
    transition: all 1s;
}

.button_pay a {
    color: #ffffff !important;
}

.button_pay:hover {
    background-color: #92a0b3;
}
</style>