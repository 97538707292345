<template>
    <div style=" display: flex;justify-content: center; align-items: center;height: 40vw;">
        <h4 style="font-weight: bold;">Talk To A Sepcialist About Your Goals. And Create A Custom Strategy.
        </h4>
    </div>
    <q-btn style="margin-top: -10vw; width: 80%;align-self: center;" class="book-call-btn"> <a
            href="mailto:contact@mindspeller.com">Book a Call</a> </q-btn>
</template>



<style scoped>
.book-call-btn {
    background-color: #ff6600;
    color: #fff;
    padding: 16px 32px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 20px;
    width: 80%;
    align-self: center;
    margin-top: -20vw;
    transition: background-color 0.3s, transform 0.3s;
    text-transform: uppercase;
}

.book-call-btn:hover {
    background-color: #ff5511;
    transform: scale(1.05);
}
</style>