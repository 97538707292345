<template>
    <div class="radarChart" id="radar-graph"></div>
</template>

<script>
import * as d3 from 'd3-3'
import axios from "axios";
import moment from "moment";
import { alert_error } from '@/helpers/alert_helper.js';

export default {
    methods: {
        exportChart: function() {
            const svg = document.querySelector("#radar-graph svg");
            // using our custom D3export plugin to export the PNG
            this.$exportD3.saveSvgAsPng(
                svg,
                "Connection Checker.png",
                { scale: 2 }
            );
        },
        
        load: async function (selected_ias, selected_assets_and_positionings) {
            const payload = {
                "sources": Array.from(selected_assets_and_positionings),
                "targets": Array.from(selected_ias),
            };
            try {
                const response = await axios.post('/neuropositioning/analyze', payload);
                const data = response.data;
                
                let colors = this.getColors();
                let stimuli_labels = Array.from(selected_assets_and_positionings).map(x =>
                    `${x.asset.display_name} ${this.formatDate(x.positioning.to)}`
                );
                this.renderChart(data, colors);
                this.renderLegend(stimuli_labels, data, colors);
            } catch(error) {
                if (error.response.status === 403) {
                    alert_error(this.$t('message.no_access_to_items'));
                }  else {
                    alert_error(this.$t('message.general_server_error'));
                }
            }
        },
        
        // below this point are copy-pasted methods from old CAS with minimal
        // modifications; potential TODO: separate them into a helper file
        renderChart: function (data, colors) {
            const myNode = document.getElementById("radar-graph");
            myNode.innerHTML = '';
            const radar_options = {
                color: colors
            };
            this.D3RadarChart(".radarChart", data, radar_options);
        },
        
        getColors: function () {
            return d3.scale.ordinal().range(["#18c61a", "#9817ff", "#d31911", "#24b7f1", "#fa82ce", "#736c31", "#1263e2", "#18c199", "#ed990a", "#f2917f", "#7b637c", "#a8b311", "#a438c0", "#d00d5e", "#1e7b1d", "#05767b", "#aaa1f9", "#a5aea1", "#a75312", "#026eb8", "#94b665", "#91529e", "#caa74f", "#c90392", "#a84e5d", "#6a4cf1", "#1ac463", "#d89ab1", "#3c764d", "#2dbdc5", "#fb78fa", "#a6a9cd", "#c1383d", "#8b614e", "#73be38", "#ff8d52", "#cea37e", "#b53c7e", "#656d61", "#436f90", "#5e7304", "#82b792", "#fb88a3", "#dd8df2", "#6a5cc0", "#d098d5", "#ac15dc", "#a4543b", "#76b4cc", "#6963a4", "#8e620d", "#77adf8", "#18c61a", "#9817ff", "#d31911", "#24b7f1", "#fa82ce", "#736c31", "#1263e2", "#18c199", "#ed990a", "#f2917f", "#7b637c", "#a8b311", "#a438c0", "#d00d5e", "#1e7b1d", "#05767b", "#aaa1f9", "#a5aea1", "#a75312", "#026eb8", "#94b665", "#91529e", "#caa74f", "#c90392", "#a84e5d", "#6a4cf1", "#1ac463", "#d89ab1", "#3c764d", "#2dbdc5", "#fb78fa", "#a6a9cd", "#c1383d", "#8b614e", "#73be38", "#ff8d52", "#cea37e", "#b53c7e", "#656d61", "#436f90", "#5e7304", "#82b792", "#fb88a3", "#dd8df2", "#6a5cc0", "#d098d5", "#ac15dc", "#a4543b", "#76b4cc", "#6963a4", "#8e620d", "#77adf8"]);
        },
        
        formatDate: function(date) {
            return moment(date).format("MMM/DD/YYYY");
        },
        
        renderLegend: function(stimuli, data, colors) {
            this.initRadarLegend(stimuli, data, colors)
        },
        
        initRadarLegend: function(brands, data, color) {
	let svg = d3.select("#radar-graph svg")
	let x = 15;
	let y = 15;
	let dy = 25;
	data.forEach(function (entry, i) {
		svg.append("text")
			.attr("x", 40)
			.attr("y", y + (dy * i))
			.text(brands[i])
			.style("font-size", "15px")
			.attr("alignment-baseline", "middle")
		svg.append("circle")
			.attr("cx", x)
			.attr("cy", y + (dy * i) - 3)
			.attr("r", 9)
			.style("fill", color(i))
	})
},
        
        D3RadarChart: function(id, data, options) {
	var cfg = {
	 w: 600,				//Width of the circle
	 h: 600,				//Height of the circle
	 margin: {top: 0, right: 0, bottom: 0, left: 0}, //The margins of the SVG
	 levels: 10,			//How many levels or inner circles should there be drawn
	 maxValue: 10, 			//What is the value that the biggest circle will represent
	 labelFactor: 1.10, 	//How much farther than the radius of the outer circle should the labels be placed
	 wrapWidth: 60, 		//The number of pixels after which a label needs to be given a new line
	 opacityArea: 0.35, 	//The opacity of the area of the blob
	 dotRadius: 7, 			//The size of the colored circles of each blog
	 opacityCircles: 0.1, 	//The opacity of the circles of each blob
	 strokeWidth: 2, 		//The width of the stroke around each blob
	 roundStrokes: false,	//If true the area and stroke will follow a round path (cardinal-closed)
	 // color: d3.scale.category10()	//Color function
	};

	//Put all of the options into a variable called cfg
	if('undefined' !== typeof options){
	  for(var i in options){
		if('undefined' !== typeof options[i]){ cfg[i] = options[i]; }
	  }//for i
	}//if

    // Add Sum helper function to the array prototype
    Array.prototype.sum = function (prop) {
        var total = 0
        for ( var i = 0, _len = this.length; i < _len; i++ ) {
            total += this[i][prop]
        }
        return total
    }

	//If the supplied maxValue is smaller than the actual one, replace by the max in the data
	var maxValue = Math.max(cfg.maxValue, d3.max(data, function(i){return d3.max(i.map(function(o){return o.value;}))}));

	var allAxis = (data[0].map(function(i, j){return i.axis})),	//Names of each axis
		total = allAxis.length,					//The number of different axes
		radius = Math.min(cfg.w/2, cfg.h/2), 	//Radius of the outermost circle
		Format = d3.format(''),
		angleSlice = Math.PI * 2 / total;		//The width in radians of each "slice"

	//Scale for the radius
	var rScale = d3.scale.linear()
		.range([0, radius])
		.domain([0, maxValue]);

	/////////////////////////////////////////////////////////
	//////////// Create the container SVG and g /////////////
	/////////////////////////////////////////////////////////

	//Remove whatever chart with the same id/class was present before
	d3.select(id).select("svg").remove();

	//Initiate the radar chart SVG
	var svg = d3.select(id).append("svg")
            .attr("viewBox", "0 0 " + 800 + " " + 800)
            .attr('preserveAspectRatio', 'xMidYMid meet')
			.attr("class", "radar"+id);
	//Append a g element
	var g = svg.append("g")
			.attr("transform", "translate(" + (400 ) + "," + (400) + ")");

	/////////////////////////////////////////////////////////
	////////// Glow filter for some extra pizzazz ///////////
	/////////////////////////////////////////////////////////

	//Filter for the outside glow
	var filter = g.append('defs').append('filter').attr('id','glow'),
		feGaussianBlur = filter.append('feGaussianBlur').attr('stdDeviation','2.5').attr('result','coloredBlur'),
		feMerge = filter.append('feMerge'),
		feMergeNode_1 = feMerge.append('feMergeNode').attr('in','coloredBlur'),
		feMergeNode_2 = feMerge.append('feMergeNode').attr('in','SourceGraphic');

	/////////////////////////////////////////////////////////
	/////////////// Draw the Circular grid //////////////////
	/////////////////////////////////////////////////////////

	//Wrapper for the grid & axes
	var axisGrid = g.append("g").attr("class", "axisWrapper");

	//Draw the background circles
	axisGrid.selectAll(".levels")
	   .data(d3.range(1,(cfg.levels+1)).reverse())
	   .enter()
		.append("circle")
		.attr("class", "gridCircle")
		.attr("r", function(d, i){return radius/cfg.levels*d;})
		.style("fill", "#CDCDCD")
		.style("stroke", "#CDCDCD")
		.style("fill-opacity", cfg.opacityCircles)
		.style("filter" , "url(#glow)");

	//Text indicating at what % each level is
	axisGrid.selectAll(".axisLabel")
	   .data(d3.range(1,(cfg.levels+1)).reverse())
	   .enter().append("text")
	   .attr("class", "axisLabel")
	   .attr("x", 4)
	   .attr("y", function(d){return -d*radius/cfg.levels;})
	   .attr("dy", "0.4em")
	   .style("font-size", "10px")
	   .attr("fill", "#737373")
	   .text(function(d,i) { return Format(maxValue * d/cfg.levels); });

	/////////////////////////////////////////////////////////
	//////////////////// Draw the axes //////////////////////
	/////////////////////////////////////////////////////////

	//Create the straight lines radiating outward from the center
	var axis = axisGrid.selectAll(".axis")
		.data(allAxis)
		.enter()
		.append("g")
		.attr("class", "axis");
	//Append the lines
	axis.append("line")
		.attr("x1", 0)
		.attr("y1", 0)
		.attr("x2", function(d, i){ return rScale(maxValue*1) * Math.cos(angleSlice*i - Math.PI/2); })
		.attr("y2", function(d, i){ return rScale(maxValue*1) * Math.sin(angleSlice*i - Math.PI/2); })
		.attr("class", "line")
		.style("stroke", "white")
		.style("stroke-width", "2px");

	//Append the labels at each axis
	axis.append("text")
		.attr("class", "legend")
		.style("font-size", "11px")
		.attr("text-anchor", "middle")
		.attr("dy", "0.35em")
		.attr("x", function(d, i){ return rScale(maxValue * cfg.labelFactor) * Math.cos(angleSlice*i - Math.PI/2); })
		.attr("y", function(d, i){ return rScale(maxValue * cfg.labelFactor) * Math.sin(angleSlice*i - Math.PI/2); })
		.text(function(d){return d})
		.call(wrap, cfg.wrapWidth);

	/////////////////////////////////////////////////////////
	///////////// Draw the radar chart blobs ////////////////
	/////////////////////////////////////////////////////////

	//The radial line function
	var radarLine = d3.svg.line.radial()
		.interpolate("linear-closed")
		.radius(function(d) { return rScale(d.value); })
		.angle(function(d,i) {	return i*angleSlice; })


	if(cfg.roundStrokes) {
		radarLine.interpolate("cardinal-closed");
	}

	//Create a wrapper for the blobs
	var blobWrapper = g.selectAll(".radarWrapper")
		.data(data)
		.enter().append("g")
		.attr("class", "radarWrapper");

	// Append the backgrounds
	// blobWrapper
	// 	.append("path")
	// 	.attr("class", "radarArea")
	// 	.attr("d", function(d,i) { return radarLine(d); })
	// 	.style("fill", function(d,i) { return cfg.color(i); })
	// 	.style("fill-opacity", cfg.opacityArea)
	// 	.on('mouseover', function (d,i){
	// 		//Dim all blobs
	// 		d3.selectAll(".radarArea")
	// 			.transition().duration(200)
	// 			.style("fill-opacity", 0.1);
	// 		//Bring back the hovered over blob
	// 		d3.select(this)
	// 			.transition().duration(200)
	// 			.style("fill-opacity", 0.7);
	// 	})
	// 	.on('mouseout', function(){
	// 		//Bring back all blobs
	// 		d3.selectAll(".radarArea")
	// 			.transition().duration(200)
	// 			.style("fill-opacity", cfg.opacityArea);
	// 	});
	//
	// // Create the outlines
	// blobWrapper.append("path")
	// 	.attr("class", "radarStroke")
	// 	.attr("d", function(d, i) { return radarLine(d); })
	// 	.style("stroke-width", cfg.strokeWidth + "px")
	// 	.style("stroke", function(d,i) { return cfg.color(i); })
	// 	.style("fill", "none")
	// 	.style("filter" , "url(#glow)");

	//Append the circles
	blobWrapper.selectAll(".radarCircle")
		.data(function(d,i) { return d; })
		.enter().append("circle")
		.attr("class", "radarCircle")
		.attr("r", cfg.dotRadius)
		.attr("cx", function(d,i){ return rScale(d.value) * Math.cos(angleSlice*i - Math.PI/2); })
		.attr("cy", function(d,i){ return rScale(d.value) * Math.sin(angleSlice*i - Math.PI/2); })
		.style("fill", function(d,i,j) { return cfg.color(j); })
		.style("fill-opacity", 0.8);

	//Append sum circle areas
	let sum_avg = 0;
	let avgs = [];
	data.forEach(function(ia, idx){
		let avg = ia.sum('value')/ia.length;
		avgs.push(avg);
		sum_avg += avg
	});

	blobWrapper.append("circle")
            .attr("class", "sumArea")
            .attr("r", function(d, i){return rScale(avgs[i])})
            .attr("cx", 0)
            .attr("cy", 0)
            .style("stroke", function(d, i){return cfg.color(i)})
            .style("stroke-width", 1)
            .style("stroke-opacity", 0.3)
            .style("fill-opacity", 0.03)
            .style("fill", function(d, i){return cfg.color(i)})
            .style("fill", "none")
            .style("filter" , "url(#glow)")
    // data.forEach(function(ia, idx){
    //     //let scaled_surface = (avgs[idx] / sum_avg) * 100 * Math.PI;
    //     //let scaled_radius = Math.sqrt(scaled_surface/Math.PI);
    //     blobWrapper.append("circle")
    //         .attr("class", "sumArea")
    //         .attr("r", rScale(avgs[idx]))
    //         .attr("cx", 0)
    //         .attr("cy", 0)
    //         .style("stroke", cfg.color(idx))
    //         .style("stroke-width", 1)
    //         .style("stroke-opacity", 0.3)
    //         .style("fill-opacity", 0.03)
    //         .style("fill", cfg.color(idx))
    //         .style("fill", "none")
    //         .style("filter" , "url(#glow)")
    //     // blobWrapper.append("circle")
    //     //     .attr("class", "sumArea")
    //     //     .attr("r", rScale(sum))
    //     //     .attr("cx", 0)
    //     //     .attr("cy", 0)
    //     //     .style("fill", cfg.color(idx))
    //     //     .style("fill-opacity", 0.1);
	// })

	/////////////////////////////////////////////////////////
	//////// Append invisible circles for tooltip ///////////
	/////////////////////////////////////////////////////////

	//Wrapper for the invisible circles on top
	var blobCircleWrapper = g.selectAll(".radarCircleWrapper")
		.data(data)
		.enter().append("g")
		.attr("class", "radarCircleWrapper");

	//Append a set of invisible circles on top for the mouseover pop-up
	blobCircleWrapper.selectAll(".radarInvisibleCircle")
		.data(function(d,i) { return d; })
		.enter().append("circle")
		.attr("class", "radarInvisibleCircle")
		.attr("r", cfg.dotRadius*1.5)
		.attr("cx", function(d,i){ return rScale(d.value) * Math.cos(angleSlice*i - Math.PI/2); })
		.attr("cy", function(d,i){ return rScale(d.value) * Math.sin(angleSlice*i - Math.PI/2); })
		.style("fill", "none")
		.style("pointer-events", "all")
		.on("mouseover", function(d,i) {
			let newX =  parseFloat(d3.select(this).attr('cx')) - 10;
			let newY =  parseFloat(d3.select(this).attr('cy')) - 10;

			tooltip
				.attr('x', newX)
				.attr('y', newY)
				.text(Format(d.value.toFixed(2)))
				.transition().duration(200)
				.style('opacity', 1);
		})
		.on("mouseout", function(){
			tooltip.transition().duration(200)
				.style("opacity", 0);
		});

	//Set up the small tooltip for when you hover over a circle
	var tooltip = g.append("text")
		.attr("class", "tooltip")
		.style("opacity", 0);

	/////////////////////////////////////////////////////////
	/////////////////// Helper Function /////////////////////
	/////////////////////////////////////////////////////////

	//Taken from http://bl.ocks.org/mbostock/7555321
	//Wraps SVG text
	function wrap(text, width) {
	  text.each(function() {
		var text = d3.select(this),
			words = text.text().split(/\s+/).reverse(),
			word,
			line = [],
			lineNumber = 0,
			lineHeight = 1.4, // ems
			y = text.attr("y"),
			x = text.attr("x"),
			dy = parseFloat(text.attr("dy")),
			tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", dy + "em");

		while (word = words.pop()) {
		  line.push(word);
		  tspan.text(line.join(" "));
		  if (tspan.node().getComputedTextLength() > width) {
			line.pop();
			tspan.text(line.join(" "));
			line = [word];
			tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
		  }
		}
	  });
	}//wrap

}//RadarChart
    },
}
</script>
<style scoped>
/* #radar-graph svg {
  width: 100%;
  height: 100%;
} */
tspan {
    font-size: small;
}

text {
	font-size: 19px !important;
}
</style>
