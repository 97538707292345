<template>
  <div class="alternative-lang-link-container">
  <span v-if="lang == 'NL'" class="login-text alternative-lang-link">
    Geïnteresseerd in het gebruik van onze <br>Engelstalige Database/Mindspeller-community?<br> Registreer <a :href="url" target="_blank">hier</a>.
  </span>
  <span v-else-if="lang == 'EN'" class="login-text alternative-lang-link">
    Interested in using our <br>Dutch-speaking Database/Mindspeller community? Sign up <a :href="url" target="_blank">here</a>.
  </span>
    </div>
</template>

<script>
export default {
  name: "AlternativeLangLink",
  data: function() {
    return {
      lang: String,
      url: String,
    }
  },
  created() {
    let def_lang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE');
    let current_url = window.location.href;

    if (def_lang == 'en') {
      this.lang = 'NL';
      this.url = current_url.replace('en', 'nl');
    } else if (def_lang == 'nl') {
      this.lang = 'EN'
      this.url = current_url.replace('nl', 'en');
    };

  }
}
</script>

<style scoped lang="scss">
.alternative-lang-link-container {
  margin-top: 10px;
}
.alternative-lang-link {
  marign-top: 10px;
  padding-top: 10px;
}
.alternative-lang-link a {
  color: $mdspblue_ui;
}
</style>