<template>
<div class="asset-name-edit">
    <input v-model="asset.display_name" type="text" />
    <div class="button-row">
        <q-btn class="editBtn" :loading="processingSave" @click="setDisplayName" size="16" icon="eva-save-outline" />
    </div>
</div>
</template>

<script>
import axios from "axios";
import {
    alert_error,
    toast_success
} from "@/helpers/alert_helper";

export default {
    name: "AssetNameEdit.vue",
    emits: ['closeAssetNameEdit'],
    props: {
        asset: {
            required: true,
            type: String
        },
    },
    data: function () {
        return {
            originalDisplayName: new String,
            processingSave: false,
        }
    },
    computed: {
        saveDisabled: function () {
            return this.originalDisplayName === this.$props.asset.display_name;
        },
    },
    methods: {
        closeAssetNameEdit: function () {
            this.$emit('closeAssetNameEdit');
        },
        setDisplayName: async function () {
            try {
                this.processingSave = true;
                const response = await axios.post(
                    '/api/cas/assets/name/change', {
                        'id': this.$props.asset.id,
                        'display_name': this.$props.asset.display_name
                    }
                );
                this.processingSave = false;
                toast_success('Name succesfully changed!');
                this.originalDisplayName = this.$props.asset.display_name;
                this.$emit('closeAssetNameEdit');
            } catch (error) {
                if (error.response.status === 403) {
                    alert_error(this.$t('The user does not have access to this asset.'));
                    this.processingSave = false;
                    this.$emit('closeAssetNameEdit');
                } else {
                    alert_error(this.$t('message.general_server_error'));
                    this.processingSave = false;
                    this.$emit('closeAssetNameEdit');
                }
            }
        },
    },
    mounted() {
        this.originalDisplayName = this.$props.asset.display_name;
    },
    watch: {
        asset: function (value) {
            this.$emit('closeAssetNameEdit');
        },
    }
}
</script>

<style lang="scss" scoped>
.asset-name-edit {
    display: flex;
    flex-flow: column;
}

.button-row {
    padding: 5px 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.cancel-button {
    background-color: white;
    color: $mdspblue_ui;
}

.q-btn:before {
    box-shadow: none;
    color: blue;
}
</style>
