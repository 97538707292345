import $ from 'jquery';
import * as d3 from 'd3-4';

let margin = {
    "bottom": 0,
    "top": 0,
    "left": 0,
    "right": 0
};
var self;

export default class ExplorerChart {
    constructor(id, data, vue_vm) {
        self = this;
        this.data = data;
        this.vue_vm = vue_vm;
        this.nodes = {};
        this.node_idx = 1;
        this.cfg = {
            w: 1600,			        // Width of the graph
            h: 900,				        // Height of the graph
            font_family: 'Open+Sans',   // Font family of the labels
            line_width: 3,              // Width of the connecting lines
            fontsize: 11,               // Fontsize of the words
            gapsize: 100,               // Gap between the two graphs
            colors: d3.scaleOrdinal().range(['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231']),
        };

        //Remove whatever chart with the same id/class was present before
        d3.select("#" + id).select("svg").remove();

        //Initiate the explorer chart SVG
        var svg = d3.select("#" + id).append("svg")
            .attr("viewBox", "0 0 " + this.cfg.w + " " + this.cfg.h)
            .attr('preserveAspectRatio', 'xMidYMid meet');
        //Append a g element
        var g = svg.append("g")
        // .attr("transform", "translate(" + (cfg.w/2 ) + "," + (cfg.h/2) + ")");

        // Upper graph specs
        let upper_graph_specs = {
            "y_range": [(this.cfg.h / 2) - (this.cfg.gapsize / 2), 0],
            "x_scale_pos": 350,
            "title_y_pos": 20,
            "y_pos": 0,
            "color_1": "#fff2cb",
            "color_2": "#ffc104",
            "y_min": 0,
            "y_max": d3.max(data, d => d['originalityScore']),
            "field": "originality",
             "field_name": 'originality',
            "line_offset": 1,
            "tooltip": "Concepts that are not commonly associated with your given Intended Associations, but for some people they are strongly associated."
        }

        // Lower graph specs
        let lower_graph_specs = {
            "y_range": [0, (this.cfg.h / 2) - (this.cfg.gapsize / 2)],
            "x_scale_pos": 85,
            "title_y_pos": 350,
            "y_pos": this.cfg.h / 2 + (this.cfg.gapsize / 2),
            "color_1": "#964fca",
            "color_2": "#d8e2f2",
            "y_min": d3.max(data, d => d['popularityScore']),
            "y_max": 0,
            "field": "popularity",
            "field_name": 'popularity',
            "line_offset": -1,
            "tooltip": "The most common concepts between your given Intended Associations."
        }

        renderPartialGraph(data, upper_graph_specs);
        renderPartialGraph(data, lower_graph_specs);

        function renderPartialGraph(data, options) {
            // Transform the data
            data.forEach(word => {
                word.value = parseFloat(word[options['field'] + "Score"])
            });
            // sort by value
            data.sort(function (a, b) {
                return d3.descending(a.value, b.value)
            });

            let x = d3.scaleBand()
                .range([0, self.cfg.w - 50])

            x.domain(data.map(function (d) {
                return d.word;
            }));

            var y = d3.scaleLinear()
                .domain([0, d3.max(data, d => d.value)]).nice()
                .range(options['y_range'])
            //
            var xAxis = g => g
                .attr("transform", `translate(-15, ${options['x_scale_pos']})`)
                .call(d3.axisBottom(x).ticks(self.cfg.w / 80).tickSizeOuter(0))
                .style("stroke-opacity", "0")
                .selectAll("text")
                .attr("transform", "rotate(-45)")
                .style("text-anchor", "start")
                .attr("font-size", self.cfg.fontsize + "px")
                .attr("font-weight", "bold")
                .on("click", self.clicked)
                .nodes().map(function (d) {
                    d.id = "label-" + options['field'] + "-" + d.textContent;
                });

            var yAxis = g => g
                .call(d3.axisLeft(y))
                .call(g => g.select(".domain").remove())
                .call(g => g.select(".tick:last-of-type text").clone()
                    .attr("x", 3)
                    .attr("text-anchor", "start")
                    .attr("font-weight", "bold")
                    .text(data.y))

            var area = d3.area()
                .x(d => x(d.word))
                .y0(y(0))
                .y1(d => y(d.value))

            var sub_graph = g.append("g")
                .attr("id", "sub-graph-" + options['field'])
                .attr("transform", `translate(-0, ${options['y_pos']})`)

            sub_graph.append("linearGradient")
                .attr("id", "color-gradient-" + options['field'])
                .attr("gradientUnits", "userSpaceOnUse")
                .attr("x1", 0).attr("y1", y(options['y_min']))
                .attr("x2", 0).attr("y2", y(options['y_max']))
                .selectAll("stop")
                .data([
                    {offset: "0%", color: options['color_1']},
                    {offset: "100%", color: options['color_2']}
                ])
                .enter().append("stop")
                .attr("offset", function (d) {
                    return d.offset;
                })
                .attr("stop-color", function (d) {
                    return d.color;
                })

            sub_graph.append("path")
                .datum(data)
                .attr("transform", `translate(20,0)`)
                .attr("class", "area")
                .attr("d", area);

            data.forEach(function (d) {
                sub_graph.append("line")
                    .attr("transform", `translate(20,0)`)
                    .attr("x1", x(d.word))
                    .attr("y1", y(0) + options['line_offset'])
                    .attr("x2", x(d.word))
                    .attr("y2", y(d.value))
                    .attr("id", 'line-' + options['field'] + "-" + d.word)
                    .attr("class", 'select-line')
                    .style("stroke", "white")
                    .style("stroke-width", self.cfg.line_width + "px")
            })


            sub_graph.append("g")
                .call(xAxis);
            // svg.append("g")
            //   .call(yAxis);

            //Title
            let title = sub_graph.append("text")
                .attr("x", (self.cfg.w / 2))
                .attr("y", options['title_y_pos'])
                .attr("text-anchor", "middle")
                .style("font-size", "18px")
                .style("font-weight", "bold")
                .text(options['field_name'] + " " + 'ranking' )
                .append("svg:title")
                .text(options['tooltip']);

            d3.selectAll("text").attr("font-family", self.cfg.font_family);
        }

    }

    clicked(p) {
        // If first time selected
        if (!self.nodes[p]) {
            self.addToSelectedNodes(p);
            self.vue_vm.addWordToList(p);
        } else {
            if (self.nodes[p].selected) {
                self.select(p);
                self.vue_vm.addWordToList(p);
            } else {
                self.clearSelection(p);
                self.vue_vm.removeWordFromList(p);
            }
        }
    }

    addToSelectedNodes(p) {
        // if (self.node_idx <= 5) {
        self.nodes[p] = {'selected': true};
        //~ explorer_controller.addWordToShortList(p, self.node_idx);
        this.select(p);
        //~ explorer_controller.selectCheckbox(p);
        self.node_idx++;
        // }
    }

    select(p) {
        self.nodes[p].selected = !self.nodes[p].selected;
        this.colorText(p);
        this.colorLines(p)
        this.drawConnectingLine(p);
    }

    drawConnectingLine(p) {

        let upper_word = d3.select("#line-originality" + "-" + p)
        let lower_word = d3.select("#line-popularity" + "-" + p)
        let upper_x = parseFloat(upper_word.attr('x1'));
        let lower_x = parseFloat(lower_word.attr('x1'));
        let upper_y = (this.cfg.h / 2) - (this.cfg.gapsize / 2);
        let lower_y = (this.cfg.h / 2) + (this.cfg.gapsize / 2);

        let svg = d3.select("#explorer-graph svg g");
        svg.append("line")
            .attr("transform", `translate(20,0)`)
            .attr("x1", lower_x)
            .attr("y1", lower_y)
            .attr("x2", upper_x)
            .attr("y2", upper_y)
            .attr("id", 'middle-line-' + p)
            .attr("class", 'middle-line')
            .style("stroke", this.cfg.colors(p))
            .style("stroke-width", this.cfg.line_width);
    }

    colorLines(p) {
        let color = this.cfg.colors(p);
        d3.select("#line-originality" + "-" + p).style("stroke", color);
        d3.select("#line-popularity" + "-" + p).style("stroke", color);
    }

    colorText(p) {
        let text_upper = d3.select("#label-originality-" + p).node();
        let text_lower = d3.select("#label-popularity-" + p).node();
        let SVGRect_upper = text_upper.getBBox();
        let SVGRect_lower = text_lower.getBBox();

        let g_upper = d3.select(text_upper.parentNode);
        let g_lower = d3.select(text_lower.parentNode);

        g_upper.append("rect").lower()
            .attr('id', "mark-upper-" + p)
            .attr("class", "text-mark")
            .attr("x", SVGRect_upper.x)
            .attr("y", SVGRect_upper.y)
            .attr("width", SVGRect_upper.width)
            .attr("height", SVGRect_upper.height)
            .attr("transform", "rotate(-45)")
            .attr("fill", this.cfg.colors(p));

        g_lower.append("rect").lower()
            .attr('id', "mark-lower-" + p)
            .attr("class", "text-mark")
            .attr("x", SVGRect_lower.x)
            .attr("y", SVGRect_lower.y)
            .attr("width", SVGRect_lower.width)
            .attr("height", SVGRect_lower.height)
            .attr("transform", "rotate(-45)")
            .attr("fill", this.cfg.colors(p));
    }

    clearSelection(p) {
        self.nodes[p].selected = !self.nodes[p].selected;
        $("#mark-lower-" + p).remove();
        $("#mark-upper-" + p).remove();
        $("#middle-line-" + p).remove();
        d3.select("#line-originality" + "-" + p).style("stroke", "white");
        d3.select("#line-popularity" + "-" + p).style("stroke", "white");
    }

    clearAll() {
        $(".select-line").css("stroke", "white");
        $(".middle-line").remove();
        $(".text-mark").remove();
    }

}
