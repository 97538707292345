<template>
    <label id="v1b6m3P5-control" class="checkbox keyword-list-item">
        <input type="checkbox" checked="" @click.stop="clickCampaign($event)">
        <span>{{ campaign.name }}</span>
    </label>
</template>

<script>

export default {
    emits: ['campaignSelected', 'campaignUnselected'],
    props: {
        campaign: { required: true, type: Object },
    },
    methods: {
        clickCampaign: function(event) {
            if (event.target.checked) {
                this.$emit('campaignSelected', this.campaign);
            } else {
                this.$emit('campaignUnselected', this.campaign);
            }
        }
    },
}
</script>
