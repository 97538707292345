import axios from 'axios';
import { alert_error } from '@/helpers/alert_helper.js';

function updateToken() {
  const refresh_token = localStorage.getItem('jwt_refresh_token');
  const config = {
    headers: { 'X-Authorization': `Bearer ${refresh_token}` },
    // mdsp_retry is not a real axios config flag; it's just used here to break
    // the infinite loop of retries in case the token cannot be refreshed
    mdsp_retry: true,
  };
  return axios.post('/api/cas/token/refresh', {}, config).then(response => {
    localStorage.setItem('jwt_token', response.data['x-jwt-access-token']);
    localStorage.setItem('jwt_refresh_token', response.data['x-jwt-refresh-token']);
  });
}

setInterval(function(){ updateToken(); }, 14 * 60 * 1000);

// axios interceptors inserts the Authorization token into every request
async function requestConfig(config) {
  // if the Authorization header hasn't been set we inject it before making the
  // request; if it has been set, that means that this request is a 'refresh
  // token' request, in which case the Authorization token is already set to
  // the refresh token value and it should be kept that way
  const token = localStorage.getItem('jwt_token');
  if (!config.headers.hasOwnProperty('X-Authorization')) {
    config.headers['X-Authorization'] = `Bearer ${token}`;
  }
  return config;
}

function requestError(err) {
  return Promise.reject(err);
}

/* If we receive 401 unauthorized from the server we try to refresh the token */
function responseError(error) {
  let originalRequest = error.config;
  
  // if the failed response is a token refresh, we delete both tokens from
  // local storage and redirect to the login page
  if (error.response &&
      error.response.status === 401 
      && localStorage.getItem('jwt_token')
      // && error.response.config.url === '/api/cas/token/refresh'
  ) {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('pageText');
    localStorage.removeItem('jwt_refresh_token');
    localStorage.removeItem('pageName');
    window.location.replace('/#/login');
  }
  
  // if it's another request (i.e. not token refresh) that failed, we try to 
  // refresh the token and try again  
  else if (originalRequest &&
           error.response &&
           error.response.status === 401 &&
           !originalRequest.hasOwnProperty('mdsp_retry')
  ) {
    // once the token has been updated, we resend the failed request
    return updateToken().then(() => {
      // after the token has been refreshed, we add the new token to the header
      // and try again
      const token = localStorage.getItem('jwt_token');
      originalRequest.headers['X-Authorization'] = `Bearer ${token}`;
      return axios.request(originalRequest);
    });
  } 
  else {
    return Promise.reject(error);
  }
}

export default class AxiosHelper {
  constructor() {
    this.axiosSetup = function() {
      // if base URL isn't specified in the meta tag we will try localhost
      // let baseUrl = 'https://en.mindspeller.com';
      // let baseUrl = 'https://dev-en.mindspell.be';
      // let baseUrl = 'https://stg-en.mindspell.be';
      // let baseUrl = 'https://stg-nl.mindspell.be';
      let baseUrl = 'http://127.0.0.1:5000'
      if (document.querySelector('meta[name="VUE_APP_API"]') !== null) {
        baseUrl = document.querySelector('meta[name="VUE_APP_API"]').content;
      }
      axios.defaults.baseURL = baseUrl;
      // request interceptors will be executed before the request is sent
      axios.interceptors.request.use(requestConfig, requestError);
      
      // response interceptors will be executed after the server responds
      axios.interceptors.response.use(null, responseError);
    };
    this.updateToken = updateToken;
  }
}
