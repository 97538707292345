<template>
    <div v-if="stimulus" style="display: block; text-align: center; margin: 0 auto; margin-bottom: 10px; font-size: 18px;">
        {{ stimulus.word }}
    </div>

    <div v-if="stimulus" class="asset-image">
        <img v-if="stimulus.type.isImage" v-bind:src="stimulus.src" @click="$refs.fullscreen_preview.open">
        <div v-if="stimulus.type.isVideo">
            <video controls>
                <source :src="stimulus.src" :type="stimulus.mime">
            </video>
        </div>
        <div v-if="stimulus.type.isAudio">
            <audio controls>
                <source :src="stimulus.src" :type="stimulus.mime">
            </audio>
        </div>
    </div>

    <!-- asset info -->
    <div class="container">
        <div>
            <label class="label">{{ $t('message.status').toUpperCase() }}</label>
            <p>Review</p>
        </div>
        <div>
            <label class="label mapping">{{ $t('message.mapped').toUpperCase() }}</label>
            <p>
                <select disabled>
                    <option value="" disabled selected hidden>......</option>
                </select>
            </p>
        </div>
        <div>
            <label class="label">{{ $t('message.type').toUpperCase() }}</label>
            <p>{{ type_name }}</p>
        </div>
    </div>
    <full-screen-preview-survey-cleaning :asset="stimulus" ref="fullscreen_preview"/>
</template>
  
<script>

import axios from 'axios';
import FullScreenPreviewSurveyCleaning from "@/components/common/FullScreenPreviewSurveyCleaning";

export default {
    components: { FullScreenPreviewSurveyCleaning},
    props: {
        word: {
            required: true,
            type: String,
        },
        survey: {
            required: true,
            type: Object,
        },

    },

    data() {
        return {
            stimuli: null,
            stimulus: null,
            src: null,
            envVars: {},
            isImageVisible: false,
            hideImageOriginal: false,
        };
    },

    created: function () {
        this.loadEnvVar('VUE_APP_API');
    },
    
    computed: {
        type_name: function () {
            if (this.stimulus) {
                return this.getTypeName(this.stimulus.type);
            }
            else {
                return '......';
            }
        },
    },

    methods: {
        handleClick(navigateTo) {
            window.scrollTo(0, 0);
            this.$router.push({
                path: navigateTo
            })
        },

        showImage() {
          this.isImageVisible = true;
          this.hideImageOriginal = true;
          document.body.style.overflow = "hidden"; // Disable scrolling
        },
        hideImage() {
          this.isImageVisible = false;
          this.hideImageOriginal = false;
          document.body.style.overflow = "auto"; // Enable scrolling
        },

        async fetchData(id, token) {
            try {
                const response = await axios.post(`/surveys/assets_source?id=${id}&token=${token}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                this.stimuli = response.data;
                this.stimulus = this.stimuli.find(item => item.word_id === Number(this.word));
            } catch (error) {
                this.error = error.message;
                console.log(this.error);
            }
        },


        loadEnvVar: function (varName) {
            let varValue = '';
            if (typeof (process.env[varName]) !== 'undefined') {
                varValue = process.env[varName];
            }
            else if (document.querySelector(`meta[name="${varName}"]`) !== null) {
                varValue = document.querySelector(`meta[name="${varName}"]`).content;
            }
            this.envVars[varName] = varValue;
        },
        getTypeName: function (type) {
            if (type.isImage) {
                return this.$t("message.image").toLowerCase();
            }
            if (type.isAudio) {
                return this.$t("message.audio").toLowerCase();
            }
            if (type.isVideo) {
                return this.$t("message.video").toLowerCase();
            }
            else {
                return this.$t("message.word").toLowerCase();
            }
        },
    },

    watch: {
        word: {
            immediate: true,
            handler(newWord) {
                if (this.stimulus) {
                    this.stimulus = this.stimuli.find(item => item.word_id === Number(newWord));
                }
            },
        },

        survey: {
            immediate: true,
            handler(newsurvey) {
                if (newsurvey) {
                    const surveyId = newsurvey['surveyId'];
                    const token = newsurvey['token'];
                    this.fetchData(surveyId, token);
                }
            },
        },
    },
};
</script>


<style scoped>
.btn-primary {
    background-color: #ffffff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    width: 20vw;
}

.asset-image {
    display: block;
    text-align: center;
    margin: 0 auto;
}
.asset-image img {
    border-radius: 0px;
    width: 200px;
    max-height: 165px;
    object-fit: contain;
    flex-basis: 100%;
    margin-top: 10px;
    cursor: zoom-in;
}

.fullscreen-image {
      position: relative;
      width: 400px;
      height: 350px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    }

.fullscreen-image img {cursor: zoom-out;}


.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
}

.container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Style for the labels */
.label {
    font-weight: bold;
    margin-right: 10px;
    margin-bottom: 20px;
}

.magnifier-icon {
  width: 24px;
  height: 24px;
}
</style>
