<template>
  <div class="headingbar">
    <span v-if="displayArrow" @click="$router.push('dashboard')"></span
    >{{ title }}
    <span class="info-tooltip"
      ><img src="../../assets/info-tooltip.png" />
      <div class="tooptip-text">
        {{ desc }}
      </div>
    </span>
     <div class="instVideo">
    <instructions-video-button
      v-if="instructionsVideoUrl !== ''"
      :tooltipText="$t('message.watch_instructions_video')"
      :instructionsVideoUrl="instructionsVideoUrl"
      :instructionsVideoTitle="instructionsVideoTitle"
      :instructionsName="instructionsName"
      :suppress-video="suppressVideo"
    >
    </instructions-video-button>
  </div>
  </div>
 
</template>

<script>
import InstructionsVideoButton from '@/components/common/InstructionsVideoButton.vue';

export default {
  components: { InstructionsVideoButton },
  props: {
    title: {
      required: true,
      type: String,
    },
    desc: {
      required: true,
      type: String,
    },
    instructionsVideoUrl: { default: '', type: String },
    instructionsVideoTitle: { default: '', type: String },
    instructionsName: { default: '', type: String },
    displayArrow: { default: true, type: Boolean },
    // Cancels instructional video
    suppressVideo: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@import '../../css/global_style';

.page-title {
  display: flex;
  margin-left: 75px;
  max-height: 100px;
}

.page-title div {
  display: flex;
}

.page-title .nav {
  align-items: center;
}

.page-title .tip {
  align-items: baseline;
}

.back-arrow img {
  //width: 32px;
  //height: 32px;
  cursor: pointer;
}

.app-label {
  margin-left: 15px;
  font-family: 'Open Sans';
  font-size: 2rem;
  font-weight: 300;
  color: $mdspblue_ui;
}

@media (min-width: 1024px) {
  .tip img {
    width: 12px;
    height: 12px;
  }

  .back-arrow img {
    width: 30px;
    height: 30px;
  }

  .page-title {
    margin-top: 40px;
  }
}

@media (min-width: 1600px) {
  .tip img {
    width: 18px;
    height: 18px;
  }

  .back-arrow img {
    width: 40px;
    height: 40px;
  }

  .page-title {
    margin-top: 50px;
  }
}

@media (min-width: 1920px) {
  .tip img {
    width: 24px;
    height: 24px;
  }

  .back-arrow img {
    width: 40px;
    height: 40px;
  }

  .page-title {
    margin-top: 60px;
  }

}
  .instVideo {
    float: right;
}
</style>
