<template>
  <asset-library
    :standalone="isModal ? true : false"
    :show_positionings="true"
    :suppress-video="openLibrary"
    ref="asset-library"
    :clicked="clicked"
    :onPositioningSelected="onPositioningSelected"
    :allowed_types="allowed_types"
    :positioned_assets_only="true"
    @assetAndPositioningSelected="onAssetAndPositioningSelected"
    :libraryKey='libraryKey'
    :isModal="isModal"
    :disable_tracking="true"
  >
  </asset-library>
</template>

<script>
import AssetLibrary from '../asset_library/AssetLibrary.vue';

export default {
  name: 'Mindspeller',
  data() {
        return {
            libraryKey: 'mindspeller'
        }
    },
  components: {
    AssetLibrary,
  },
  props: {
    openLibrary: {
      required: false,
      default: false,
      type: Boolean,
    },
    showBeingMapped: {
      required: false,
      default: false,
      type: Boolean,
    },
    clicked: Function,
    onPositioningSelected: Function,
    show_positionings: String,
    allowed_types: String,
    standalone: String,
    positioned_assets_only: String,
    assetAndPositioningSelected: Function,
    isModal: Boolean,
  },
  mounted: function() {
    if (this.$props.openLibrary) {
      this.$refs['asset-library'].onAddNewAsset();
    }
    if (this.$props.showBeingMapped) {
      this.$refs['asset-library'].onAssetStateChanged('being_positioned');
    }
  },
};
</script>
