import { ref } from 'vue';

export function useDialog() {
  // data
  const visible = ref(false);
  
  // methods
  const open = () => {
    visible.value = true;
  }
  
  const close = () => {
    visible.value = false;
  }
  
  return { visible, open, close };
}

