<template>
  <div class="data-pocket">
    <span class="step-label">
      <p v-if="!firstAssetSelected">Concept 1</p>
      <single-asset
        @selectedAsset="onSelectedAsset1"
        ref="first-single-asset">
      </single-asset>
    </span>
  </div>
  <div class="data-pocket">
    <span class="step-label">
      <p v-if="!secondAssetSelected">Concept 2</p>
      <single-asset
        @selectedAsset="onSelectedAsset2"
        ref="second-single-asset">
      </single-asset>
    </span>
  </div>
</template>

<script>
import SingleAsset from '@/components/target_pickers/assets/SingleAsset.vue';

export default {
  components: { SingleAsset },
  emits: ['bothAssetsSelected'],
  data: function() {
    return {
      first_asset: {},
      second_asset: {},
    }
  },
  computed: {
    firstAssetSelected: function() {
      return Object.keys(this.first_asset).length > 0;
    },
    secondAssetSelected: function() {
      return Object.keys(this.second_asset).length > 0;
    },
  },
  methods: {
    reset: function() {
      this.first_asset = {};
      this.second_asset = {};
      this.$refs['first-single-asset'].reset();
      this.$refs['second-single-asset'].reset();
    },
    onSelectedAsset1: function(asset) {
      this.first_asset = asset;
      this.$refs['first-single-asset'].setAsset(asset);
      this._emit_if_both_selected();
    },
    onSelectedAsset2: function(asset) {
      this.second_asset = asset;
      this.$refs['second-single-asset'].setAsset(asset);
      this._emit_if_both_selected();
    },
    _emit_if_both_selected: function() {
      if (
        Object.keys(this.first_asset).length > 0 &&
        Object.keys(this.second_asset).length > 0
      ) {
        this.$emit('bothAssetsSelected', this.first_asset, this.second_asset);
      }
    },
  },
}
</script>

<style scoped>
.rightBar .wt-box-col .data-pocket {
    text-align: center;
}
.rightBar .wt-box-col .data-pocket span  {
    text-align: center;
}

.rightBar .wt-box-col .data-pocket span p {
    text-align: center;
}
</style>