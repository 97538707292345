<template>
  <div v-if="showWelcomeMessage">
    <div class="bg-trans" style="display: block; width: 100%; padding: 30px; border-radius: 25px;">
      <div
      class="hello-msg"
      style="
        display: block;
        width: 40%;
        height: 70%;
        padding: 105px;
        /* position: absolute; */
        /* top: 10%; */
        /* left: 12.5%; */
        background-color: rgba(120, 120, 233, 0.97);
        border-radius: 50%;
        color: #ffffff;
      "
    >
        <strong style="font-size: 24px;">Welcome!</strong>
        <ul style="margin-top: 15px; font-size: 16px;">
          <li style="margin-bottom: 10px;">
            - To get started, copy and paste your brand URL to select the concepts you want to test, or use your free Mindcoins to transfer benchmarks from the “Mindspeller Library” to “My Library.”
          </li>
          <li style="margin-bottom: 10px;">
            - Explore “MindBias” under the “Tools” menu to analyze your concepts.
          </li>
          <li>
            - Want to upload your own ideas? Buy more Mindcoins to add them to “My Library” and start testing!
          </li>
        </ul>
        <div style="display: flex; justify-content: flex-end; margin-top: 10px;">
          <span><a class="cursor-p" @click="dismissWelcomeMessage" style="font-size: 16px; color: #3f51b5; text-decoration: underline;">OK</a></span>
        </div>
      </div>
    </div>
</div>
  <section class="middle-section">
    <div class="container">
      <div class="inspiration-pg library-pg">
        <div class="popup-select-asset">
          <div class="lft-col-md-1">
            <div class="colm-1-top">
              <h3>Select Concepts</h3>

              <ul>

                <li v-bind:class="!active.length ? 'active' : ''" v-on:click="handleActive('mindspeller')">
                  Mindspeller Library
                </li>
                <li v-bind:class="active.length ? 'active' : ''" v-on:click="handleActive('library')">
                  My Library
                </li>
              </ul>
            </div>

            <div class="top-btn-col">
              <div class="col-btn-md-1">
                <div v-if="active.length">
                  <MyLibrary @clicked="onClickChild" :onPositioningSelected="onPositioningSelected" :isModal="false"/>
                </div>
                <div v-else>
                  <Mindspeller @clicked="onClickChild" :onPositioningSelected="onPositioningSelected" :isModal="false"/>
                </div>
              </div>
            </div>
          </div>

          <div class="rgt-col-md-1 scroll">
            <!-- <div class="rightBar scroll"> -->
            <sidebar :show_positionings="true" :asset="selected_asset"
              :assetType="active === 'my_library' ? assetType : 'store'" :showAdditionalOptions="showAdditionalOptions"
              :onPositioningSelected="onPositioningSelected" :onStoreAssetsPurchased="onStoreAssetsPurchased" @showLifecycle="handleShowLifecycle" @showRatingExplanation="handleShowRatingExplanation" @ratingExplanationData="handleRatingExplanationData">
            </sidebar>
            <!-- </div> -->
          </div>
        </div>
      </div>
      <q-dialog v-model="showLifecycle" style="width: 100% !important;">
        <div slot="content" style="width: 70%; height:50%;">
          <ConceptLifecycle :lifecycle=selected_asset.lifecycle :lightVersion=false />
        </div>
      </q-dialog>
      <q-dialog v-model="showRatingExplanation">
        <div class="dialog-content">
          <q-tabs
            v-model="activeTab"
            vertical
            class="tabs"
          >
            <q-tab name="clarity" icon="info" label="Clarity" />
            <q-tab name="emotional" icon="mood" label="Emotional" />
            <q-tab name="ethical" icon="check" label="Ethical" />
            <q-tab name="value" icon="flag" label="Value" />
            <q-tab name="archetype" icon="person" label="Archetype" />
            <q-tab name="uniqueness" icon="fingerprint" label="Uniqueness" />
          </q-tabs>
          <q-tab-panels
            v-model="activeTab"
            animated
            swipeable
            vertical
            transition-prev="jump-up"
            transition-next="jump-up"
            class="tab-panels"
          >
            <q-tab-panel name="clarity" style="height: 100%; width:100%;">
              <ClarityRatingExplanation :ratingExplanationData="ratingExplanationData"></ClarityRatingExplanation>
            </q-tab-panel>
            <q-tab-panel name="emotional">
              <div class="text-h4 q-mb-md">Concept Emotional Analysis</div>
              <p>Coming Soon...</p>
            </q-tab-panel>
            <q-tab-panel name="ethical">
              <div class="text-h4 q-mb-md">Concept Ethical Analysis</div>
              <p>Coming Soon...</p>
            </q-tab-panel>
            <q-tab-panel name="value">
              <div class="text-h4 q-mb-md">Concept Brand Value Analysis</div>
              <p>Coming Soon...</p>
            </q-tab-panel>
            <q-tab-panel name="archetype">
              <div class="text-h4 q-mb-md">Concept Brand Archetype Analysis</div>
              <p>Coming Soon...</p>
            </q-tab-panel>
            <q-tab-panel name="uniqueness">
              <div class="text-h4 q-mb-md">Concept Uniqueness Analysis</div>
              <p>Coming Soon...</p>
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </q-dialog>
    </div>
  </section>
</template>

<script>
import MyLibrary from './myLibrary.vue';
import Mindspeller from './mindspeller.vue';
import SearchBar from '../asset_library/SearchBar.vue';
import Sidebar from '../asset_library/sidebar/Sidebar.vue';
import ConceptLifecycle from '../asset_library/ConceptLifecycle.vue';
import RatingBellCurve from '../asset_library/sidebar/RatingBellCurve.vue';
import ClarityRatingExplanation from '../asset_library/sidebar/ClarityRatingExplanation.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  emits: ['assetAndPositioningSelected'],
  name: 'Library',
  components: {
    MyLibrary,
    Mindspeller,
    SearchBar,
    Sidebar,
    ConceptLifecycle,
    RatingBellCurve,
    ClarityRatingExplanation
  },
  data() {
    return {
      active: 'my_library',
      assetType: '',
      selected_asset: {},
      showLifecycle: null,
      showRatingExplanation: null,
      ratingExplanationData: {},
      activeTab: 'clarity',
    };
  },
  props: {
    // active:{
    //   default:'my_library',
    //   type:String,
    // },
    standalone: {
      default: false,
      type: Boolean,
    },
    suppressVideo: {
      type: Boolean,
      default: false,
    },
    positioned_assets_only: {
      type: Boolean,
      default: false,
    },
    openLibrary: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  mounted: function () {
    this.$store.dispatch('initializeWelcomeMessage');
    this.initializeWelcomeMessage().then(() => {
      console.log('Welcome message initialized');
    });
    if (this.$props.openLibrary) {
      this.$refs['search-bar'].onAddNewAsset();
    }
    const activeParam = this.$route.query.active;
    if (activeParam !== undefined) {
      this.active = activeParam;
    }
  },
  computed: {
    ...mapGetters(['showWelcomeMessage']),
    // we only want to show the store in the standalone version (i.e. in
    // the Library app)
    showWelcomeDialog() {
      return this.$store.getters.showWelcomeMessage;
    },
    showStore: function () {
      return this.standalone;
    },
    // only enable additional options (such as asset tracking toggle and
    // jumpstart) in the standalone version
    showAdditionalOptions: function () {
      return true;
    },
    showAddAssetButton: function () {
      return this.standalone;
    },
  },
  methods: {
    ...mapActions(['initializeWelcomeMessage', 'dismissWelcomeMessage']),
    dismissWelcomeMessage() {
      this.$store.dispatch('dismissWelcomeMessage');
    },
    handleShowLifecycle: function(data){
      this.showLifecycle = data
    },
    handleShowRatingExplanation: function(data){
      this.showRatingExplanation = data
    },
    handleRatingExplanationData: function(data){
      this.ratingExplanationData = data
    },
    handleActive: function (value) {
      if (value === 'mindspeller') {
        this.active = ''
      }
      else if (value === 'library') {
        this.active = 'my_library'
      }
      this.selected_asset = {};
    },
    onSearch: async function (filter) {
      this.filter = filter;
    },
    onClickChild(value1, value2) {
      this.selected_asset = value1;
      this.assetType = value2;
    },

    onPositioningSelected: function (positioning) {
      this.selected_positioning = positioning;
      this.$emit(
        'assetAndPositioningSelected',
        this.selected_asset,
        this.selected_positioning
      );
    },
    onStoreAssetsPurchased: function (asset) {
      this.$refs['store-grid'].reFetchAssets(asset);
      if (this.$refs['search-bar'].assetState == 'being_positioned') {
        this.selected_asset = {};
        return;
      } else {
        this.$refs['asset-grid'].addAsset(asset);
        this.selected_asset = {};
        return;
      }
    },
  },
};
</script>

<style scoped>
.rightBar.scroll {
  max-height: 100vh !important;
  overflow-x: hidden;
  margin-bottom: 50px;
  padding-top: 8vw;
}

.lft-col-md-1 {
  height: 100vh;
  padding-top: 7vw !important;
}

.library-pg .popup-select-asset .rgt-col-md-1 {
  height: 100vh;
}

.rgt-col-md-1 {
  padding-top: 8vw;
}
.q-dialog__inner--minimized > div {
  max-width: 100% !important;
  width: 75%; 
  height: 60%; 
  overflow-y: visible;
  overflow-x: hidden;
}

.dialog-content {
  width: 90%; 
  height: 100%; 
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  background-color: white;
}

.tabs {
  height: 100%;
  width: 15%;
  background-color: #7878E9;
  color: white;
  margin-left: 5px;
}
.tab-panels {
  width: 100%;
  overflow-y: auto;
}
</style>