<template>
    <div class="ver-col-box">
        <div class="tooltip-container">
            <h4>
                {{ $t('message.version') }} {{ versionText }}
            </h4>
        </div>
        <div class="al-img-col" @click="this.$refs['co-asset-picker'].openAssetPicker()">
            <img v-if="imageSelected" class="" v-bind:src="this.selectedImage.urls.thumb_medium"
                @click="this.$refs['co-asset-picker'].openAssetPicker()" />
            <div class="add_asset" v-else>
                <img src="../../assets/black-img-icon.png" class="imgcls" />

                <div v-if="generic == 'true'"> Step 1:</div>
                Click here to add your concept

            </div>
        </div>

        <div class="al-img-col graybx">

            <div :id="editorID" spellcheck="false" @copy="copyText" @paste="pasteText" contentEditable="true"
                v-bind:placeholder="placeholderText" v-on:input="updateCharCounter" class="editorTest scroll"
                ref="editor-select">
                <span></span>
            </div>


            <q-dialog v-model="sliders">
                <q-card style="width: 300px" class="q-px-sm q-pb-md">
                    <q-card-section>
                        <div class="text-h6">Emotion</div>
                    </q-card-section>

                    <q-item-label header>Emotional valence ({{ parseInt(valence) }}%)</q-item-label>
                    <q-item dense>
                        <q-item-section>
                            <q-slider color="teal" v-model="valence" :step="0" />
                        </q-item-section>
                    </q-item>

                    <q-item-label header>Emotional arousal ({{ parseInt(arousal) }}%)</q-item-label>
                    <q-item dense>
                        <q-item-section>
                            <q-slider color="teal" v-model="arousal" :step="0" />
                        </q-item-section>
                    </q-item>

                    <q-item-label header>Emotional dominance ({{ parseInt(dominance) }}%)</q-item-label>
                    <q-item dense>
                        <q-item-section>
                            <q-slider color="teal" v-model="dominance" :step="0" />
                        </q-item-section>
                    </q-item>
                    <div style="display:flex;justify-content: center;">
                        <q-btn rounded label="Cancel" v-close-popup style="margin-right:  1vw;" />
                        <q-btn rounded label="Confirm" :disable="this.getEditorText() == 0" v-close-popup
                            @click="handleChangeTone" />
                    </div>
                </q-card>
            </q-dialog>


            <div class="bottom-no-col" style="width: 100%; margin-bottom: 1vw;">

                <hr style="width: 100%;margin-bottom: 0.4vw;">

                <div :style="{ 'display': 'flex', 'justify-content': generic === 'true' ? 'space-between' : 'flex-start' }">
                    <div>
                        <span>{{ editorChars }}/{{ editorMaxChars }}</span>{{ $nbsp }}
                        <span>{{ $t('message.characters').toLowerCase() }}</span>
                    </div>
                    <div v-if="generic == 'true'" style="display: flex; justify-content: flex-end;">
                        <div>
                            <span class="tooltip-icon" @mouseenter="showTooltip = true" @mouseleave="showTooltip = false">
                                <img @click="handleClick" src="../../assets/info-tooltip.png" alt="Tooltip"
                                    id="tooltip-image" />
                                <span class="tooltip-text" v-if="showTooltip">
                                    Go to story creator to fine-tune <br>
                                    the AI generated (generic) story!
                                    <span class="tooltip-arrow"></span>
                                </span>
                            </span>
                        </div>
                        <div style="margin-left: 0.5vw;">
                            <q-btn @click="sliders = true" style="font-size: 0.7vw;">
                                Step 3: Tune
                                <q-icon size="0.9vw" name="eva-flash-outline" />
                            </q-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <transition name="fade">
            <text-statistics v-if="showStatistics" :text_stats="textStatistics"></text-statistics>
        </transition>
    </div>
    <asset-picker ref="co-asset-picker" :allowed_types="['images']" :show_positionings="false" @addAsset="selectImage">
    </asset-picker>

    <loading-app-wide v-if="display_loading_overlay" />
</template>

<script>
import LoadingAppWide from "@/components/common/LoadingAppWide.vue";
import axios from 'axios';
import $ from 'jquery';
import {
    mutationObserverCallback,
    mutationObserverOptions,
    escapeScriptTags,
    restoreScriptTags,
    setEditorHTML,
} from './CoHelper';
import AssetPicker from '../asset_picker/AssetPicker';
import {
    alert_error
} from '@/helpers/alert_helper';
import TextStatistics from '@/components/content_tuner/TextStatistics';
import { ref } from 'vue'

export default {
    setup() {
        return {
            sliders: ref(false),
            valence: ref(50),
            arousal: ref(50),
            dominance: ref(50)
        }
    },
    props: {
        version: String,
        versionText: String,
        placeholderText: String,
        generic: String,
        new_text: String,
    },
    emits: ['suggestion', 'editorContentChange', 'editorTextImageEmpty', 'tone_text'],
    components: {
        AssetPicker,
        TextStatistics,
        LoadingAppWide
    },

    computed: {
        // Dynamically created editor ID, used to reference input div in case if
        // there are more than one editors.
        editorID: function () {
            return 'editor-input-' + this.version;
        },
        imageSelected: function () {
            return Object.keys(this.selectedImage).length > 0;
        },
        editorTextImageEmpty: function () {
            return (
                (!this.editorChars > 0 && !this.imageSelected) ||
                (this.editorText == '\n' && !this.imageSelected)
            );
        },
        demoUser: function () {
            return this.$store.state.current_license.is_demo == 1;
        },
    },

    data() {
        return {
            showTooltip: false,
            showButton: false,
            selectedText: "",
            showTooltip: false,
            display_loading_overlay: false,
            selected_ia: {},
            textValue: null,
            selectedImage: {},
            // JSON containing score returned from API (used to $emit suggestions).
            generatedScore: {},
            // Plain text stripped from editor-view div.
            editorContent: new Text(),
            // HTML stripped from editor-view div.
            editorHTML: new Text(),
            editorMaxChars: this.$store.state.current_license.co_limit_characters,
            editorChars: 0,
            editorText: '',
            textStatistics: {
                average_sentence_length: 0,
                average_word_length: 0,
                characters: 0,
                readability_score: 0,
                reading_time: 0,
                sentences: 0,
                words: 0,
            },
            showStatistics: false,
            // Characters displayed in the char counter below.
            excludedCharacters: [
                '.',
                ',',
                ';',
                ':',
                '-',
                '(',
                ')',
                '{',
                '}',
                '"',
                "'",
                '0',
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
            ],
            // Colors used to reflect MAS score.
            scores: {
                10: '#2FFF18',
                9: '#63FF1F',
                8: '#96FF25',
                7: '#C5FF2B',
                6: '#F3FF31',
                5: '#FFE037',
                4: '#FFB83D',
                3: '#FF9343',
                2: '#FF7049',
                1: '#FF5050',
            },
        };
    },
    methods: {
        async handleChangeTone() {
            if (!this.editorIsEmpty()) {
                let emotion_scale = {
                    'valence': Math.trunc(this.valence),
                    'arousal': Math.trunc(this.arousal),
                    'dominance': Math.trunc(this.dominance),
                    'text': this.getEditorText()
                }
                this.display_loading_overlay = true;

                const new_story = await axios.post('/api/cas/path_finder/change_tone', {
                    emotion: emotion_scale,
                });
                this.$emit('tone_text', new_story.data);
                this.display_loading_overlay = false;
            }
        },


        // checkSelection() {
        //     const selection = window.getSelection();
        //     if (selection.rangeCount > 0) {
        //         const range = selection.getRangeAt(0);
        //         const editor = this.$refs['editor-select'];

        //         if (
        //             range.startContainer === editor ||
        //             editor.contains(range.startContainer)
        //         ) {
        //             this.showButton = true;
        //             const button_select = this.$refs['button-select'];
        //             const rect = range.getBoundingClientRect();

        //             button_select.style.position = "absolute";
        //             const top = (rect.top - 510) / window.innerHeight * 100 + 'vh';
        //             const left = (rect.right - 160) / window.innerWidth * 100 + 'vw';

        //             button_select.style.top = top;
        //             button_select.style.left = left;

        //         } else {
        //             this.showButton = false;
        //         }
        //     } else {
        //         this.showButton = false;
        //     }
        // },


        handleClick: function () {
            window.open(this.$router.resolve('/story_creator').href, '_blank');
        },

        setText: function (text) {
            let textArea = document.getElementById('pathfinder-textarea');
            textArea.innerText = text;
        },
        reset: function () {
            this.selected_ia = {};
            this.selectedImage = {};
            this.setEditorText('');
            this.showStatistics = false;
        },
        generateResults: async function (selected_ia) {
            if (this.editorIsEmpty() && $.isEmptyObject(this.selectedImage)) {
                return {
                    text_score: null,
                    image_score: null,
                    emotional_score: {
                        valence: null,
                        arousal: null,
                        dominance: null,
                    },
                    text_stats: null,
                };
            }
            let images = [];
            if (!$.isEmptyObject(this.selectedImage)) {
                images = [{
                    src: '',
                    asset: this.selectedImage.id,
                },];
            }
            try {
                const response = await axios.post(
                    // "keywords" - legacy name for IA. We pass an array containing only one IA id.
                    // "text" - plain text from the editor. We strip an invisible character from it.
                    // "images" - array of selected image ids.
                    '/api/cas/copy_opt/analyze', {
                    keywords: [selected_ia.word_id],
                    text: this.getEditorText(),
                    images: images,
                }
                );
                var text_stats = {
                    data: {},
                };
                if (!this.editorIsEmpty()) {
                    text_stats = await axios.post('/api/cas/copy_opt/text_info', {
                        text: this.getEditorText(),
                    });
                }
                this.textStatistics = text_stats.data;
                // We set editor's HTML to plain text to render spans, then we
                // iterate over tokens and generate span HTML tags.
                let cleaned_text = escapeScriptTags(this.editorText);
                setEditorHTML(this.editorID, cleaned_text);

                let tokens = response.data.text.Tokens[selected_ia.word];
                this.generatedScore = tokens;
                tokens.forEach((token) => {
                    if (token.Type !== 'SENT' && token.Type !== '$.') {
                        // if (token.Status !== "Excluded") {
                        this.generateTextScores(token.Word, token.Score, token.Status);
                    }
                });
                this.updateDecorationBindings();
                this.textAverageScore = response.data.text.AverageScore;
                let image_score = 0;
                if (!$.isEmptyObject(this.selectedImage)) {
                    image_score = response.data.images.Tokens[selected_ia.word][0].Score;
                }
                let emotional_score = {};
                if (!this.editorIsEmpty()) {
                    // We only get emotional scores per particular word and not every word
                    // has score. Hence we need to get mean score for the scored words.
                    emotional_score = this.calculateEmotionalMean(
                        response.data.text.Tokens[selected_ia.word]
                    );
                    this.showStatistics = true;
                } else {
                    (emotional_score = {
                        valence: null,
                        arousal: null,
                        dominance: null,
                    }),
                        (this.showStatistics = false);
                }
                return {
                    text_score: response.data.text.AverageScore,
                    image_score: image_score,
                    emotional_score: emotional_score,
                    text_stats: text_stats.data,
                };
            } catch (error) {
                if (
                    error.message ===
                    'Invalid regular expression: /\b?\b/: Nothing to repeat'
                ) { } else if (error.response.status === 403) {
                    alert_error(this.$t('message.no_access_to_items'));
                } else {
                    alert_error(this.$t('message.general_server_error'));
                }
            }
            return;
        },

        // We manipulate editor's HTML to change words into span tags, then we
        // update editor's HTML. This is done till every token is transformed
        // into spans.
        generateTextScores: function (word, score, status) {
            var test_var = this.excludedCharacters.includes(word);
            if (this.excludedCharacters.includes(word)) {
                return;
            }
            this.getEditorHTML();
            let indices = this.getWordIndices(word);
            indices.forEach((index) =>
                this.generateSpanTag(word, index, score, status)
            );
            setEditorHTML(this.editorID, this.editorHTML);
        },

        // Creating an actual HTML tag within the text.
        generateSpanTag: function (word, index, score, status) {
            let color_code = '';
            let parameter = 'true';

            if (status !== 'Excluded') {
                color_code = this.getColorScore(score);
            }
            if (status === 'NotFoundInDB') {
                parameter = 'false';
                color_code = 'color: #000AFF';
            }

            let span = document.createElement('span');
            span.textContent = this.editorHTML.slice(index.from, index.to);
            span.setAttribute('class', 'co-token');
            span.setAttribute('contenteditable', 'true');
            span.setAttribute('data-db-exists', parameter);
            span.setAttribute('style', color_code);

            let first_part = this.editorHTML.slice(0, index.from);
            let last_part = this.editorHTML.slice(index.to);

            let output = [first_part, span.outerHTML, last_part].join('');
            this.editorHTML = output;
            return;
        },

        // We create color underlining while protecting ourselves from negative
        // scores and rounding them to full numbers, since we only have 10 colors.
        getColorScore: function (score) {
            if (score < 1) {
                score = 1;
            }
            let roundedScore = this.scores[Math.round(score)];
            return 'box-shadow: inset 0 -1px 0 0 #f7f7f7, inset 0 -3px 0 0 ${roundedScore}'.replace(
                '${roundedScore}',
                roundedScore
            );
        },

        // Initializes MutationObserver for each .co-token element.
        updateDecorationBindings: function () {
            $('.co-token').each(function () {
                var target = this;
                var observer = new MutationObserver(mutationObserverCallback);

                observer.observe(target, mutationObserverOptions);
            });
        },

        // Initializes suggestion selector - when user clicks an underlined
        // text, right panel will get populated with word info and forward
        // associations.
        initSuggestionSelector: function () {
            $('#' + this.editorID).contextmenu((event) => {
                let attr_check = event.target.getAttribute('data-db-exists');
                var text = $(event.target).text();
                if (text.length > 0 && this.editorText.length > 0) {
                    let suggestion_obj = this.generatedScore.filter(
                        (w) => w.Word == text
                    );
                    if (suggestion_obj.length > 0) {
                        this.$emit('suggestion', {
                            data: suggestion_obj[0],
                            db_exists: attr_check,
                        });
                    }
                }
            });
        },

        // Updates character counter at the bottom of the editor. Has to be called
        // manually everytime content changes.
        updateCharCounter: async function () {
            //   this.editorChars = this.textValue.length;
            this.editorChars = this.getEditorText().length;
            if (this.editorChars > this.editorMaxChars) {
                alert_error(this.$t('message.exceeds_limit'));
                let currentText = this.getEditorText();
                this.setEditorText(currentText.slice(0, this.editorMaxChars));
                this.moveCursorToEnd(document.getElementById(this.editorID));
            }
        },

        // Putting editor's plain text into variable. We do so, because Vuejs doesn't
        // support v-model for contenteditable divs.
        getEditorText: function () {
            this.editorText = document
                .getElementById(this.editorID)
                .innerText.replace(/[\u200B-\u200D\uFEFF]/g, '');
            return restoreScriptTags(this.editorText);
        },

        // Populates editor with text.
        setEditorText: function (text) {
            document.getElementById(this.editorID).innerHTML = text;
            this.updateCharCounter();
        },

        getAssetId: function () {
            if (this.imageSelected) {
                return this.selectedImage.id;
            } else {
                return null;
            }
        },

        /* Set the selected image by asset id.*/
        setImage: async function (asset_id) {
            if (asset_id) {
                const response = await axios.get(`/api/cas/assets/${asset_id}`);
                this.selectedImage = response.data;
            }
        },

        // Putting editor's HTML into variable. We do so, because Vuejs doesn't
        // support v-model for contenteditable divs.
        getEditorHTML: function () {
            this.editorHTML = document.getElementById(this.editorID).innerHTML;
            return restoreScriptTags(this.editorHTML);
        },

        // Gets editor text content and matches all words with regex. Then it creates
        // lists of indices for every occurrence in source text. We reverse the array
        // containing occurrences, because if we started normally, we would extend
        // the text and initially generated indices would not match.
        getWordIndices: function (word) {
            let regex = new RegExp('\\b' + word + '\\b', 'g');
            var result = Object;
            let indices = [];
            let editorText = this.editorHTML;

            while ((result = regex.exec(editorText))) {
                indices.push({
                    from: result.index,
                    to: result.index + word.length,
                });
            }
            return indices.reverse();
        },

        // Inserts empty character to the editor. We do so because else the text
        // cursor would initially be huge and shrink after first keypress. We avoid
        // that bug with this trick. This character gets stripped when we send text
        // to the back-end.
        insertEmptyCharToEditor: function () {
            let editor = document.getElementById(this.editorID);
            editor.innerHTML = '\u200b';
        },

        moveCursorToEnd: function (el) {
            el.focus();
            if (
                typeof window.getSelection != 'undefined' &&
                typeof document.createRange != 'undefined'
            ) {
                var range = document.createRange();
                range.selectNodeContents(el);
                range.collapse(false);
                var sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
            } else if (typeof document.body.createTextRange != 'undefined') {
                var textRange = document.body.createTextRange();
                textRange.moveToElementText(el);
                textRange.collapse(false);
                textRange.select();
            }
        },

        // Tells whether editor is empty or not. Used as computed variable substitute.
        editorIsEmpty: function () {
            return this.getEditorText() == 0;
        },

        // Function overriding default copying event.
        copyText: function (event) {
            const selection = document.getSelection();
            event.clipboardData.setData(
                'text/plain',
                selection.replace(/[\u200B-\u200D\uFEFF]/g, '').toString()
            );
            event.preventDefault();
        },

        pasteText: function (event) {
            // get text representation of clipboard
            var text = (event.originalEvent || event).clipboardData.getData(
                'text/plain'
            );

            // insert text manually
            const selection = window.getSelection();
            selection.getRangeAt(0).insertNode(document.createTextNode(text));
            this.updateCharCounter();
            event.preventDefault();
        },

        selectImage: function (payload) {
            this.selectedImage = payload.asset;
            this.$refs['co-asset-picker'].closeAssetPicker();
        },

        calculateEmotionalMean(word_array) {
            let scored_words = word_array.filter((word) => 'emotions' in word);
            let emotion_scores = scored_words.map((word) => word.emotions);

            let valence = emotion_scores.map((score) => score.valence);
            let dominance = emotion_scores.map((score) => score.dominance);
            let arousal = emotion_scores.map((score) => score.arousal);

            // if (this.demoUser) {
            //     return {
            //         valence: valence.reduce((a, b) => a + b) / valence.length,
            //         dominance: null,
            //         arousal: null,
            //     };
            // } else {
            //     return {
            //         valence: valence.reduce((a, b) => a + b) / valence.length,
            //         dominance: dominance.reduce((a, b) => a + b) / dominance.length,
            //         arousal: arousal.reduce((a, b) => a + b) / arousal.length,
            //     };
            // }
            return {
                valence: valence.reduce((a, b) => a + b) / valence.length,
                dominance: dominance.reduce((a, b) => a + b) / dominance.length,
                arousal: arousal.reduce((a, b) => a + b) / arousal.length,
            };
        },
    },

    watch: {
        new_text(newValue, oldValue) {
            if (newValue) {
                // if (!this.editorIsEmpty()) {
                //     this.setEditorText(newValue);
                // }
                // overwrite the text field;
                this.setEditorText(newValue);
            }

        },
        editorText: function () {
            this.$emit('editorContentChange');
        },
        selected_ia: async function (value) {

            if (this.editorIsEmpty() && this.generic == 'true' && Object.keys(this.selected_ia).length > 0 && Object.keys(this.selectedImage).length > 0) {
                this.display_loading_overlay = true;
                //when the editor is empty then we generate with open AI
                const payload = { 'brand': this.selectedImage.id, 'word': this.selected_ia.word_id };
                const response = await axios.post('/api/cas/path_finder/generate_story_cas', payload);
                let text_response = response.data;
                if (text_response.length >= this.editorMaxChars) {
                    text_response = text_response.substring(0, this.editorMaxChars - 10) + '...';

                }
                this.setEditorText(text_response);
                this.display_loading_overlay = false;
            }
        },
        selectedImage: async function (value) {
            this.$emit('editorContentChange');
            if (this.editorIsEmpty() && this.generic == 'true' && Object.keys(this.selected_ia).length > 0 && Object.keys(this.selectedImage).length > 0) {
                this.display_loading_overlay = true;
                //when the editor is empty then we generate with open AI
                const payload = { 'brand': this.selectedImage.id, 'word': this.selected_ia.word_id };
                const response = await axios.post('/api/cas/path_finder/generate_story_cas', payload);
                let text_response = response.data;
                if (text_response.length >= this.editorMaxChars) {
                    text_response = text_response.substring(0, this.editorMaxChars - 10) + '...';

                }
                this.setEditorText(text_response);
                this.display_loading_overlay = false;
            }
        },
        editorTextImageEmpty: function (value) {
            this.$emit('editorTextImageEmpty', {
                data: value,
                editor: this.version,
            });
        },
    },

    mounted() {
        // Populating variables.
        this.getEditorText();
        this.getEditorHTML();
        // Inserting empty character.
        this.insertEmptyCharToEditor();
        this.initSuggestionSelector();
},

    beforeUnmount() { },
};
</script>

<style lang="scss" scoped>
/*
Setting editor's display to inline-block prevents from creating divs whenever
we click enter. This way we ensure line beak consistency between before and after
generation.
*/

.bottom-no-col {
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 1.6vw;
    color: #405261;
    position: relative;
    bottom: 0.5vw;
}

.al-img-col {
    overflow: hidden;
    height: 19.8vw;
    background: #FFFFFF;
    border: 0.1vw solid #0A00FF;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 0.3vw 0.3vw 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 2vw;
    color: #1B2F3F;
    flex-wrap: wrap;
    flex-direction: column;
    cursor: pointer;
}

.custom-popup {
    width: auto;
    height: auto;
    max-width: 200px;
    max-height: 200px;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
}

.popup-card {
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    width: 200px;
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    z-index: 10;
}

.custom-button {
    position: absolute;
    background-color: rgba(255, 255, 255);
    border: none;
    padding: 10px;
    color: white;
    z-index: 5;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-button:hover {
    background-color: #2980b9;
}

.editor {
    flex-basis: 50%;
    display: flex;
    flex-flow: column;
}

.editor-input {
    display: inline-block;
    flex: 1 1 0;
    margin-top: 10px;
    overflow: auto;
}

.editor-view {
    display: flex;
    flex-flow: column;
    box-shadow: 0px 8px 0px #f7f7f7;
    flex: 1;
    outline: 0px solid transparent;
    background-color: #f7f7f7;
    font-family: 'Open Sans';
    padding: 5px 20px;
    position: relative;
}

[contenteditable] {
    outline: 0px solid transparent;
}

.asset-img-container {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f7;
    padding: 10px;
    flex: 1;
    margin-bottom: 10px;
    overflow: hidden;
}

.asset-img-container img {
    max-height: 200px;
    max-width: 100%;
    cursor: pointer;
    width: 50%;
    flex-shrink: 0;
    object-fit: contain;
}

.asset-img-container .add-icon {
    width: 30%;
}

.add-asset-button {
    width: auto !important;
    padding: 0 10px;
}

.custom-button {
    margin-left: 5px;
}

.verDiv {
    width: 57vw !important;
}

// .al-img-col {
//     overflow: hidden;
// }

.add_asset {
    border-radius: 0.3vw 0.3vw 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 2vw;
    color: #1b2f3f;
    flex-wrap: wrap;
    flex-direction: column;
}

.editorTest {
    height: 14vw;
    /* overflow: hidden; */
}

.tooltip-container {
    position: relative;
    display: inline-block;
}

.custom-slider {
    --q-slider-height: 6px;
    --q-slider-handle-size: 16px;
    --q-slider-color: blue;
}

.tooltip-icon {
    position: relative;
    display: inline-block;
    cursor: pointer;
}


.tooltip-icon:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

#tooltip-image {
    cursor: pointer;
}

#tooltip-image:hover+.tooltip-text {
    visibility: visible;
    opacity: 1;
}

.tooltip-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.tooltip-icon img {
    width: 1.2vw;
}

.tooltip-text {
    font-size: 0.8vw;
    position: absolute;
    background-color: rgb(120, 120, 226);
    color: white;
    white-space: nowrap;
    padding: 5px 10px;
    border-radius: 5px;
    bottom: 2vw;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 101;
    text-transform: none;
}

.tooltip-icon:hover .tooltip-text {
    opacity: 1;
    visibility: visible;
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid rgb(120, 120, 226);
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
}
</style>
