<template>
  <section class="settings-workspace">
    <h2>
      <q-icon name="arrow_back" class="cursor-pointer" @click="cancel_edit" />
      {{ $t("message.edit_a_team_member") }}
    </h2>

    <div class="form">
      <div class="form-group">
        <label class="form-label">{{ $t("message.username") }}</label>
        <input class="form-control" disabled type="text" :value="username">
      </div>

      <div class="form-group">
        <label class="form-label">{{ $t("message.email") }}</label>
        <input class="form-control" disabled type="email" :value="email">
      </div>

      <div class="form-group">
        <label class="form-label">{{ $t("message.user_type") }}</label>
        <select class="form-control" v-model="selected_category">
          <option disabled selected value="">{{ $t("message.select") }}</option>
          <option v-for="c in user_categories" :value="c.id" :key="c.id">{{ c.name }}</option>
        </select>
      </div>

      <div class="form-group">
        <input type="checkbox" class="settings-checkbox" id="active-checkbox" v-model="is_active">
        <label class="settings-checkbox-label" for="active-checkbox">{{ $t("message.active") }}</label>
      </div>

      <div class="button-group">
        <button class="btn btn-primary" @click="update_user">{{ $t("message.save") }}</button>
        <button class="btn btn-outline btn-frame" @click="cancel_edit">{{ $t("message.cancel") }}</button>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { alert_error, alert_success } from '@/helpers/alert_helper.js';
import { QIcon } from 'quasar';

export default {
  components: {
    QIcon,
  },
  props: {
    id: { required: true, type: Number },
    username: { required: true, type: String },
    email: { required: true, type: String },
    type: { required: true, type: Number },
    active: { required: true, type: Boolean },
  },
  data: function () {
    return {
      selected_category: 0,
      is_active: false,
    }
  },
  methods: {
    cancel_edit: function () {
      this.$router.push({ name: 'My Account' });
    },
    update_user: function () {
      const payload = {
        'active': this.is_active,
        'category': this.selected_category,
      };

      axios.post(`/api/cas/users/update/${this.id}`, payload)
        .then(response => {
          alert_success(this.$t("message.user_updated"))
            .then(() => {
              this.$router.push({ name: 'My Account' });
            });
        })
        .catch(error => {
          alert_error(this.$t("message.error_user_update"));
        });
    },
  },
  computed: {
    // we take the user categories from the Vuex store (they only get loaded
    // once on beforeCreate of App.vue since they are constant and are used
    // in multiple places)
    user_categories: function () {
      var ignore = [3]
      return this.$store.state.user_categories.filter(cat => ignore.indexOf(cat.id) === -1);
    }
  },
  beforeMount: function () {
    // we set the values of the data variables based on the props
    this.selected_category = this.type;
    this.is_active = this.active;
  },
}
</script>

<style scoped>
.settings-workspace {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.settings-checkbox-label {
  margin-left: 10px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-frame {
  border: 1px solid #ccc;
  padding: 6px 12px;
  border-radius: 4px;
}
</style>