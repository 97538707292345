<template>
    <div class="button-container">
        <q-btn @click="handleClick('/association-wheel')" label="Association Wheel" class="btn-primary"></q-btn>
        <q-btn @click="handleClick('/mindbias')" label="Mindbias" class="btn-primary"></q-btn>
        <q-btn @click="handleClick('/competitive_profiler')" label="Competitive Profiler" class="btn-primary"></q-btn>
        <q-btn @click="handleClick('/connection_checker')" label="Connection Checker" class="btn-primary"></q-btn>
        <q-btn @click="handleClick('/inspiration-explorer')" label="Inspiration Explorer" class="btn-primary"></q-btn>
        <q-btn @click="handleClick('/story_creator')" label="Story Creator" class="btn-primary"></q-btn>
        <q-btn @click="handleClick('/content_tuner')" label="Content Tuner" class="btn-primary"></q-btn>
        <q-btn @click="handleClick('/brand_tracker')" label="Brand Tracker" class="btn-primary"></q-btn>
        <q-btn @click="handleClick('/brand_campaign')" label="Brand Campaign" class="btn-primary"></q-btn>
    </div>
</template>
<script>
export default {
    methods: {
        handleClick(navigateTo) {
            window.scrollTo(0, 0);
            window.open(this.$router.resolve({
                path: navigateTo
            }).href, '_blank');

        },

    },
};
</script>

<style scoped>
.button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    justify-content: space-around;
    height: 80vh;
}

.btn-primary {
    height: auto;
    background-color: #ffffff;
    margin-bottom: 1vw;
    padding: 12px 20px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 16px;
    width: 20vw;
    transition: background-color 0.3s, color 0.3s;
   
}

.q-btn {
    box-shadow: none;
}

.btn-primary:hover {
    background-color: #ffffff;
    color: #1f1fff;
    border-color: #1f1fff
}
</style>
  

