<template>
<div id="pathfinder-graph" class="pathfinder-graph"></div>
</template>

<script>
import axios from "axios";
import PathFinderChart from "./pathfinder.js";
import {
    alert_error
} from '@/helpers/alert_helper.js';

export default {
    emits: ['wordToggled'],
    data: function () {
        return {
            showing: false,
            data: {},
            d3_chart: {
                data: {}
            },
        }
    },
    methods: {
        exportChart: function () {
            const svg = document.querySelector("#pathfinder-graph svg");
            // using our custom D3export plugin to export the PNG
            this.$exportD3.saveSvgAsPng(svg, "Story Creator.png", {
                scale: 2
            });
        },
        load: async function (asset, ia) {
            const payload = {
                'brand': asset,
                'word': ia.word_id
            };
            try {
                const response = await axios.post('/api/cas/path_finder/analyze', payload);
                // remove the last element from the received data
                response.data.splice(-1, 1);
                this.data = response.data;
                this.d3_chart = new PathFinderChart(
                    this.data,
                    asset.display_name,
                    ia.word,
                    "pathfinder-graph", {}, // no options
                    '#pathfinder-controls',
                    this,
                );
                this.showing = true;
            } catch (error) {
                if (error.response.status === 403) {
                    alert_error(this.$t('message.no_access_to_items'));
                } else {
                    alert_error(this.$t('message.general_server_error'));
                }
            }
        },
        // this method is only called by the D3 chart (when a word is clicked in the
        // D3 chart)
        toggleWord: function (word) {
            this.$emit('wordToggled', word);
        },
        externalSelectWord: function (word) {
            this.d3_chart.externalWordToggle(word);
        },
        externalUnselectWord: function (word) {
            this.d3_chart.externalWordToggle(word);
        },
    },
}
</script>

<style lang="scss">
text {
    font-family: 'Open Sans', sans-serif;
}

.radio span {
    margin-left: 4px;
}

circle {
    cursor: pointer;
}

.tooltip {
    font-weight: bold;
}

.stop-left {
    stop-color: #DDD;
}

.stop-right {
    stop-color: #fff;
}

.filled {
    fill: url(#mainGradient);
}

.outlined {
    fill: none;
    stroke: url(#mainGradient);
    stroke-width: 4;
}

.tooltip {
    position: absolute;
    font-size: 12px;
    width: auto;
    height: auto;
    pointer-events: none;
    background-color: white;
}

text {
    font-size: 13px;
}

#pathfinder-graph {
    flex: 1;
    padding: 0 10px;
}

/* CONTROL CHECKBOXES STYLES*/

.control-1 input:checked {
    background-color: rgb(110, 64, 170);
    border-color: rgb(110, 64, 170);
}

.control-2 input:checked {
    background-color: rgb(143, 61, 178);
    border-color: rgb(143, 61, 178);
}

.control-3 input:checked {
    background-color: rgb(178, 60, 178);
    border-color: rgb(178, 60, 178);
}

.control-4 input:checked {
    background-color: rgb(210, 62, 167);
    border-color: rgb(210, 62, 167);
}

.control-5 input:checked {
    background-color: rgb(238, 67, 149);
    border-color: rgb(238, 67, 149);
}

.control-6 input:checked {
    background-color: rgb(255, 78, 125);
    border-color: rgb(255, 78, 125);
}

.control-7 input:checked {
    background-color: rgb(255, 94, 99);
    border-color: rgb(255, 94, 99);
}

.control-8 input:checked {
    background-color: rgb(255, 115, 75);
    border-color: rgb(255, 115, 75);
}

.control-9 input:checked {
    background-color: rgb(255, 140, 56);
    border-color: rgb(255, 140, 56);
}

.control-10 input:checked {
    background-color: rgb(239, 167, 47);
    border-color: rgb(239, 167, 47);
}

.control-11 input:checked {
    background-color: rgb(217, 194, 49);
    border-color: rgb(217, 194, 49);
}

.control-12 input:checked {
    background-color: rgb(194, 219, 64);
    border-color: rgb(194, 219, 64);
}

.control-13 input:checked {
    background-color: rgb(175, 240, 91);
    border-color: rgb(175, 240, 91);
}

.control-14 input:checked {
    background-color: rgb(135, 245, 87);
    border-color: rgb(135, 245, 87);
}

.control-15 input:checked {
    background-color: rgb(96, 247, 96);
    border-color: rgb(96, 247, 96);
}

.control-16 input:checked {
    background-color: rgb(64, 243, 115);
    border-color: rgb(64, 243, 115);
}

.control-17 input:checked {
    background-color: rgb(40, 234, 141);
    border-color: rgb(40, 234, 141);
}

.control-18 input:checked {
    background-color: rgb(28, 219, 169);
    border-color: rgb(28, 219, 169);
}

.control-19 input:checked {
    background-color: rgb(26, 199, 194);
    border-color: rgb(26, 199, 194);
}

.control-20 input:checked {
    background-color: rgb(33, 176, 213);
    border-color: rgb(33, 176, 213);
}

.control-21 input:checked {
    background-color: rgb(47, 150, 224);
    border-color: rgb(47, 150, 224);
}

.control-22 input:checked {
    background-color: rgb(65, 125, 224);
    border-color: rgb(65, 125, 224);
}

.control-23 input:checked {
    background-color: rgb(84, 101, 214);
    border-color: rgb(84, 101, 214);
}

.control-24 input:checked {
    background-color: rgb(99, 81, 195);
    border-color: rgb(99, 81, 195);
}

.control-25 input:checked {
    background-color: rgb(110, 64, 170);
    border-color: rgb(110, 64, 170);
}

#pathfinder-graph svg {
    width: 100%;
    height: 435px;
}
</style>
