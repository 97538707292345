<template>
  <section class="settings-workspace">
    <h2>
      <q-icon name="arrow_back" class="cursor-pointer" @click="close_change_password" />
      {{ $t("message.change_your_password") }}
    </h2>

    <div class="form">
      <div class="form-group">
        <label for="old-password" class="form-label">{{ $t("message.old_password") }}</label>
        <input class="form-control" id="old-password" v-model="old_password" :placeholder="$t('message.old_password')" type="password" />
      </div>

      <div class="form-group">
        <label for="new-password" class="form-label">
          {{ $t("message.new_password") }}
        </label>
        <input class="form-control" id="new-password" v-model="new_password" :placeholder="$t('message.new_password')"
          type="password" />
      </div>

      <div class="form-group">
        <label for="verify-password" class="form-label">
          {{ $t("message.verify_password") }}
        </label>
        <input class="form-control" id="verify-password" v-model="verify_password"
          :placeholder="$t('message.verify_password')" type="password" />
      </div>

      <div v-if="!passwords_match" class="alert alert-danger">
        <span>{{ $t("message.passwords_dont_match") }}</span>
      </div>

      <div class="button-group">
        <button class="btn btn-primary" @click="save_password" :disabled="!save_allowed">
          {{ $t("message.save") }}
        </button>
        <button class="btn btn-outline btn-frame " @click="close_change_password">
          {{ $t("message.cancel") }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { alert_error, alert_success } from '@/helpers/alert_helper.js';
import { QIcon } from 'quasar';

export default {
  components: {
    QIcon,
  },
  data: function () {
    return {
      old_password: '',
      new_password: '',
      verify_password: '',
    }
  },
  methods: {
    close_change_password: function () {
      this.$router.push({ name: 'My Account' });
    },
    save_password: function () {
      const payload = {
        old_password: this.old_password,
        new_password: this.new_password,
      };
      axios.post('/api/cas/users/change_password', payload)
        .then(response => {
          alert_success(this.$t("message.password_updated"))
            .then(() => {
              this.close_change_password();
            });
        })
        .catch(error => {
          alert_error(this.$t("message.old_password_incorrect"));
        });
    },
  },
  computed: {
    passwords_match: function () {
      return this.new_password === this.verify_password;
    },
    save_allowed: function () {
      const old_pass_long_enough = this.old_password.length > 5;
      const new_pass_long_enough = this.new_password.length > 5;
      return new_pass_long_enough && this.passwords_match && old_pass_long_enough;
    },
  },
}
</script>

<style scoped>
.settings-workspace {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-title {
  margin-bottom: 20px;
}

.form-group {
  /* margin-left: 60px; */
  /* max-width: 60vw; */
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-label {
  display: block;
  font-weight: bold;
}
.form {
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
}
.form-control {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.alert {
  margin-top: 10px;
  padding: 8px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
}

.btn-frame {
  border: 1px solid #ccc;
  padding: 6px 12px;
  border-radius: 4px;
}
.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
