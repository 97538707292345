<template>
    <section class="middle-section">
        <div class="container">
            <div class="inspiration-pg association-wheel-pg">
                <div class="inspir-lft-sec scroll">
                    <div class="leftBar ">
                        <workspace ref="workspace" @reset="reset"></workspace>
                    </div>
                </div>
                <div class="rightbox-sec scroll">
                    <div class="rightBar">
                        <left-sidebar ref="left-sidebar" @generate="onGenerate"></left-sidebar>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <loading-app-wide v-if="display_loading_overlay"></loading-app-wide>
</template>

<script>
import LeftSidebar from './LeftSidebar.vue';
import Workspace from './Workspace.vue';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';

export default {
    components: {
        LeftSidebar,
        Workspace,
        LoadingAppWide,
    },
    data: function () {
        return {
            display_loading_overlay: false,
        };
    },
    methods: {
        reset: function () {
            this.$refs['workspace'].reset();
            this.$refs['left-sidebar'].reset();
        },
        onGenerate: async function (first_asset, second_asset) {
            this.display_loading_overlay = true;
            await this.$refs['workspace'].generate(first_asset, second_asset);
            this.display_loading_overlay = false;
        },
        jumpstart: async function () {
            const jumpstartEmpty = await this.$store.dispatch('jumpstart_empty');
            if (!jumpstartEmpty) {
                const assetAndPositioning = await this.$store.dispatch('pop_jumpstart');
                this.$refs['left-sidebar'].manuallyAddAssetAndPositioning(
                    assetAndPositioning.asset
                );
            }
        },
    },
    mounted: async function () {
        this.jumpstart();
    },
};
</script>

<style scoped>
.container {
    padding-left: 5vw !important;
}
</style>
