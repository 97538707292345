<template>
<teleport to="#large_modal" v-if="visible">
<div class="overlay" @click="close">
    <div class="modal" style="display: flex;" @click.stop>
         
        <a class="close" @click="close">
            <img :src="require(`@/assets/times-light.svg`)" width="12" height="19">
        </a>

        <div class="modal-content">

            <h3 class="modal-content-title">
                {{ $t('message.add_campaign_marker') }}
            </h3>
            <div id="purchase-asset-preview" class="order-preview"></div>

            <div class="form" style="margin: 15px 0 0px 0;">
                
                <label class="form-label">
                    {{ $t('message.campaign_name') }}:
                </label>
                <input :placeholder="$t('message.name')"
                       type="text"
                       class="form-control"
                       v-model="name">

                <label class="form-label">{{ $t('message.start_date') }}:</label>
                <datepicker
                    v-model="start_date"
                    :placeholder="$t('message.start_date')"
                    class="form-control">
                </datepicker>

                <label class="form-label">{{ $t('message.end_date') }}:</label>
                <datepicker
                    v-model="end_date"
                    :placeholder="$t('message.end_date')"
                    class="form-control">
                </datepicker>
                
            </div>
            
            <footer class="modal-footer">
                <a class="btn btn-outline pull-left btn-close" @click="close">
                    {{ $t('message.close') }}
                </a>
                <button class="btn btn-primary pull-right"
                        @click="add"
                        :disabled="name.length === 0"
                        >{{ $t('message.add') }}
                </button>
            </footer>
        </div><!-- end .modal-content -->

    </div>
</div>
</teleport>
</template>

<script>
import Datepicker from 'vue3-datepicker';

export default {
    components: { Datepicker },
    emits: ['addCampaign'],
    data: function() {
        return {
            visible: false,
            name: new String(),
            start_date: new Date(),
            end_date: new Date(),
        }
    },
    methods: {
        open: function() {
            this.visible = true;
        },
        close: function() {
            this.visible = false;
        },
        add: function() {
            this.$emit('addCampaign', {
                name: this.name,
                start_date: this.start_date,
                end_date: this.end_date,
            });
            this.name = '';
            this.close();
        },
    },
}
</script>
