const target_picker_translation = {
    en: {
        message: {
            add_ia: 'Add an Intended Association (I.A.)',
            ia_tip: 'Add Intended Association(s)',
            framework_tip: 'Use Intended Association Set',
            add_assets: 'Add Concept from My Library',
            duplicate_ia: 'You can not add the same Intended Associations twice',
            insufficient_credits_for_ia: 'You do not have enough mindcoins to perform this action.',
            read_only_user_no_ia: 
                "You are a View Only user - you are not authorized to deduct mindcoins on behalf of " +
                "your organization. Please contact your organization administrator if " +
                "you'd like to edit your admin rights",
            purchase_ia: 'Do you want to add the word {word}?',
            ia_cost: 'This addition will deduct {price} mindcoins.',
            buy_multiple_words: 'Do you want to add the following words: {word_list}?',
            each_word_costs: 'Each word deducts {price} mindcoins ({total} mindcoins in total.',
            marketing_framework_insufficient_funds: 'You do not have sufficient mindcoins to upgrade this IA set.',
            added: 'Added {words}.',
            select_an_asset: 'Select a Concept',
            select_a_slogan: 'Add Concept from my library',
        },
    },
    nl: {
        message: {
            add_ia: 'Voeg een Gewenste Associatie (G.A.) toe',
            ia_tip: 'Voeg Gewenste Associatie(s) toe',
            framework_tip: 'Selecteer een raamwerk',
            add_assets: 'Voeg Concept toe via Mijn Bibliotheek',
            duplicate_ia: 'Je kan niet tweemaal dezelfde Gewenste Associatie toevoegen',
            insufficient_credits_for_ia: 'Je hebt niet genoeg Mindcoins om deze actie uit te voeren.',
            read_only_user_no_ia:
                "Je bent een View Only gebruiker - je bent niet bevoegd om assets toe te voegen namens uw " +
                "organisatie. Neem contact op met de beheerder van uw organisatie voor " +
                "wijziging van je admin rechten.",
            purchase_ia: 'Wil je dit woord {word} toevoegen?',
            ia_cost: 'Deze toevoeging vergt {price} mindcoins.',
            buy_multiple_words: 'Wil je devolgende woorden toevoegen: {word_list}?',
            each_word_costs: 'Elk woord vergt {price} mindcoins ({total} mindcoins in totaal).',
            marketing_framework_insufficient_funds: '',
            added: '{words} toegevoegd.',
            select_an_asset: 'Selecteer een Concept',
            select_a_slogan: 'Voeg Keyword toe via Mijn Bibliotheek',
        },
    }
};

export { target_picker_translation };
