<template>
  <section class="settings-workspace">
    <h2>
      <q-icon name="arrow_back" class="cursor-pointer" @click="cancel_invite" />
      {{ $t("message.invite_a_team_member") }}
    </h2>

    <div class="form">
      <div class="form-section-desc">
        <p>{{ $t("message.provide_user_details") }}</p>
      </div>

      <div class="form-group">
        <label class="form-label">{{ $t("message.username") }}</label>
        <input class="form-control" type="text" v-model="username" required>
      </div>

      <div class="form-group">
        <label class="form-label">{{ $t("message.user_type") }}</label>
        <select class="form-control" v-model="selected_category">
          <option disabled selected value="">{{ $t("message.select") }}</option>
          <option v-for="c in user_categories" :value="c.id" :key="c.id">
            {{ c.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label class="form-label">{{ $t("message.email") }}</label>
        <input class="form-control" type="email" v-model="email" required>
      </div>

      <div class="button-group">
        <button class="btn btn-primary" @click="send_invite">{{ $t("message.send_invitation") }}</button>
        <button class="btn btn-outline btn-frame" @click="cancel_invite">{{ $t("message.cancel") }}</button>
      </div>

    </div>
  </section>
</template>

<script>
import axios from "axios";
import { alert_error, alert_success } from '@/helpers/alert_helper.js';
import { QIcon } from 'quasar';

export default {
  components: {
    QIcon,
  },

  data: function () {
    return {
      username: '',
      selected_category: 0,
      email: '',
      usernameExists: true,
      emailExists: true,
    }
  },

  computed: {
    // we take the user categories from the Vuex store (they only get loaded
    // once on beforeCreate of App.vue since they are constant and are used
    // in multiple places)
    user_categories: function () {
      var ignore = [3]
      return this.$store.state.user_categories.filter(cat => ignore.indexOf(cat.id) === -1);
    }
  },

  methods: {
    cancel_invite: async function () {
      this.$router.push({ name: 'My Account' });
    },
    send_invite: async function () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.username.length < 3) {
        const msg = this.$t("message.username_less_than_3_chars");
        alert_error(msg);
        return;
      }

      if (!re.test(this.email)) {
        const msg = this.$t("message.enter_valid_email_address");
        alert_error(msg);
        return;
      }

      if (this.selected_category === 0) {
        const msg = this.$t("message.select_user_category");
        alert_error(msg);
        return;
      }

      await axios.post('/api/cas/users/exist/by_email', { 'email': this.email })
        .then(response => {
          this.emailExists = response.data;
        });

      await axios.post('/api/cas/users/exist/by_name', { username: this.username })
        .then(response => {
          this.usernameExists = response.data;
        });


      const payload = {
        'username': this.username,
        'category': this.selected_category,
        'email': this.email,
      };

      if (this.usernameExists && !this.emailExists) {
        const msg = "Username unavailable";
        alert_error(msg);
      }
      else if (this.emailExists && !this.usernameExists) {
        const msg = "Email unavailable";
        alert_error(msg);
      }
      else if (!this.usernameExists && !this.emailExists) {
        axios.post('/api/cas/users/invite', payload)
          .then(response => {
            alert_success(this.$t("message.invitation_sent"))
              .then(() => {
                this.$router.push({ name: 'My Account' });
              });
          })
          .catch(error => {
            const msg = this.$t("message.invitation_error");
            alert_error(msg);
          });
      } else if (this.usernameExists && this.emailExists) {
        const msg = "Username and Email unavailable";
        alert_error(msg);
      }

    },
  },
}
</script>

<style scoped>
.settings-workspace {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-section-desc {
  margin-bottom: 20px;
}


.form {
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
}

.form-label {
  display: block;
  font-weight: bold;
}

.input-group {
  margin-bottom: 20px;
  /* display: flex; */
  /* align-items: center; */
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-group label {
  display: block;
  font-weight: bold;
}

.input-group input {
  flex-grow: 1;
  width: 80%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.input-group select {
  flex-grow: 1;
  width: 80%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.form-control {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.form-group {
  margin-bottom: 20px;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-outline {
  background-color: transparent;
  border: 1px solid #007bff;
  color: #007bff;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-close {
  margin-left: auto;
}

.btn-frame {
  border: 1px solid #ccc;
  padding: 6px 12px;
  border-radius: 4px;
}
</style>
