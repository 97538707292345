<template>
  <!-- <div class="asset-checkbox-container">
    <img
      v-if="asset_and_positioning.asset.type_.isImage"
      :src="asset_and_positioning.asset.urls.thumb_small"
      class="checkbox-img"
    />
    <span class="b-60 asset-info">
      <span class="ellipsis-overflow">
        {{ asset_and_positioning.asset.display_name }}
      </span>
      <span
        class="ellipsis-overflow positioning-date"
        v-if="show_positionings && asset_and_positioning.positioning.to"
        >{{ asset_and_positioning.positioning.to }}
      </span>
    </span>
    <q-btn
      size="16"
      flat
      v-if="show_delete_btn"
      @click.prevent="removeAsset"
      color="black"
      icon="close"
    >
    </q-btn>
  </div> -->

  <div class="upload-img-col">
    <div class="uplod-img-bx">
      <!-- <img src="img/icon12.png"> -->
      <img
        v-if="asset_and_positioning.asset.type_.isImage"
        :src="asset_and_positioning.asset.urls.thumb_small"
      />
    </div>
    <div class="img-name-date">
      <h5>{{ asset_and_positioning.asset.display_name }}</h5>
      <span class="img-size">600 X 800</span>
      <span class="img-date">{{ asset_and_positioning.positioning.to }}</span>
    </div>
    <div
      class="delate-icon"
      v-if="show_delete_btn"
      @click.prevent="removeAsset"
    >
      <img src="../../../assets/delete-icon.png" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show_positionings: { default: false, type: Boolean },
    asset_and_positioning: { required: true, type: Object },
    show_delete_btn: { default: true, type: Boolean },
  },
  emits: ['removeAsset'],
  methods: {
    removeAsset: function() {
      this.$emit('removeAsset', this.asset_and_positioning);
    },
  },
};
</script>
<style scoped>
.asset-checkbox-container {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}

.asset-checkbox-container img {
  padding-left: 5px;
  object-fit: contain;
}

.keyword-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.asset-info {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-left: 15px;
}

.b-10 {
  flex-basis: 10%;
}

.b-60 {
  flex-basis: 60%;
}

.b-20 {
  flex-basis: 20%;
}

.checkbox {
  cursor: pointer;
  padding: 0 10px;
  line-height: 27px;
  border-radius: 4px;
  -moz-transition: 400ms all ease;
  -o-transition: 400ms all ease;
  -webkit-transition: 400ms all ease;
  transition: 400ms all ease;
}

.checkbox input,
.checkbox span {
  display: inline-block;
  vertical-align: middle;
}

.keyword-list-item .btnDelete,
.keyword-list-item .btnShowAssociations {
  margin-top: 0;
  position: initial;
}

.checkbox-img {
  width: 32px;
  max-height: 40px;
}

.checkbox span {
  color: #212529;
  font-size: 0.875rem;
  font-weight: normal;
  margin-right: 10px;
}

.ellipsis-overflow {
  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#sidebar input {
  margin: 5px 0 5px 5px;
}

.positioning-date {
  font-size: 0.8rem;
}
</style>
