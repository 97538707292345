<template>
  <div class="btn-col" v-if="showButton">
    <button
      class="purchase btn3"
      :disabled="this.asset_empty"
      @click="purchase"
    >
      <span class="addlibrary">{{ $t('message.add_to_my_library') }}</span>
      <span class="mindcoins">{{ assetPrice }} mindcoins</span>
    </button>
  </div>
</template>

<script>
import axios from 'axios';
import {
  alert_error,
  toast_success,
  genericDialog,
} from '@/helpers/alert_helper.js';

/*
This component contains the elements that only apply to the store assets (and
 not owned assets), such as the purchase button.
*/
export default {
  emits: ['assetPurchased'],
  props: {
    asset: {
      required: true,
      type: Object,
    },
  },
  computed: {
    assetPrice: function() {
      return this.$store.getters.get_price('public_asset');
    },
    // asset_empty: function() {
    //     return Object.keys(this.asset).length === 0;
    // },
    showButton: function() {
      return Object.keys(this.asset).length > 0;
    },
  },
  methods: {
    // getClass() {
    //   return {
    //     btn3: !this.asset_empty,
    //     'disabled-btn': this.asset_empty,
    //   };
    // },
    purchase: async function() {
      const dialog_options = {
        title: this.$t('message.buy_asset', {
          asset: this.asset.display_name,
        }),
        text: this.$t('message.asset_cost', {
          price: this.assetPrice,
        }),
        confirmButtonText: this.$t('message.add'),
        cancelButtonText: this.$t('message.cancel'),
      };
      const dialog_result = await genericDialog(dialog_options);
      if (dialog_result.isConfirmed) {
        try {
          const response = await axios.post(
            '/api/cas/assets/store/purchase',
            this.asset
          );
          this.$store.dispatch('get_mindcoin_balance');
          toast_success(
            this.$t('message.purchased_asset')
          );
          this.$emit('assetPurchased');
        } catch (error) {
          if (error.response.status === 422) {
            alert_error(this.$t('message.insufficient_credits_for_asset'));
          } else {
            alert_error(this.$t('message.general_server_error'));
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
button.btn3.purhcase {
  height: auto;
  text-transform: capitalize;
  font-size: 1.1vw;
  line-height: 1.5vw;
  padding: 0.5vw 1.5vw;
}

button.btn3.purhcase span.addlibrary {
  margin-bottom: 0;
}

button.btn3.purhcase span.mindcoins {
  margin-bottom: 0;
}

.q-btn--actionable.q-btn--standard:before {
  display: none;
}

.overlay
  .modal
  .inspiration-pg.library-pg
  .rgt-col-md-1
  .rightBar
  .btn-col
  button.purchase.disabled-btn {
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

.overlay
  .modal
  .inspiration-pg.library-pg
  .rgt-col-md-1
  .rightBar
  .btn-col
  button.purchase.btn3 {
  height: auto;
  line-height: inherit;
  font-size: 1.2vw;
  padding: 5px 0;
}

span.mindcoins {
  display: block;
}
</style>
