const shared_translation = {
    en: {
        message: {
            settings: 'Settings',
            username: 'Username',
            password: 'Password',
            email: 'Email',
            organization: 'Organization',
            save: 'Save',
            open: 'Open',
            cancel: 'Cancel',
            name: 'Name',
            type: 'Type',
            select: 'Select',
            enter_valid_email_address: 'Please enter a valid email address.',
            active: 'Active',
            your_mindcoins: 'Your mindcoins',
            sign_out: 'Sign out',
            help: 'Help',
            new: 'New',
            generate: 'Generate',
            new_document: 'New document',
            version: 'Version',
            characters: 'Characters',
            word: 'Word',
            score: 'I.A. recall score',
            click_here: 'Click here',
            association: 'Association',
            associations: 'Associations',
            purchase: 'Purchase',
            add: 'Add',
            asset_creator_add: 'Add',
            close: 'Close',
            your_documents: 'Your documents',
            no_match: 'Concept not surveyed',
            library_no_match: 'Concept not yet in your Library. Try searching in Mindspeller Library concepts and add to your Library.',
            loading: 'Loading',
            queued:"Queued",
            in_survey:"Surveying",
            in_cleaning:"In Review",
            in_ingestion:"Updating",
            done:"Ready",
            queued_description:"Your concept has been added to a survey queue",
            in_survey_description:"Your concept is being surveyed",
            in_cleaning_description:"Your concept survey results are in review for Quality Assurance",
            in_ingestion_description:"The network is being updated with your concept survey results",
            done_description:"You can now test your concept using the tools",
            not_positioned: 'Not mapped',
            being_positioned: 'Being mapped',
            being_repositioned: 'Being remapped',
            positioned: 'Mapped',
            audio: 'Audio',
            video: 'Video',
            image: 'Image',
            delete: 'Delete',
            reset: 'Reset',
            reset_color:'Reset color',
            export: 'Export',
            change: 'Change',
            workspace_empty: 'Your {app_name} results will appear here',
            no_access_to_items: 'You do not have access to selected items.',
            general_server_error: 'Something has gone wrong on our end. Try refreshing your browser or contact your customer success manager',// CHECKUI
            your_results: 'Your results',
            start_date: 'Start date',
            end_date: 'End date',
            quick_scan: 'Quick scan',
            select_asset_and_ia: 'Please select an Asset and Intended Association.',
            select_two_assets: 'Please select two Assets.',
            responses: 'Responses',
            percentage: 'Percentage',
            percentages: 'Show percentages',
            statistics: 'Show statistics',
            open_in: 'Open in',
            upload: 'Upload',
            rows_per_page: 'Rows per page',
            intended_associations: 'Intended Associations',
            search: 'Search',
            duplicate_asset: "You cannot add the same asset twice!",
            yes: "Yes",
            no: "No",
            logo: "Logo",
            photo: "Photo",
            other: "Other",
            status: "Status",
            tracking: "Tracking",
            track: "Track",
            map: "Map",
            branding: "Branding",
            sort_by: "Sort by",
            all: "All",
            results: "Results",
            dont_show_again: "Don't show again",
            copy_to_clipboard: "copy to clipboard",
            category: "Category",
            watch_instructions_video: "Watch instructions video",
            date: 'Date',
            survey: "Review the human associations entered after exposure to your Asset. Assess if they require editing, deletion or removal",
            edit:"Click on the entries that you would like to edit and replace by another keyword entry with similar meaning",
            delete:"Select the boxes next to the entries that reflect a definition, opinion or meaningless response that you would like to delete (i.e. remove from the dataset that will be used to position this Asset in the network). Don’t forget to submit your batch selection at the bottom of the page",
            noIdea:"Select the boxes next to the entries that reflect a ‘no idea’ type response (eg. ‘?’, ‘???’, ‘no idea’, ‘x’, ‘1,2,4’, etc. ) to cluster them into one single ‘no idea category’. Don’t forget to submit your batch selection at the bottom of the page",
            proportion_no_idea:"The proportion of no idea is: "
        },
    },
    nl: {
        message: {
            settings: 'Instellingen',
            username: 'Gebruikersnaam',
            password: 'Wachtwoord',
            email: 'E-mail',
            organization: 'Organisation',
            save: 'Opslaan',
            open: 'Open',
            cancel: 'Annuleer',
            name: 'Naam',
            type: 'Type',
            select: 'Selecteer',
            enter_valid_email_address: 'Gelieve een geldig email adres in te geven.',
            active: 'Actief',
            your_mindcoins: 'Jouw Mindcoins',
            sign_out: 'Afmelden',
            help: 'Help',
            new: 'Nieuwe',
            generate: 'Genereer',
            new_document: 'Naamloos document',
            version: 'Versie',
            characters: 'Karakters',
            word: 'Woord',
            score: 'G.A. recall score',
            click_here: 'Klik hier',
            association: 'Association',
            associations: 'Associaties',
            purchase: 'Kopen',
            your_documents: 'Jouw documenten',
            add: 'Toevoegen',
            asset_creator_add: 'Voeg toe',
            close: 'Sluiten',
            no_match: 'Geen zoekresultaten',
            library_no_match: "Concept nog niet beschikbaar in uw Library. Zoek in de Publieke concepten en voeg toe in je Library.",
            loading: 'Laden',
            queued:"In wachtrij",
            in_survey:"Surveying",
            in_cleaning:"In Review",
            in_ingestion:"Updating",
            done:"Klaar",
            queued_description:"Je concept is toegevoegd aan een survey wachtrij",
            in_survey_description:"Je concept wordt bevraagd",
            in_cleaning_description:"De resultaten van je concept responses worden gecontroleerd op kwaliteit.",
            in_ingestion_description:"Het netwerk wordt geüpdate met de resultaten van je survey resultaten",
            done_description:"Je kunt je concept nu testen met de tools",
            not_positioned: 'Niet gemapped',
            being_positioned: 'Wordt gemapped',
            being_repositioned: 'Wordt opnieuw gemapped',
            positioned: 'Gemapped',
            audio: 'Audio',
            video: 'Video',
            image: 'Afbeelding',
            delete: 'Verwijderen',
            reset: 'Reset',
            export: 'Exporteer',
            change: 'Aanpassen',
            workspace_empty: 'Jouw {app_name} resultaten zullen hier verschijnen',
            no_access_to_items: 'Je hebt geen toegang tot de geselecteerde items.',
            general_server_error: 'Er is iets misgegaan aan onze kant. Probeer uw browser te herladen of contacteer uw customer success manager',
            your_results: 'Jouw resultaten',
            start_date: 'Startdatum',
            end_date: 'Einddatum',
            quick_scan: 'Snelle scan',
            select_asset_and_ia: 'Gelieve een Asset en Gewenste Associatie te selecteren.',
            select_two_assets: 'Gelieve twee Assets te selecteren.',
            responses: 'Antwoorden',
            percentage: 'Percentage',
            percentages: 'Toon percentages',
            statistics: 'Toon statistieken',
            open_in: 'Openen in',
            upload: 'Upload',
            rows_per_page: 'Rijen per pagina',
            intended_associations: 'Gewenste Associaties',
            search: 'Zoek',
            duplicate_asset: "Je kan dezelfde Asset geen tweemaal toevoegen",
            yes: "Ja",
            no: "Nee",
            logo: "Logo",
            photo: "Foto",
            other: "Ander",
            status: "Status",
            tracking: "Tracking",
            track: "Track",
            map: "Map",
            branding: "Branding",
            sort_by: "Sorteer als",
            all: "Alles",
            results: "Resultaten",
            dont_show_again: "Toon niet meer opnieuw",
            copy_to_clipboard: "kopieer naar klembord",
            category: "Categorie",
            watch_instructions_video: "Bekijk instructie video",
            date: 'Datum',
        },
    }
};

export { shared_translation };
