<template>
    <div style="font-size: 0.8vw; text-align: justify; color:#858585">
        <h5 style="text-align: center;font-weight: bold; color: black !important;">General Terms & Conditions</h5>

        <!-- <h6 style="text-align: center; margin-bottom: 10px; font-weight:bold">
            Software As A Service Agreement
        </h6> -->
        <div>
            Mindspeller and You as the user (Customer) are hereinafter also referred to
            individually, respectively collectively, as “<strong>Party</strong>”
            and “<strong>Parties</strong>”.
        </div>
        <div>
            <strong>WHEREAS</strong>
            :
        </div>
        <div>
            (A) Mindspeller offers Software as a Service providing companies with
            decision support web applications for their marketing
            communication management through the use of a Mindspeller proprietary
            cloud-based hosted platform that is accessible through a secured web
            browser portal;
        </div>
        <div>
            (B) Customer is interested in accessing such software based online services
            from Mindspeller;
        </div>
        <div>
            (C) on the condition of Customer’s acceptance of and continuous
            compliance with the present

            agreement, Mindspeller is willing to provide Customer with the said
            service;
        </div>
        <div>

            <strong>NOW, THEREFORE, THE PARTIES HEREBY AGREE AS FOLLOWS:</strong>
        </div>

        &nbsp;
        <div>
            <strong>I. </strong>
            <strong><u>AGREEMENT</u></strong>
        </div>

        <div>
            The Agreement comprises the Order Form and the General Conditions
            below.
        </div>


        <div>
            <strong>II. </strong>
            <strong><u>GENERAL CONDITIONS</u></strong>
        </div>&nbsp;

        <h7>
            1. Definitions
        </h7>

        <div>
            For the purposes of the Agreement, the terms defined in this article
            shall have the meanings set forth below. Where the context requires,
            the singular shall include the plural and vice versa.
        </div>

        <div><span style="font-size: 0.8vw;">“<strong>Account
                    Administrator<strong>“</strong></strong></span></div>


        <div>means a physical person designated as such by Customer in the
            Platform and authorized to administer Customer’s use of the Service and
            Customer’s Account on Customer’s behalf;</div>
        <div><span style="font-size: 0.8vw;"><strong>“Additional
                    Service”</strong></span></div>


        <div>means the additional services, not included in the
            License,
            offered by Mindspeller, such as consultancy,
            training, set-up services,
            template building or reporting;</div>

        <div><span style="font-size: 0.8vw;"><strong>“Affiliate”</strong></span>
        </div>

        <div>means, with respect to a Party, any person,
            partnership, corporation,
            organization or entity that directly or indirectly
        </div>



        <div><span style="font-size: 0.8vw;">“<strong>Business
                    Day</strong>”</span></div>

        <div>means any day of the week, except for Saturdays,
            Sundays and public holidays.
        </div>


        <div><span style="font-size: 0.8vw;">“<strong>Business
                    Hours</strong>”</span></div>

        <div>means between 09.00-18 hour on Business Days;</div>


        <div><span style="font-size: 0.8vw;">“<strong>Change of
                    Control</strong>”</span>
        </div>


        <div>means any occurrence having as a result that any
            person other
            than the person or persons having Control on the
            date of signing of the
            Agreement, acquire the Control, whether alone or
            acting in concert with
            other parties;</div>


        <div><span style="font-size: 0.8vw;">“<strong>Confidential
                    Information</strong>”</span></div>


        <div>shall have the meaning as set out in the designated
            article below;</div>


        <div><span style="font-size: 0.8vw;">“<strong>Control</strong>”</span>
        </div>


        <div>means the ownership of more than half the capital,
            business or
            assets of a Party or the power to exercise more than
            half the voting
            rights of a Party or the power to appoint more than
            half the members of
            the board of directors of a Party or sufficient
            authority to direct,
            directly or indirectly, the adoption and/or
            execution of the policies,
            management or operations of a Party by any means
            whatsoever;</div>


        <div><span style="font-size: 0.8vw;"><strong>“Controller
                    Personal
                    Data”</strong></span></div>


        <div>means any Data that is Personal Data processed by
            Mindspeller
            on behalf and under the instructions of Customer
            under the Agreement;</div>


        <div><span style="font-size: 0.8vw;">“<strong>Customer
                    Operations</strong>”</span>
        </div>

        <div>means Customer’s usual activities related to
            marketing
            communication management of (i) trademarks it
            controls and related
            marketing communication stimuli it designs
            (internally and/or
            externally) and communicates via internal or
            external channels;</div>


        <div><span style="font-size: 0.8vw;">“<strong>Customer
                    Data</strong>”</span></div>
        <div><span style="font-size: 0.8vw;">&nbsp;</span></div>

        <div>means the Data provided to Mindspeller through the
            Platform or
            submitted, uploaded or stored in the Platform by
            Customer under the
            Agreement;</div>


        <div><span style="font-size: 0.8vw;">“<strong>Mindspeller
                    Data</strong>”</span></div>


        <div>means any Data, other than Customer Data;</div>


        <div><span style="font-size: 0.8vw;">“<strong>Data</strong>”</span>
        </div>


        <div>means any data, information or material regardless of
            its form
            or the medium in which it is comprised, submitted,
            uploaded or stored
            in the Platform;</div>


        <div><span style="font-size: 0.8vw;">“<strong>Documentation</strong>”</span>
        </div>


        <div>means Mindspeller’s then-current manuals and
            technical
            materials, in such medium as Mindspeller elects that
            are delivered by
            Mindspeller to Customer under the Agreement for the
            purpose of the setup
            and/or use of the Service;</div>


        <div><span style="font-size: 0.8vw;">“<strong>Effective
                    Date</strong>“</span></div>

        <div>means the date indicated in the Order Form;</div>


        <div><span style="font-size: 0.8vw;">“<strong>Extensions</strong>”</span>
        </div>


        <div>means any extension to the Service Package;</div>


        <div><span style="font-size: 0.8vw;">“&nbsp;<strong>Mindspeller
                    Technology</strong>”</span></div>

        <div>means all of Mindspeller’s proprietary technology
            (including
            software, hardware, products, processes, algorithms,
            user interfaces,
            know-how, techniques, designs and other tangible or
            intangible technical
            material or information) used by Mindspeller in the
            Platform;</div>

        <div><span style="font-size: 0.8vw;">“<strong>EU Data
                    Protection
                    Laws</strong>”</span></div>

        <div>means EU Directive 95/46/EC as amended, replaced, or
            superseded from time to time, including by the EU
            General Data
            Protection Regulation 2016/679 and laws implementing
            or supplementing
            this regulation;</div>

        <div><span style="font-size: 0.8vw;"><strong>“GDPR”</strong></span>
        </div>

        <div>means EU General Data Protection Regulation 2016/679;
            means these general
            conditions including its Schedules hereto;</div>


        <div><span style="font-size: 0.8vw;">“<strong>Intellectual
                    Property
                    Rights</strong>”</span></div>

        <div>means, without limitation, and in particular with
            regard to
            the Platform and Mindspeller’s products and
            services, all rights
            throughout the world in the nature of intellectual
            property rights
            including copyright, trademark, service marks, trade
            and business names,
            domain names, goodwill, registered designs, patents,
            database rights,
            topography rights and rights in know-how and trade
            secrets, whether
            registered or unregistered and including
            applications for the grant of
            any of the foregoing and all rights or forms of
            protection having
            equivalent or similar effect to any of the
            foregoing; Intellectual
            Property Rights shall also include the Know-How;</div>

        <div><span style="font-size: 0.8vw;">“<strong>Know-How</strong>”</span>
        </div>

        <div>means, in particular with regard to the Platform and
            Mindspeller’s products and services all technical
            and processing data,
            information, specifications, and knowledge of an
            intellectual or
            industrial nature, concerning products and/or
            services, including but
            not limited to specifications, manufacturing
            instructions, quality
            control procedures and other like data;</div>

        <div><span style="font-size: 0.8vw;">“<strong>License</strong>”</span>
        </div>

        <div>means the licenses granted by Mindspeller to Customer
            as
            defined in this document for use of the Platform,
            the Service and of the
            Service Results;</div>

        <div><span style="font-size: 0.8vw;">“<strong>License
                    Fee</strong>”</span></div>

        <div>shall have the meaning as given in the designated
            article below;</div>

        <div><span style="font-size: 0.8vw;"><strong>“Order
                    Form</strong>”</span></div>

        <div>means the order form representing a purchase order,
            evidencing
            the purchase of the Service specifying, among other
            things, the subject
            of the Service (the Service Package chosen) and/or
            of Additional
            Services and any specific terms and conditions
            thereof;</div>

        <div><span style="font-size: 0.8vw;">“<strong>Platform</strong>”</span>
        </div>

        <div>means the Mindspeller proprietary cloud-based
            platform to
            which Customer obtains access under the Agreement
            and via which
            Mindspeller performs the Service;</div>
        <div><span style="font-size: 0.8vw;"><strong>“Representative”</strong></span>
        </div>

        <div>means, with respect to a Party or a Party’s
            Affiliate, its
            directors, officers, employees, contractors, agents,
            advisors,
            counsellors, auditors, accountants or lawyers; in
            relation to Customer,
            Representative includes Customer’s Account
            Manager(s) and User(s);</div>

        <div><span style="font-size: 0.8vw;">“<strong>Service
                    Package</strong>”</span></div>


        <div>means one of the Service packages offered by
            Mindspeller to
            Customer as set out in the Order Form, defining the
            scope of the License
            and the set of rights awarded to Customer under the
            License and the
            Service Results that may be obtained through the
            Service;</div>

        <div><span style="font-size: 0.8vw;">“<strong>Service</strong>”</span>
        </div>

        <div>means the services to which Customer is being granted
            access
            under the License as related to a specific Service
            Package; the Service
            includes Documentation and, as the case may be,
            Extensions;</div>

        <div><span style="font-size: 0.8vw;">“<strong>Service
                    Result</strong>”</span></div>

        <div>means any file, document, data or information
            generated through or stored on
            the Platform as a result of the Service;</div>

        <div><span style="font-size: 0.8vw;">“<strong>User</strong>”</span>
        </div>

        <div>means a physical person designated as such by
            Customer in the Platform.</div>
        &nbsp;
        <h7>
            2. Scope of the Agreement
        </h7>

        <div>
            1. The Agreement governs all contractual relationships between
            Mindspeller and Customer regarding the Service and applies, among
            others, to all offers and orders issued or accepted or entered into
            by
            Mindspeller with Customer in relation to the Service. The Agreement
            sets out the terms and conditions that apply to all services,
            products,
            documents, data and any other element provided by Mindspeller or
            exchanged with Mindspeller in relation to the Service.
        </div>
        &nbsp;
        <div>
            2. By signing the Agreement or accepting it on the Platform Customer
            is
            deemed to have unconditionally accepted the terms and conditions set
            out in the Agreement.
        </div>
        <div>
            The Agreement shall be binding upon Customer regardless any
            stipulations to the contrary in a document issued by Customer. In
            case
            of conflict between the Agreement and terms and conditions of
            Customer,
            order forms or any other document passed between the Parties in
            relation to the Service, the Agreement shall prevail, unless
            expressly
            and specifically agreed to the contrary by Mindspeller in writing.
        </div>
        &nbsp;
        <div>
            3. In order to comply with regulatory or governmental provisions
            and/or
            to safeguard continuity of the Service, Mindspeller reserves the
            right
            to modify the terms and conditions of access to the Service in its
            reasonable discretion from time to time. Such modifications shall be
            deemed to be accepted by Customer provided that Mindspeller notifies
            them to Customer in accordance with article 23 of the Agreement.
            Continued use of the Service by Customer after such notification
            shall
            constitute Customer’s acceptance of the modification.
        </div>
        &nbsp;
        <h7>
            3. License
        </h7>

        <div>
            1. By the Agreement Mindspeller grants to Customer and Customer
            accepts
            the License, meaning:
        </div>
        <div>
            - a non-exclusive, restricted, personal, non-transferable and a
            non-assignable license to use the Service for the entire duration of
            the Agreement and
        </div>

        <div>
            - a non-assignable license to use the Service Results via the
            Platform for
            the entire duration of the Agreement
        </div>
        <div>
            under the terms and conditions set out in the Agreement for the
            purpose
            of Customer Operations and for such purpose only.
        </div>
        <div>
            2. It is understood that the Agreement shall not prevent Mindspeller
            from entering into similar agreements with third parties or from
            developing, using, selling or licensing documentation, products and
            /
            or services which are similar to those provided under this
            Agreement.
        </div>
        <div>
            3. The License may be granted and any (part of the) Service may be
            provided only by Mindspeller, including any technical support, and
            no
            rights are being granted to Customer in respect of error correction,
            support or maintenance of the Service.
        </div>
        <div>
            The License is granted to Customer only and shall not be sublicensed
            in
            any form.
        </div>
        &nbsp;
        <h7>
            4. License Restrictions
        </h7>

        <div>
            1. The Agreement does not grant and shall not be construed to grant
            to
            Customer any other right than the rights expressly granted by the
            Agreement. Any right that is not expressly granted to Customer
            hereunder is reserved by Mindspeller.
        </div>
        <div>
            2. The License is granted subject to Customer’s continuous
            compliance
            with the Agreement, including timely payment of the License Fee,
            failing which Mindspeller reserves the right to fully or partially
            end,
            deny or limit Customer’s access to the Service and/or to refuse,
            suspend, interrupt or terminate the License granted to Customer at
            all
            times, without detriment to Mindspeller’s rights hereunder and its
            right to claim compensation for losses, damages and costs it
            suffered.
        </div>
        <div>
            3. By the acceptance of the Agreement Customer commits and
            guarantees
            that he is not and shall not be a Mindspeller competitor and that,
            except with Mindspeller's explicit prior written consent, he shall
            not
            use (any part of) the Service or(of) the Service Results for any
            other
            purpose than Customer Operations, among others not for any activity
            that is competing with Mindspeller’s services or products or is
            detrimental to Mindspeller’s interests.
        </div>
        <div>
            4. With respect to the Service, Customer shall not and guarantees
            that
            his Affiliates, his Representatives and/or its Affiliates’
            Representatives shall not, (attempt to) do the following and shall
            not
            assist any third party in doing the same by any means, unless when
            explicitly stated otherwise in this Agreement:
        </div>
        <div>
            (i) copy, modify, duplicate, create derivative works from, frame,
            mirror, republish, download, display, transmit, translate, adapt,
            alter
            or distribute all or any portion of the Service or Platform in any
            form
            or media; or
        </div>
        <div>
            (ii) license, sublicense, sell, resell, rent, lease, transfer,
            assign,
            distribute, display, disclose, or otherwise commercially exploit, or
            otherwise make available the Service to any party, or
        </div>
        <div>
            (iii) access the Service or any part thereof for purposes of
            monitoring
            the availability, the performance or the functionality of the
            Service,
            nor for any benchmarking or competitive purposes; or
        </div>

        <div>
            (iv) translate, modify, adapt, alter, reverse compile or reverse
            engineer, decompile, disassemble or otherwise reduce to
            human-perceivable form all or any part of the Service or Platform;
            or
        </div>
        <div>
            (v) reconstruct, identify or discover any source code, underlying
            ideas, underlying user interface techniques or algorithms of the
            Service or disclose any of the foregoing; or
        </div>
        <div>
            (vi) create Internet “links” to the Service or any part thereof or
            "frame" or "mirror" the Service or any part thereof
        </div>
        <div>
            on any server or wireless or Internet-based device; or
        </div>
        <div>
            (vii) access or use the Service or any part thereof to provide
            services
            to third parties, to build a competitive product or service or a
            product or service using similar ideas, features, functions or
            graphics
            as the Service or any part thereof or to copy any ideas, features,
            functions or graphics of the Service or of any part thereof; or
        </div>
        <div>
            (viii) take any action that would cause the Service or Platform to
            fall
            into the public domain; or
        </div>
        <div>
            (ix) access or use the service or any part thereof to reconstruct
            Mindspeller proprietary databases or any part thereof.
        </div>
        <div>
            With respect to the Service Results, Customer shall not and
            guarantees
            that his Affiliates, his Representatives and/or its Affiliates’
            Representatives shall not, (attempt to) do the following and shall
            not
            assist any third party in doing the same by any means:
        </div>
        <div>
            (i) copy, modify, duplicate, create derivative works from, frame,
            mirror, republish, download, display, transmit, translate, adapt,
            alter
            or distribute all or any portion of the Service Results in any form
            or
            media except for Customer Operations; or
        </div>
        <div>
            (ii) sell, resell, rent, lease, distribute, display, disclose or
            otherwise commercially exploit or otherwise make available the
            Service
            Results to any party except for Customer Operations, or
        </div>
        <div>
            (iii) use the Service Results or any part thereof to provide
            services
            to third parties, to build a competitive product or service or a
            product or service using similar ideas, features, functions or
            graphics
            as the Service or any part thereof or to copy any ideas, features,
            functions or graphics of the Service Results or of any part thereof.
        </div>
        <div>
            It is understood that Customer may use the Documentation, or any
            part
            of it, for security, test and backup purposes.
        </div>&nbsp;
        <h7>
            5. Mindspeller’s obligations in relation to the Service
        </h7>

        <div>
            1. By the Agreement, subject to the terms and conditions under which
            the License is provided, during the term of the Agreement,
            Mindspeller
            shall provide the Service to Customer substantially in accordance
            with
            the Documentation and with reasonable skill and care, and make
            available the Documentation to Customer.
        </div>
        <div>
            2. Mindspeller shall use commercially reasonable efforts to ensure a
            99
            % uptime of the Service.
        </div>
        <div>
            However, notwithstanding the foregoing, the Customer recognizes that
            Mindspeller may interrupt the Service (in whole or in part) for
            maintenance work purposes, including both scheduled maintenance work
            and unscheduled work in the event of an emergency. Mindspeller
            undertakes to have the scheduled maintenance work to be executed
            outside of the Business Days or during Business Days but outside
            Business Hours, and to notify such works at least 48 hours in
            advance
            and shall use commercially reasonable efforts to notify the Customer
            of
            any unscheduled works as soon as reasonably practicable.
        </div>
        <div>
            3. Mindspeller shall not have the obligation to (continue to)
            perform
            the Service in case of any (suspected) use of the Service by the
            Customer (its Affiliates, his Representatives and/or its Affiliates’
            Representatives) contrary to the Agreement or Mindspeller's
            instructions, of modification or alteration of the Services by any
            party other than Mindspeller (or Mindspeller's duly authorized
            contractors or agents) and shall be relieved of its obligations to
            perform the Agreement for the time that it is prevented from
            performing
            the Agreement as a result of any failure or delay by Customer to
            perform its obligations under the Agreement.
        </div>

        <div>
            If the Service does not comply with the Agreement, Customer's sole
            and
            exclusive remedy shall be, at Mindspeller’s discretion, the
            correction
            of the breach by Mindspeller or the provision of the Customer by
            Mindspeller with an alternative means of accomplishing the desired
            performance.
        </div>&nbsp;
        <h7>
            6. Customer obligation in relation to the Service
        </h7>

        <div>
            1. Customer shall provide Mindspeller with all necessary
            co-operation
            and with access to all information as may be required by Mindspeller
            for the performance of the Agreement, shall maintain all necessary
            consents and permissions necessary for Mindspeller (and its
            contractors, agents or Representatives) to perform their obligations
            under the Agreement and shall perform its obligations under the
            Agreement in a timely and efficient manner, failing which
            Mindspeller
            may adjust any agreed timetable or delivery schedule as reasonably
            necessary.
        </div>
        <div>
            2. Customer shall ensure that its network, file and web transfer
            protocols and message structures and any other systems, as may be
            used
            in relation to the Service, comply with the required specifications
            provided by Mindspeller from time to time and shall be solely
            responsible for procuring and maintaining the network and
            telecommunications connections between its IT-environment and
            Mindspeller's platform and for all loss or damage due to events such
            as
            delays and delivery failures related to the said connections.
        </div>
        <div>
            3. Customer shall be responsible and liable for all activity
            occurring
            under its Customer account, including, among others, for all actions
            of
            his Representatives, including for Service Results and the use
            thereof.
        </div>
        <div>
            Customer shall ensure at all time that the License is not used and
            the
            Service are not accessed by incompetent persons, in particular
            persons
            other than his Account Administrator or Users, and that his account,
            his Account Administrator’s account and/or his User’s account is not
            shared or used by more than one person designated in the Platform as
            Account Administrator, respectively, User. Customer shall in
            particular
            ensure that the password giving access to the Service is kept secret
            and is known only to that person to whom it is allocated. Customer
            shall not, and warrants that his Representatives shall not, provide
            false identity information to gain access to the Service or
            impersonate
            another person.
        </div>
        <div>
            4. Customer represents that his Representatives, and in particular
            his
            Account Administrator and Users, shall at all time fully comply with
            the Agreement and shall indemnify, defend and hold Mindspeller, its
            Affiliates and its Representatives harmless from and against any
            damage, cost, claim, demand, lawsuit, cause of action or loss of any
            nature whatsoever, suffered or incurred by any of them, arising out
            of
            or in connection with the infringement of this representation.
        </div>
        <div>
            Customer shall prevent any unauthorized access to, or use of, the
            Service or the Service Results and, in the event of any such
            unauthorized access or use, promptly notify Mindspeller of any
            unauthorized use of Customers
        </div>

        <div>
            account or password as well as of any other breach of security,
            including illegitimate copying or distribution of Data known to or
            suspected by Customer or its Representatives and shall use all
            reasonable efforts to immediately stop any such breach.
        </div>&nbsp;
        <h7>
            7. Maintenance and Troubleshooting
        </h7>

        <div>
            (i) The Service includes Mindspeller’s maintenance activities
            required
            to ensure the availability of the Service in accordance with the
            Agreement and technical troubleshooting support services to
            Customer.
            These services shall be provided on Business
            Days during Business Hours.
        </div>
        <div>
            (ii) In relation to troubleshooting, Mindspeller shall examine any
            incident in relation to the performance of the Service as notified
            to
            Mindspeller by the Account Administrator.
        </div>
        <div>
            Mindspeller shall have no obligation to correct the incident if it
            is
            not able to replicate the incident and/or the incident is (a) not
            caused by malfunctioning of the Service and (b) is caused by (i) the
            use of the Service contrary to the Agreement, (ii) modification of
            the
            Service without Mindspeller’s prior consent, (iii) the use of
        </div>
        <div>
            incorrect data or data format, (iv) disruption or unavailability of
            external services (including but not limited to third party
            validation
            services or network disruptions), (v) disruption or unavailability
            of
            networks, internet, file transfer mechanisms, etc. or (v) Customer’s
            use of the Service with any incompatible or unauthorized products or
            services (“<strong>Unconfirmed Incident</strong>”).
        </div>
        <div>
            In case of Unconfirmed Incident, if the Customer so requests,
            Mindspeller may advise the Customer on and assist
        </div>

        <div>
            the Customer with resolving such incident. Any work performed by
            Mindspeller for investigation of Unconfirmed Incidents and the
            advice
            and assistance in relation thereto shall be invoiced to the Customer
            on
            a time and materials basis at Mindspeller's then standard fee rates.
        </div>
        <div>
            (iii) Other than Unconfirmed Incidents shall be handled by
            Mindspeller
            in accordance with the following priority schedule:
        </div>
        <div>
            <strong>Priority 1</strong>
            : Downtime of the entire Service, security violation, loss of
            critical
            functionality having major impact on the Service: support requests
            shall be responded to within one (1) Business Day.
        </div>
        <div>
            <strong>Priority 2</strong>
            : Loss of certain functionalities with no critical impact: support
            requests shall be responded to within five (5) Business Days.
        </div>
        <div>
            <strong>Priority 3: </strong>
            Minor impact on Customer: support requests shall be responded to
            within
            ten (10) Business Days.
        </div>
        <div>
            The Customer shall provide Mindspeller with all information required
            for Mindspeller to understand and assess the issue, including date
            and
            timing of the occurrence of the issue and all data required to
            reproduce it.
        </div>&nbsp;
        <h7>
            8. Data, Use of Data and Data protection in general
        </h7>

        <div>
            (i) It is understood that the (performance of the) Agreement does
            not result and shall not be construed to result in any change in the
            ownership of Data.
        </div>
        <div>
            (ii) Customer shall be responsible and liable for the accuracy,
            quality, integrity, legality, reliability, accuracy, appropriateness
            and compliance with any third party Intellectual Property Rights or
            other proprietary rights of all Customer Data and warrants and
            represents that it shall obtain prior to the communication of
            Customer
            Data to Mindspeller, or the storage of Customer Data on or the use
            of
            Customer Data via the Platform, any and all required third party
            authorizations and fulfil any and all required legal conditions and
            obligations and industry standards that must be obtained or
            fulfilled
            for the processing, storage, transfer and in general, any use, of
            Customer Data under the Agreement.
        </div>
        <div>
            (iii) Customer acknowledges and agrees that:
        </div>
        <div>
            • Mindspeller's employees or contractors have access to Customer's
            Data
            in order to provide the Service under the Agreement;
        </div>
        <div>
            • Mindspeller may further use and disclose Customer Data that
            Mindspeller collects, obtains, retrieves or otherwise captures under
            the Agreement to the extent required for the execution of the
            Agreement
            as well as, on the condition that Mindspeller does so in aggregate
            and
            non-Customer identifiable and non- person identifiable formats, for
            other purposes;
        </div>
        <div>
            • the Customer Data may be transferred or stored within the European
            Economic Area and/or outside of the European Economic Area in
            accordance with applicable legislation and rules to carry out
            Mindspeller's other obligations under this Agreement;
        </div>
        <div>
            • Mindspeller shall be entitled to keep records of Customer Data as
            necessary to comply with any applicable laws and regulations and for
            its business records, subject to its confidentiality obligations
            hereunder.
        </div>

        <div>
            (iv) Mindspeller acknowledges that Customer may use and disclose
            Mindspeller Data provided to Customer under the Agreement and
            collected, obtained, retrieved or otherwise captured by him as a
            result
            of the Service on the condition that this is done only for the
            purpose
            of the Customer Operations, not unintendedly or improperly, in
            accordance to the Agreement and in compliance with the applicable
            laws.
        </div>
        <div>
            (v) In order to prevent Data loss, Mindspeller shall maintain a
            back-up
            strategy as it may amend in its sole discretion from time to time.
            Mindspeller shall inform Customer about the back-up strategy upon
            Customer’s request.
        </div>
        <div>
            <a name="_30j0zll"></a>
            <a name="_1fob9te"></a>
            <a name="_3znysh7"></a>
            In the event of any loss or damage to Data, Customer's sole and
            exclusive remedy shall be to use reasonable commercial efforts to
            restore the lost or damaged Customer Data from the latest back-up of
            such Customer Data maintained in the Platform in accordance with the
            said back-up strategy. Mindspeller’s liability for loss,
            destruction,
            alteration or unauthorized access or disclosure of Data not
            attributable to Mindspeller (or to persons acting on
        </div>
        <div>
            <a name="_bookmark0"></a>
            its behalf) and/or attributable to Customer is excluded.
        </div>&nbsp;
        <h7>
            9. Personal data
        </h7>&nbsp;

        <div>
            <a name="_bookmark1"></a>
            <a name="_bookmark2"></a>
            <a name="_bookmark3"></a>
            <a name="_2et92p0"></a>
        </div>
        <h7>
            (i) Communications
        </h7>

        <div>
            At the moment of creation of Customer account, Customer shall be
            asked
            whether or not he wishes to receive marketing and other non-critical
            Service-related communications from Mindspeller from time to time.
            Customer may opt in on receiving such communications at that time
            or,
            should he wish to change his position, opt-in or opt-out at any
            subsequent time by changing his preference.
        </div>
        <div>
            Customer however recognizes and accepts that, because the Service is
            a
            hosted, cloud-based application, even if he opted out or did not
            opt-in, Mindspeller shall occasionally need to notify him of
            important
            information regarding the Service.
        </div>&nbsp;
        <h7>

            (ii) Third Party Interactions
        </h7>

        <div>
            1. Mindspeller does not endorse any third party websites, services
            or
            products even if they are linked through the Service. Any such links
            are provided to Customer only for convenience.
        </div>
        <div>
            Customer recognizes that both the provision and the use of the
            Service
            require the use of the internet and of means of electronic
            communication and may require the availability and use of certain
            ancillary software, hardware or services provided by third parties.
            Mindspeller shall not be liable for any delays or failures resulting
            from the use of the internet and of means of electronic
            communication
            nor for the (continuous or interrupted) availability of the said
            software, hardware and services.
        </div>
        <div>
            2. Mindspeller shall use best endeavors to preserve the stability of
            the Service APIs. Customer however acknowledges that external
            changes
            (including but not limited to: regulations, standards, technology,
            third party software …) may require Mindspeller to bring
            modifications
            to the available APIs. Mindspeller reserves the right to adapt the
            Service APIs at its sole discretion, and Customer accepts this
            right.
        </div>
        <div>
            Customer accepts and agrees to adapt the integration within 3 months
            after being required to do so. Mindspeller shall not be held liable,
            neither contractually nor extra-contractually, for discontinuing the
            maintenance of an older API version, three (3) months after
            communicating any changes on the older version.
        </div>&nbsp;
        <h7>
            License Fee
        </h7>

        <div>
            Customer shall pay to Mindspeller in compensation of the Service the
            agreed license fee as set out in the Order form (the “
            <strong>License Fee</strong>”).
        </div>
        <div>
            It is understood that any License fees published by Mindspeller on
            its
            website or otherwise are non-binding.
        </div>
        <div>
            Mindspeller reserves the right to modify the License fees and to
            introduce additional License fees at any time provided that it
            notifies
            this to Customer with at least thirty (30) days prior notice. For
            Licenses that are then current, the modified fee shall apply from
            the
            first renewal of the License after the said term of thirty (30)
            days.
        </div>
        <div>
            Customer shall pay the License fee in advance for the entire term of
            the License according to the Agreement. The fee is due regardless of
            whether Customer effectively uses the Service. The payment
            obligation
            is non-cancellable and irrevocable and the amounts paid are
            non-refundable.
        </div>&nbsp;
        <h7>
            Billing and Payments
        </h7>
        <div>
            Customer shall provide Mindspeller with complete, correct and
            accurate
            billing and contact information, including Customer’s legal company
            name, address, enterprise number, email address, valid credit card
            number and name and telephone number of Customer’s authorized
            billing
            administrator
        </div>
        <div>
            Mindspeller shall have no obligation to start (or to
            continue) the
            Service under the License prior to full payment by
            Customer of the
            corresponding License fee or agreed upon advance
            payments. Unless otherwise stated, invoiced amounts are due in 15
            days.
            For invoices not paid by the due date, the Client shall
            automatically
            and without formal notice owe contractual interest on arrears at the
            interest rate determined in application of Article 5 of the Belgian
            Law
            of August 2, 2002 on combating late payment in commercial
            transactions,
            with a minimum of EUR 75. In addition, all possible legal costs
            shall be
            borne by the Client.
        </div>&nbsp;
        <h7>
            <a name="_bookmark4"></a>
            <a name="_tyjcwt"></a>
            Term and Termination
        </h7>
        <div>
            <strong> </strong>
        </div>
        <div>
            1. Without prejudice to Mindspeller’s right to compensation for
            losses,
            damages and costs suffered and without prejudice to other provisions
            of
            the Agreement, Mindspeller may terminate the Agreement and any and
            all
            License(s) granted hereunder at any time during the term thereof by
            a
            notice sent to Customer in accordance with Article 23 if Customer:
        </div>
        <div>
            - fails to observe or breaches any of its material covenants,
            agreements or obligations under the Agreement in any material
            respect,
            provided that such breach continues for a period of fifteen (15)
            days
            after receipt by Customer of notice of default specifying the nature
            of
            the breach and requesting to cure it; or
        </div>
        <div>
            - makes any assignment for the benefit of creditors or files a
            petition
            in bankruptcy or is adjudged bankrupt or becomes insolvent or
            generally
            unable to pay its debts when due or is placed in the hands of a
            receiver or if the equivalent of any such proceedings occurs,
            provided
            that such termination is not contrary to legal provisions of public
            order applicable to Customer;
        </div>
        <div>
            - fails to maintain any authorization, license or permit of any
            nature
            that is legally required to execute the Agreement as a result of
            which
            Customer is no longer authorized to receive the Service or execute
            the
            whole or a part of its obligations under the Agreement; or
        </div>
        <div>
            - commits a serious breach of the Agreement, the breaches to the
            following obligations constituting such breach: unauthorized use of
            the
            Service or of the Platform, breach of Customers Responsibilities in
            relation to use of the Service, Data and Customer account;
            non-payment
            of fees; breach of Customer warranties, breach of Mindspeller’s
            Intellectual Property or of Customer’s confidentiality obligations
            and
            breach of non- enticement obligations; or
        </div>
        <div>
            - undergoes or intends to undergo a Change of Control.
        </div>&nbsp;
        <h7>
            <a name="_bookmark5"></a>
            <a name="_3dy6vkm"></a>
            Consequences of Termination or Expiry of the Agreement
        </h7>
        <div>
            1. Any provision of the Agreement expressly or implicitly intended
            to
            survive its termination or expiry, whatever the cause, shall survive
            as
            provided and the termination or expiry shall not affect any
            obligation
            to pay any amount due under the Agreement whilst it was in effect.
        </div>
        <div>
            2. Upon termination of the Agreement:
        </div>
        <div>
            - all licenses granted under the Agreement with regard to the
            Service
            shall immediately terminate;
        </div>
        <div>
            - Customer shall return to Mindspeller within five (5) Business Days
            all latter’s Confidential Information and all copies and embodiments
            thereof that is in Customer’s possession and Mindspeller is entitled
            to
            conserve all Customer Data for a period of twelve (12) months
            following
            the date of termination of the Agreement. Upon termination of this
            conservation period, Mindspeller will permanently delete and/or
            discard
            all Customer Data, statistical and anonymized behavioral Customer
            Data
            excluded, unless Mindspeller is bound by a legal obligation to
            conserve
            Customer Data beyond the said conservation period;
        </div>
        <div>
            - Mindspeller shall have no obligation to maintain, forward or
            return
            to Customer any Customer Data or other Data after the expiry or
            termination of the Agreement, unless Mindspeller receives, no later
            than ten (10) Business Days after the date of the termination or
            expiry
            of this Agreement, a written request for the delivery to Customer of
            the then most recent backup of Customer Data, provided that Customer
            has, at that time, paid all Licenses Fees and all other fees
            outstanding under the Agreement and/or resulting from termination or
            expiry (whether or not due at the date of termination or expiry) of
            the
            Agreement, and against payment by Customer of all expenses incurred
            by
            Mindspeller in returning the Customer Data; Customer Data shall only
            be
            returned to Customer in a standard format to be specified at
            Mindspeller’s discretion.
        </div>
        <div>
            - each Party shall promptly return and make no further use of any
            equipment and tokens (and all copies of them) belonging to the other
            Party;
        </div>
        <div>
            - Mindspeller shall be entitled to recover possession from Customer
            of
            all copies of the Documentation and related information supplied to
            or
            provided by Customer to third parties and in existence at the time
            of
            expiry or termination or require Customer to destroy the same and
            certify on oath that it has done so.
        </div>
        <div>
            3. Upon termination of the Agreement, whatever the cause, Customer
            shall not be entitled to any indemnity whatsoever.
        </div>&nbsp;
        <h7>
            Warranties
        </h7>

        <div>
            1. Without detriment to other warranties and representations
            provided
            under the Agreement, the Parties provide the following warranties
            and
            representations:
        </div>
        <div>
            Customer represents and warrants that:
        </div>
        <div>
            - he has the legal power and authority to enter into the Agreement
            and
        </div>
        <div>
            - his Representatives (including the Account Administrators and the
            Users) shall and are duly authorized to act
        </div>

        <br clear="all">

        <div>
            on Customer’s behalf and under his sole responsibility and they
            shall
            at all time act in accordance to the
        </div>
        <div>
            Agreement.
        </div>
        <div>
            2. Mindspeller represents and warrants that:
        </div>
        <div>
            - it has the legal power and authority to enter into the Agreement;
            and
        </div>
        <div>
            - it shall provide the Service in a manner consistent with general
            industry standards reasonably applicable to the provision thereof;
            and
        </div>
        <div>
            - that the Service shall perform substantially in accordance with
            the
            Agreement under normal use and circumstances; and
        </div>
        <div>
            - to the best of its knowledge it has all the rights in relation to
            the
            Platform and the Service that are necessary to grant all the rights
            granted hereunder, including the License, and in accordance with the
            terms of the Agreement.
        </div>
        <div>
            3. It is understood that Mindspeller's performance of the Service is
            an
            obligation of means and that (any part of) the
        </div>
        <div>
            Service is provided on an “as is” basis.
        </div>
        <div>
            Except as expressly provided herein, Mindspeller hereby disclaims
            all
            other conditions, representations and warranties, whether express,
            implied, statutory or otherwise, to the maximum extent permitted by
            applicable law. Without limiting the foregoing, as such and among
            others:
        </div>
        <div>
            - any service, good or information provided by Mindspeller under the
            Agreement is provided without any warranties or representations,
            express or implied, including, without limitation, warranties of
            merchantability or fitness for a particular purpose, performance,
            non-infringement, timeliness, reliability, availability, accuracy,
            quality or completeness of the Service or of the Platform or of any
            data provided under the Agreement;
        </div>
        <div>
            - Mindspeller does not warrant that the Service shall be
            uninterrupted,
            error-free or that it shall meet Customer’s, its Affiliates’, its
            Representatives’, its Affiliates’ Representatives or any other
            party’s
            specific needs, nor that a service, good or information provided by
            Mindspeller under the Agreement shall be free from viruses, harmful
            components, errors or defects (nor that the said shall be
            corrected),
            nor that the Service or the Platform shall operate in combination
            with
            any other service, software, hardware, system or data.
        </div>
        <div>
            - Mindspeller shall have no liability for the (in)correctness of any
            Data provided to it, used by it or provided by it under the
            Agreement
            and shall not be responsible or liable for the deletion, correction,
            destruction, damage, loss or failure to store any Data.
        </div>
        <div>
            4. Customer acknowledges and agrees that the Service and any
            Additional
            Service and Service Results as the case may be are not to be
            considered
            as financial, legal or other advice. The information which might be
            provided via the Service, the Additional Service and Service Results
            is
            only provided on an “as is” basis, without any representations or
            warranties, express or implied. Mindspeller makes no representations
            or
            warranties whatsoever in relation to the information provided via
            the
            Service, the Additional Service and the Service Results. Customer
            acknowledges and agrees that it shall bear sole responsibility with
            regard to ultimate marketing communication actions taken by Customer
            or
            on Customer’s behalf.
        </div>&nbsp;
        <div>
            <strong>10. <u>Liability</u></strong>
        </div>
        <div>
            1. Each Party shall indemnify and hold the other Party harmless from
            and against any and all claims, costs, damages,
        </div>
        <div>
            losses, liabilities and expenses arising out of or in connection
            with
            the former’s (its Affiliates’ or their
        </div>
        <div>
            Representatives’) breach of the of the Agreement, including its
            warranties thereunder. The same shall apply to direct infringement
            attributable to a Party a third party Intellectual Property Rights.
        </div>
        <div>
            2. Without limiting the foregoing, Customer shall indemnify and hold
            Mindspeller, its Affiliates and their Representatives harmless from
            and
            against any and all claims, costs, damages, losses, liabilities and
            expenses arising out of or in connection with (i) any unintended,
            improper or illegal use of (any part of) the Service or the Service
            Results, (ii) any harm caused to a third party as a result of the
            use
            of Customer Data, (iii) any consequences of the use by Customer, its
            Affiliates, its Representatives’ or its Affiliates’ Representatives
            of
            any hardware or software not provided by Mindspeller, (iv)
            Customer's
            (or its Affiliates’, its Representatives’ or its Affiliates’
            Representatives) use of the Service or the Service Results (in the
            broadest sense including sublicensing and the use through a
            sublicense).
        </div>
        <div>
            3. In the event of founded third party claim against Customer for
            reason of infringement of that party’s right (including the
            Intellectual Property Rights of such party) as a result of
            infringement
            by Mindspeller of its warranties and/or obligations under the
            Agreement, Mindspeller shall defend and hold Customer (with the
            exclusion of any other party) harmless against the said claim if (i)
            Customer notified the claim in writing to Mindspeller not later than
            thirty (30) days after the receipt thereof setting forth in detail
            the
            circumstances of the claim and accompanied by a copy of all the
            received documents and (ii) has not taken and does not take
            unilateral
            step in relation to the claim without Mindspeller's prior written
            consent. Mindspeller shall have the exclusive right to defend or
            settle
            the third party claim and Customer shall provide Mindspeller with
            reasonable assistance, at Mindspeller's expense.
        </div>
        <div>
            In the event the Service in Mindspeller’s opinion is likely to be or
            to
            become the subject of a third party claim, Mindspeller shall have
            the
            right at its discretion (i) to procure for Customer the right to
            continue using the Service as provided in this Agreement or (ii) to
            use
            reasonable efforts to replace or modify the ((alleged) infringing
            part
            of the Service or to provide a work around solution and/or service
            that
            is substantially similar to the Service so that it is no longer
            subject
            to Third Party Claim or (iii) to terminate (part of) the
            Subscription
            the Service.
        </div>
        <div>
            The foregoing states the entire liability and obligation of
            Mindspeller
            and the sole remedy of Customer with respect to any Third Party
            Claim.
        </div>
        <div>
            4. In the event of founded third party claim against Mindspeller for
            reason of infringement of that party’s right (including the
            Intellectual Property Rights of such party) as a result of
            infringement
            by Customer of its warranties and/or obligations under the
            Agreement,
            Customer shall defend and hold Mindspeller harmless against the said
            claim if (i) the same is not solely attributable to the Service as
            such, (ii) Customer is given prompt notice of any such claim,
        </div>
        <div>
            (iii) Mindspeller provides reasonable co-operation to Customer in
            the
            defense and settlement of such claim, at Customer's expense and (iv)
            Customer is given sole authority to defend or settle the claim.
        </div>
        <div>
            5. Mindspeller, its Affiliates or its Representatives shall not be
            liable towards Customer (its Affiliates, its
        </div>
        <div>
            Representatives’ or its Affiliates’ Representatives) for any damage
            of
            third party claim to the extent that the alleged infringement is the
            result of Customer’s breach of the Agreement, including but not
            limited
            to (i) modification of the Service by anyone other than Mindspeller,
            (ii) Customer’s (or its Affiliates’, its
        </div>
        <div>
            Representatives’ or its Affiliates’ Representatives) use of the
            Service
            contrary to the Agreement or the instructions given by Mindspeller,
            (iii) after Customer was informed that the Service infringes (or may
        </div>
        <div>
            infringe) a third party right, (iv) Customer’s failure to integrate
            any
            update or corrections to the Service as indicated by Mindspeller or
            (v)
            Customer’s (or its Affiliates’, its Representatives’ or its
            Affiliates’
        </div>
        <div>
            Representatives) use of the Service in combination with any
            incompatible or unauthorized products or services.
        </div>
        <div>
            6. To the extent permitted under the applicable law, Mindspeller shall
            in
            no event be liable for any incidental, special, punitive or
            consequential
            damages (including loss of profit and loss of business) suffered by
            Customer, his Affiliates, its Representatives’ or its Affiliates’
            Representatives or by any other party in connection with the Agreement.
            The
            total aggregate liability of Mindspeller shall in any event be limited
            to
            the yearly License Fee
        </div>
        <div>
            paid or payable by Customer for the Service when the claim relates to
            the
            Service, or, when the claim relates to an Additional Service, the fee
            for
            that Additional Service.
        </div>
        <div>
            7. Nothing in the Agreement excludes the liability of a Party for death
            or
            personal injury caused by that Party's or for fraud or fraudulent
            misrepresentation attributable to it.
        </div>
        <div>
            8. Each Party shall have a duty to mitigate damages for which the other
            Party is liable.
        </div>&nbsp;
        <h7>
            11. Intellectual Property
        </h7>
        <div>
            1. The Agreement does not intend and shall not bring or be construed to
            bring any change to the propriety of or licenses to any Intellectual
            Property Rights of Mindspeller and Customer shall not, as a result of
            the
            Agreement, be entitled to any claim with regard to any Intellectual
            Property Rights to which, prior to the signing of the Agreement, it was
            not
            entitled.
        </div>
        <div>
            2. Mindspeller is and remains the exclusive owner of all Intellectual
            Property Rights relating to its products and services including, but not
            limited to, the Intellectual Property that underlies or is incorporated,
            included or used in the Platform, the Service, including Documentation,
            and
            the Service Results. All rights, titles and interests in such
            Intellectual
            Property shall remain vested in Mindspeller.
        </div>
        <div>
            If the Agreement awards to Customer certain rights or licenses to
            Mindspeller’s Intellectual Property or if the purpose of the Agreement
            requires such use, it is understood that such rights are awarded only
            for
            the term of the License and that Customer's sole right in relation to
            Mindspeller’s Intellectual Property are at all time restricted to the
            use
            thereof to the extent that they are incorporated in the Service or the
            Service Results under the terms and conditions set out herein.
        </div>
        <div>
            3. Without limiting the scope of other provisions of the Agreement and
            of
            License limitations set forth herein, Customer shall not, without prior
            written authorization of Mindspeller, copy, modify, make derivative
            works
            based on, disassemble, decompile, reverse engineer or translate anything
            that is protected by Mindspeller's Intellectual Property Rights nor
            remove,
            modify or obliterate any trademark, logo, name, notice, identifying
            number
            or coding applied to the Service provided by Mindspeller, its Affiliates
            or
            its Representatives under the Agreement.
        </div>
        <div>
            In the event that, notwithstanding any prohibition thereto, Customer
            modifies or creates derivative works of the Service or the Service
            Results,
            Mindspeller shall own all right, title and interest, including any
            Intellectual Property Rights, in and to such modifications and
            derivatives
            and Customer hereby assigns any such rights, title and interest in such
            modifications and derivatives to Mindspeller at no cost to the latter.
            Customer represents and warrants that it has the authority to assign
            full
            title to these modifications and derivative works and the Intellectual
            Property Rights pertaining thereto in accordance with the terms and
            conditions of the Agreement.
        </div>
        <div>
            4. Mindspeller may use Customer's name or logo (or the name or logo of
            any
            other member of Customer's Group or Customer’s commercial name or logo)
            in
            any advertising or promotional media in the course of performance of
            this
            Agreement or thereafter.
        </div>&nbsp;
        <h7>
            12. Confidentiality
        </h7>
        <div>
            1. The Parties acknowledge that under the Agreement they may disclose to
            each other information that may be of a confidential nature if so
            designated
            (“<strong>Disclosing Party</strong>”), and shall remain the Disclosing
            Party’s exclusive
            property (“ <strong>Receiving Party</strong>”).
        </div>
        <div>
            2. For the purposes of the Agreement " <strong>Confidential
                Information</strong>" shall mean
            without
            limitation,
            all information that is obtained directly or indirectly by the Receiving
            Party (including its Affiliates or its Representatives) from the
            Disclosing
            Party (including its Affiliates or its Representatives) in connection
            with
            the Agreement or the Disclosing Party’s business. Confidential
            Information
            shall also include, but not be limited to, any information related to
            the
            Platform and any Mindspeller’s products or services and any Intellectual
            Property Rights related thereto, as well
        </div>
        <div>
            as any valuations, analyses, compilations, studies of the same and any
            documents or other materials independently of the form in which and the
            medium by which the information is provided (among others oral and
            written
            transfer as well as transfer by means of a software or any electronic or
            audio-visual form and whether in draft or final form) related thereto.
        </div>
        <div>
            Confidential Information shall not include any information that:
        </div>
        <div>
            - is in the public domain at the time it is disclosed or becomes part of
            the public domain after disclosure without the Receiving Party’s
            Affiliates
            (or its Affiliates’ or its Representatives’) breach of any obligation
            under
            the Agreement;
        </div>
        <div>
            - is already known to the Receiving Party (or its Affiliates’ or its
            Representatives’) at the time of disclosure and
        </div>
        <div>
            is not subject to restriction;
        </div>
        <div>
            - becomes known to the Receiving Party (or its Affiliates’ or its
            Representatives’) from a source other than the Disclosing Party (or its
            Affiliates’ or its Representatives’) without breach of an obligation of
            confidentiality owed to Disclosing Party;
        </div>
        <div>
            - is developed by the Receiving Party (or its Affiliates’ or its
            Representatives’) without access to any of the
        </div>
        <div>
            other Party’s Confidential Information;
        </div>
        <div>
            - is released for publication or disclosed pursuant to judicial order, a
            requirement of a governmental agency
        </div>
        <div>
            or by operation of law by the Disclosing Party (or its Affiliates’ or
            its
            Representatives’).
        </div>
        <div>
            3. Unless the Receiving Party obtains explicit prior authorization
            from the Disclosing Party, the former shall not to use the Confidential
            Information disclosed by the latter for any other purpose than strictly
            necessary for the due performance of the Agreement. The Receiving Party
            shall keep the Confidential Information confidential at all times and
            shall
            not disclose it to any third party, shall take at least those measures
            as
            the Receiving Party takes to protect own similar confidential
            information
            (but in no event less than a reasonable degree of care).
        </div>
        <div>
            The Receiving Party shall only be permitted to disclose (and shall not
            be
            required to destroy) Confidential Information if and to the extent that:
        </div>
        <div>
            - is required by the applicable law or regulation or pursuant to a court
            or
            administrative order issued by a court of competent jurisdiction or by a
            governmental authority that is lawfully entitled to require such
            disclosure, provided, however, that the Receiving Party shall (i) prior
            to
            any disclosure notify and fully inform the Disclosing Party of such
            obligation to disclose and demonstrate the existence and the extent of
            the
            requirement to disclose in order to allow the Disclosing Party to assess
            and, as a case may be, to contest it,
        </div>
        <div>
            (ii) cooperate with the Disclosing Party if the Disclosing Party seeks a
            protective order or other remedy in respect of any such disclosure and
            (iii) abide by the Disclosing Party’s reasonable instructions in order
            to
            limit the disclosure; or
        </div>
        <div>
            - to its Affiliates and Representatives however, that the Receiving
            Party
            shall do this (i) only the on need-to- know basis for the performance of
            the Agreement and (ii) if they are bound by confidentiality obligations
            that are at least as restrictive as those set forth in this article 21.
        </div>
        <div>
            Subject to other provisions of the Agreement and without prejudice to
            article 17, upon the Disclosing Party’s request and in any case after
            the
            termination or expiry of the Agreement, whatever the reason, the
            Receiving
            Party shall immediately return to the Disclosing Party all Confidential
            Information (and any copies thereof) in its possession or destroy the
            same
            if this is requested by the Disclosing Party.
        </div>
        <div>
            4. The Receiving Party shall promptly notify the Disclosing Party of any
            unauthorized access, use, copying or disclosure of Confidential
            Information
            of which the former becomes aware, shall use its best endeavors to
            terminate such access, use etc. and shall provide all reasonable
            assistance
            requested by the Disclosing Party.
        </div>
        <div>
            5. The provisions of this article shall remain in force during five (5)
            years after the termination or expiry of the Agreement.
        </div>&nbsp;
        <h7>
            a. Non-enticement
        </h7>
        <div>
            For the entire duration of the Agreement and for one (1) year following
            termination or expiry of the Agreement, Customer, its Affiliates, its
            Representatives’ or its Affiliates’ Representatives shall not (attempt
            to)
            hire, offer to hire or otherwise entice away any Representative of
            Mindspeller or of its Affiliates whether directly or indirectly. In case
            of
            breach of the said obligation by Customer, Mindspeller shall be entitled
            to
            a lump-sum compensation of fifty thousand euro (€ 50.000,00) without
            prejudice to its right to claim compensation for losses, damages and
            costs
            effectively suffered.
        </div>&nbsp;
        <h7>
            b. Miscellaneous
        </h7>
        <div>
            1. <strong><u>Notices</u></strong> Mindspeller may give
            any notice to Customer by means of a general notice through the
            Platform,
            by notice to the Account Administrator or by e-mail or registered mail
            to
            Customer’s (e-mail) address that is on record in Mindspeller's account
            information. Such notice shall be deemed to have been given upon the
            expiration of 48 hours after sending if made though the Mindspeller
            platform or by registered mail and of 12 hours if sent by email.
            Customer
            may give notice to Mindspeller (such notice shall be deemed given when
            received by Mindspeller) by registered letter sent through nationally
            recognized overnight delivery service at Mindspeller’s
            registered address, with full copy to be sent to <a
                href="mailto:helpdesk@mindspeller.com">helpdesk@mindspeller.com.</a>
            Such notice
            shall be deemed to have been given upon the expiration of 48 hours after
            sending of the registered letter.
        </div>
        <div>
            2. <strong><u>Severability</u></strong> If any
            provision
            of the Agreement is invalid or unenforceable, this shall not affect the
            remaining provisions thereof which shall remain in effect. The invalid
            or
            unenforceable provision shall be deemed to be replaced by an alternative
            valid and enforceable provision that is as closely in line with the
            Parties’ original intent as allowed under the applicable law.
        </div>
        <div>
            3. <strong><u>Entire Agreement</u></strong> The
            Agreement
            constitutes the entire agreement between the Parties with respect to the
            subject matter hereof, and supersedes and replaces all prior or
            contemporaneous understandings or agreements, written or oral, regarding
            the subject matter including, but not limited to, any prior
            non-disclosure
            or confidentiality agreement. No amendment of the Agreement is binding
            unless executed in writing and signed by duly authorized representatives
            of
            the Parties.
        </div>
        <div>
            4. <strong><u>Non-agency</u></strong> At all times
            during
            the term of the Agreement, Customer shall act as an independent party
            and
            neither the execution of the Agreement nor the performance thereof shall
            be
            construed to constitute, for any purpose, an agency relationship between
            the Parties, nor a relation of legal representation in the name of or on
            behalf of either Party nor a joint venture or a partnership. No Party
            shall
            have the right to act in the name or on behalf of the other Party,
            without
            latter’s prior written consent.
        </div>
        <div>
            5. <strong><u>No Waiver</u></strong> The election of
            any
            one or more remedies by either Party shall not constitute a waiver by
            such
            Party of the right to pursue any other available remedies. No failure by
            either Party to exercise and no delay by either Party in exercising (in
            whole or in part), any right in relation to the Agreement shall operate
            as
            a waiver of any such right.
        </div>
        <div>
            6. <strong><u>Force Majeure</u></strong> If, for any
            reason beyond the reasonable control of a Party, including, without
            limitation, acts of God, earthquakes, floods and other natural
            disasters,
            wars, insurrections, strikes, riots, fires or orders by any government
            department, council or other constituted body, such Party is unable to
            perform in whole or in part its obligations under the Agreement, such
            Party
            shall be relieved of those obligations to the extent it is unable to
        </div>
        <div>
            perform and such inability to perform, so caused, shall not make such
            Party
            liable to the other Party on the condition that the Party who is unable
            to
            perform notified the other Party of such inability within seven (7) days
            of
            the onset of such inability.
        </div>
        <div>
            7. <strong><u>Assignment</u></strong> Customer shall
            not assign or otherwise transfer the Agreement in whole or in part or any of
            its rights and obligations under the Agreement without prior written
            consent of Mindspeller. The Agreement shall be binding on and inure to
            the benefit of the Parties and their respective successors and permitted
            assigns.
        </div>
        <div>
            8. <strong><u>Third Party Beneficiaries</u></strong> Nothing in the
            Agreement is intended to confer any benefits on any
            person other than the Parties or their respective successors or permitted
            assigns.
        </div>
        <div>
            9. <strong><u>Government Approvals</u></strong> The
            Agreement, and the obligations and rights of the Parties hereunder, may
            be
            subject to any review, registration, approval, or other requirement of
            any
            governmental authority that is mandated by applicable law including,
            without limitation, export controls. Customer shall obtain and maintain
            all
            permits, licenses, and government registrations necessary or appropriate
            to
            receive and/or use the Service, the Additional Service and the Service
            Results.
        </div>
        <div>
            10. <strong><u>Injunctive Relief</u></strong> Since a
            breach of any part of the Agreement by Customer, in particular the
            provisions governing intellectual property rights, may cause irreparable
            harm to Mindspeller for which monetary damages are inadequate,
            Mindspeller
            may seek immediate injunctive and/or other equitable relief should such
            a
            breach occur.
        </div>
        <div>
            11. <strong><u>Governing Law and Dispute Resolution</u></strong> All
            disputes in connection with the existence,
            validity, construction, performance, non-performance, breach or termination
            of the Agreement (or any terms thereof) that are not settled shall
            be settled.
        </div>
    </div>
</template>
