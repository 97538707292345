<template>
  <div class="rang-bar">
   <div class="emoSlider">
     <div class="emoA">
        <q-slider
          class="emotional-analysis-slider"
          v-model="emotional_data.A"
          min="0"
          max="10"
          step="1"
          label
          label-always
          :label-value="computedLabelValueA"
          color="teal-13"
          readonly
        />
      </div>
      <div class="emoB">
        <q-slider
          class="emotional-analysis-slider"
          v-model="emotional_data.B"
          min="0"
          max="10"
          step="1"
          label
          label-always
          switch-label-side
          :label-value="computedLabelValueB"
          readonly
        />
      </div>
    </div>
  </div>
  <div class="one-line-text">
    <span>{{ labels.left }}</span> <span>{{ labels.right }}</span>
  </div>
</template>

<script>
export default {
  name: 'EmotionalAnalysis.vue',
  props: {
    emotional_data: { required: true, type: Object },
    labels: { required: true, type: Object },
    showValueLabel: {required: true, type: Boolean},
  },
  computed: {
    hasData() {
      return (
        this.$props.emotional_data.A !== null ||
        this.$props.emotional_data.B !== null
      );
    },
    demoUser: function() {
      return this.$store.state.current_license.is_demo == 1;
    },
    displayLock: function() {
      return this.demoUser && !this.hasData;
    },
    computedLabelValueA() {
      return this.showValueLabel === true ? this.emotional_data.A : 'A';
    },
    computedLabelValueB() {
      return this.showValueLabel === true ? this.emotional_data.B : 'B';
    }
  },
};
</script>

<style scoped>

.emoB {
  position:relative;
    bottom: 34px;
    left: 0;
}
</style>