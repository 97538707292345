<template>
    <q-dialog class="popup-container" v-model="isVisible">
      <q-card>
        <div class="dialog-header">
            <img v-bind:src="dialogHeaderImageUrl" class="header-image">
            <q-btn class="close-btn" flat round dense @click="closePopup">
                <q-icon name="close" class="close-icon" />
            </q-btn>
        </div>
        <q-card-section class="tip-title">
            <q-card-title>{{ dialogTitle }}</q-card-title>
        </q-card-section>
        <q-card-section class="tip-content">            
            <q-card-main class="tip-content-items">
                <div>{{ dialogContent }}</div>
                <hr v-if="showExtendedContent" class="separator">
                <q-btn v-if="!showExtendedContent" dense flat label="Show more" @click="showExtendedContent = true" class="extended-content-btns" icon="arrow_downward" />
                <div v-if="showExtendedContent">{{ dialogExtendedContent }}</div>
                <hr v-if="showExtendedContent" class="separator">
                <div v-if="showExtendedContent && hasAccordion" class="accordion-container">
                    <p class="archetypes-heading">{{ accordionHeading }}</p>
                    <div class="accordion">
                        <div
                        v-for="(item, index) in accordionItems"
                        :key="index"
                        class="accordion-item"
                        >
                        <div v-if="!item.open" class="accordion-header" @click="toggleItem(index)">
                            {{ item.title }}
                        </div>
                        <div v-if="item.open" class="accordion-header-selected" @click="toggleItem(index)">
                            {{ item.title }}
                        </div>
                        <div v-if="item.open" class="accordion-content">
                            {{ item.content }}
                        </div>
                        </div>
                    </div>
                    <p v-if="hasFooter" style="margin-top: 10px;">{{ footerContent }}</p>
                </div>
                <q-btn v-if="showExtendedContent" dense flat label="Show less" @click="showExtendedContent = false" class="extended-content-btns" icon="arrow_upward" />
            </q-card-main>
        </q-card-section>
      </q-card>
    </q-dialog>
</template>
  
<script>
  export default {
    name: "OnboardingTipPopup",
    methods: {
        toggleItem(index) {
            this.accordionItems.forEach((item, i) => {
                if (i !== index) {
                    item.open = false;
                }
            });
            this.accordionItems[index].open = !this.accordionItems[index].open;
        },
        closePopup() {
            this.showExtendedContent = false;
            this.tipVisible = false;
            this.$emit('update:tipVisible', this.tipVisible);
        },
        prepareAccordionContent(){
                switch (this.onboardingPhase){
                    case "archetypes":
                        this.accordionItems = [
                            { title: 'The Magician', content: 'These companies don’t just sell you a product that you enjoy. Magicians sell you a transformative experience that lasts far beyond that first purchase. The typical Magician company is Disney. Disney creates brands and experiences that are timeless and without peer. Disney also has the vision to take its powerful brands and create entirely new product categories. The company might have redefined animation, but it also created a whole new industry: theme parks. Magician companies enjoy brand equity that most companies can only dream of.', open: false },
                            { title: 'The Sage', content: 'A Magician might capture your imagination but a Sage commands respect. A Sage brand is built upon accumulated knowledge and seeks the fundamental truths to set itself apart from competitors. Berkshire Hathaway (BK) is a perfect example of a Sage. Warren Buffet’s brainchild weathers recessions and market shocks to provide strong, consistent returns. BK makes it a habit to be right without being flashy. The company flies under the radar but still competes with the best conglomerates and asset managers.', open: false },
                            { title: 'The Innocent', content: 'These brands keep it simple and try to charm you with nostalgia or a good time. Innocent brands aren’t concerned with trying to make a statement or cause controversy. They are virtuous in a world that is content and happy. Orville Redenbacher is an Innocent brand; it sells you a childhood treat without any baggage or guilt.', open: false },
                            { title: 'The Outlaw', content: 'The Outlaw has far broader ambitions than the Innocent. The Outlaw wants you to bend the rules and take a walk on the wild side. Harley Davidson is the quintessential Outlaw. Why drive a car with four wheels when you can ride a motorcycle and face a significantly higher risk of injury? Outlaws sometimes paint themselves as fighting the status quo or being a disruptor. Consequently, they can never been seen as timid or complacent.', open: false },
                            { title: 'The Lover', content: 'These brands emphasize being there for the most intimate moments of your life. The Lover pushes pleasure, passion, even sensuality in its messaging. Most importantly, the Lover wants to be the purchase of choice for moments of celebration or indulgence. Godiva is one of the brands most associated with the Lover. Godiva isn’t sold to solve a problem or create change. Godiva seduces you with rich flavors and silky caramel.', open: false },
                            { title: 'The Explorer', content: 'Freedom and adventures are the top priorities for the Explorer. The Explorer isn’t concerned about routines or creature comforts; the Explorer wants to enable customers to go off the beaten path and experience new things. Subaru is a good example of an Explorer. The car company doesn’t sell comfort or luxury interiors; Subaru wants you to hop in their car and hit the open road.', open: false },
                            { title: 'The Ruler', content: 'These brands base their value on luxury and exclusivity. They are bywords for their industry and instantly identifiable. The Ruler is a gatekeeper and the benchmark for all competitors. Rolex is the quintessential Ruler brand. You can’t say “luxury watch” without mentioning Rolex in the same breath. Rolex watches aren’t cheap or practical, but they say a lot about their owners. Someone who owns a Rolex must have taste and a fair amount of disposable income.', open: false },
                            { title: 'The Hero', content: 'The Hero isn’t concerned about nurturing or fixing problems. The Hero wants to make a dent in the universe. These brands want you to rise to the occasion and rely on their products to do it. GoPro is a Hero brand that is dedicated to creating products that record your proudest accomplishments. GoPro cameras aren’t used to film mundane activities; GoPro’s are used to record you cresting a massive wave or summiting a mountain. There can be some overlap between the Hero and the Explorer. The Explorer calls on you to push your boundaries but the Hero is there to help make it happen.', open: false },
                            { title: 'The Regular Guy/Girl', content: 'Many of these brands want you to push your limits or rebel against the status quo. That’s not the case with the Regular Guy/Girl. These brands want to fit in and appeal to everyone. One of the biggest challenges with these types of brands is that they need to appeal to a very broad demographic. Folgers’s Coffee does exactly that. It’s not hip, it’s not inspiring, but it appeals to everyone who drinks coffee and doesn’t want to be bothered. Few things are as universal as coffee, which allows Folgers to target a very large audience.', open: false },
                            { title: 'The Creator', content: 'The Creator strives to create a product or experience that you cannot live without. Though similar to the Magician, the Creator focuses less on vision and more on creating the perfect product. If Disney is the Magician, Pixar is the Creator. Pixar isn’t concerned about creating franchises or building an empire. Pixar is trying to do one thing and do it better than anyone else: computer enhanced animation. No other company comes close to Pixar in this category and Pixar isn’t interested in expanding beyond that niche.', open: false },
                            { title: 'The Jester', content: 'The Jester is all about lighthearted fun. Jester brands aren’t trying to solve problems or save the world. They just want to brighten your day through humor and a little silliness. Mentos is a Jester brand that promotes taking a break in your day and having fun, if only for a moment. Jester brands are daily consumer products rather than serious purchases. This can make it difficult to stretch the brand to other categories.', open: false },
                            { title: 'The Caregiver', content: 'The Caregiver’s brand is built on trust and reliability. These brands are there for you (and your family) when you need them most. Caregiver brands don’t attack competitors and their messaging is never confrontational. One of the best examples of a Caregiver brand is Johnson & Johnson. The company makes the products you take home to your family and your children. For these brands trust is critical; once lost, it can be very difficult to regain.', open: false },
                        ]
                        this.accordionHeading = "Archetypes Description"
                        this.dialogHeaderImageUrl = require('../../../assets/tips/archetype-tip-header.jpg')
                        break;
                    case "values":
                        this.accordionItems = [
                            { title: 'Google', content: 'To organize the world’s information and make it universally accessible and useful.', open: false },
                            { title: 'TED', content: 'Spread Ideas.', open: false },
                            { title: 'Sony', content: 'To be a company that inspires and fulfills curiosity.', open: false },
                            { title: 'Tesla', content: 'To accelerate the world’s transition to sustainable energy.', open: false },
                            { title: 'JetBlue', content: 'To inspire humanity – both in the air and on the ground.', open: false },
                            { title: 'IKEA', content: 'To create a better everyday life for many people.', open: false },
                            { title: 'Paypal', content: 'To build the web’s most convenient, secure, cost-effective payment solution.', open: false },
                            { title: 'Nike', content: 'Bring inspiration and innovation to every athlete in the world. If you have a body, you are an athlete.', open: false },
                            { title: 'Warby Parker', content: 'To offer designer eyewear at a revolutionary price, while leading the way for socially conscious businesses.', open: false },
                            { title: 'Prezi', content: 'To reinvent how people share knowledge, tell stories, and inspire their audiences to act.', open: false },
                            { title: 'Whole Foods Market', content: 'To nourish people and the planet.', open: false },
                            { title: 'Honest Tea', content: 'To create and promote great-tasting, healthy, organic beverages.', open: false },
                            { title: 'Kickstarter', content: 'To help bring creative projects to life.', open: false },
                            { title: 'Squarespace', content: 'To empower people with creative ideas to succeed.', open: false },
                            { title: 'Patagonia', content: 'To build the best product, cause no unnecessary harm, use business to inspire and implement solutions to the environmental crisis.', open: false },
                            { title: 'Coca-Cola', content: 'To refresh the world in mind, body and spirit. To inspire moments of optimism and happiness through our brands and actions.', open: false },
                        ]
                        this.accordionHeading = "Solid Brand Value Examples"
                        this.dialogHeaderImageUrl = require('../../../assets/tips/values-tip-header.jpg')
                        break;
                    case "brand_info":
                        this.accordionItems = []
                        this.accordionHeading = ""
                        this.dialogHeaderImageUrl = require('../../../assets/tips/brand-info-tip-header.jpg')
                    default:
                        this.accordionItems = []
                        this.accordionHeading = ""
                }
            }
    },
    mounted() {
        this.prepareAccordionContent()
    },
    props: {
        hasAccordion: { required: true, type: Boolean },
        hasFooter: { required: true, type: Boolean },
        dialogTitle: {required: true, type: String},
        dialogContent: {required: true, type: String},
        dialogExtendedContent: {required: true, type: String},
        onboardingPhase: {required: true, type: String},
        footerContent: {required: false, type: String}
    },
    data() {
      return {
        showExtendedContent: false,
        accordionItems: [],
        accordionHeading: "",
        dialogHeaderImageUrl2: ""
      };
    }
  };
</script>

<style scoped>
    .q-card {
        width: 50%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 10px;
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 10px;
        align-items: center;
        gap: 20px;
    }
    .dialog-header {
        display: flex;
        align-items: flex-start;
        width: 100%;
        gap: 5px;
    }

    .header-image {
        width: 100%;
        height: 200px;
    }
    .close-btn{
        border-color: white !important;
    }
    .close-icon {
        color: white !important;
    }
    .tip-title{
        font-size: 18px;
        font-weight: bolder;
        text-align: center;
    }
    .tip-content{
        text-align: justify;
    }
    .tip-content-items{
        display: flex;
        flex-direction: column;
        gap: 25px;
        align-items: center;
    }
    .extended-content-btns {
        width: 100px;
        text-transform: none;
    }
    .accordion {
        width: 100%;
    }
    .accordion-item {
        border: 1px solid #ccc;
        margin-bottom: 5px;
        width: 100%;
    }
    .accordion-header {
        background-color: #f4f4f4;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
    }
    .accordion-header-selected {
        background-color: #7878E9;
        padding: 10px;
        cursor: pointer;
        color: white;
        font-weight: bolder;
    }
    .accordion-content {
        padding: 10px;
    }
    .accordion-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }
    .q-dialog__inner--minimized > div {
        max-width: none !important;
    }
    .archetypes-heading{
        font-weight: bold;
    }
    .separator{
        border: solid; 
        border-width: 1px; 
        width: 100%;
        color: #7878E9;
    }
</style>