<template>
     <section class="middle-section">
                <div class="container">
                    <div class="subsc-pg-order">
                        <div class="top-pg-hed">
                            <h6>Checkout</h6>
                        </div>
                        <div class="flx-tow-col">
                            <div class="billing-info-col form-css-col">
                                <h5>Billing Information<span class="ext-sml-txt">We use this information to register products secure your identity and calculate taxes and fees</span></h5>
                                <form>
                                    <div class="ext-sml-txt">All fields are required otherwise stated</div>
                                    <div class="flx-col-2text">
                                        <div class="box-flx-com">
                                            <label>First Name</label>
                                            <input type="text" placeholder="John">
                                        </div>
                                        <div class="box-flx-com">
                                            <label>Last Name</label>
                                            <input type="text" placeholder="Smith">
                                        </div>
                                    </div>
                                    <div class="flx-col-2text">
                                        <div class="box-flx-com">
                                            <label>Country</label>
                                            <select class="form-select" aria-label="form-select-sm example">
                                                <option selected="">United States</option>

                                            </select>
                                        </div>
                                        <div class="box-flx-com">
                                            <label>Phone Number</label>
                                            <input type="Phone" placeholder="+1 0000 0000 00">
                                        </div>
                                    </div>
                                    <div class="border-box-bott">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Notify about Renewal and special offers
                                        </label>
                                    </div>
                                    <label>Address</label>
                                    <input type="text" placeholder="Enter address">
                                    <div class="flx-col-2text">
                                        <div class="box-flx-com">
                                            <label>Country</label>
                                            <select class="form-select" aria-label="form-select-sm example">
                                                <option selected="">United States</option>

                                            </select>
                                        </div>
                                        <div class="box-flx-com">
                                            <label>Postal</label>
                                            <input type="text" placeholder="123456">
                                        </div>
                                    </div>
                                    <label>Organization</label>
                                    <input type="text" placeholder="Organization">

                                    <div class="btn-div">
                                        <button type="button" class="btn3" style="width: 100%" @click="$router.push('/payment_success')">Save</button>
                                    </div>
                                </form>
                            </div>
                            <div class="gry-sml-rgt-bx">
                                <div class="top-pg-hed">
                                    <h6>Order Summary</h6>
                                </div>
                                <div class="sub-head">
                                    <spna class="lft-tx-com">Subtotal (€)</spna>
                                    <spna class="rgt-tx-com">€ 1660</spna>
                                </div>
                                <div class="ext-sml-txt">Subtotal includes all applicable taxes</div>
                                <div class="mid-sml-txt">You’re getting the best price we have got</div>
                                <div class="seq-pay"><img src="../../assets/msg-icon.png">Nice! <span class="ext-sml-txt" style="display: inline-block; padding-left:5px;">You have saved $ 100.00 on your order </span></div>

                                <div class="seq-pay" style="justify-content:center;"><img src="../../assets/lock-icon.png">Secure Payment</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
</template>

<script>

export default {
    name: "BillingInfo"
}
</script>