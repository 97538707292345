// Translation fo parts of speech displayed after clicking on a word
// in content optimizer. English and Dutch have different parts of speech and
// abbreviations, that's why translations for each language are different.

const pos_translation = {
    en: {
        message: {
            "CC": 'Coordinating conjunction',
            "CD": 'Cardinal number',
            "DT": 'Determiner',
            "EX": 'Existential \'there\'',
            "FW": "Foreign word",
            "IN": 'Preposition or subordinating conjunction',
            "IN/that": 'subordinator',
            "JJ": 'Adjective',
            "JJR": 'Adjective, comparative',
            "JJS": 'Adjective superlative',
            "LS": 'List item marker',
            "MD": 'Modal',
            "NN": 'Noun singular',
            "NNS": "Noun plural",
            "NP": 'Proper noun singular',
            "NPS": 'proper noun plural',
            "PDT": 'Predeterminer',
            "POS": 'Possessive ending',
            "PP": 'Personal pronoun',
            "PP$": 'Possessive pronoun',
            "RB": 'Adverb',
            "RBR": 'Adverb, comparative',
            "RBS": 'Adverb, superlative',
            "RP": 'Particle',
            "SYM": 'Symbol',
            "TO": 'to',
            "UH": 'Interjection',
            "VV": 'Verb, base form',
            "VVD": 'Verb, past tense',
            "VVG": 'Verb, gerund',
            "VVN": 'Verb, past participle',
            "VVP": 'Verb',
            "VVZ": 'Verb',
            "WDT": 'Wh-determiner',
            "WP": 'Wh-pronoun',
            "WP$": 'Possessive wh-pronoun',
            "WRB": 'Wh-adverb',
        },
    },
    nl: {
        message: {
            "$.": 'leesteken',
            "adj": 'bijvoeglijk naamwoord',
            "adj*kop": 'bijvoeglijk naamwoord',
            "adjabbr": 'bijvoeglijk naamwoord',
            "adv": 'bijwoord',
            "advabbr": 'bijwoord',
            "conjcoord": 'voegwoord',
            "conjsubo": 'voegwoord',
            "det__art": 'lidwoord',
            "det__demo": 'aanwijzend voornaamwoord',
            "det__indef": 'onbepaald voornaamwoord',
            "det__poss": 'bezittelijk voornaamwoord',
            "det__quest": 'vragend voornaamwoord',
            "det__rel": 'betrekkelijk voornaamwoord',
            "int": 'tussenwerpsel',
            "noun*kop": 'zelfstandig naamwoord',
            "nounabbr": 'zelfstandig naamwoord',
            "nounpl": 'zelfstandig naamwoord',
            "nounprop": 'zelfstandig naamwoord',
            "nounsg": 'zelfstandig naamwoord',
            "num__card": 'kardinaalgetal',
            "num__ord": 'ordinaalgetal',
            "partte": 'partikel \"te\"',
            "prep": 'voorzetsel',
            "prepabbr": 'voorzetsel',
            "pronadv": 'voornaamwoordelijk bijwoord',
            "prondemo": 'voornaamwoord',
            "pronindef": 'voornaamwoord',
            "pronpers": 'voornaamwoord',
            "pronposs": 'voornaamwoord',
            "pronquest": 'voornaamwoord',
            "pronrefl": 'voornaamwoord',
            "pronrel": 'voornaamwoord',
            "punc": 'leesteken',
            "verbinf": 'infinitief',
            "verbpapa": 'werkwoord',
            "verbpastpl": 'werkwoord',
            "verbpastsg": 'werkwoord',
            "verbpresp": 'werkwoord',
            "verbprespl": 'werkwoord',
            "verbpressg": 'werkwoord',
        },
    }
};

export { pos_translation };
