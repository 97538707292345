<template>
    <div class="home-menu-item">
     <a @click="handleClick"><font-awesome-icon class="logo" :icon="['fas', 'home']"/>Home</a>
    </div> 
 </template>

<script>
export default {
    name: "Home",
    methods: {
        handleClick : function () {
             this.$router.push({name: 'Dashboard'})
        },
    },
}
</script>

<style>
.home-menu-item{
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    font-size: 1.1vw;
    line-height: 1.5vw;
    color: white;
    position: relative;
    cursor: pointer;
}
.logo{
    width: 20px;
    margin-right: 10px;
    color: white;
}
</style>
