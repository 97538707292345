<template>
  <div class="svg-icon">
	<svg version="1.1" :id="svgId" xmlns="http://www.w3.org/2000/svg" x="0px"
		y="0px"
		width="100%" viewBox="0 0 1080 1080" enable-background="new 0 0 1080 1080" xml:space="preserve">
		<defs>
			<linearGradient id="gradient_fill" x1="0%" y1="0%" x2="100%" y2="0%" gradientTransform="rotate(90 0 0)">
				<stop offset="0%" stop-color="rgba(246,185,64)"/>
				<stop offset="50%" stop-color="rgba(244,49,117)"/>
				<stop offset="80%" stop-color="rgb(54,86,231)"/>
			</linearGradient>
			<linearGradient id="gradient_stroke" x1="0%" y1="0%" x2="100%" y2="0%" gradientTransform="rotate(90 0 0)">
				<stop offset="0%" stop-color="rgba(246,185,64)"/>
				<stop offset="50%" stop-color="rgba(244,49,117)"/>
				<stop offset="100%" stop-color="rgb(54,86,231)"/>
			</linearGradient>
			<mask :id="maskId">
				<rect x="0" y="0" :width="fill_percentage + '%'" height="1000" fill="white" />
			</mask>
		</defs>
		<path fill="url(#gradient_fill)" opacity="1.000000" stroke="none" stroke-width="10" :mask="`url(#${maskId})`"
			d="
	M433.990753,979.996826
		C434.075348,956.510132 434.126709,933.023193 434.251770,909.536743
		C434.481781,866.350281 434.601227,823.162048 435.158722,779.979675
		C435.254120,772.590027 433.701935,766.615540 427.652191,762.354858
		C419.545227,756.645508 411.659088,750.429260 402.934052,745.847595
		C394.905670,741.631775 385.830780,744.006104 377.245178,745.452454
		C362.775269,747.890198 348.351410,750.600342 333.884766,753.058350
		C312.617035,756.671936 294.642242,748.814026 277.511871,737.220154
		C261.474487,726.366150 249.943436,711.963867 240.790344,695.107788
		C233.673431,682.001587 225.668808,669.367065 217.800034,656.683411
		C213.135376,649.164490 205.005402,648.322754 197.233734,647.046692
		C176.761902,643.685303 156.766220,638.648682 139.436447,626.708557
		C124.741058,616.583435 114.853683,602.242432 108.105461,585.943176
		C102.275246,571.861267 99.517708,557.081421 98.747139,541.714417
		C97.386963,514.589539 100.604843,488.095337 106.448128,461.688324
		C110.810287,441.974792 118.093620,423.227417 123.780045,403.985260
		C129.807465,383.589294 139.248398,364.944916 148.557724,346.191681
		C159.465240,324.218903 172.777664,303.525665 188.242996,284.498260
		C201.528442,268.152802 215.572144,252.310333 230.427917,237.387650
		C244.224396,223.529068 259.464050,211.096375 274.206848,198.194229
		C285.702179,188.134140 296.964691,177.727066 309.133728,168.537704
		C325.205078,156.401550 341.751556,144.823792 358.682434,133.920029
		C371.199127,125.859077 384.465759,118.886650 397.735687,112.087822
		C421.474091,99.925476 446.725677,91.709106 472.712799,86.117424
		C489.244568,82.560265 506.037445,79.694481 522.862915,78.165268
		C541.303223,76.489288 559.914429,76.283836 578.453369,76.234138
		C593.158081,76.194717 607.926392,76.733452 622.556213,78.152481
		C638.779480,79.726082 654.922058,82.319771 671.017395,84.969818
		C687.214722,87.636673 703.389771,90.553406 719.431396,94.022675
		C740.017639,98.474777 760.529358,103.317245 780.948120,108.485580
		C805.519287,114.704933 828.913086,124.196457 851.257385,136.082840
		C870.845947,146.503204 889.055725,159.105270 905.975952,173.469955
		C913.773926,180.090179 921.412476,186.943695 928.661377,194.155701
		C943.112976,208.533569 954.440063,225.311127 964.527649,242.921280
		C971.244507,254.647156 977.977112,266.392792 984.007446,278.476562
		C993.296326,297.089935 999.373718,316.917969 1002.856934,337.356110
		C1005.380432,352.163025 1008.377380,367.351776 1007.548157,382.167938
		C1005.989197,410.023224 1000.476624,437.123169 984.572327,461.340759
		C972.650269,479.494537 959.597473,496.460541 943.526062,511.028107
		C928.538025,524.613831 911.883850,535.733154 892.945251,543.039734
		C880.411255,547.875488 867.845154,552.853516 854.910522,556.352478
		C844.885437,559.064331 834.310730,560.124390 823.903381,560.958984
		C805.961243,562.397888 787.960144,563.107788 769.982117,564.091797
		C750.495056,565.158508 734.576782,573.929321 720.099365,586.400024
		C714.201660,591.480225 707.502930,595.719299 700.839233,599.803650
		C685.129211,609.432678 669.214294,618.727051 653.395691,628.179321
		C640.475342,635.899841 631.899475,647.636292 625.080688,660.520752
		C606.154236,696.283447 587.455200,732.171509 569.135986,768.248596
		C558.396729,789.398132 548.220398,810.851135 538.400452,832.444946
		C528.064941,855.172424 516.789551,877.483276 508.768860,901.260254
		C504.217255,914.753418 497.889038,927.637939 492.666718,940.916870
		C488.337311,951.925415 484.441193,963.104797 480.402588,974.226440
		C480.077515,975.121643 480.136749,976.156372 479.588745,977.741333
		C479.102692,981.183716 479.076416,984.012024 478.981873,986.838135
		C478.764252,993.344421 474.740021,997.316101 468.404755,997.316833
		C459.404816,997.317871 450.404846,997.326965 441.404907,997.313721
		C435.155579,997.304504 434.774811,996.820007 434.653381,990.371460
		C434.588226,986.910339 434.221436,983.454834 433.990753,979.996826
	M556.499878,515.992676
	"/>
		<path fill="rgb(215,224,253)" opacity="0.5" stroke="none"
			d="
	M433.523071,979.998413 
		C434.221954,983.454895 434.588989,986.910217 434.654114,990.371277 
		C434.775513,996.820129 435.155304,997.303833 441.405457,997.313110 
		C450.405273,997.326416 459.405121,997.317200 468.404938,997.316162 
		C474.740295,997.315430 478.763916,993.343994 478.981415,986.837402 
		C479.075867,984.011353 479.102386,981.183105 479.531372,978.135925 
		C484.030853,978.610718 488.136017,979.606445 492.292603,979.919861 
		C496.932678,980.269714 501.624451,979.859253 506.285004,980.025330 
		C523.357361,980.633728 540.472717,980.767090 557.486023,982.142395 
		C588.234558,984.628052 618.981262,987.520142 649.195557,994.105957 
		C661.625854,996.815369 674.099426,999.763123 684.936462,1006.994263 
		C692.521606,1012.055542 692.593201,1017.944397 685.067810,1022.941467 
		C674.082886,1030.235962 661.494202,1033.408447 648.837341,1035.869385 
		C632.828430,1038.982300 616.707520,1041.629761 600.543823,1043.798340 
		C588.437195,1045.422485 576.203430,1046.300781 563.996338,1046.930542 
		C539.986694,1048.168579 515.959229,1049.086060 491.933136,1049.972290 
		C478.154816,1050.480591 464.358673,1051.090088 450.581177,1050.897217 
		C431.172180,1050.625610 411.749481,1050.041260 392.376343,1048.868896 
		C373.254120,1047.711670 354.127716,1046.145752 335.109314,1043.871216 
		C311.041962,1040.992676 286.908844,1038.018799 263.839844,1029.981934 
		C258.726807,1028.200684 253.721954,1025.785767 249.120193,1022.932251 
		C241.366653,1018.124390 241.009338,1011.246094 249.022476,1007.271118 
		C259.004791,1002.319275 269.554565,997.881958 280.358398,995.363342 
		C296.535065,991.592102 313.078247,989.117371 329.590820,987.134888 
		C348.771606,984.831970 368.070557,983.409180 387.350586,982.070435 
		C402.559967,981.014282 417.818359,980.663818 433.523071,979.998413 
	z"/>
		<path fill="none" opacity="1.000000" stroke="url(#gradient_stroke)" stroke-width="20"
			d="
	M433.990753,979.996826
		C434.075348,956.510132 434.126709,933.023193 434.251770,909.536743
		C434.481781,866.350281 434.601227,823.162048 435.158722,779.979675
		C435.254120,772.590027 433.701935,766.615540 427.652191,762.354858
		C419.545227,756.645508 411.659088,750.429260 402.934052,745.847595
		C394.905670,741.631775 385.830780,744.006104 377.245178,745.452454
		C362.775269,747.890198 348.351410,750.600342 333.884766,753.058350
		C312.617035,756.671936 294.642242,748.814026 277.511871,737.220154
		C261.474487,726.366150 249.943436,711.963867 240.790344,695.107788
		C233.673431,682.001587 225.668808,669.367065 217.800034,656.683411
		C213.135376,649.164490 205.005402,648.322754 197.233734,647.046692
		C176.761902,643.685303 156.766220,638.648682 139.436447,626.708557
		C124.741058,616.583435 114.853683,602.242432 108.105461,585.943176
		C102.275246,571.861267 99.517708,557.081421 98.747139,541.714417
		C97.386963,514.589539 100.604843,488.095337 106.448128,461.688324
		C110.810287,441.974792 118.093620,423.227417 123.780045,403.985260
		C129.807465,383.589294 139.248398,364.944916 148.557724,346.191681
		C159.465240,324.218903 172.777664,303.525665 188.242996,284.498260
		C201.528442,268.152802 215.572144,252.310333 230.427917,237.387650
		C244.224396,223.529068 259.464050,211.096375 274.206848,198.194229
		C285.702179,188.134140 296.964691,177.727066 309.133728,168.537704
		C325.205078,156.401550 341.751556,144.823792 358.682434,133.920029
		C371.199127,125.859077 384.465759,118.886650 397.735687,112.087822
		C421.474091,99.925476 446.725677,91.709106 472.712799,86.117424
		C489.244568,82.560265 506.037445,79.694481 522.862915,78.165268
		C541.303223,76.489288 559.914429,76.283836 578.453369,76.234138
		C593.158081,76.194717 607.926392,76.733452 622.556213,78.152481
		C638.779480,79.726082 654.922058,82.319771 671.017395,84.969818
		C687.214722,87.636673 703.389771,90.553406 719.431396,94.022675
		C740.017639,98.474777 760.529358,103.317245 780.948120,108.485580
		C805.519287,114.704933 828.913086,124.196457 851.257385,136.082840
		C870.845947,146.503204 889.055725,159.105270 905.975952,173.469955
		C913.773926,180.090179 921.412476,186.943695 928.661377,194.155701
		C943.112976,208.533569 954.440063,225.311127 964.527649,242.921280
		C971.244507,254.647156 977.977112,266.392792 984.007446,278.476562
		C993.296326,297.089935 999.373718,316.917969 1002.856934,337.356110
		C1005.380432,352.163025 1008.377380,367.351776 1007.548157,382.167938
		C1005.989197,410.023224 1000.476624,437.123169 984.572327,461.340759
		C972.650269,479.494537 959.597473,496.460541 943.526062,511.028107
		C928.538025,524.613831 911.883850,535.733154 892.945251,543.039734
		C880.411255,547.875488 867.845154,552.853516 854.910522,556.352478
		C844.885437,559.064331 834.310730,560.124390 823.903381,560.958984
		C805.961243,562.397888 787.960144,563.107788 769.982117,564.091797
		C750.495056,565.158508 734.576782,573.929321 720.099365,586.400024
		C714.201660,591.480225 707.502930,595.719299 700.839233,599.803650
		C685.129211,609.432678 669.214294,618.727051 653.395691,628.179321
		C640.475342,635.899841 631.899475,647.636292 625.080688,660.520752
		C606.154236,696.283447 587.455200,732.171509 569.135986,768.248596
		C558.396729,789.398132 548.220398,810.851135 538.400452,832.444946
		C528.064941,855.172424 516.789551,877.483276 508.768860,901.260254
		C504.217255,914.753418 497.889038,927.637939 492.666718,940.916870
		C488.337311,951.925415 484.441193,963.104797 480.402588,974.226440
		C480.077515,975.121643 480.136749,976.156372 479.588745,977.741333
		C479.102692,981.183716 479.076416,984.012024 478.981873,986.838135
		C478.764252,993.344421 474.740021,997.316101 468.404755,997.316833
		C459.404816,997.317871 450.404846,997.326965 441.404907,997.313721
		C435.155579,997.304504 434.774811,996.820007 434.653381,990.371460
		C434.588226,986.910339 434.221436,983.454834 433.990753,979.996826
	M556.499878,515.992676
	"/>

		<path fill="#FFFFFF" opacity="1.000000" stroke="none"
			d="
	M807.798889,439.295715 
		C805.984558,437.309082 804.415955,435.581909 802.720764,433.715271 
		C792.826843,442.947357 783.349365,451.952484 773.697876,460.767090 
		C741.052246,490.582275 706.958740,518.446838 668.827820,541.196228 
		C635.412903,561.132080 599.999207,576.456360 563.360657,589.146667 
		C554.472473,592.225281 545.260986,594.364502 536.225159,597.027161 
		C533.853943,597.725830 532.717834,598.659790 532.362488,602.015930 
		C529.848389,625.758667 515.114197,645.330566 489.953552,650.050781 
		C467.675842,654.230164 449.351776,647.011963 436.122864,628.563171 
		C427.655701,616.755005 424.929260,603.300903 427.800293,588.881714 
		C428.393829,585.900757 427.417145,584.207642 424.271881,582.917603 
		C405.980896,575.415344 387.106079,568.919373 369.792358,559.560303 
		C349.477081,548.578796 330.347321,535.329224 311.123840,522.436401 
		C302.423645,516.601318 294.738556,509.252716 286.294098,502.346344 
		C272.273926,514.042725 255.749527,518.913879 237.490875,516.928101 
		C221.164658,515.152649 207.680328,507.562500 196.648483,495.309814 
		C177.764404,474.335907 176.644516,438.543427 194.420319,415.058136 
		C205.543228,400.362579 221.035019,393.181335 238.727692,391.143280 
		C256.528015,389.092834 272.547668,394.689331 286.660034,406.138611 
		C289.882080,400.123657 293.033936,394.475403 295.969727,388.717041 
		C306.927399,367.224365 319.395813,346.708588 335.138855,328.338013 
		C342.530609,319.712555 350.063873,311.200867 357.767303,302.853851 
		C360.763153,299.607666 364.192169,296.648895 367.827789,294.145874 
		C370.171021,292.532593 370.154297,291.224640 369.395264,288.882294 
		C364.386505,273.425171 364.275635,258.139374 370.868134,242.969894 
		C379.364014,223.420746 394.120483,211.254807 414.745544,207.123245 
		C437.581146,202.548859 457.583801,208.922287 473.542511,226.157043 
		C477.694244,230.640686 480.725189,236.162125 484.204315,241.112122 
		C497.598724,237.955856 511.165924,234.451569 524.875305,231.640289 
		C533.491882,229.873337 542.289062,228.732132 551.062805,228.039886 
		C568.195679,226.688126 585.363220,225.737259 602.528503,224.855927 
		C608.565491,224.545975 614.639343,224.869385 620.693481,225.018433 
		C623.128601,225.078400 624.222290,224.487793 625.144653,221.776550 
		C635.361694,191.741348 670.655396,182.191086 694.520508,198.855347 
		C707.490723,207.912048 714.540649,220.444321 713.951355,236.401810 
		C713.721863,242.616959 715.352173,245.790970 721.390686,248.026505 
		C731.681946,251.836426 742.044495,255.908386 751.520874,261.358887 
		C766.042725,269.711243 780.065735,278.997040 793.859375,288.525238 
		C802.602173,294.564453 810.544312,301.762726 818.701355,308.325165 
		C825.247131,305.622833 831.878845,302.176971 838.916321,300.118683 
		C856.709290,294.914734 874.520996,295.649689 891.832092,302.362335 
		C910.470093,309.589447 924.567810,322.365295 933.879761,339.913666 
		C939.863525,351.190125 942.774719,363.471771 943.176331,376.451569 
		C943.927917,400.742950 935.078796,420.990936 917.855103,437.497955 
		C903.892395,450.879578 886.824829,458.028473 867.397766,459.122040 
		C845.610352,460.348480 826.120056,454.455109 809.353943,440.217651 
		C808.991455,439.909821 808.484314,439.772186 807.798889,439.295715 
	M310.975433,424.455139 
		C322.348083,418.950928 333.734619,413.475159 345.090698,407.936920 
		C373.055481,394.298798 400.971649,380.560089 429.003021,367.060425 
		C432.850555,365.207489 434.234680,362.952484 434.063202,358.732697 
		C433.731995,350.583160 433.724731,342.400543 434.064392,334.251709 
		C434.245148,329.914734 432.720917,328.885620 428.634430,329.023987 
		C409.451477,329.673676 393.600250,322.260956 380.804993,308.227570 
		C377.755707,304.883209 375.446808,300.863739 372.794434,297.150726 
		C346.885193,316.941132 317.538391,354.509247 290.540009,408.938965 
		C294.982330,415.454956 299.281586,421.761108 303.589020,428.079224 
		C305.984009,426.961700 308.174927,425.939392 310.975433,424.455139 
	M782.342102,395.033356 
		C781.708862,392.359924 781.075684,389.686493 780.389832,386.790924 
		C778.555908,387.755096 777.731018,388.301239 776.829285,388.646515 
		C751.585876,398.311401 725.377258,403.139038 698.422119,403.971130 
		C694.159912,404.102722 691.684875,405.420502 690.657654,409.735901 
		C689.761353,413.500793 688.295471,417.134369 687.015259,420.802948 
		C677.219238,448.876648 662.506592,473.590454 638.565796,492.076538 
		C633.515076,495.976501 628.787537,500.294952 623.911072,504.420563 
		C624.050476,504.915192 624.189819,505.409821 624.329163,505.904449 
		C626.862305,505.595978 629.413330,505.392456 631.925720,504.962860 
		C656.864319,500.698395 681.363647,494.682861 703.866882,482.829895 
		C728.890320,469.649414 753.522278,455.718475 778.190735,441.875702 
		C785.082825,438.008179 791.502136,433.298126 798.238403,428.909332 
		C790.670349,418.446289 784.775696,407.991669 782.342102,395.033356 
	M350.656708,542.904846 
		C362.325287,549.010498 373.780365,555.578003 385.719238,561.099121 
		C399.122925,567.297546 412.993256,572.481506 426.586975,578.279297 
		C429.013947,579.314392 429.979279,578.931763 431.111694,576.597107 
		C436.278961,565.944092 443.970856,557.621887 454.325897,551.708069 
		C455.412628,551.087463 456.205414,548.564941 455.928314,547.163330 
		C453.748749,536.138123 451.342163,525.155762 448.849213,514.195801 
		C448.157104,511.152924 446.579956,509.242493 442.784760,508.932037 
		C429.848999,507.873810 416.916168,506.600769 404.053284,504.878693 
		C389.305847,502.904327 374.627594,500.405151 359.931519,498.057434 
		C343.632111,495.453491 327.336548,492.822235 311.059418,490.083313 
		C303.825012,488.865997 296.528107,486.958160 292.608856,495.987915 
		C292.242310,496.832367 291.468842,497.500183 290.344025,498.943054 
		C300.202026,506.773315 309.612030,514.755737 319.557129,522.003906 
		C329.444122,529.209778 339.868988,535.677490 350.656708,542.904846 
	M689.051331,297.360413 
		C690.038635,304.298737 691.642578,311.222778 691.803284,318.180206 
		C691.916443,323.076202 694.033630,324.835999 698.063904,325.950500 
		C725.767639,333.611664 753.493347,341.198547 781.108398,349.168274 
		C784.673950,350.197327 785.851013,349.325500 787.049866,346.491058 
		C788.179016,343.821350 789.152222,340.955627 790.869385,338.687317 
		C798.028687,329.229889 805.442505,319.965179 812.830139,310.544281 
		C784.647827,284.128448 750.201843,265.343384 712.763855,250.018387 
		C707.778320,263.256409 698.922791,273.291473 686.247253,279.014160 
		C687.247070,285.277771 688.139648,290.869934 689.051331,297.360413 
	M604.263000,567.886658 
		C613.458191,563.755920 622.892639,560.079346 631.793701,555.390198 
		C645.913818,547.951721 660.094116,540.482300 673.485107,531.842102 
		C689.809753,521.309143 705.529419,509.801605 721.151855,498.231049 
		C730.778870,491.100952 739.855713,483.205994 748.956543,475.394592 
		C756.456055,468.957703 763.627625,462.138702 770.944519,455.489044 
		C770.737061,455.008057 770.529602,454.527069 770.322205,454.046112 
		C768.532898,454.714325 766.641663,455.191010 764.969788,456.080048 
		C745.291931,466.543671 725.795837,477.360107 705.955872,487.504761 
		C680.903687,500.314575 653.786133,506.458954 626.250977,511.135986 
		C619.469788,512.287781 612.802307,513.109558 606.607239,517.560547 
		C586.730530,531.841370 566.327026,545.396606 545.968201,558.994385 
		C539.273193,563.466003 532.095093,567.213989 525.809387,570.899231 
		C527.724854,576.879089 529.574707,582.254028 531.144531,587.709534 
		C532.039001,590.818054 532.848145,592.158630 536.832092,591.141968 
		C559.722046,585.301392 581.820312,577.285156 604.263000,567.886658 
	M597.555847,229.062088 
		C593.791016,229.373932 590.024719,229.668594 586.261658,230.000656 
		C571.215759,231.328369 556.040466,231.848099 541.159607,234.216492 
		C523.735657,236.989639 506.549927,241.295471 489.328430,245.232101 
		C488.081451,245.517136 486.263489,248.527115 486.605591,249.732468 
		C490.004730,261.709137 490.189819,273.540100 486.433136,285.468353 
		C486.159119,286.338470 487.592896,288.704132 488.456390,288.829163 
		C499.127167,290.373932 509.850433,291.550049 520.538147,292.985229 
		C531.528870,294.461121 542.469666,296.351501 553.487244,297.558777 
		C556.998230,297.943451 561.237732,297.717590 564.198914,296.071381 
		C579.895691,287.344971 595.319092,278.123718 610.777588,268.973236 
		C616.609680,265.520966 622.281921,261.798584 626.403076,259.216705 
		C625.108276,249.972061 623.866577,242.203354 623.012634,234.392258 
		C622.728516,231.793518 622.748230,229.975296 619.328064,229.933929 
		C612.395081,229.850098 605.466797,229.374680 597.555847,229.062088 
	M352.384369,409.973602 
		C337.783997,417.016602 323.162628,424.016815 308.616913,431.170898 
		C307.213898,431.860931 305.163330,433.666138 305.309265,434.681152 
		C306.296082,441.546906 307.793518,448.339264 309.160614,455.333557 
		C318.696747,454.536438 328.454498,453.824982 338.189148,452.876587 
		C347.159790,452.002625 356.139435,451.105164 365.054077,449.800812 
		C376.983521,448.055328 388.918365,446.225647 400.731232,443.839600 
		C411.683563,441.627380 422.507690,438.745178 433.307709,435.856598 
		C434.733093,435.475403 436.727264,433.277344 436.685516,431.977661 
		C436.347809,421.461487 435.508362,410.962830 434.978271,400.450958 
		C434.536865,391.698334 434.332947,382.933868 433.949188,374.177917 
		C433.903137,373.127136 433.389191,372.096832 433.060181,370.945282 
		C406.117920,383.941467 379.572815,396.746094 352.384369,409.973602 
	M305.990326,472.177246 
		C304.924896,474.734680 303.989594,477.356995 302.756744,479.831024 
		C301.565247,482.222168 301.897400,483.510864 304.708130,483.990387 
		C316.130432,485.939117 327.529938,488.022552 338.958282,489.934204 
		C349.275177,491.659912 359.603638,493.329926 369.954681,494.834229 
		C380.334290,496.342621 390.751068,497.593842 401.146027,498.998383 
		C410.390778,500.247528 419.611542,501.691956 428.874512,502.781342 
		C434.633667,503.458588 440.451660,503.635437 446.097595,504.026825 
		C443.280731,483.916077 440.653687,464.724548 437.822205,445.563202 
		C437.510040,443.450714 437.309845,440.282837 433.391663,441.255981 
		C424.565765,443.448029 415.792786,445.982422 406.854462,447.565247 
		C389.322754,450.669922 371.727905,453.474457 354.090790,455.907471 
		C343.023315,457.434235 331.829773,458.042755 320.695953,459.096283 
		C316.933441,459.452301 312.098236,458.642059 309.722076,460.670776 
		C307.255005,462.777222 307.171570,467.675507 305.990326,472.177246 
	M666.499756,399.974335 
		C672.326599,399.962097 678.174622,400.221954 683.967407,399.771698 
		C685.703247,399.636780 688.509644,397.709778 688.731445,396.279083 
		C689.860291,388.998871 690.947510,381.603027 690.844360,374.264587 
		C690.652039,360.575012 689.618896,346.897522 688.955811,333.214050 
		C688.777527,329.534607 686.723572,327.775360 683.262085,327.000854 
		C673.153564,324.739136 662.959900,322.732300 653.040405,319.808533 
		C648.531128,318.479431 646.893250,320.126587 644.891907,323.407288 
		C642.102539,327.979614 639.214783,332.573181 635.762695,336.643372 
		C628.207947,345.550751 620.271362,354.134338 612.393677,362.958923 
		C621.375977,372.841248 628.289429,384.260620 632.241638,397.475739 
		C632.693787,398.987671 635.508423,400.760040 637.231506,400.764008 
		C646.653687,400.785858 656.077026,400.292786 666.499756,399.974335 
	M656.500854,405.020447 
		C649.915894,405.336731 643.322510,405.553955 636.757507,406.107117 
		C636.019043,406.169342 634.924194,408.105499 634.869385,409.215820 
		C634.421631,418.284943 635.573059,427.676056 633.622070,436.383026 
		C627.370422,464.282379 611.155334,484.516724 584.252319,495.457153 
		C571.494202,500.645386 558.111328,502.475006 544.567993,500.708069 
		C533.329468,499.241821 533.406616,498.626801 531.438354,509.262360 
		C531.393311,509.505768 531.625000,509.800385 531.944031,510.642181 
		C535.651733,511.033234 539.663635,511.849396 543.666748,511.808655 
		C558.764709,511.654999 573.861206,511.274109 588.955078,510.861816 
		C593.230713,510.745056 597.524536,510.439606 601.757568,509.846161 
		C606.551880,509.173981 611.132263,508.324219 615.075439,504.634155 
		C620.428345,499.624939 626.680054,495.592072 632.323669,490.874176 
		C643.745178,481.326111 654.571228,471.144501 662.982239,458.740601 
		C673.000549,443.966156 679.416382,427.499481 684.959595,410.659271 
		C686.724854,405.296417 686.493835,404.997894 680.982605,404.990356 
		C673.155273,404.979614 665.327881,405.007172 656.500854,405.020447 
	M697.794006,399.002686 
		C712.580688,397.298981 727.535706,396.458923 742.101196,393.620178 
		C753.909546,391.318787 765.290771,386.736053 776.752808,382.845337 
		C778.439941,382.272675 780.445740,380.081635 780.727661,378.378448 
		C781.823242,371.759827 782.099304,365.009155 783.067627,358.364075 
		C783.466736,355.625336 782.706055,354.616241 780.178467,353.951141 
		C776.278137,352.924835 772.513367,351.390808 768.624390,350.312927 
		C745.272400,343.840759 721.871155,337.543152 698.564087,330.914185 
		C694.337463,329.712006 693.698364,331.115051 693.987427,334.690796 
		C694.702759,343.539124 695.928284,352.399384 695.851929,361.245392 
		C695.751099,372.921417 694.765930,384.601990 693.795471,396.250153 
		C693.537537,399.346344 694.381897,399.712769 697.794006,399.002686 
	M510.584991,349.018005 
		C511.743439,349.122253 513.071472,349.643585 514.029175,349.254150 
		C517.159424,347.981323 520.088135,346.215515 523.212769,344.926117 
		C541.120728,337.536255 559.393188,336.970673 577.901917,342.345337 
		C589.249695,345.640533 599.215942,351.555420 608.364624,359.369110 
		C620.336426,345.974854 633.578369,334.166626 642.270569,317.529358 
		C634.261169,315.933502 627.018188,314.333527 619.717285,313.067932 
		C603.847595,310.316956 587.975586,307.552032 572.043884,305.203949 
		C567.526489,304.538147 562.260254,304.004089 558.285034,305.670349 
		C550.729919,308.837158 543.953369,313.812042 536.680176,317.712769 
		C526.548462,323.146515 516.332214,328.437073 505.991913,333.459015 
		C502.641449,335.086212 502.517151,336.835815 504.217926,339.504272 
		C506.137543,342.515991 508.098175,345.501587 510.584991,349.018005 
	M928.428711,349.008545 
		C927.279114,346.702606 926.233215,344.337921 924.964172,342.099701 
		C909.094543,314.108521 876.806641,299.663788 845.575134,307.189667 
		C838.798523,308.822632 832.361877,311.866516 825.465149,314.370728 
		C829.461914,318.369659 832.511719,321.421082 835.610840,324.521820 
		C836.180603,324.308411 836.943420,324.091858 837.645142,323.749878 
		C860.123962,312.793671 888.032593,317.256744 905.461121,334.952789 
		C921.733704,351.475128 926.734802,371.398010 920.916321,393.592499 
		C914.450684,418.255707 894.041931,434.673615 868.803528,436.917786 
		C851.416077,438.463928 835.912842,433.241180 822.774597,421.444214 
		C821.927917,420.683990 819.846985,420.171875 819.055786,420.673309 
		C815.802612,422.735168 812.826172,425.233704 809.731262,427.584351 
		C830.925659,452.033997 868.353088,458.679688 897.559631,441.462128 
		C932.021851,421.146271 943.081604,382.676025 928.428711,349.008545 
	M523.362732,495.235107 
		C513.340149,491.926056 504.857666,485.942993 497.446930,478.764862 
		C484.901001,466.612671 476.803253,451.803253 473.961395,434.402283 
		C473.374054,430.806152 472.173553,429.674774 468.415558,430.959747 
		C460.753479,433.579651 452.898712,435.632965 445.211823,438.186249 
		C443.989929,438.592133 442.481201,440.338623 442.404266,441.548004 
		C442.220123,444.442963 442.658447,447.410889 443.112305,450.307556 
		C445.716461,466.928223 448.367340,483.541809 451.085938,500.144073 
		C451.514374,502.760620 452.145020,505.024994 455.896973,505.152039 
		C461.943634,505.356812 467.956116,506.422424 473.998383,506.948486 
		C479.752808,507.449493 485.534454,507.634918 491.290558,508.120148 
		C501.607819,508.989899 511.905853,510.130371 522.236267,510.754028 
		C523.731140,510.844269 525.856873,509.005066 526.790833,507.500244 
		C527.749146,505.956085 527.483093,503.695984 527.966919,501.803955 
		C528.871277,498.267456 527.842346,496.118988 523.362732,495.235107 
	M484.998291,376.638245 
		C491.995087,368.528656 498.991882,360.419037 506.237335,352.021240 
		C504.516998,349.205719 502.215363,345.309845 499.778168,341.500702 
		C498.419678,339.377533 496.966614,338.430206 494.088104,339.908997 
		C477.061462,348.656311 459.935455,357.213379 442.753296,365.651947 
		C439.735016,367.134308 438.850586,369.059906 438.961945,372.230347 
		C439.427368,385.481750 439.653015,398.741455 440.077271,411.994537 
		C440.267212,417.928345 440.725006,423.853455 441.047516,429.783234 
		C441.199921,432.585480 442.309265,433.922943 445.344208,432.993347 
		C453.236908,430.575806 461.152222,428.231445 469.071289,425.901245 
		C472.038300,425.028229 473.017975,423.256592 472.953247,420.086029 
		C472.638855,404.689514 476.676178,390.433899 484.998291,376.638245 
	M482.092194,430.518738 
		C484.543701,444.902893 490.225098,457.843079 500.230499,468.521973 
		C516.985474,486.404785 537.513306,494.718781 562.189819,492.012665 
		C577.894897,490.290375 591.627686,484.081146 602.984253,473.284454 
		C620.283142,456.838318 628.558167,436.732819 626.029175,412.590576 
		C625.253174,405.182343 625.484131,405.348053 617.584473,406.849182 
		C614.794739,407.379303 614.554016,407.894836 614.735046,410.836456 
		C615.138672,417.392334 615.127380,424.135590 614.042603,430.593842 
		C608.751770,462.094879 579.611877,483.168945 547.261169,478.792450 
		C520.875610,475.222931 501.263794,455.981537 496.822021,430.568909 
		C495.278656,421.738831 495.248383,421.873962 486.416168,424.811127 
		C483.791534,425.683960 481.737396,426.451538 482.092194,430.518738 
	M631.762024,310.894226 
		C634.952026,311.488678 638.123352,312.317047 641.340332,312.573761 
		C642.832275,312.692841 645.315857,312.146088 645.812988,311.141022 
		C650.492188,301.680542 654.839233,292.055756 659.619263,281.751678 
		C647.003723,278.661530 637.911011,271.718719 630.527039,262.952484 
		C610.264160,275.043579 590.481079,286.848389 570.697937,298.653198 
		C570.762573,299.123016 570.827209,299.592834 570.891846,300.062653 
		C590.935242,303.519775 610.978638,306.976868 631.762024,310.894226 
	M459.107727,538.292358 
		C459.747162,540.471436 460.699829,542.615112 460.950439,544.838074 
		C461.299133,547.931152 462.893097,548.341492 465.402679,547.655518 
		C480.638184,543.490906 494.924408,546.013672 508.893311,554.148682 
		C509.358337,553.488220 509.778656,552.745422 510.334351,552.123657 
		C518.795898,542.655579 522.379028,531.038452 524.730469,518.944824 
		C524.880981,518.170654 523.008362,516.220215 521.950134,516.117126 
		C512.936035,515.238831 503.887054,514.727783 494.860016,513.969727 
		C489.304749,513.503235 483.779572,512.671448 478.222992,512.228271 
		C470.277557,511.594666 462.315674,511.166626 453.219299,510.576752 
		C455.269043,520.059021 457.149231,528.756897 459.107727,538.292358 
	M392.871521,252.522079 
		C405.793854,223.618149 440.905029,222.096603 461.510010,249.753571 
		C465.992065,247.952042 470.524994,246.130081 475.110016,244.287170 
		C465.588013,225.380630 446.796448,213.478241 426.150452,214.218887 
		C404.806030,214.984573 388.998230,225.956253 379.494507,244.618790 
		C372.856689,257.653564 371.723694,271.824646 378.005829,285.899536 
		C381.821106,283.337524 385.272949,281.186188 388.509125,278.748474 
		C389.527863,277.981110 390.750092,276.287842 390.548340,275.286469 
		C389.016083,267.682495 389.877228,260.347168 392.871521,252.522079 
	M202.014130,487.468567 
		C224.240067,515.515869 261.517426,513.447693 279.268066,495.763000 
		C277.762543,493.820099 276.257538,491.877838 274.752533,489.935608 
		C274.753845,490.524689 274.755157,491.113800 274.756470,491.702911 
		C267.638763,494.262604 260.701721,497.698883 253.367981,499.210388 
		C234.639832,503.070312 218.400833,495.613373 208.285751,481.389923 
		C193.999008,461.300446 197.956635,434.417450 216.238327,419.001465 
		C232.054733,405.664398 259.081940,405.312927 274.702087,419.188660 
		C277.578796,421.744080 279.111237,421.514984 280.421173,418.019043 
		C280.859161,416.850128 281.501068,415.757599 282.216309,414.287842 
		C269.685883,403.139618 255.584274,397.908112 238.809845,399.916779 
		C222.304001,401.893311 209.480301,409.832703 200.088181,423.218842 
		C186.337189,442.817505 186.809021,467.225067 202.014130,487.468567 
	M538.628296,557.061401 
		C559.724487,544.962524 579.551392,530.999695 598.924255,515.843933 
		C596.388306,515.111328 594.008728,514.999329 591.643921,515.138428 
		C588.365112,515.331299 585.099121,515.950073 581.824707,515.973267 
		C566.328369,516.082886 550.830872,516.094543 535.334167,516.035095 
		C532.252625,516.023254 530.127686,515.964111 529.759460,520.236206 
		C529.375000,524.697021 527.995239,529.243896 526.185364,533.373962 
		C522.518433,541.741943 518.267212,549.853821 513.998474,558.600769 
		C515.288269,559.819641 517.467834,561.455200 519.095520,563.524780 
		C521.132507,566.114807 523.056213,566.384521 525.779602,564.664429 
		C529.774597,562.141296 533.928650,559.869995 538.628296,557.061401 
	M600.526001,365.946960 
		C600.161621,365.025848 600.068542,363.736145 599.392395,363.238586 
		C588.101807,354.929108 575.849426,349.490295 561.603394,347.768524 
		C547.004700,346.004150 533.413574,348.718140 520.933838,355.296387 
		C500.214661,366.217834 487.147858,383.654785 482.299072,406.766846 
		C481.341888,411.329346 481.537354,416.133667 481.135681,421.742096 
		C484.894501,420.132660 487.041443,418.666962 489.351959,418.332825 
		C494.915710,417.528229 496.265778,413.735535 496.911499,409.095917 
		C498.457794,397.985199 503.154968,388.221985 510.555145,379.955719 
		C530.799683,357.341858 563.285828,353.231079 588.761169,369.991730 
		C591.680847,371.912628 593.863037,372.286377 596.376221,369.870117 
		C597.572327,368.720184 598.791809,367.594543 600.526001,365.946960 
	M446.598480,358.029449 
		C462.575775,350.059753 478.553101,342.090088 495.622070,333.575897 
		C488.044067,324.407288 481.430878,316.405945 474.517609,308.041626 
		C464.984589,318.047272 454.424835,324.358002 442.055237,327.747742 
		C440.716705,328.114532 439.194275,330.729614 439.134827,332.352264 
		C438.836884,340.484924 438.948944,348.634979 439.043213,356.777466 
		C439.059814,358.213165 439.676178,359.641907 440.092834,361.399963 
		C442.509979,360.195343 444.237823,359.334259 446.598480,358.029449 
	M489.511200,317.954041 
		C492.085846,321.502319 494.601288,325.095795 497.260529,328.579498 
		C498.558929,330.280457 499.665527,331.716339 502.539215,330.142700 
		C514.594788,323.541016 526.884094,317.367950 539.027710,310.924652 
		C543.148926,308.738007 547.093201,306.217865 551.608032,303.561981 
		C549.942993,302.466797 548.949463,301.303986 547.817078,301.147705 
		C537.139160,299.674103 526.435852,298.386292 515.743469,297.015900 
		C508.622467,296.103210 501.448395,295.456482 494.404572,294.126465 
		C485.307251,292.408661 485.174133,292.534058 479.568634,300.708527 
		C477.589844,303.594177 478.283386,304.789337 480.237091,306.868164 
		C483.365692,310.197113 486.144287,313.854980 489.511200,317.954041 
	M680.168640,321.020782 
		C687.024841,323.507599 687.574646,323.366394 686.889893,316.613922 
		C685.839050,306.252197 684.210449,295.947998 682.750122,285.630310 
		C682.573669,284.383362 682.142212,283.051361 681.438599,282.026825 
		C680.180115,280.194519 663.958801,281.965118 663.092407,283.972321 
		C659.204163,292.980164 655.390991,302.020447 651.485840,311.020874 
		C650.348145,313.642975 651.411499,314.480652 653.902832,315.031250 
		C662.408203,316.911102 670.870483,318.985992 680.168640,321.020782 
	M677.822510,211.247101 
		C688.755920,216.109436 694.788452,224.271439 695.207703,236.464508 
		C695.249573,237.680847 696.849182,239.279266 698.119263,239.918396 
		C700.165833,240.948273 702.540100,241.326889 704.722595,241.965012 
		C709.036621,229.480270 698.875793,206.514969 678.314270,200.561615 
		C659.548279,195.128113 637.628235,207.862045 633.616516,224.991394 
		C641.341797,227.991241 641.771729,227.809296 646.304626,221.287491 
		C647.471558,219.608597 649.026428,218.166794 650.545044,216.770493 
		C658.235229,209.699615 667.276184,208.685638 677.822510,211.247101 
	M493.167084,633.006653 
		C470.884491,640.520935 447.825378,628.135742 443.570831,605.140137 
		C442.797882,600.962463 443.541870,596.506958 442.798462,592.319519 
		C442.509827,590.693787 440.040894,589.455078 438.565155,588.040100 
		C437.716461,589.205566 436.451935,590.254700 436.087830,591.555847 
		C434.009644,598.982483 435.528595,606.180298 438.035217,613.209045 
		C445.616821,634.468506 468.519897,647.227783 489.507660,641.447510 
		C508.213470,636.295715 520.195618,624.006104 522.956238,604.087952 
		C523.177673,602.490173 522.439331,600.759338 522.147034,599.090393 
		C520.438965,600.441162 517.544983,601.548096 517.209167,603.181030 
		C514.362854,617.022278 507.000244,627.066406 493.167084,633.006653 
	M394.408264,285.996826 
		C393.308044,283.187134 392.019470,281.779205 389.034698,284.368835 
		C386.236237,286.796814 383.030151,288.754944 379.149170,291.530853 
		C383.450989,296.694153 387.050110,301.613281 391.258728,305.939423 
		C399.733246,314.650604 410.475830,318.679993 422.327362,320.725433 
		C432.143799,322.419647 434.003387,321.372894 434.004303,311.633301 
		C434.004913,305.077637 434.004578,305.122894 427.627991,305.070953 
		C413.312347,304.954285 402.274902,298.990814 394.408264,285.996826 
	M804.839417,367.208038 
		C804.969910,365.321320 805.100403,363.434631 805.242371,361.382263 
		C802.024536,360.533569 798.940125,360.066864 796.149048,358.899017 
		C792.377136,357.320770 791.646667,359.358032 791.037109,362.282562 
		C789.179565,371.195557 788.440247,380.109192 790.057739,389.167480 
		C792.303101,401.742188 796.997437,413.146973 805.196594,422.886688 
		C808.579956,419.619019 811.804077,416.505188 815.269958,413.157867 
		C805.680664,399.537201 802.242493,384.423004 804.839417,367.208038 
	M806.505676,331.081268 
		C802.155518,337.768280 797.805359,344.455292 792.736267,352.247498 
		C798.415039,354.107605 802.572815,355.469513 807.146545,356.967651 
		C811.964905,344.511688 819.956543,334.815674 829.953369,327.492889 
		C826.617065,324.142853 823.713623,321.227478 820.405823,317.906036 
		C816.080811,322.001709 811.551086,326.291229 806.505676,331.081268 
	M477.966827,284.285645 
		C481.860107,272.464447 482.248779,260.782532 477.013184,249.139389 
		C473.253876,250.461273 469.782593,251.316101 466.712006,252.932220 
		C465.386810,253.629730 463.913971,256.091248 464.173706,257.427734 
		C466.019623,266.925903 465.491791,276.095001 460.926300,285.609558 
		C464.817169,286.121368 468.126007,286.623962 471.451019,286.971222 
		C473.816559,287.218262 476.691162,288.913788 477.966827,284.285645 
	M636.960693,255.551971 
		C641.748474,264.122925 649.112976,269.207703 658.363953,272.206635 
		C663.202209,273.775116 662.626709,269.914703 663.466064,267.827789 
		C664.340027,265.654877 665.168396,263.112122 661.383545,261.976440 
		C658.189636,261.018005 654.916626,259.656158 652.265259,257.684143 
		C644.840576,252.161926 641.476379,244.436661 642.000366,235.245209 
		C642.255005,230.779404 639.646912,230.934433 636.628540,230.983414 
		C633.783081,231.029602 630.810181,230.364288 631.024597,234.991867 
		C631.353760,242.095184 632.463440,248.904434 636.960693,255.551971 
	M464.958832,305.415558 
		C465.692413,304.200623 466.426025,302.985687 467.265503,301.595367 
		C466.223541,300.943542 465.405609,300.456085 464.612061,299.931732 
		C455.523926,293.926575 455.935822,294.720276 446.750458,299.466888 
		C439.612885,303.155212 437.974213,307.994904 439.007385,314.967255 
		C439.031403,315.129364 439.012146,315.298218 439.009613,315.463898 
		C438.961212,318.608948 440.206787,319.977936 443.235046,318.241486 
		C450.319122,314.179352 457.343475,310.013031 464.958832,305.415558 
	M617.913086,385.667725 
		C613.872925,379.867889 609.832825,374.068054 605.500000,367.848083 
		C602.034241,371.404358 599.605713,373.896271 598.277283,375.259369 
		C602.866821,383.428711 607.151733,391.178741 611.593384,398.837799 
		C612.312561,400.078003 613.737122,401.781830 614.820190,401.770691 
		C617.776978,401.740356 620.725586,400.907898 623.764709,400.377899 
		C622.929260,397.627502 622.448853,395.414215 621.583740,393.363220 
		C620.575867,390.973907 619.231384,388.726562 617.913086,385.667725 
	M692.100342,248.671844 
		C690.132629,251.247864 688.375793,254.024719 686.153564,256.358398 
		C681.433777,261.314819 681.170166,263.541107 685.259705,269.644928 
		C694.395752,264.641327 700.862122,257.459412 703.973694,247.399094 
		C695.799316,243.561203 695.057861,243.618790 692.100342,248.671844 
	M502.337067,562.046875 
		C502.416046,561.789246 502.618805,561.341492 502.556366,561.300842 
		C491.462738,554.076477 479.514801,552.516052 466.924042,556.312561 
		C466.018250,556.585754 464.918610,559.262573 465.339478,560.117981 
		C465.866180,561.188538 467.868317,562.053955 469.243256,562.068115 
		C471.476532,562.091125 473.701904,561.208435 475.958344,560.958069 
		C484.310242,560.031494 492.162689,561.754333 499.449371,566.145691 
		C500.456451,564.726624 501.198700,563.680786 502.337067,562.046875 
	M445.485748,583.789246 
		C448.304504,576.692078 453.165985,571.322510 459.184052,566.719971 
		C460.358429,565.821838 460.142029,563.105103 460.567078,561.227173 
		C459.064240,561.305420 457.282501,560.884155 456.103455,561.542358 
		C447.956207,566.090576 442.698822,573.163513 438.589630,582.309998 
		C440.992035,583.060913 442.986755,583.684326 445.485748,583.789246 
	M296.446350,471.896484 
		C297.318756,468.587463 298.191162,465.278473 299.438538,460.547272 
		C295.376953,461.966919 291.399994,462.147125 290.510925,463.896606 
		C287.936920,468.961639 286.604126,474.657440 284.675934,480.429688 
		C286.871033,480.860229 289.172577,481.311646 292.091888,481.884247 
		C293.396851,478.813873 294.720764,475.698883 296.446350,471.896484 
	M299.810455,453.981598 
		C299.217957,449.778564 298.547607,445.584564 298.056763,441.369690 
		C297.639069,437.783112 296.023438,437.147217 293.064423,438.968292 
		C291.981750,439.634552 290.801941,440.142883 289.724548,440.693573 
		C290.202759,444.329437 290.588959,447.724609 291.116669,451.097656 
		C291.410309,452.974579 291.939667,454.814636 292.362793,456.671295 
		C294.755096,456.038849 297.147369,455.406403 299.810455,453.981598 
	M518.498474,578.118469 
		C515.963196,574.589661 514.030579,576.659973 512.314148,578.266724 
		C514.360229,584.148438 516.257385,589.602051 518.121033,594.959473 
		C522.917175,594.208374 523.906128,591.788818 522.332336,587.892273 
		C521.115173,584.878662 520.040894,581.807373 518.498474,578.118469 
	M290.709961,434.044312 
		C292.108032,433.314545 293.506134,432.584747 295.259766,431.669342 
		C292.314636,427.132172 289.625610,422.989502 286.807892,418.648560 
		C281.604584,424.635986 281.788818,429.665375 286.967712,434.274780 
		C287.566467,434.807739 288.983612,434.421204 290.709961,434.044312 
	M677.696838,272.726318 
		C680.140381,269.717529 679.083435,262.313171 676.055115,262.242126 
		C673.833801,262.190002 670.585266,262.917145 669.572815,264.462250 
		C668.070068,266.755310 668.140198,270.079071 667.540833,272.964111 
		C669.839172,272.976501 672.137512,272.997101 674.435852,272.996918 
		C675.262756,272.996857 676.089600,272.946777 677.696838,272.726318 
	M505.557831,570.883362 
		C506.813019,572.210754 508.068207,573.538147 509.312775,574.854309 
		C514.489319,570.347778 514.278748,569.342651 506.463043,564.506714 
		C505.950287,566.664062 505.508820,568.521484 505.557831,570.883362 
	M472.016724,296.409210 
		C472.927948,294.948761 473.839172,293.488312 474.749054,292.030029 
		C470.227661,291.501892 466.304718,291.043640 462.381775,290.585419 
		C462.255493,291.140930 462.129181,291.696472 462.002899,292.251984 
		C465.183807,293.812317 468.364685,295.372650 472.016724,296.409210 
	M285.972870,486.027405 
		C283.758362,485.830719 281.543854,485.634064 279.329376,485.437378 
		C279.207520,485.818176 279.085693,486.198975 278.963867,486.579773 
		C280.538025,488.151672 282.112152,489.723602 284.748932,492.356659 
		C285.596130,489.673828 286.147064,487.929199 285.972870,486.027405 
	z"/>
	</svg>
</div>
</template>
<script>
export default {
  name: 'SvgIconComponent',
  props: {
    fill_percentage: {
      type: Number,
      default: 100
    },
	svgId: {
      type: String,
      required: true
    },
	maskId: {
      type: String,
      required: true
    }
  },
}
</script>
<style scoped>
.svg-icon {
  display: inline-block;
}
</style>