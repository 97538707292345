<template>
  <q-menu v-model="innerMenu" auto-close anchor="top right">
    <q-list class="q-list-style">

      <q-item v-if="mindbias" @click.prevent="jumpstart('Mindbias')" clickable>
        <q-item-section>MindBias (Beta)</q-item-section>
      </q-item>
      <q-item v-if="competitiveProfiler" @click.prevent="jumpstart('Competitive Profiler')" clickable>
        <q-item-section>Competitive Profiler</q-item-section>
      </q-item>
      <q-item v-if="neuropositioning" @click.prevent="jumpstart('Connection Checker')" clickable>
        <q-item-section>Connection Checker</q-item-section>
      </q-item>
      <q-item v-if="explorer" @click.prevent="jumpstart('Inspiration Explorer')" clickable>
        <q-item-section>Inspiration Explorer</q-item-section>
      </q-item>
      <q-item v-if="pathFinder" @click.prevent="jumpstart('Story Creator')" clickable>
        <q-item-section>Story Creator</q-item-section>
      </q-item>
      <q-item v-if="contentTuner" @click.prevent="jumpstart('Content Tuner')" clickable>
        <q-item-section>Content Tuner</q-item-section>
      </q-item>
      <q-item v-if="mindTracker" @click.prevent="jumpstart('Brand Tracker')" clickable>
        <q-item-section>Brand Tracker</q-item-section>
      </q-item>
      <q-item v-if="associationWheels" @click.prevent="jumpstart('Association Wheel')" clickable>
        <q-item-section>Association Wheel</q-item-section>
      </q-item>
    </q-list>
  </q-menu>
</template>

<script>
export default {
  props: {
    asset: { required: true, type: Object },
    selectedPositioning: { default: {}, type: Object },
    modelValue: {type: Boolean, required: true},
  },
  computed: {
    innerMenu: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },

    contentTuner: function() {
      return this.$store.state.accessible_apps.includes(5);
    },
    associationWheels: function() {
      return this.$store.state.accessible_apps.includes(6);
    },
    pathFinder: function() {
      return this.$store.state.accessible_apps.includes(7);
    },
    explorer: function() {
      return this.$store.state.accessible_apps.includes(8);
    },
    neuropositioning: function() {
      return this.$store.state.accessible_apps.includes(10);
    },
    mindTracker: function() {
      return this.$store.state.accessible_apps.includes(12);
    },
    competitiveProfiler: function() {
      return this.$store.state.accessible_apps.includes(13);
    },
    mindbias: function() {
      return this.$store.state.accessible_apps.includes(14);
    },
    jumpstartObject: function() {
      return {
        asset: this.asset,
        positioning: this.selectedPositioning,
      };
    },
  },
  methods: {
    jumpstart: function(route_name) {
      this.$store.commit('SET_JUMPSTART', this.jumpstartObject);
      this.$router.push({ name: route_name });
    },
  },
};
</script>

<style scoped>
  .q-menu-offset {
    margin-left: 5%;
  }
  .q-list-style{
    min-width: 200px;
    overflow: hidden;
  }
</style>
