<template>
    <table class="license-list-table">
      <tr>
        <th>{{ $t("message.plan_name") }}</th>
        <th>{{ $t("message.plan_credits") }}</th>
        <th>{{ $t("message.plan_monthly_fee") }}</th>
      </tr>
      <tr class="hoverable" v-for="license in allLicenses"
          @click="changePlan(license)">
        <td>{{ license.name }}</td>
        <td>{{ license.credits }}</td>
        <td>{{ license.monthly_fee }}</td>
      </tr>
    </table>
  </template>
  
  <script>
  export default {
    name: "LicenseListTable",
    emits: ['changePlan'],
    props: {
      allLicenses: {required: true, type: Array},
    },
    methods: {
      changePlan: function (license){
        this.$emit('changePlan', license);
      },
    }
  }
  </script>
  
  <style scoped lang="scss">
  .license-list-table {
    margin-top: 15px;
    width: 100%;
    flex: 1;
  }
  table {
    border-radius: 5px;
    border: 1px solid #000AFF;
  }
  
  tr {
    border-bottom: 1px solid #000AFF;
  }
  
  tr:hover td {
    background: #DAD7D7FF;
    cursor: pointer;
  }
  
  th, td {
    text-align: left;
    padding: 5px;
    border-radius: 5px;
  }
  
  th {
    background-color: #ece9e9;
  }
  
  td {
    background-color: whitesmoke;
  }
  </style>