<template>
  <img v-bind:src="require(`@/assets/${background}`)" alt="" />
</template>

<script>
export default {
  props: {
    app_name: { required: true, type: String },
    background: {
      required: false,
      type: String,
      default: 'empty-association-wheel.png',
    },
  },
};
</script>
