<template>
  <page-title
    title="Competitive Profiler"
    :desc="this.$t('message.cp')"
    :instructionsVideoTitle="$t('cp.video_instructions')"
    instructionsName="cp_instructions_main"
    :instructionsVideoUrl="
      $store.getters.getInstructionalVideoUrl('cp_instructions_main')
    "
  >
  </page-title>
  <workspace-empty
    v-if="empty"
    background="icon19.png"
    app_name="Competitive Profiler"
  />
  <div class="profiler_graph">
    <cp-chart ref="chart" :style="{ display: empty ? 'none' : 'block' }">
    </cp-chart>
  </div>
  <div class="link-bx-div">
    <div class=" rgt-link-col bottom-lnk">
      <ul>
        <li>
          <a
            class="btn btn-small reset-button"
            @click="$emit('reset')"
            v-if="!empty"
            ><span><img src="../../assets/icon9.png"/></span>
            {{ $t('message.reset') }}</a
          >
        </li>
        <li>
          <a
            class="btn btn-small export-button"
            @click="exportChart"
            v-if="!empty"
            ><span><img src="../../assets/icon10.png"/></span
            >{{ $t('message.export') }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import WorkspaceEmpty from './workspaceEmpty.vue';
import CpChart from './CpChart.vue';
import PageTitle from '@/components/common/PageTitle.vue';

export default {
  components: {
    WorkspaceEmpty,
    CpChart,
    PageTitle,
  },
  emits: ['reset'],
  data: function() {
    return {
      empty: true,
    };
  },
  methods: {
    reset: function() {
      this.empty = true;
    },
    generate: async function(first_asset, second_asset) {
      const load = await this.$refs['chart'].load(first_asset, second_asset);

      if (!load) {
        return;
      } else if (load.status === 200) {
        this.empty = false;
      } else {
        return;
      }
    },
    exportChart: function() {
      this.$refs['chart'].exportChart();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-editor {
  height: 100%;
  width: 100%;
  position: relative;
}

.editor {
  padding: 0 50px 35px 50px;
  height: calc(100% - #{$pagetitle});
}

.main .main-editor .editor .title {
  color: #343a40;
  font-size: 1.3125rem;
  margin: 0 0 5px 0;
  display: block;
}

.main {
  height: 100%;
  -moz-transition: 400ms width ease;
  -o-transition: 400ms width ease;
  -webkit-transition: 400ms width ease;
  transition: 400ms width ease;

  @media (min-width: 1024px) {
    width: calc(100% - #{$sidebar-small});
  }

  @media (min-width: 1600px) {
    width: calc(100% - #{$sidebar-medium});
  }

  @media (min-width: 1920px) {
    width: calc(100% - #{$sidebar-big});
  }
}

.state {
  height: 100%;
  display: flex;
}

.main-footer {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  width: 200px;
}

.profiler_graph {
  margin-top: 2vw;
}

text {
  font-size: 11px !important;
  font-family: inherit !important;
}
</style>
