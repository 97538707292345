<template>
    <tr>
        <td>{{ user.username }}</td>
        <td>{{ user.category.name }}</td>
        <td>
            <a class=" link"
               v-if="$store.state.current_user.category.is_organisation_admin && !user.category.is_organisation_admin"
               @click="edit_click">
                <font-awesome-icon icon="user-edit" />
                <i class="fal fa-user-edit"></i>
            </a>
        </td>
    </tr>
</template>

<script>
export default {
    emits: ["editClick"],
    props: {
        user: { required: true, type: Object },
    },
    methods: {
        edit_click: function() {
            this.$emit("editClick", this.user.id);
        }
    },
}
</script>

<style scoped>
.link {
  cursor: pointer;
}


</style>

