<template>
<main>
    <div class="container-fluid full-width-pg">
        <div class="free-trial-pg">
            <MapBrand />
            <div class="rgt-sec-bar">
                <div class="free-login-box">
                    <a href="https://www.mindspeller.com/" target="_blank">
                        <div class="login-logo-imgbx">
                            <img src="../../assets/logo-with-text.png" alt="" />
                        </div>
                    </a>
                    <div class="textbx bold-text">Log in</div>
                    <div class="formbx singup-from">
                        <form class="row g-3">
                            <div class="col-12">
                                <label for="inputEmail4" class="form-label impot">E mail</label>
                                <input type="email" class="form-control" id="inputEmail4" placeholder="Enter Your Corporate Email Address" v-model="loginSet.email" @click="isEmailNotValid = null" />
                                <p class="matchingPasswordText" v-if="isEmailNotValid">
                                    Please enter a valid email
                                </p>
                            </div>

                            <div class="col-12">
                                <label for="inputPassword4" class="form-label impot">Password</label>

                                <div class="password-div">
                                    <input v-bind:type="getTypePwd()" class="form-control" id="inputPassword4" placeholder="Enter Password" v-model="loginSet.password" />
                                    <span class="eye-icon" @click="this.showPassword = !this.showPassword">
                                        <img v-if="showPassword" src="../../assets/password-eye-open.png" />
                                        <img v-else src="../../assets/password-eye.png" />
                                    </span>
                                </div>
                            </div>
                            <div class="row mb-4 forgot-pass">
                                <div class="col d-flex justify-content-center">
                                    <!-- Checkbox -->
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" v-on:input="handleUser" />
                                        <label class="form-check-label" for="form1Example3">
                                            Remember me
                                        </label>
                                    </div>
                                    <div class="col forgot-link cursor-p">
                                        <!-- Simple link -->
                                        <a @click="forgotPassword">Forgot password?</a>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-col">
                                <!-- <button class="btn btn-primary btn-round" @click="$router.push('/archetypes')">Log in</button> -->
                                <button type="button" v-bind:class="getClass()" @click="login" :disabled="!this.loginSet.email || !this.loginSet.password">
                                    Log In
                                </button>
                            </div>
                        </form>

                        <div class="google-login">
                            <div class="logindiv">
                                Don't have an account?
                                <router-link :to="{ name: 'Free Trial' }">
                                    Start Trial
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<loading-app-wide v-if="loading" />
</template>

<script>
import MapBrand from './Common/MapBrand.vue';
import {
    changeLanguage
} from '@/helpers/language_helper.js';
import {
    isLoggedIn
} from '@/helpers/login_helper.js';
import AlternativeLangLink from '@/components/common/AlternativeLangLink';
import VueCookies from 'vue-cookies'
import {
    alert_error
} from '@/helpers/alert_helper.js';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';

export default {
    name: 'Login',
    components: {
        MapBrand,
        AlternativeLangLink,
        LoadingAppWide
    },
    data() {
        return {
            loginSet: {
                username: '',
                email: '',
                password: '',
                showPassword: false,
                loginFailed: false,
            },
            remember: false,
            showPassword: false,
            isEmailNotValid: null,
            loading: false,
        };
    },
    computed: {
        passwordInputType: function () {
            return this.loginSet.showPassword ? 'text' : 'password';
        },
    },
    methods: {
        forgotPassword() {
            this.$router.push({
                name: 'Forgot Password'
            });
        },
        getTypePwd() {
            if (this.showPassword) {
                return 'text';
            } else {
                return 'password';
            }
        },
        getClass() {
            return {
                'btn3': this.loginSet.email && this.loginSet.password,
                'disabled-btn': !this.loginSet.email || !this.loginSet.password,
            };
        },
        handleUser() {
            const user = {
                email: `${this.loginSet.email}`,
                password: `${this.loginSet.password}`,
                username: `${this.loginSet.username}`,
            }
            $cookies.set('user', user);
        },
        login: async function () {
            const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!this.loginSet.email || !this.loginSet.password) {
                return;
            } else if (!this.loginSet.email.toLowerCase().match(emailRegex)) {
                return (this.isEmailNotValid = 'Please enter a valid email');
            }
            this.loading = true;
            try {
                this.isEmailNotValid = null;
                const user = {
                    username: this.loginSet.username,
                    password: this.loginSet.password,
                    email: this.loginSet.email,
                    remember: this.remember,
                };

                await this.$store.dispatch( 'loginWe', user);
                await this.$store.dispatch('load_user_data');
                changeLanguage(this.$i18n, this.$store.state.current_user.language);

                // if (this.$store.state.current_user.onboarding_step === null || this.$store.state.current_user.onboarding_step === 0) {
                //     this.$router.push({
                //         name: 'Archetypes',
                //         params: {
                //             userId: this.$store.state.current_user.id,
                //             onboarding_step: this.$store.state.current_user.onboarding_step,
                //         },
                //     });
                // } else if (this.$store.state.current_user.onboarding_step === 1) {
                //     this.$router.push({
                //         name: 'Passions',
                //         params: {
                //             onboarding_step: this.$store.state.current_user.onboarding_step,
                //         },
                //     });
                // } else if (this.$store.state.current_user.onboarding_step === 2) {
                //     this.$router.push({
                //         name: 'Brand Value',
                //         params: {
                //             onboarding_step: this.$store.state.current_user.onboarding_step,
                //         },
                //     });
                // } else {
                    this.$router.push({
                        name: 'Dashboard',
                    });
                // }
            } catch (error) {
                if (error.response.status === 500) {
                    alert_error(this.$t('message.general_server_error'));
                } else {
                    this.loginFailed = true;
                    alert_error('Wrong username or password');
                }
            }
            this.loading = false;
        },
    },
    beforeMount: function () {
        // if the user is not logged in we choose the language based on the
        // browser settings since we don't know their preferred language (once the
        // user logs in we get their preferred language from the API)
        if (!isLoggedIn()) {
            const lang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE');
            if (lang !== '') {
                changeLanguage(this.$i18n, lang);
            } else {
                const browserLang = navigator.language;
                changeLanguage(this.$i18n, browserLang);
            }
        }
    },
    mounted: function () {
        const user = $cookies.get('user');
        if (user) {
            this.loginSet.email = user.email;
            this.loginSet.password = user.password;
            this.loginSet.username = user.username;
        }
    },
};
</script>

<style scoped>
.matchingPasswordText {
    text-align: left;
    color: red;
    margin-top: 14px;
}
.login-logo-imgbx {
    width: 80%;
    margin: 0 auto 2.5vw;
}
</style>
