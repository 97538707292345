const competitive_profiler_translation = {
    en: {
        cp: {
            video_instructions: 'Competitive Profiler instructions',
        },
    },
    nl: {
        cp: {
            video_instructions: 'Competitive Profiler instructies',
        },
    },
};

export { competitive_profiler_translation };
