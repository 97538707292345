<template>
<h4>Load Concepts</h4>
   <div class="wt-box-col">
    <div class="control">
      <aw-picker
        ref="aw-picker"
        :show_positionings="true"
        :showFrameworkSelector="false"
        @assetAdded="onAssetAdded"
        @assetRemoved="onAssetRemoved"
        @onTotalAssetsCount="onTotalAssetsCount"
        >
      </aw-picker>
    </div>
    
    <div class="control" v-if="show_options">
      <wheel-options
        ref="wheel-options"
        @percentageChange="onPercentageChange"
        @wheelInformationChange="onWheelInformationChange"
        @fontSizeChange="onFontSizeChange"
        @wheelSizeChange="onWheelSizeChange"
        @topCollapseChange="onTopCollapseChange"
        @applyColorScheme="onApplyColorScheme">
      </wheel-options>
    </div>
   </div>
   <RightSideBar/>
</template>

<script>
import RightSideBar from '../common/RightSideBar.vue';
import AwPicker from './AwPicker.vue';
import WheelOptions from './WheelOptions.vue';

export default {
  components: { AwPicker, WheelOptions,RightSideBar  },
  emits: [
    'assetAdded', 'assetRemoved', 'fontSizeChange', 'wheelSizeChange',
    'applyColorScheme', 'percentageChange', 'wheelInformationChange',
    'topCollapseChange'
  ],
  data: function() {
    return {
      show_options: false,
    }
  },
  methods: {
    reset: function() {
      this.show_options = false;
      this.$refs['aw-picker'].reset();
      if (typeof(this.$refs['wheel-options']) !== 'undefined') {
        this.$refs['wheel-options'].reset();
      }
    },
    onPercentageChange: function(value) {
      this.$emit('percentageChange', value);
    },
    onWheelInformationChange: function(value) {
      this.$emit('wheelInformationChange', value);
    },
    onFontSizeChange: function(size) {
      this.$emit('fontSizeChange', size);
    },
    onWheelSizeChange: function(size) {
      this.$emit('wheelSizeChange', size);
    },
    onTopCollapseChange: function(size) {
      this.$emit('topCollapseChange', size);
    },
    onApplyColorScheme: function() {
      this.$emit('applyColorScheme', 'Turbo');
    },
    onAssetAdded: function(asset_and_positioning) {
      this.$emit('assetAdded', asset_and_positioning);
      this.show_options = true;
    },
    onAssetRemoved: function(asset_and_positioning) {
      this.$emit('assetRemoved', asset_and_positioning);
    },
    manuallyAddAssetAndPositioning: function(asset_and_positioning) {
      this.$refs['aw-picker'].onAddAsset(asset_and_positioning);
    },
    onTotalAssetsCount: function (value) {
      if(Array.from(value).length  <= 0) {
        this.show_options = false;
          if (typeof(this.$refs['wheel-options']) !== 'undefined') {
        this.$refs['wheel-options'].reset();
      }
        }
    }
  },
 
}
</script>
<style scoped>
#sidebar {
  z-index: 80;
}
</style>
