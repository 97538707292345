const explorer_translation = {
    en: {
        message: {
            inspiration_for_alternative_ias:
                "Inspiration for alternative Intended Associations that can help " + 
                "differentiate your brand subconsciously",
            at_least_3_ias: ' Please select at least 3 Intended Associations before exploring',
            avoid_exotic_concepts: 
                "Avoid using concepts with low stability score, because these are sparsely connected in the " +
                "network and may yield concepts that will be less useful.",
            explorer_instructions: 'Inspiration Explorer instructions',
        },
    },
    nl: {
        message: {
            inspiration_for_alternative_ias:
                "Inspiratie voor alternatieve Gewenste Associaties die uw merk onbewust kunnen " +
                "differentieren",
            at_least_3_ias: 'Gelieve ten minste 3 Gewenste Associaties te selecteren voor verder te gaan',
            avoid_exotic_concepts: "Vermijd exotische concepten, omdat deze spaarzaam geconnecteerd zijn",
            explorer_instructions: 'Inspiration Explorer instructies',
        },
    }
};

export { explorer_translation };
