import { createStore } from 'vuex';
import axios from 'axios';
import { i18n } from '@/translations/index.js';
import { login, logout, isLoggedIn } from '@/helpers/login_helper.js';

export default createStore({
  state: {
    logged_in: false,
    // contains a jumpstart asset and positioning object; the initiator of
    // jumpstart should push the asset and positioning object here and then
    // redirect to the corresponding app; it's up to every app that supports
    // jumpstart to check this value every time it is mounted - if it is not
    // empty an action with the asset and positioning should be performed
    jumpstart: {},
    mindcoins: 0,
    current_user: '',
    current_user_category: '',
    current_license: '',
    current_org: '',
    accessible_apps: [],
    user_categories: [],
    prices: [],
    envVars: {},
    showWelcomeMessage: localStorage.getItem('showWelcomeMessage') !== 'false',
    addedAssets: [], // Array to store added asset names or IDs
  },
  strict: true,
  mutations: {
    SET_ENV_VAR(state, { varName, varValue }) {
      state.envVars[varName] = varValue;
    },

    LOGIN_SUCCESS(state) {
      state.logged_in = true;
    },
    LOGIN_FAILURE(state) {
      state.logged_in = false;
      state.current_user = '';
    },
    LOGOUT(state) {
      state.logged_in = false;
      state.current_user = '';
    },

    SET_JUMPSTART(state, jumpstart_object) {
      state.jumpstart = jumpstart_object;
    },
    CLEAR_JUMPSTART(state) {
      state.jumpstart = {};
    },
    SET_WELCOME_MESSAGE_VISIBILITY(state, visibility) {
      state.showWelcomeMessage = visibility;
      localStorage.setItem('showWelcomeMessage', visibility);
    },
    SET_USER_CATEGORIES(state, categories) {
      state.user_categories = categories;
    },

    SET_MINDCOINS(state, new_balance) {
      state.mindcoins = new_balance;
    },

    SET_PRICES(state, prices) {
      state.prices = prices;
    },

    SET_CURRENT_USER(state, user) {
      state.current_user = user;
      // we also keep a separate 'current_user_category' state, even though the
      // current license is
      // contained in 'current_user' - the reason is that we can't access
      // current_user.category.name from other components for some reason
      // (tested by printing this in the template of settings/License.vue);
      // if we try to print this.$store.state.current_user.category everything
      // works fine (and it we can see the name as one of the properties of
      // the license object), but if we try to print
      // this.$store.state.current_user.category.name we get that
      // this.$store.state.current_user.category (without the name) is
      // undefined; same goes for current_org
      state.current_user_category = user.category;
      state.current_org = user.organisation;
    },

    SET_ACCESSIBLE_APPS(state, apps) {
      state.accessible_apps = apps;
    },

    SET_CURRENT_LICENSE(state, license) {
      state.current_license = license;
    },

    SET_PRODUCT_UPDATES(state, accepted) {
      state.current_user.consent_general_communication = accepted;
    },

    SET_POSITIONING_UPDATES(state, accepted) {
      state.current_user.notify_asset_positioning = accepted;
    },

    // Add asset to addedAssets array
    ADD_ASSET(state, assetName) {
      if (!state.addedAssets.includes(assetName)) {
        state.addedAssets.push(assetName);
      }
    },
  },
  actions: {
    loadEnvVar: ({ commit }, varName) => {
      let varValue = '';
      // first, try to load the variable from the standard vue-cli process.env
      if (typeof process.env[varName] !== 'undefined') {
        varValue = process.env[varName];
      } 
       // if the env var is not specified in process.env we try to get it from
      // <meta> in index.html (if it's not specified there either it will be
      // set to empty string)
      else if (document.querySelector(`meta[name="${varName}"]`) !== null) {
        varValue = document.querySelector(`meta[name="${varName}"]`).content;
      }
      return commit('SET_ENV_VAR', { varName, varValue });
    },
    loadAllEnvVars: ({ commit, dispatch }) => {
      const allEnvVars = [
        'VUE_APP_APP_VERSION',
        'VUE_APP_DATADOG_APPLICATION_ID',
        'VUE_APP_DATADOG_CLIENT_TOKEN',
        'VUE_APP_DATADOG_SERVICE_NAME',
        'VUE_APP_STATIC_S3',
        'VUE_APP_L_INSTRUCTIONS_MAIN',
        'VUE_APP_MT_INSTRUCTIONS_MAIN',
        'VUE_APP_CO_INSTRUCTIONS_MAIN',
        'VUE_APP_LIBRARY_TUTORIAL',
        'VUE_APP_E_INSTRUCTIONS_MAIN',
        'VUE_APP_CP_INSTRUCTIONS_MAIN',
        'VUE_APP_NP_INSTRUCTIONS_MAIN',
        'VUE_APP_PF_INSTRUCTIONS_MAIN',
        'VUE_APP_AW_INSTRUCTIONS_MAIN',
        'VUE_APP_CC1',
        'VUE_APP_CC2',
        'VUE_APP_CC3',
        'VUE_APP_CC4',
        'VUE_APP_CC5',
        'VUE_APP_RECAPTCHA_SECRET',
        'VUE_APP_RECAPTCHA_SITEKEY',
        'VUE_APP_STRIPE_PUBLISHABLE_KEY',
        'VUE_APP_DEFAULT_LANGUAGE',
      ];
      for (const envVar of allEnvVars) {
        dispatch('loadEnvVar', envVar);
      }
    },
    login: ({ commit, dispatch }, user) => {
      const payload = {
        username: user.username,
        password: user.password,
        email: user.email,
      };
      return axios.post('/api/cas/token/login', payload).then(
        (user) => {
          login(
            user.data['x-jwt-access-token'],
            user.data['x-jwt-refresh-token']
          );
          commit('LOGIN_SUCCESS', user);
          return Promise.resolve(user);
        },
        (error) => {
          commit('LOGIN_FAILURE');
          return Promise.reject(error);
        }
      );
    },
    initializeWelcomeMessage: ({ commit }) => {
      const storedValue = localStorage.getItem('showWelcomeMessage');
      const showWelcomeMessage = storedValue !== 'false';
      commit('SET_WELCOME_MESSAGE_VISIBILITY', showWelcomeMessage);
    },
    dismissWelcomeMessage: ({ commit }) => {
      commit('SET_WELCOME_MESSAGE_VISIBILITY', false);
    },
    loginWe: ({ commit, dispatch }, user) => {
      const payload = {
        email: user.email,
        password: user.password,
      };
      return axios.post('/api/cas/token/loginwe', payload).then(
        (user) => {
          login(
            user.data['x-jwt-access-token'],
            user.data['x-jwt-refresh-token']
          );
          commit('LOGIN_SUCCESS', user);
          return Promise.resolve(user);
        },
        (error) => {
          commit('LOGIN_FAILURE');
          return Promise.reject(error);
        }
      );
    },

    handleForgotPwd: ({ commit }, email) => {
      const payload = { email: email };
      return axios.post('/api/cas/users/forgot', payload).then(
        (email) => {
          console.log(email);
          return Promise.resolve(email);
        },
        (error) => {
          commit('LOGIN_FAILURE');
          return Promise.reject(error);
        }
      );
    },

    logout: ({ commit }) => {
      // logout() removes the tokens from local storage
      logout();
      commit('LOGOUT');
    },
    load_user_data: ({ state, dispatch }) => {
      // only load this data is the user is logged in
      if (state.logged_in) {
        return Promise.all([
          dispatch('get_mindcoin_balance'),
          dispatch('get_current_user'),
          dispatch('get_prices'),
          dispatch('get_user_categories'),
          dispatch('get_current_license'),
          dispatch('get_accessible_apps'),
        ]);
      }
    },
    /*
     * We initialize the page by checking if the user is logged in (we
     * consider a user to be logged in if the csrf_access_token cookie is
     * set); if the user is logged in we load the user data. If the user is not
     * logged in nothing happens here, but the router will take the user to the
     * login page.
     */
    initialize_page: ({ commit, dispatch }) => {
      if (isLoggedIn()) {
        commit('LOGIN_SUCCESS');
        return dispatch('load_user_data');
      }
    },
    jumpstart_empty: function(context) {
      return Object.keys(context.state.jumpstart).length === 0;
    },
    pop_jumpstart: function(context) {
      const jumpstart_object = context.state.jumpstart;
      context.commit('CLEAR_JUMPSTART');
      return jumpstart_object;
    },

    get_user_categories: function(context) {
      return axios.get('/api/cas/users/categories').then((response) => {
        context.commit('SET_USER_CATEGORIES', response.data);
      });
    },

    get_mindcoin_balance(context) {
      return axios.get('/api/cas/org/credits').then((response) => {
        context.commit('SET_MINDCOINS', response.data);
      });
    },

    get_prices(context) {
      return axios.get('/api/cas/prices').then((response) => {
        context.commit('SET_PRICES', response.data);
      });
    },
    get_current_user(context) {
      return axios.get('/api/cas/users/current_user').then((response) => {
        context.commit('SET_CURRENT_USER', response.data);
      });
    },

    get_current_license(context) {
      return axios.get('/api/cas/org/license').then((response) => {
        context.commit('SET_CURRENT_LICENSE', response.data);
      });
    },

    get_accessible_apps(context) {
      return axios.get('/api/cas/org/accessible_apps').then((response) => {
        context.commit('SET_ACCESSIBLE_APPS', response.data);
      });
    },

    set_product_updates: function(context, accepted) {
      context.commit('SET_PRODUCT_UPDATES', accepted);
    },

    set_positioning_updates: function(context, accepted) {
      context.commit('SET_POSITIONING_UPDATES', accepted);
    },

    addAsset({ commit }, assetName) {
      commit('ADD_ASSET', assetName);
    },
  },

  getters: {
    get_price: (state) => (type) => {
      return state.prices.find((price) => price.type === type).value;
    },
    getInstructionalVideoUrl: (state) => (instructionsName) => {
      const name = instructionsName.toUpperCase();
      const videoName = state.envVars[`VUE_APP_${name}`];
      const staticS3 = state.envVars['VUE_APP_STATIC_S3'];
      return `${staticS3}/${videoName}`;
    },
    getEnvVar: (state) => (varName) => {
      return state.envVars[varName];
    },
    showWelcomeMessage: (state) => state.showWelcomeMessage,
    addedAssets: (state) => state.addedAssets, // Getter for addedAssets
  },
});
