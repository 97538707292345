<template>
  <page-title title="Story Creator" :desc="this.$t('message.sc')"
    :instructionsVideoTitle="$t('message.path_finder_instructions')" instructionsName="pf_instructions_main"
    :instructionsVideoUrl="$store.getters.getInstructionalVideoUrl('pf_instructions_main')
      ">
  </page-title>
  <div class="explorer-text" v-if="empty">
    {{ $t('message.workspace_empty', { app_name: 'Story Creator' }) }}
  </div>
  <div class="inspir-img-box" v-if="empty">
    <div class="inspir-img-div svg-col-div" v-bind:style="{ marginLeft: empty ? '18vw' : '0vw' }">
      <workspace-empty v-if="empty" app_name="Story Creator" background="story-creator.png" />
    </div>
  </div>
  <div class="panes" v-bind:style="{
    visibility: empty ? 'hidden' : 'visible',
    position: empty ? 'fixed' : '',
  }">
    <div class="left-checkbx-div scroll">
      <word-list class="wordlist" ref="word-list" v-show="!empty" @wordSelected="onWordListWordSelected"
        @wordUnselected="onWordListWordUnselected">
      </word-list>
    </div>
    <div class="inspir-img-div">
      <pf-chart ref="chart" @wordToggled="onChartWordToggled"> </pf-chart>
    </div>
  </div>

  <div class="link-bx-div">
    <div class=" rgt-link-col bottom-lnk">
      <ul>
        <li>
          <a class="btn btn-small reset-button" @click=resetBrandStory v-if="!empty"><span><img
                src="../../assets/icon9.png" /></span>
            {{ $t('message.reset') }}</a>
        </li>
        <li>
          <a class="btn btn-small export-button" @click="exportChart" v-if="!empty"><span><img
                src="../../assets/icon10.png" /></span>{{ $t('message.export') }}</a>
        </li>
      </ul>
    </div>
  </div>

  <div>
    <div v-show="!empty" class="brand-story-container">
      <brand-story @generateStory="generateStory" ref="brand-story" />
      <div v-if="loading" class="loading-overlay">
        <q-spinner size="36px" color="primary" />
      </div>
    </div>
  </div>
</template>

<script>
import BrandStory from './BrandStory.vue';
import PfChart from './PfChart.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import WorkspaceEmpty from '@/components/common/WorkspaceEmpty.vue';
import WordList from './WordList.vue';
import { alert_error, genericDialog } from '@/helpers/alert_helper.js';
import axios from "axios";

export default {
  components: {
    BrandStory,
    WorkspaceEmpty,
    PfChart,
    PageTitle,
    WordList,
  },
  emits: ['reset'],
  data: function () {
    return {
      empty: true,
      selected_ia: null,
      selected_asset: null,
      loading: false,
      text: null,
      image:'',
      imageURL:'',
    };
  },
  methods: {
    reset: function () {
      this.empty = true;
    },
    resetBrandStory: async function () {
      const dialog_options = {
        title: "Reset",
        text: "Did you download the generated image below before removing it?",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      };
      const dialog_result = await genericDialog(dialog_options);
      if (!dialog_result.isConfirmed) {
        return;
      }
      if (dialog_result.isConfirmed) {
        this.$refs['brand-story'].clearData();
      }
    },
    generate: async function (asset, ia) {
      const load = await this.$refs.chart.load(asset, ia);
      this.empty = false;
      // every time we load a new chart we need to pass the loaded data to the
      // word list, since the word list needs to show the words that appear in
      // the d3 chart
      await this.$refs['word-list'].clearData();// we need to clear the checkedboxes
      if (!this.empty) {
        await this.$refs['brand-story'].clearData();// we clear the data in the brand story as well
      }
      await this.$refs['word-list'].setData(this.$refs.chart.data);
      this.selected_ia = ia;
      this.selected_asset = asset;
    },

    generateStory: async function () {
      const dialog_options = {
        title: this.$t("message.generate_brand_story_title"),
        text: this.$t("message.generate_brand_story"),
        confirmButtonText: this.$t("message.yes"),
        cancelButtonText: this.$t("message.cancel"),
      };
      const dialog_result = await genericDialog(dialog_options);
      if (dialog_result.isConfirmed) {
        try {
          this.loading = true;
          // let keywords = this.$refs['word-list'].getSelectedWords();
          let keywords = this.$refs['brand-story'].getWord();
          let lowercaseKeywords = keywords.map(keyword => keyword.toLowerCase());
          const payload = { 'keywords': lowercaseKeywords, 'brand': this.selected_asset.word.word_id, 'word': this.selected_ia.word_id };
          const response = await axios.post('/api/cas/path_finder/generate_story', payload);
          this.text = response.data.story;
          this.image = response.data.image;
          this.imageURL = response.data.imageURL;
          this.$store.dispatch('get_mindcoin_balance');
        } catch (error) {
          console.log(error)
          alert_error(this.$t('message.general_server_error'));
        }
        this.loading = false;
        this.$refs['brand-story'].setText(this.text);
        this.$refs['brand-story'].setImage(this.image);
        this.$refs['brand-story'].setImageURL(this.imageURL);
      }
    },

    exportChart: function () {
      this.$refs.chart.exportChart();
    },
    onWordListWordSelected: function (word) {
      // this is for the left word list selection
      this.$refs.chart.externalSelectWord(word);
      this.$refs['brand-story'].toggleWord(word);
    },
    onWordListWordUnselected: function (word) {
      this.$refs.chart.externalUnselectWord(word);
      this.$refs['brand-story'].toggleWord(word);
    },
    onChartWordToggled: function (word) {
      // this.$refs['word-list'].toggleWord(word);
      this.$refs['brand-story'].toggleWord(word);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.main {
  display: flex;
  height: 100%;

  @media (min-width: 1024px) {
    width: calc(100vw - #{$sidebar-small});
  }

  @media (min-width: 1600px) {
    width: calc(100vw - #{$sidebar-medium});
  }

  @media (min-width: 1920px) {
    width: calc(100vw - #{$sidebar-big});
  }
}

.main-editor {
  width: 100%;
  display: flex;
  flex-flow: column;
}

.panes {
  display: flex;
  height: calc(100% - 110px);
  width: 100%;
  // width: 68vw;
  margin-top: 2vw;
}

.left-checkbx-div {
  flex-basis: 18% !important;
}

.inspir-img-div {
  display: flex !important;
  flex-direction: column !important;
  flex-basis: 82% !important;
}

.inspir-img-box {
  margin-top: 4vw;
}

.wordlist {
  padding-left: 1vw;
}

// .inspir-img-div {
//   margin-left: 18vw !important;
// }

// .link-bx-div {
//   margin-top: 15vw !important;
// }

.inspir-img-div.svg-col-div img {
  width: 327px;
}

.q-checkbox__label.q-anchor--skip {
  padding-left: 0px;
}

.loading-container {
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

.brand-story-container {
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>
