<template>
  <div class="explorer-text">
    Select concepts from the right panel to get results here
  </div>
  <div class="inspir-img-box">
    <div class="competitor-box">
      <h4>Competitor 1</h4>
      <div class="click-add-ass">
        <img v-bind:src="require(`@/assets/${background}`)" alt="">
      </div>
    </div>
    <div class="competitor-box">
      <h4>Competitor 2</h4>
      <div class="click-add-ass">
         <img v-bind:src="require(`@/assets/${background}`)" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        app_name: { required: true, type: String },
        background: { required: false, type: String, default: "icon19.png"},
    }
}
</script>
