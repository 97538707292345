<template>
    <div id="tracker-graph" class="mind-tracker-graph">
        <!-- display the images below -->
        <div id="loading-message" style="display: none;">
            <loading-app-wide />
        </div>
        <loading-app-wide v-if="loading" />
        <q-dialog v-model="imageModalVisible" ref="myDialog" @show="onShow" @hide="onHide">
            <q-card>
                <q-bar class="bg-primary text-white" :class="draggable ? 'cursor-move' : ''">
                    <q-space />
                </q-bar>
                <q-card-section>
                    <p style="text-align: center; font-size: larger; font-weight: bolder;">Add Image To Your Timeline? </p>
                </q-card-section>
                <div style="display: flex; flex-direction: column;margin: 0 auto; margin-bottom: 2vw;">
                    <div style="text-align: center;">
                        <img :src="image" style="width:280px;" id="image">
                    </div>
                    <a class="download-link" @click="exportImage" style="text-align: start;">Download</a>

                </div>
                <q-card-actions>
                    <div style="display: flex; justify-content: flex-end; margin-right: 1.3vw;">
                        <btn style="background-color:#92a0b3 !important;margin-right: 1vw;" class="button_pay"
                            @click="closeImageModal">Cancel​</btn>
                        <btn class="button_pay" @click="addToLibrary(imageURL)">Add</btn>
                    </div>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>

<script>
import axios from "axios";
import Mindtracker from './mind_tracker.js';
import {
    alert_error,
    genericDialog,
    toast_success
} from '@/helpers/alert_helper.js';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';

export default {

    components: {
        LoadingAppWide,
    },
    props: {
        draggable: { type: Boolean, default: true },
        modelDialog: { type: Boolean, default: false },
    },
    data: function () {
        return {
            swDialog: false,
            target: null,
            nodeDragg: null,
            imageModalVisible: false,
            loading: false,
            date: null,
            link_word_id: null,
            ia_id:null,
        }
    },
    methods: {
        async exportImage() {
            try {
                var a = document.createElement("a"); //Create <a>
                a.href = this.image //Image Base64 Goes here
                a.download = "Image.png"; //File name Here
                a.click(); //Downloaded file
            } catch (error) {
                console.error('Error downloading image:', error);
            }
        },

        onShow() {
            let dialogElements = document.getElementsByClassName("q-dialog");
            this.target = dialogElements[0].querySelector(".q-card");
            this.nodeDragg = this.target.querySelector(".q-bar");
            if (this.draggable) {
                this.nodeDragg.addEventListener("mousedown", this.onGrab);
            }

            this.$emit("onShow");
        },
        onHide() {
            if (this.draggable) {
                document.removeEventListener("mousemove", this.onDrag);
                document.removeEventListener("mouseup", this.onLetGo);
                this.nodeDragg.removeEventListener("mousedown", this.onGrab);
            }
            this.$emit("onHide");
        },
        onDrag(e) {
            let originalStyles = window.getComputedStyle(this.target);
            this.target.style.left =
                parseInt(originalStyles.left) + e.movementX + "px";
            this.target.style.top = parseInt(originalStyles.top) + e.movementY + "px";
        },

        onLetGo() {
            document.removeEventListener("mousemove", this.onDrag);
            document.removeEventListener("mouseup", this.onLetGo);
        },

        onGrab() {
            document.addEventListener("mousemove", this.onDrag);
            document.addEventListener("mouseup", this.onLetGo);
        },

        closeImageModal() {
            this.imageModalVisible = false;
        },

        async addToLibrary(imageURL) {
            await this.$store.dispatch('get_mindcoin_balance');
            try {
                this.loading = true;
                this.imageModalVisible = false;
                const response = await axios.post('/api/cas/assets/story_creator', { "imageURL": imageURL, "link_word_id": this.link_word_id, "date": this.date,'intended_association':this.ia_id });
                this.loading = false;
                if (response.status == 204) {
                    toast_success(`Image added to the timeline successfully`);
                    this.$store.dispatch('get_mindcoin_balance');
                }
                // pass the image list to the workspace
                const res = await axios.post('/api/cas/assets/get_link_images', { 'link_word_id': this.link_word_id ,'intended_association':this.ia_id});
                this.$emit('someEvent', res.data.image_list);
            } catch (error) {
                console.log(error)
            }
        },

        load: async function (assets, ia, campaigns) {
            this.ia_id = ia.word_id;
            const payload = {
                "sources": assets,
                "targets": [ia.word_id],
            }
            try {
                const response = await axios.post('/api/cas/mind_tracker/analyze', payload);
                const chart = new Mindtracker(
                    'tracker-graph', assets, ia, response.data, campaigns, 'loading-message',
                    (value) => { this.imageModalVisible = value; },
                    (value) => { this.image = value; },
                    (value) => { this.imageURL = value; },
                    this.$store,
                    (value) => { this.link_word_id = value; },
                    (value) => { this.date = value; },
                );
                // pass the image list to the workspace
                const res = await axios.post('/api/cas/assets/get_link_images', { 'link_word_id': assets[0].id ,'intended_association':ia.word_id});
                this.$emit('someEvent', res.data.image_list);

            } catch (error) {
                if (error.response.status === 403) {
                    alert_error(this.$t('message.no_access_to_items'));
                } else {
                    alert_error(this.$t('message.general_server_error'));
                }
            }
        },
        exportChart: function () {
            const svg = document.querySelector("#tracker-graph svg");
            this.$exportD3.saveSvgAsPng(svg, "Brand Tracker.png", {
                scale: 2
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.mind-tracker-graph {
    margin-top: 2px;
    // width: 100%;
    height: 100%;
}

.loading-spinner {
    position: absolute;
    top: 40%;
    left: 35%;
    transform: translate(-50%, -50%);
    display: none;
}

.spinner {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #0A00FF;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.cursor-move {
    cursor: move;
}

.download-link {
    text-decoration: none;
    color: grey;
    transition: color 0.3s ease;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        color: grey;
    }
}
</style>
