import Swal from 'sweetalert2';

const Alert = Swal.mixin({
    iconColor: '#000AFF',
    backdrop: false,
    buttonsStyling: false,
    customClass: {
        confirmButton: 'swal2-mdsp-confirm-btn',
        cancelButton: 'swal2-mdsp-cancel-btn',
    },
});

async function swal_alert(options) {
    if (options.text_align) {
        return Alert.fire({
            html: `<div style="text-align:${options.text_align}">
                      ${options.text}
                   </div>`,
            icon: options.icon,
            title: options.title,
        });
    } else {
        return Alert.fire({
            icon: options.icon,
            title: options.title,
            text: options.text,
        });
    }
};

async function Dialog(customOptions) {
    const initialCustomClass = {
        confirmButton: 'swal2-mdsp-confirm-btn',
        cancelButton: 'swal2-mdsp-cancel-btn',
        denyButton: 'swal2-mdsp-deny-btn',
    };
    const hasAdditionalClasses = customOptions.hasOwnProperty('additionalClasses');
    const additionalClasses =
        hasAdditionalClasses ? customOptions.additionalClasses : {};
    const finalCustomClass = Object.assign(initialCustomClass, additionalClasses);
    const initialOptions = {
      customClass: finalCustomClass,
      buttonsStyling: false,
      showCancelButton: true,
      backdrop: false,
      reverseButtons: true,
      showCloseButton: true,
    };
    const finalOptions = Object.assign(initialOptions, customOptions);
    return Swal.fire(finalOptions);
};

// the functions below are exported and can be imported and used in the vue app

export async function monitoringDialog(customOptions) {
    // expect customOptions to contain the following attributes
    const {
        title, confirmButtonText, cancelButtonText, line1, line2, price, line3
    } = customOptions;
    
    return Dialog({
        title: title,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        html: `
        <p>${line1}</p>
        <p>${line2}</p>
        <p><h3 class="swal2-mdsp-tracking-price">${price}</h3></p>
        <p>${line3}</p>
        `,
        additionalClasses: {
          popup: 'swal2-mdsp-tracking-popup',
        },
    });
};


export async function repositionDialog(customOptions) {
    // expect customOptions to contain the following attributes
    const {
        title, confirmButtonText, cancelButtonText, line1, price, line2
    } = customOptions;
    
    return Dialog({
        title: title,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        html: `
        <p>${line1}</p>
        <p><h3 class="swal2-mdsp-tracking-price">${price}</h3></p>
        <p>${line2}</p>
        `,
        additionalClasses: {
          popup: 'swal2-mdsp-tracking-popup',
        },
    });
};

export async function appLockedDialog(customOptions) {
    // expect customOptions to contain the following attributes
    const {
        title, confirmButtonText, cancelButtonText, denyButtonText, line1
    } = customOptions;

    return Dialog({
        title: title,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        denyButtonText: denyButtonText,
        showDenyButton: true,
        html: `
        <p>${line1}</p>
        `,
        additionalClasses: {
          popup: 'swal2-mdsp-tracking-popup',
        },
    });
};

export async function genericDialog(customOptions) {
    // expect customOptions to contain the following attributes
    const { title, text, confirmButtonText, cancelButtonText } = customOptions;
    
    return Dialog({
        title: title,
        text: text,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
    });
};

export function association_list(title, html) {
    Swal.fire({
        title: title,
        html: html,
        confirmButtonColor: '#1f1fff',
        backdrop: false,
    })
};

export function toast_success(title) {
    Swal.fire({
        iconColor: '#000AFF',
        icon: 'success',
        title: title,
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
};

export async function alert_success(title, text='', text_align=null) {
    return swal_alert({
        iconColor: '#000AFF',
        icon: 'success',
        title: title,
        text: text,
        text_align: text_align
    });
};

export async function alert_info(title, text='', text_align=null) {
    return swal_alert({
        iconColor: '#000AFF',
        icon: 'info',
        title: title,
        text: text,
        text_align: text_align
    });
};

export async function alert_error(title, text='', text_align=null) {
    return swal_alert({
        icon: 'error',
        title: 'Error',
        text: title,
        text_align: text_align,
        additionalClasses: {
            popup: 'alertBox',
          }
    });
};

export async function alert_reminding(title, text='', text_align=null) {
    return swal_alert({
        // icon: 'error',
        title: 'Warning',
        text: title,
        text_align: text_align,
        additionalClasses: {
            popup: 'alertBox',
          }
    });
};
