const text_statistics_translation = {
    en: {
        message: {
            score_1: 'very difficult to read, best understood by university graduates',
            score_2: 'difficult to read, best understood by college graduates',
            score_3: 'fairly difficult to read',
            score_4: 'easily understood by 13- to 15-year-old students',
            score_5: 'fairly easy to read',
            score_6: 'easy to read',
            score_7: 'very easy to read, easily understood by an average 11-year-old student',
            text_info_readability: 'readability',
            text_info_characters: 'characters',
            text_info_words: 'words',
            text_info_sentences: 'sentences',
            text_info_avg_sent_length: 'average sentence length',
            text_info_reading_time: 'reading time',
            text_info_score: 'score',
            text_info_meaning: 'meaning',
        },
    },
    nl: {
        message: {
            score_1: 'zeer moeilijk te begrijpen, begrijpbaar, best begrepen door de gemiddelde master student',
            score_2: 'moeilijk begrijpbaar, best begrepen door de gemiddelde bachelor student',
            score_3: 'relatief moeilijk te begrijpen',
            score_4: 'begrijpbaar voor de gemiddelde leerling van 13 tot 15',
            score_5: 'relatief vlot begrijpbaar',
            score_6: 'vlot begrijpbaar',
            score_7: 'zeer vlot begrijpbaar, zelfs voor leerlingen van gemiddeld 11 jaar',
            text_info_readability: 'leesbaarheid',
            text_info_characters: 'karakters',
            text_info_words: 'woorden',
            text_info_sentences: 'zinnen',
            text_info_avg_sent_length: 'gemiddelde zin lengte',
            text_info_reading_time: 'leestijd',
            text_info_score: 'score',
            text_info_meaning: 'betekenis',
        },
    },
};

export { text_statistics_translation };