<template>
  <q-dialog v-model="visible">
    <q-card class="suggestion-card">
      
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h4">{{ wordData.Word }}</div>
        <q-space />
        <q-btn icon="close" size="16px" flat round dense v-close-popup />
      </q-card-section>
      
      <q-card-section v-if="wordExists">
        <p>{{ $t('message.score') }}: {{ wordData.Score.toFixed(2) }}</p>
        <p>{{ $t('message.category') }}: {{ translatedSuggestionCategory }}</p>
      </q-card-section>
        
      <q-card-section v-if="loading">
        <q-circular-progress
          indeterminate
          size="50px"
          color="blue"
          class="q-ma-md">
        </q-circular-progress>
      </q-card-section>
        
      <q-card-section v-if="!loading">
        <q-table
          v-if="wordExists"
          :rows-per-page-options="[0]"
          :title="$t('message.associations')"
          :rows="rows"
          :columns="columns"
          :rows-per-page-label="$t('message.rows_per_page')"
          row-key="word">
        </q-table>
        
        <div v-if="!wordExists">
          <span v-if="canRequestPositioning">
            {{ $t("message.do_you_want_to_position") }}
            <br><br>
            <q-btn @click="requestWordPositioning">
              {{ $t("message.request_positioning") }}
            </q-btn>
          </span>
          <span v-if="!canRequestPositioning">
            {{ $t("message.included_next_survey") }}
          </span>
        </div>
      </q-card-section>
      
    </q-card>
  </q-dialog>
</template>

<script>
import axios from "axios";
import { toast_success, genericDialog } from '@/helpers/alert_helper.js';

export default {
  data: function() {
    return {
      visible: false,
      wordData: {},
      wordExists: false,
      loading: true,
      canRequestPositioning: false,
      columns: [
        { field: 'word', label: this.$t('message.word'), align: 'left' },
        { field: 'score', label: this.$t('message.score'), align: 'left' },
      ],
      rows: [],
    }
  },
  computed: {
    translatedSuggestionCategory: function () {
      if (typeof(this.wordData.Type) === 'undefined') { return '' }
      return this.$t("message." + this.wordData.Type);
    },
  },
  methods: {
    close: function() {
      this.visible = false;
    },
    open: async function (suggestion_obj, selected_ia) {
      this.wordData = suggestion_obj.data;
      this.wordExists = suggestion_obj.db_exists === 'true' ? true : false;
      this.loading = true;

      if (this.wordExists) {
        const payload = {
          word: this.wordData.Word, // currently clicked word
          ia: selected_ia.word_id, // array containing IA
        }
        axios.post('/api/cas/copy_opt/suggestions', payload).then((response) => {
          // clear the rows first (splice preserves vue reactivity)
          this.rows.splice(0, this.rows.length);
          // fill the rows with the received data
          for (const a of response.data.suggestions) {
            this.rows.push({ word: a[0], score: a[1].toFixed(2) });
          }
          this.loading = false;
        })
      } else {
        const payload = { "word": this.wordData.Word };
        axios.post('/api/cas/words/is_ordered', payload).then(response => {
          // the API tells us whether this word has already been ordered (but
          // not yet positioned); in case it has already been ordered we should
          // not allow the user to request positioning again 
          this.canRequestPositioning = !response.data;
          this.loading = false;
        });
      }
      this.visible = true;
    },
    requestWordPositioning: async function() {
      const price = this.$store.getters.get_price('private_word_asset');
      const dialog_options = {
        title: this.$t("message.position_dialog_question", {'word': this.wordData.Word}),
        text: this.$t("message.ia_cost", { price: price }),
        confirmButtonText: this.$t("message.map"),
        cancelButtonText: this.$t("message.cancel"),
      };
      const dialog_result = await genericDialog(dialog_options);
      if (dialog_result.isConfirmed) {
        const request_access_url = `/api/cas/assets/create_text_asset`;
        const response = await axios.post(request_access_url, {
          'word': this.wordData.Word,
        });
        this.close();
        const msg = this.$t("message.positioning_success");
        toast_success(msg);
        this.canRequestPositioning = false;
        this.$store.dispatch('get_mindcoin_balance');
      }
    },
  }
}
</script>

<style scoped>
  .suggestion-card {
    width: 350px;
  }

  button.q-btn.q-btn-item.non-selectable.no-outline.q-btn--flat.q-btn--round.q-btn--actionable.q-focusable.q-hoverable.q-btn--dense {
  position: absolute;
  right: -13px;
  top: 3px;
}

.q-card.suggestion-card {
  padding: 0 2vw 2vw 2vw;
  width: 360px;
}
.q-card__section.q-card__section--vert.row.items-center.q-pb-none {
  margin-left: 0;
  padding-top: 2.5vw;
  text-transform: capitalize;
}

.q-card__section.q-card__section--vert.row.items-center.q-pb-none .text-h4 {
  font-weight: 600;
  font-size: 1.9vw;
  padding: 0 0 0.5vw;
  border-bottom: 1px #ccc solid;
  margin-bottom: 1vw;
}

.q-card__section.q-card__section--vert {
  padding: 0;
}
</style>
