<template>
  <section class="middle-section">
    <div class="container-fluid">
      <div class="dashboard-page">
        <div class="ac-info-div">
          <div class="container">
            <div style="display: flex; gap:50px; justify-content: space-between; align-items: flex-start; margin: 1%;">
              <div v-if="!loading" style="width: 40%;">
                <h6 style="text-align:center;">Your Brand Profile</h6>
                <div>
                  <div>
                    <div class="rank-bar">
                      <div class="green-colo">✓</div>
                      Brand Archetypes <span
                        v-if="!(archetypes.first_archetype && archetypes.second_archetype && archetypes.third_archetype)"></span>
                      <div class="edit-icon-col cursor-p" title="Edit archetypes" @click="handleEdit('archetypes')">
                        <img src="../../assets/edit-icon.png" />
                      </div>
                    </div>
                    <div class="rank-border-box" v-if="(archetypes.first_archetype &&
                      archetypes.second_archetype &&
                      archetypes.third_archetype)">
                      <div class="archetype-box">
                        <span class="no-bx">02</span>
                        <img v-if="archetypes.second_archetype" v-bind:src="archetypes.second_archetype.urls.big_large
                          " />
                      </div>
                      <div class="archetype-box">
                        <span class="no-bx">01</span>
                        <img v-if="archetypes.first_archetype" v-bind:src="archetypes.first_archetype.urls.big_large
                          " />
                      </div>
                      <div class="archetype-box">
                        <span class="no-bx">03</span>
                        <img v-if="archetypes.third_archetype" v-bind:src="archetypes.third_archetype.urls.big_large
                          " />
                      </div>
                    </div>
                  </div>
                  <div class="rank-bar">
                    <div class="green-colo">✓</div>
                    Brand Values <span
                      v-if="!(passions.second_passion && passions.first_passion && passions.third_passion)"></span>
                    <div class="edit-icon-col cursor-p" title="Edit passions" @click="handleEdit('passions')">
                      <img src="../../assets/edit-icon.png" />
                    </div>
                  </div>
                  <div class="rank-border-box" v-if="((passions.second_passion||imageUrl2) &&
                    (passions.first_passion||imageUrl1) &&
                    (passions.third_passion||imageUrl3))">
                    <div class="archetype-box">
                      <span class="no-bx">02</span>
                      <img v-if="passions.second_passion" v-bind:src="passions.second_passion.urls.thumb_small" />
                      <img v-if="imageUrl2" :src="imageUrl2" class="uploaded-image">
                    </div>
                    <div class="archetype-box">
                      <span class="no-bx">01</span>
                      <img v-if="passions.first_passion" v-bind:src="passions.first_passion.urls.thumb_small" />
                      <img v-if="imageUrl1" :src="imageUrl1" class="uploaded-image">
                    </div>
                    <div class="archetype-box">
                      <span class="no-bx">03</span>
                      <img v-if="passions.third_passion" v-bind:src="passions.third_passion.urls.thumb_small" />
                      <img v-if="imageUrl3" :src="imageUrl3" class="uploaded-image">
                    </div>
                  </div>
                </div>
                <div class="rank-bar">
                  <div class="green-colo">✓</div>
                  Brand Info <span v-if="!(brandValue.length && brandValue[0].brand_description)"></span>
                  <div class="edit-icon-col cursor-p" title="Edit brand value" @click="handleEdit('brand')">
                    <img src="../../assets/edit-icon.png" />
                  </div>
                </div>
                <div class="rank-border-box blockdiv" v-if="(brandValue.length && brandValue[0].brand_description)">
                  <div class="startupbx">
                    Start Up -
                    <span>{{
                      brandValue[1] && brandValue[1].investor === 0
                      ? 'No'
                      : ''
                    }}
                      Influencial Investor</span>
                  </div>
                  <p v-if="brandValue[0].brand_description">
                    {{
                      brandValue[0] && (brandValue[0].brand_description.length > 25) ?
                      brandValue[0].brand_description.slice(0, 25) +
                      '...' : ''
                    }}
                  </p>
                </div>
              </div>
              <div v-if="!loading" style="width: 40%; display: flex; flex-direction: column; align-items: center;">
                <h6 style="text-align:center;">Your brand archetype</h6>
                <div class="chart-bx-img">
                  <dashboard-chart v-if="dataset.length" :dataSet="dataset"></dashboard-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <loading-app-wide v-if="loading" />
</template>
    
<script>
import axios from 'axios';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
import DashboardApps from '../dashboard/common/DashboardApps.vue';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import DashboardChart from '../dashboard/common/DashboardChart.vue';

export default {
  name: 'MyBrand',
  components: {
    LoadingAppWide,
    DashboardApps,
    LoadingSpinner,
    DashboardChart,
  },
  data() {
    return {
      imageUrl1: null,
      imageUrl2: null,
      imageUrl3: null,
      imageUrl: '',
      image: '',
      brandValue: [],
      archetypes: {
        first_archetype: '',
        second_archetype: '',
        third_archetype: '',
      },
      passions: {
        first_passion: '',
        second_passion: '',
        third_passion: '',
      },
      loading: false,
      graphData: {},
      archetypes: [],
      dataset: [],
    };
  },
  methods: {
    handleEdit(value) {
      //Here to judge whether the user finished a certain step
      let finish_status = {
        "archetype": this.$store.state.current_user.third_archetype,
        "passion": this.$store.state.current_user.third_passion,
        "brand_value": this.$store.state.current_user.brand_description
      }
      localStorage.setItem('finish_status', finish_status);
      localStorage.setItem('pageText', 'account');

      if (value === 'passions') {
        this.$router.push({
          name: 'Passions',
        });
      } else if (value === 'archetypes') {
        this.$router.push({
          name: 'Archetypes',
        });
      } else if (value === 'brand') {
        this.$router.push({
          name: 'Brand Value',
        });
      }
    },
  },
  mounted: async function () {
    const res = await axios.get('/api/cas/assets/s3_prefix');
    this.imageUrl = res.data.s3_bucket_url;

    document.getElementById('mindspeller').style.overflow = 'auto';
    localStorage.removeItem('pageText');
    localStorage.removeItem('finish_status');
    localStorage.removeItem('pageName');

    try {
      const result = await axios.get('/api/cas/users/get_all_archetypes');

      if (result.status === 200) {
        this.archetypes = result.data;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const result = await axios.get('/api/cas/users/current_user');
      if (result.status === 200) {
        this.user.user_name = result.data.username;
        this.user.brand_name = result.data.brand_name;
        this.user.email = result.data.email;
        this.user.phone_no = result.data.phone;
        this.user.country = result.data.country;
        this.user.address = result.data.address;
        this.user.first_name = result.data.first_name;
        this.user.last_name = result.data.last_name;
        this.user.password = result.data.password;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const archetypes = await axios.get('/api/cas/users/get_archetypes');
      if (archetypes.status === 200) {
        this.archetypes.first_archetype = archetypes.data.first_archetype;
        this.archetypes.second_archetype = archetypes.data.second_archetype;
        this.archetypes.third_archetype = archetypes.data.third_archetype;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const passions = await axios.get('/api/cas/users/get_passions');
      if (passions.status === 200) {
        if (typeof (passions.data.first_passion) === 'object') {
          this.passions.first_passion = passions.data.first_passion;
        } else {
          this.imageUrl1 = this.imageUrl + passions.data.first_passion;
        }

        if (typeof (passions.data.second_passion) === 'object') {
          this.passions.second_passion = passions.data.second_passion;
        } else {
          this.imageUrl2 = this.imageUrl + passions.data.second_passion;
        }

        if (typeof (passions.data.third_passion) === 'object') {
          this.passions.third_passion = passions.data.third_passion;
        } else {
          this.imageUrl3 = this.imageUrl + passions.data.third_passion;
        }
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const brandData = await axios.get('/api/cas/users/get_brand_description');
      if (brandData.status === 200) {
        this.brandValue.push(brandData.data);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const investData = await axios.get('/api/cas/users/get_investor');
      if (investData.status === 200) {
        this.brandValue.push(investData.data);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const graph = await axios.get('/api/cas/users/get_brand_positions');
      if (graph.status === 200) {
        this.graphData = graph.data;
      }
    } catch (error) {
      console.log(error);
    }
  },
  watch: {
    graphData: function () {
      if (this.graphData && Object.keys(this.graphData.perceived).length) {
        for (let i = 0; i < Object.keys(this.graphData.perceived).length; i++) {
          for (let j = 0; j < this.archetypes.length; j++) {
            if (
              Object.keys(this.graphData.perceived)[i].includes(
                this.archetypes[j].name
              )
            ) {
              var dataName = function (imgSrc, name) {
                return (
                  '<span class="outerChartDiv" style="margin-left: 2vw "><span class="innerChartDiv">' +
                  name.toUpperCase() +
                  '</span><img src=' +
                  imgSrc +
                  ' ' +
                  'style="" /><br></span>'
                );
              };

              if (!(this.$store.state.current_user.third_archetype)) {
                this.dataset.push([
                  0,
                  dataName(
                    this.archetypes[i].urls.iconurl_small,
                    this.archetypes[i].name
                  ),
                ]);
              } else {
                this.dataset.push([
                  Object.values(this.graphData.perceived)[i],
                  dataName(
                    this.archetypes[i].urls.iconurl_small,
                    this.archetypes[i].name
                  ),
                ]);
              }
            }
          }
        }
      }
    },
  },
};
</script>