<template>
    <div>
      <div v-for="payment_method in paymentMethodList"
           :key="payment_method"
           class="payment-method-detail">
        <div class="data" @click="togglePaymentMethod(payment_method.id)">
          <input type="radio" v-model="selectedPaymentMethod" :value="payment_method.id">
          <span>**** {{ payment_method.card.last4 }} </span>
          <span>Expires by: {{ payment_method.card.exp_month }}/{{ payment_method.card.exp_year }} </span>
        </div>
        <div class="control">
          <q-icon
            @click="deletePaymentMethod(payment_method.id)"
            class="trash-icon"
            size="20px"
            name="eva-trash-2-outline"/>
          <q-icon
            @click="updateFavoritePaymentMethod(payment_method.id)"
            class="star-icon"
            :class="{ 'favorite-icon': payment_method.id == favoritePaymentMethod }"
            size="20px"
            name="eva-star-outline"/>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { alert_success } from '@/helpers/alert_helper.js';
  
  export default {
    name: "PaymentMethods",
    emits: ['paymentMethodListEmpty', 'paymentMethodChange'],
    data: function() {
      return {
        paymentMethodList: [],
        selectedPaymentMethod: null,
        favoritePaymentMethod: null,
      }
    },
    computed: {
  
    },
    mounted: async function() {
      let default_payment_method = '';
      await axios.post('/api/cas/org/default_payment_method/get', {'org_id': this.$store.state.current_org.id}).then(
          response => default_payment_method = response.data);
      this.selectedPaymentMethod = default_payment_method;
      this.favoritePaymentMethod = default_payment_method;
      this.$emit('paymentMethodChange', default_payment_method);
  
      let payment_methods;
      await axios.post('/payment_methods/list',
          {'customer_id': this.$store.state.current_org.stripe_customer_id}).then(response =>
          payment_methods = response.data
      );
  
      this.paymentMethodList = payment_methods.data;
      if (this.paymentMethodList.length == 0) {
        this.$emit('paymentMethodListEmpty');
      }
    },
    methods: {
      togglePaymentMethod: function (payment_method_id) {
        this.selectedPaymentMethod = payment_method_id;
        this.$emit('paymentMethodChange', payment_method_id);
      },
      deletePaymentMethod: async function(payment_method_id, vue_vm=this) {
        await axios.post('/payment_methods/delete',
            {'customer_id': this.$store.state.current_org.stripe_customer_id,
                  'payment_method_id': payment_method_id,
                  'org_id': this.$store.state.current_org.id});
        this.paymentMethodList = this.paymentMethodList.filter(method => method.id != payment_method_id);
        if (this.paymentMethodList.length == 0) {
          this.$emit('paymentMethodListEmpty');
        };
        await alert_success('Success', vue_vm.$t('message.payment_method_deleted'))
      },
      updateFavoritePaymentMethod: async function(payment_method_id, vue_vm=this) {
        let fav = await axios.post('/api/cas/org/default_payment_method/update',
                                  {'org_id': this.$store.state.current_org.id,
                                        'payment_method_id': payment_method_id})
        this.favoritePaymentMethod = payment_method_id;
        await alert_success('Success', vue_vm.$t('message.payment_method_favourited'))
      },
    }
  }
  </script>
  
  <style scoped lang="scss">
  .payment-method-detail {
    display: flex;
    cursor: pointer;
    padding: 5px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #EFEFEF;
    border-radius: 20px;
    background-color: #FFFFFF;
    color: #000000;
    transition: box-shadow 0.12s ease-in-out;
  }
  
  .payment-method-detail .data {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex: 1;
  }
  
  .payment-method-detail .data span {
    margin: 5px 5px;
  }
  
  .payment-method-detail .control {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
  }
  
  .payment-method-detail .control span {
    margin: 0px 5px;
  }
  
  .payment-method-detail:hover {
    box-shadow: 0px 3px 20px rgba(0, 10, 255, 0.3);
  }
  .trash-icon{
  
  }
  .favorite-icon {
    color: gold;
  }
  .star-icon {
  
  }
  .trash-icon:hover {
    color: red;
  }
  .star-icon:hover {
    color: $mdspblue_ui;
  }
  </style>