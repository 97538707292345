<template>
  <label class="marginTop" v-if="showAdvancedOptions">
    {{ $t('message.font_size') }}: {{ fontSize }}pt
    <q-slider v-model="fontSize" :min="minFontSize" :max="maxFontSize" />
  </label>

  <label class="marginTop" v-if="showAdvancedOptions">
    {{ $t('message.limit_wheel_size') }}: {{ wheelSize }}%
    <q-slider
      v-model="wheelSize"
      :min="minWheelSize"
      :max="100"
      :step="wheelSizeIncrements"
    >
    </q-slider>
  </label>

  <label class="marginTop">
    {{ $t('message.hide_x_percent_associations', { percent: topCollapse }) }}
    <q-slider
      v-model="topCollapse"
      :min="0"
      :max="100"
      
    >
    </q-slider>
  </label>

  <div class="aw-toggle-wrapper marginTop">
    <q-toggle
      left-label
      v-model="showPercentages"
      :label="$t('message.percentages')"
    />
    <q-toggle
      left-label
      v-model="showInformation"
      :label="$t('message.statistics')"
    />
  </div>

  <a class="btn btn-outline btn3 marginTop" @click="$emit('applyColorScheme')">
    {{ $t('message.apply_color_scheme') }}
  </a>
</template>

<script>
export default {
  emits: [
    'fontSizeChange',
    'wheelSizeChange',
    'applyColorScheme',
    'percentageChange',
    'wheelInformationChange',
    'topCollapseChange',
  ],
  props: {
    minFontSize: { default: 6, type: Number },
    maxFontSize: { default: 50, type: Number },
    minWheelSize: { default: 40, type: Number },
    wheelSizeIncrements: { default: 10, type: Number },
    showAdvancedOptions: { default: false, type: Boolean },
    // default values
    defaultWheelSize: { default: 100, type: Number },
    defaultTopCollaps: { default: 0, type: Number },
    defaultFontSize: { default: 18, type: Number },
    defaultShowInformation: { default: true, type: Boolean },
    defaultShowPercentages: { default: false, type: Boolean },
  },
  data: function() {
    return {
      wheelSize: this.defaultWheelSize,
      topCollapse: this.defaultTopCollaps,
      fontSize: this.defaultFontSize,
      showInformation: this.defaultShowInformation,
      showPercentages: this.defaultShowPercentages,
    };
  },
  methods: {
    reset: function() {
      this.wheelSize = this.defaultWheelSize;
      this.topCollapse = this.defaultTopCollaps;
      this.fontSize = this.defaultFontSize;
      this.showInformation = this.defaultShowInformation;
      this.showPercentages = this.defaultShowPercentages;
    },
  },
  watch: {
    wheelSize: function(value) {
      this.$emit('wheelSizeChange', value);
    },
    topCollapse: function(value) {
      this.$emit('topCollapseChange', value);
    },
    fontSize: function(value) {
      this.$emit('fontSizeChange', value);
    },
    showInformation: function(value) {
      this.$emit('wheelInformationChange', value);
    },
    showPercentages: function(value) {
      this.$emit('percentageChange', value);
    },
  },
};
</script>
<style scoped lang="scss">
.collapse-label {
  font-size: 12px;
}
.aw-toggle-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.marginTop {
  margin-top: 2vw;
  width: 100%;
}

a.btn.btn-outline.btn3.marginTop {
    margin-left: 1vw;
}
</style>
