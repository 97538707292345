<template>
<section class="middle-section">

        <div class="inspiration-pg association-wheel-pg">
            <div class="inspir-lft-sec scroll">
                    <workspace ref="workspace" @assetAdded="onAssetAdded" @assetRemoved="onAssetRemoved" @reset="reset"></workspace>
            </div>

        </div>
</section>
</template>

<script>
import Workspace from './Workspace.vue';

export default {
    name: 'Mindbias',
    components: {
        Workspace,
    },
    data() {
        return {
            show: 'hide',
            display_loading_overlay: false,
        };
    },
    methods: {
        getClass(value) {
            this.show = value;
        },
        reset: function () {
            this.$refs['workspace'].reset();
        },

        onAssetRemoved: function ({asset_and_positioning, canvasId}) {
            this.$refs.workspace.removeWheel({asset_and_positioning, canvasId});
        },

        jumpstart: async function () {
            const jumpstartEmpty = await this.$store.dispatch('jumpstart_empty');
            if (!jumpstartEmpty) {
                const assetAndPositioning = await this.$store.dispatch('pop_jumpstart');
                const canvasId = this.$refs.workspace.canvases[0].id;
                console.log("canvasindexid:",canvasId);
                console.log("asset",assetAndPositioning);

                this.$refs.workspace.manuallyAddAssetAndPositioning(assetAndPositioning,canvasId);
            }
        },
    },
    mounted: async function () {
        this.jumpstart();
    },
};
</script>

<style scoped>

.inspiration-pg {
    display: flex;
    justify-content: start;
    align-items: stretch;
}
.inspir-lft-sec {
    width: 100%;
    padding: 1.5vw;
    min-height: 45.2vw;
}
</style>
