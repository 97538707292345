<template>
  <page-title title="Brand Tracker" :desc="this.$t('message.bt')"
    :instructionsVideoTitle="$t('message.mt_video_instructions')" instructionsName="mt_instructions_main"
    :instructionsVideoUrl="$store.getters.getInstructionalVideoUrl('mt_instructions_main')
      ">
  </page-title>
  <div class="inspir-img-box mpDiv">
    <div class="inspir-img-div scroll3" v-bind:style="{ textAlign: empty ? 'center' : 'left' }" style="width: 100%;">
      <workspace-empty v-if="empty" app_name="Brand Tracker" background="brand-track-img.png" />

      <strong class="title" v-if="!empty">
        {{ $t('message.your_results') }}
        <tooltip title="Brand Tracker" message="mind_tracker_tooltip">
        </tooltip>
      </strong>
      <mp-chart ref="chart" :style="{ display: empty ? 'none' : 'block' }" @some-event="callback">
      </mp-chart>
      <!-- here we display the images added by the user of the chart -->
      <div class="better-banner" :style="{ display: empty ? 'none' : 'block' }">
          Load your tracked brand concept and click on the dots to reveal the evolution of your brand image
        <div style="display: flex; max-width: 100px; justify-content: flex-start; ">
          <div v-for="(image, index) in images" :key="index" style="margin-right: 10px;">
            <img :src="image" alt="Image" @click="enlargeImage(index)"
              style="cursor: pointer; max-width: 50px; max-height: 100px;" />
            <p style="font-size: 10px;">{{ images_date[index] }}</p>
          </div>
        </div>
        <!-- Enlarged Image Modal -->
        <div v-if="enlargedImage !== null" class="enlarged-image-modal" @click="closeEnlargedImage">
          <div class="enlarged-image-container">
            <img :src="enlargedImage" alt="Enlarged Image" />
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="link-bx-div">
    <div class="col-md-8 rgt-link-col bottom-lnk">
      <ul>
        <li>
          <a class="cursor-p" @click="$emit('reset')" v-if="!empty">
            <span><img src="../../assets/icon9.png" />
              {{ $t('message.reset') }}</span>
          </a>
        </li>
        <li>
          <a class="cursor-p" @click="exportChart" v-if="!empty">
            <span><img src="../../assets/icon10.png" />{{
              $t('message.export')
            }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import WorkspaceEmpty from '@/components/common/WorkspaceEmpty.vue';
import MpChart from './MpChart.vue';
import Tooltip from '@/components/common/Tooltip.vue';
import PageTitle from '../common/PageTitle';
import axios from "axios";

export default {
  components: {
    WorkspaceEmpty,
    MpChart,
    Tooltip,
    PageTitle,
  },
  emits: ['reset'],
  data: function () {
    return {
      empty: true,
      images: [],
      images_date: [],
      image_prefix: '',
      rerenderFlag: false,
      enlargedImage: null,
    };
  },
  async mounted(){
    const res = await axios.get('/api/cas/assets/s3_prefix');
    this.image_prefix = res.data.s3_bucket_url;
  },
  methods: {
    enlargeImage(index) {
      this.enlargedImage = this.images[index];
    },
    closeEnlargedImage() {
      this.enlargedImage = null;
    },
    callback(data) {
      // clear the list first
      this.images = [];
      this.images_date = [];
      // sort the array by the date
      data.sort((a, b) => {
        const dateA = new Date(a[1]);
        const dateB = new Date(b[1]);
        return dateA - dateB;
      });
      data.forEach((item) => {
        const [id, date] = item;
        // process with images
        this.images.push(this.image_prefix + id);
        // process with the dates
        var fullDate = new Date(date);
        var options = { month: 'short', year: 'numeric' };
        var truncatedDateString = fullDate.toLocaleDateString('en-US', options);
        this.images_date.push(truncatedDateString);
      });
    },
    reset: function () {
      this.empty = true;
    },
    generate: async function (assets, selected_ia, campaigns) {
      const load = await this.$refs['chart'].load(
        assets,
        selected_ia,
        campaigns
      );
      this.empty = false;
    },
    exportChart: function () {
      this.$refs['chart'].exportChart();
    },
  },
  watch: {
    images(newValue) {
      this.empty = newValue.length === 0;
      this.rerenderFlag = !this.rerenderFlag;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
}

.editor {
  padding: 35px 50px !important;
  height: 100%;
  overflow: hidden;
}

.main-editor {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.state {
  width: 100%;
  height: 100%;
  display: flex;
}

.main-footer {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  width: 200px;
}

.enlarged-image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.enlarged-image-container {
  max-width: 80vw;
  max-height: 80vh;
}
.reset-button {
  background: white;
  position: fixed;
  bottom: 85px;

  @media (min-width: 1024px) {
    right: calc(30px + #{$sidebar-small});
  }

  @media (min-width: 1600px) {
    right: calc(30px + #{$sidebar-medium});
  }

  @media (min-width: 1920px) {
    right: calc(30px + #{$sidebar-big});
  }
}

.export-button {
  background-color: white;
  position: fixed;
  bottom: 35px;

  @media (min-width: 1024px) {
    right: calc(30px + #{$sidebar-small});
  }

  @media (min-width: 1600px) {
    right: calc(30px + #{$sidebar-medium});
  }

  @media (min-width: 1920px) {
    right: calc(30px + #{$sidebar-big});
  }
}

.btn.btn-small {
  border: 1px solid $mdspblue_ui;
  border-radius: 20px;
  box-shadow: 0 3px 9px 0 rgba(31, 31, 255, 0.3);
  color: $mdspblue_ui;
  background-color: white;
  display: block;
  font-size: 0.875rem;
  line-height: 38px;
  margin: 20px auto;
  text-align: center;
  text-transform: uppercase;
  width: 50px;
  -moz-transition: 400ms all ease;
  -o-transition: 400ms all ease;
  -webkit-transition: 400ms all ease;
  transition: 400ms all ease;
  overflow: hidden;

  &:hover {
    width: 150px;
    color: white;
    background-color: $mdspblue_ui;
  }
}

strong.title {
  margin-right: 50vw;
}

.cursor-p {
  cursor: pointer;
}

.better-banner {
  background: linear-gradient(45deg, #3498db, #1abc9c);
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

p {
  font-size: 1.2em;
}
</style>
