<template>
    <!-- <div class="store-workspace">
        <div class="store-grid">
          <div class="tooltip-wrapper">
            <div class="text">
              <div class="upper">
                {{ $t('message.load_concept_from_store1') }}
              </div>
              <div class="lower">
                {{ $t('message.store_asset_description1') }}
                <br>
                {{ $t('message.store_asset_description2') }}
              </div>
            </div>
          </div>
          <div class="button-wrapper">
            <div class="store-button" v-if="page>0" @click="previousPage">◀</div>
          </div>
            <asset-tile
                v-for="a in assets"
                :asset="a"
                :key="a.id"
                :store-asset="true"
                :ref="tilesRef"
                @assetSelected="onAssetSelected">
            </asset-tile>
            <div class="pseudo-asset"></div>
          <div class="button-wrapper">
            <div class="store-button" v-if="assets.length>0" @click="nextPage">▶</div>
          </div>
        </div>
    </div> -->
<p></p>
</template>

<script>
import axios from "axios";
import AssetTile from './AssetTile.vue';

export default {
    components: { AssetTile },
    emits: ['storeAssetClick'],
    props: {
        pageSize: { default: 5, type: Number },
        filter: { required: true, type: Object },
    },

    data: function () {
        return {
            page: 0,
            assets: [],
            // this array contains refs to all the tile components; it is
            // filled out by the tilesRef() method
            tile_elements: [],
            // we use this to send a ping value along with the payload which
            // the API endpoint is expected to ping back; based on the ping
            // we can disregard all data that is not the result of the latest
            // API request
            requests_sent: 0,
            loading: false,
        }
    },

    methods: {
        nextPage: function () {
            this.page += 1;
            this._fetchAssets();
        },
        previousPage: function(){
            this.page -= 1;
            if (this.page >= 0) {
                this._fetchAssets();
            }
        },
        _fetchAssets: async function() {
            // if the filter is not yet set (i.e. is empty), we skip fetching
            if (Object.keys(this.filter).length === 0) { return }
            // otherwise, we proceed with fetching the assets
            this.requests_sent++;
            // we will send this ping along with the request and compare it
            // to this.requests_sent after we receive a response
            const ping = this.requests_sent;
            this.loading = true;
            const payload = {
                "page_size": this.pageSize,
                "page": this.page,
                "filter": this.filter,
                "ping": ping,
                "include_positionings": false,
            };
            const response = await axios.post('/api/cas/assets/store/search_new', payload);
            // we only want to process the latest query so we disregard all
            // responses that are not a result of the latest request (i.e. the
            // request whose ping is this.requests_sent)
            if (response.data.ping === this.requests_sent) {
                this.assets = response.data.assets;
                this.loading = false;
            }
        },
        /*
        This method is used to keep an array of asset tiles by using the :ref
        attibute during v-for of the <asset-tile> component (see
        https://v3.vuejs.org/guide/migration/array-refs.html#migration-strategy
        */
        tilesRef: function(element) {
            this.tile_elements.push(element);
        },
        /*
        This method goes through the list of all asset tiles and asks each one
        to unselect itself.
        */
        resetSelection: function() {
            for (const tile of this.tile_elements) {
                if (tile !== null) {
                  tile.unselect();
                }
            }
        },
        onAssetSelected: function(asset) {
            // when an asset is selected we ask all asset tiles to unselect
            // themselves (the newly selected asset will select itself after
            // emitting this event)
            this.resetSelection();
            this.$emit('storeAssetClick', asset);
        },
        /*
        Remove asset from the grid. This is useful, for example, when an asset
        is purchased and it needs to be removed from the list to signal to the
        user that something has happened.
        */
        reFetchAssets: function(asset) {
            this._fetchAssets();
        },
    },
    watch: {
        // whenever filter changes we need to initiate a new search
        filter: {
            deep: true,
            immediate: true, 
            handler (val, oldVal) {
                this.page = 0;
                this.assets = [];
                this._fetchAssets();
            }
        },
    },
  beforeUpdate() {
    this.tile_elements = [];
  },
}
</script>

<style scoped lang="scss">
.store-workspace {
  flex: 0 1 auto;
  padding: 0 50px 0 0;
  width: 90%;
  background-color: #F8F9FF;
  box-shadow: inset 0px 0px 12px #BCBCBC;
  border: 1px solid #F7F7F7;
  border-radius: 128px;
  @media (min-width: 1024px) {
    margin: 15px 5% 10px 5%;
  }
  @media (min-width: 1600px) {
    margin: 15px 5% 10px 5%;
  }
  @media (min-width: 1920px) {
    margin: 15px 5% 10px 5%;
  }
}

.store-grid {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.store-grid .store-button {
  cursor: pointer;
  color: #c3cafa;
}

.store-grid .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tooltip-wrapper {
  background-color: #000;
  border-radius: 128px 0 0 128px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  font-family: 'Open Sans';
  font-weight: 300;
  color: white;
  @media (min-width: 1280px) {
    padding: 10px 30px 10px 50px;
  }
  @media (min-width: 1600px) {
    padding: 10px 30px 10px 50px;
  }
  @media (min-width: 1920px) {
    padding: 10px 30px 10px 50px;
  }
}
.tooltip-wrapper .text .upper {
  flex-basis: 100%;
  /*font-size: 1.25rem;*/

  @media (min-width: 1024px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1600px) {
    font-size: 1.25rem;
  }

  @media (min-width: 1920px) {
    font-size: 1.25rem;
  }
}
.tooltip-wrapper .text .lower {
  flex-basis: 100%;
  /*font-size: 0.875rem;*/

  @media (min-width: 1024px) {
    font-size: 0.725rem;
  }

  @media (min-width: 1600px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1920px) {
    font-size: 0.875rem;
  }
}

.text {
  @media (min-width: 1024px) {
    padding: 10px 10px 10px 30px;
  }
  @media (min-width: 1280px) {
    padding: 0;
  }
}
</style>
