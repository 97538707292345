<template>
  <div>
    <monitoring-toggle :asset="asset"></monitoring-toggle>
    <jumpstart
      v-if="!positioningsEmpty   "
      :asset="asset"
      :selectedPositioning="selectedPositioning">
    </jumpstart>
    
  </div>
</template>

<script>
import Jumpstart from './Jumpstart.vue';
import MonitoringToggle from './MonitoringToggle.vue';

/*
This component contains the elements that only apply to the assets owned by the
current user (and not store assets), such as the tracking toggle and jumpstart.
*/
export default {
    components: { Jumpstart, MonitoringToggle },
    props: {
        asset: { required: true, type: Object },
        positioningsEmpty: { required: true, type: Boolean },
        selectedPositioning: { default: {}, type: Object },
        showTrackToggle: { default: false, type: Boolean },
    },
}
</script>
