<template>
  <div class="wt-box-col rightBar">
    <div class="col-md-12 choose-libra">
      <h6>Load Brand Concept</h6>
      <div class="divider"></div>
      <asset-checkbox-picker ref="asset-picker" @assetAdded="onAssetChanged" @assetRemoved="onAssetChanged">
      </asset-checkbox-picker>
    </div>
  </div>

  <div class="wt-box-col rightBar" style="margin-top: 20px;">
    <div class="col-md-12 choose-libra">
      <h6>Load Target Association</h6>
      <div class="divider"></div>
      <single-ia-picker ref="ia-picker" @iaSelected="onIaSelected">
      </single-ia-picker>
      <div classs="data-pocket" v-show="false">
        <span class="step-label">
          <label>{{ $t('message.add_campaigns') }}</label>
          <campaign-picker ref="campaign-picker" @campaignSelected="campaignChanged"
            @campaignUnselected="campaignChanged">
          </campaign-picker>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import AssetCheckboxPicker from '@/components/target_pickers/sidebar_pickers/AssetCheckboxPicker.vue';
import SingleIaPicker from '@/components/target_pickers/sidebar_pickers/SingleIaPicker_BT.vue';
import CampaignPicker from './campaigns/CampaignPicker.vue';

export default {
  components: { SingleIaPicker, AssetCheckboxPicker, CampaignPicker },
  emits: ['generate'],
  data: function () {
    return {
      selected_asset: {},
      ia_is_selected: false,
      asset_is_selected: false,
      is_generate_disable: true,
    };
  },
  computed: {
    generateAllowed: function () {
      return this.ia_is_selected && this.asset_is_selected;
    },
  },
  methods: {
    getClass: function () {
      return {
        'btn3': this.generateAllowed && !this.is_generate_disable,
        'disabled-btn': !(this.generateAllowed && !this.is_generate_disable),
      };
    },
    reset: function () {
      this.$refs['asset-picker'].reset();
      this.$refs['ia-picker'].reset();
      this.$refs['campaign-picker'].reset();
    },
    generateChart: function () {
      const assets = Array.from(
        this.$refs['asset-picker'].selected_assets_and_positionings
      ).map((ap) => ap.asset);
      const selected_ia = this.$refs['ia-picker'].selected_intended_association;
      const campaigns = this.$refs['campaign-picker'].selected_campaigns;
      this.$emit('generate', assets, selected_ia, campaigns);
      this.is_generate_disable = true;
    },
    /*
    Whenever IAs change in some way the sidebar needs to figure out if
    clicking the generate button is allowed.
    */
    onIaSelected: function (selected_ia) {
      this.ia_is_selected = Object.keys(selected_ia).length > 0;
      // Here if onAsserChanged is called then we trigger the generate
      if (this.generateAllowed) {
        this.generateChart();
      }
    },
    /*
    Whenever the list of selected assets changes the sidebar needs to
    figure out if clicking the generate button is allowed.
    */
    onAssetChanged: function () {
      this.is_generate_disable = false;
      this.asset_is_selected =
        this.$refs['asset-picker'].selected_assets_and_positionings.size > 0;
      if (this.generateAllowed) {
        this.generateChart();
      }
    },
    campaignChanged: function (campaign) {
      if (this.generateAllowed) {
        this.generateChart();
      }
    },
    manuallyAddAsset: function (asset) {
      const payload = { asset: asset, date: null };
      this.$refs['asset-picker'].onAddAsset(payload.asset);
    },
  },
};
</script>


<style scoped>
.single_picker {
  margin-top: 2vw;
}

.divider {
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
  margin-bottom: 20px;
}
</style>