<template>
  <div class="concept-rating">
    <div style="display: flex; justify-content: space-evenly; width: 75%;">
      <SvgIconComponent 
        v-for="(percentage, index) in iconPercentages"
        :key="index"
        :fill_percentage="percentage"
        :svgId="'icon' + (index + 1)"
        :maskId="'mask' + (index + 1)"
      />
    </div>
  </div>
</template>

<script>
import SvgIconComponent from './mindspeller_full_star.vue'
export default {
  name: 'ConceptRating',
  components: {SvgIconComponent},
  props: {
    rating: {
      type: Number,
      default: 0
    }
  },
  computed: {    
    iconPercentages() {
      const rating = this.rating;
      const fullIcons = Math.floor(rating);
      const partialIconPercentage = Math.round((rating - fullIcons) * 100);
      const emptyIcons = 5 - fullIcons - (partialIconPercentage > 0 ? 1 : 0);

      const percentages = Array(fullIcons).fill(100);
      if (partialIconPercentage > 0) {
        percentages.push(partialIconPercentage);
      }
      percentages.push(...Array(emptyIcons).fill(0));

      return percentages;
    }
  }
}
</script>

<style>
.concept-rating {
  display: flex;
  justify-content: center;
  gap: 5px;
}
</style>
