const neuropositioning_translation = {
    en: {
        message: {
            neuropositioning_tooltip: "Since we are not censoring our semantic network, the results will reflect" +
                " actual population bias, which may include discriminatory and otherwise " +
                "insensitive concepts. Please apply professional prudence when crafting " +
                "your brand story using these concepts.",
            neuropositioning_instructions: "Connection Checker instructions",
        },
    },
    nl: {
        message: {
            neuropositioning_tooltip: "Aangezien we ons semantisch netwerk niet censureren zullen de resultaten " +
                "de werkelijke bias in de populatie weerspiegelen, dit kan discriminerende" +
                " en ongevoelige concepten bevatten. Pas voorzichtigheid toe bij het maken" +
                " van je brand story met deze concepten.",
            neuropositioning_instructions: "Connection Checker instructies",
        },
    }
};

export { neuropositioning_translation };
