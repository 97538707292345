<template>
  <page-title title="MindBias" :desc="this.$t('message.mb')"
>
  </page-title>
  <loading-app-wide v-if="loading" />
  <div class="canvas-grid" ref="canvasGrid">
    <div  :key="canvases[0].id + '-container'">

<div class="canvas-container" :data-id="canvases[0].id" >
  <mb-control
    ref="first-mb-control"
    :canvasId="canvases[0].id"
    :show_positionings="true"
    :showFrameworkSelector="false"
    :data = "data"
    :comparisonData = "comparisonData"
    @assetAdded="onAssetAdded"
    @assetRemoved="onAssetRemoved"
    @visualizationTypeSelected="handleVisualizationSelection"
    >
  </mb-control>
  <div class="chart-grid" v-if="concepts[canvases[0].id]">
    <div v-for="concept in concepts[canvases[0].id]" :key="concept.chartId">
        <div v-if="concept.chartId.endsWith('-comp')" :id="concept.chartId" class="chart-container"></div>
        <div v-if="concept.chartId.endsWith('-main')" :id="concept.chartId" class="chart-container"></div>
    </div>

    <!-- Check if there is no concept with '-main' suffix -->
    <div v-if="!concepts[canvases[0].id].some(concept => concept.chartId.endsWith('-main'))" class="chart-container status-container">
        <div class="status-content">
            <p class="brand-status-text">
                Compare AI RESPONSES with HUMAN RESPONSES here.
            </p>
            <p class="brand-status-text">
                To check the SURVEY STATUS follow the steps: GO TO
                <ul class="status-path">
                    <li><font-awesome-icon :icon="['fas', 'arrow-right']" /> Library</li>
                    <li><font-awesome-icon :icon="['fas', 'arrow-right']" /> My library</li>
                    <li><font-awesome-icon :icon="['fas', 'arrow-right']" /> Click on your concept</li>
                    <li><font-awesome-icon :icon="['fas', 'arrow-right']" /> Click on the info tooltip icon inside the Right Window Pane</li>
                </ul>
            </p>
            <p class="brand-status-text">
                Stressed for time? <a href="mailto:contact@mindspeller.com">contact@mindspeller.com</a> to expedite your survey results.
            </p>
        </div>
    </div>
</div>
  <q-dialog v-model="isDeleteDialogOpen" persistent>
    <q-card class="delete-dialog-card">
      <q-card-section class="delete-dialog-content">
        <div class="dialog-header">
          <q-icon name="report_problem" color="amber" />
          <span class="dialog-text">Are you sure you want to delete this monitor?</span>
        </div>
      </q-card-section>
      <q-card-actions class="dialog-actions">
        <q-btn flat label="Cancel" color="primary" @click="isDeleteDialogOpen = false" class="dialog-button" />
        <q-btn flat label="Yes, Delete" color="negative" @click="confirmDelete" class="dialog-button delete-button" />
      </q-card-actions>
    </q-card>
  </q-dialog>

</div>
<div :key="'placeholder-' + index" class="placeholder"></div>

</div>
    <div v-if="canvases.length>1" v-for="(canvas, index) in canvases.slice(1)" :key="canvas.id + '-container'">
      <div class="canvas-container" :data-id="canvas.id">
        <mb-control
          ref="mb-control"
          :canvasId="canvas.id"
          :show_positionings="true"
          :showFrameworkSelector="false"
          :data = "data"
          :comparisonData = "comparisonData"
          @assetAdded="onAssetAdded"
          @assetRemoved="onAssetRemoved"
          @visualizationTypeSelected="handleVisualizationSelection"
          >
        </mb-control>
        <div class="chart-grid" v-if="concepts[canvas.id]">
        <div v-for="concept in concepts[canvas.id]" :key="concept.chartId">
            <div v-if="concept.chartId.endsWith('-comp')" :id="concept.chartId" class="chart-container"></div>
            <div v-if="concept.chartId.endsWith('-main')" :id="concept.chartId" class="chart-container"></div>
        </div>

        <!-- Check if there is no concept with '-main' suffix -->
        <div v-if="!concepts[canvas.id].some(concept => concept.chartId.endsWith('-main'))" class="chart-container status-container">
            <div class="status-content">
                <p class="brand-status-text">
                    Compare AI RESPONSES with HUMAN RESPONSES here.
                </p>
                <p class="brand-status-text">
                    To check the SURVEY STATUS follow the steps: GO TO
                    <ul class="status-path">
                        <li><font-awesome-icon :icon="['fas', 'arrow-right']" /> Library</li>
                        <li><font-awesome-icon :icon="['fas', 'arrow-right']" /> My library</li>
                        <li><font-awesome-icon :icon="['fas', 'arrow-right']" /> Click on your concept</li>
                        <li><font-awesome-icon :icon="['fas', 'arrow-right']" /> Click on the info tooltip icon inside the Right Window Pane</li>
                    </ul>
                </p>
                <p class="brand-status-text">
                    Stressed for time? <a href="mailto:contact@mindspeller.com">contact@mindspeller.com</a> to expedite your survey results.
                </p>
            </div>
        </div>
    </div>
        <q-dialog v-model="isDeleteDialogOpen" persistent>
          <q-card class="delete-dialog-card">
            <q-card-section class="delete-dialog-content">
              <div class="dialog-header">
                <q-icon name="report_problem" color="amber" />
                <span class="dialog-text">Are you sure you want to delete this monitor?</span>
              </div>
            </q-card-section>
            <q-card-actions class="dialog-actions">
              <q-btn flat label="Cancel" color="primary" @click="isDeleteDialogOpen = false" class="dialog-button" />
              <q-btn flat label="Yes, Delete" color="negative" @click="confirmDelete" class="dialog-button delete-button" />
            </q-card-actions>
          </q-card>
        </q-dialog>

        <button class="delete-btn" @click="promptDelete(index+1)">
          <font-awesome-icon :icon="['fas', 'trash-alt']" style="color: #e05252;" />
        </button>
      </div>
      <div :key="'placeholder-' + index+1" class="placeholder"></div>

</div>
    <button class="add-chart-btn" @click="addCanvas()">
    <font-awesome-icon :icon="['fas', 'plus']" />
  </button>
  </div>

</template>






<script>
import axios from 'axios';
import WorkspaceEmpty from '@/components/common/WorkspaceEmpty.vue';
import AwPicker from '@/components/association_wheel/AwPicker.vue';
import MonitoringToggle from '@/components/asset_library/sidebar/MonitoringToggle.vue';
import AssetNameEdit from "@/components/asset_library/sidebar/AssetNameEdit";
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
import PageTitle from '../common/PageTitle.vue';
import MbControl from './MbControl.vue';
import { toast_success, genericDialog, alert_error } from '@/helpers/alert_helper.js';

import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    WorkspaceEmpty,
    PageTitle,
    LoadingAppWide,
    MbControl,
    AwPicker,
    AssetNameEdit, 
    MonitoringToggle,
  },
  emits: [
    'positioningSelected','showLifecycle'
  ],
  props: {
    asset: { required: true, type: Object },
    show_positionings: { default: false, type: Boolean },
    positioning_surveys: { required: true, type: Array },
    isMyLibrary: { default: false, type: Boolean },
    AssetMonitoringQueue: { type: Array },
    fromAssetPicker: {type: Boolean}
  },
  computed:{
    asset_empty: function (asset_and_positioning) {
      if (!this.asset_and_positioning || !this.asset_and_positioning.asset) {
      return true; // Return true if it's empty or null
    }
      return Object.keys(asset_and_positioning.asset).length === 0;
    },
    state_name: function (asset_and_positioning) {
      if (this.asset_empty) { return ''; }
      return this.getStateName(asset_and_positioning.asset.lifecycle);
    },
  },
  data: function () {
    return {
      empty: true,
      assets_and_positionings: new Set(),
      concepts: {},
      sunburstData: [],
      compSunburstData: [],
      showChartGrid: false,
      canvases: [{id:uuidv4()}],
      defaultCanvasId: uuidv4(),
      isDragging: false,
      currentItem: null,
      currentItemIndex: null,
      containerOffsetY: 0,
      initY: 0,
      offsetY: 0,
      dropTargetIndex: null,
      show_options: false,
      chartInstances:[],
      dataCache: {},
      dataCompCache: {},
      loading: false,
      isDeleteDialogOpen: false,
      currenIndex: null,
    };
  },
  mounted() {
    // Optional: You can perform an initial check here if needed
    this.manuallyAddAssetAndPositioning();
  },
  methods: {

  reset: function() {
    this.canvases = [{id: uuidv4()}]; 
    this.assets_and_positionings.clear();
    this.concepts = {};
    this.sunburstData = [];
    this.compSunburstData = []; 
    this.showChartGrid = false;
    this.dataCache = {}; 
    this.dataCompCache = {};
    this.loading = false; 
    this.isDeleteDialogOpen = false;
    this.chartInstances = []; 

    if (this.$refs['mb-control']) {
      this.$refs['mb-control'].reset();
    }

    },


    emitLifecycle: function(){
      this.$emit('showLifecycle', true);
    },

    getCanvasId() {
      return this.defaultCanvasId; 
    },
    async handleVisualizationSelection({type, asset_and_positioning, canvasId}) {
  
      this.clearChart(canvasId);
      await this.addWheel({type, asset_and_positioning, canvasId});
        
    },
    clearChart(canvasId) {
      // Access the concepts associated with the canvasId
      const canvasConcepts = this.concepts[canvasId]
      if (canvasConcepts) {
        canvasConcepts.forEach(concept => {
          const chartIndex = this.chartInstances.findIndex(chart => chart.id === concept.chartId)

          this.removeChart(concept.chartId)
          if (chartIndex !== -1) {
            this.chartInstances.splice(chartIndex, 1)
          }
        })

        // Clear the concepts once all associated charts are destroyed
        this.concepts[canvasId] = []
      }
    },


    onAssetAdded: function(asset_and_positioning) {
      this.$emit('assetAdded', asset_and_positioning);
      this.show_options = true;
    },


    onAssetRemoved: function({asset_and_positioning,canvasId}) {
      this.$emit('assetRemoved', {asset_and_positioning,canvasId});
    },
    addCanvas() {
      this.canvases.push({
        id: uuidv4(),
      });

    },
    promptDelete(index) {
      this.currentIndex = index;  
      this.isDeleteDialogOpen = true;  
    },
    confirmDelete() {
      this.deleteCanvas(this.currentIndex);  
      this.isDeleteDialogOpen = false;  
    },
    deleteCanvas(index) {
      this.canvases.splice(index, 1);
    },

    async addWheel({type, asset_and_positioning,canvasId}) {
      this.assets_and_positionings.add({type, asset_and_positioning,canvasId});
      this.empty = false;
      await this.fetchAndProcessData({type, asset_and_positioning,canvasId});

    },

    async fetchAndProcessData({type, asset_and_positioning, canvasId}) {
        let asset = asset_and_positioning.asset;
        const positioning = asset_and_positioning.positioning;
        const assetId = asset.id;

        // Check if data is already cached
        if (this.dataCache[assetId] || this.dataCompCache[assetId]) {
            this.chartOptions(this.dataCache[assetId], this.dataCompCache[assetId], asset, type, canvasId);
            return;
        }

        // Handle asset with state 1 by cleaning and ensuring all required fields exist
        if (asset.state == 1) {
            asset = Object.fromEntries(Object.entries(asset).filter(([_, v]) => v != null && v !== ""));
            
            // Ensure 'word' field is populated from 'candidate_word' if missing
            // This is just a useless manipulation because the backend wants the data
            // to have a word array. But this is not used as a parameter in the backend
            // if state == 1.
            if (!asset.word && asset.candidate_word) {
                asset.word = {
                    word_id: asset.candidate_word.id,
                    word: asset.candidate_word.word,
                    asset_mime_type: asset.candidate_word.asset_mime_type,
                    file_identifier: asset.candidate_word.file_identifier,
                    type_: asset.candidate_word.type_
                };
            }

            // Assign default values to asset fields if missing
            asset = {
                ...asset,
                total_count: asset.total_count || 0,
                state: asset.state || 0,
                brand: asset.brand || 0,
                user: asset.user || {},
                display_name: asset.display_name || '',
                top_associations: asset.top_associations || '',
                labels: asset.labels || '',
                passion: asset.passion || 0,
                link_word_id: asset.link_word_id || null,
                map_date: asset.map_date || null,
                intended_association: asset.intended_association || null,
                blocked: asset.blocked || 0,
                lifecycle: asset.lifecycle || 0,
                clarity_score: asset.clarity_score || null,
                candidate_word: [],
                asset_mime_type: asset.asset_mime_type || '',
                file_identifier: asset.file_identifier || '',
                type_: asset.type_ || {},
                urls: asset.urls || {},
                positioning_count: asset.positioning_count || 0
            };
        }

        let payload = {
            proportion: 0.0,
            asset: asset,
            filters: [],
            from_date: positioning.from_date || '',
            to_date: positioning.to_date || ''
        };

        if ('mock' in asset_and_positioning) {
            payload['mock'] = true;
        }

        this.loading = true;
        try {
            const dataResponse = await axios.post('/api/cas/association_wheels/analyze', payload);
            const comparisonResponse = await axios.post('/api/cas/association_wheels/comparison', payload);

            let data = dataResponse.data[0];
            let comparisonData = comparisonResponse.data[0];

            // Handle the case where the asset's state is 1 or if data is empty
            if (asset.state == 1 || !data) {
                const fallbackData = {
                    children: [],
                    nbResponses: 125,
                    date: "2100-01-01",
                    stability: 0,
                    image: false,
                    url: comparisonData.url || ''
                };

                Object.assign(comparisonData, fallbackData);
                this.comparisonData = comparisonData;
                console.warn("Data is empty, using fallback values.");
            } else {
                // Append additional non-children information if not state 1
                const additionalInfo = {
                    date: data.date,
                    image: data.image || false,
                    url: data.url || '',
                    nbResponses: data.nbResponses,
                    stability: data.stability || 0
                };

                Object.assign(comparisonData, additionalInfo);
                this.data = data;
                this.comparisonData = comparisonData;

            }

            // Cache the processed data
            this.dataCache[assetId] = data;
            this.dataCompCache[assetId] = comparisonData;

            this.chartOptions(data, comparisonData, asset, type, canvasId);
        } catch (error) {
            alert_error(this.$t('message.general_server_error'));
            // this.handleError(error);
        } finally {
            this.loading = false;
        }
    },


    handleError(error) {
      if (error.response) {
        console.log(error.response.status);
        console.log(error.response.data);
        this.$emit('chartError', { error: error.response.data, status: error.response.status, asset_and_positioning });
        alert_error(this.$t('message.no_access_to_items'));
      } else if (error.request) {
        console.log(error.request);
        alert_error(this.$t('message.no_response'));
      } else {
        console.log('Error', error.message);
        alert_error(this.$t('message.request_setup_error'));
      }
    },


    manuallyAddAssetAndPositioning: function(asset_and_positioning, canvasId) {

        const refKey = "first-mb-control";

        this.$nextTick(() => {
            if (this.$refs[refKey]) {
                this.$refs[refKey].onAddAsset(asset_and_positioning);
            } else {
                console.error('MbControl reference or onAddAsset method is not available', refKey);
            }
        });
    },
    chartOptions(data,comparisonData, asset, type, canvasId){


      if(!data || !data.children || data.children.length === 0){
        if(type == 'pie'){
        this.prepareComparisonSunburstData(comparisonData, asset.urls.thumb_small, asset.display_name, canvasId,'AI RESPONSES');

        }
      if (type === 'histogram') {
          this.prepareComparisonHistogramData(comparisonData, asset.display_name, canvasId, 'AI RESPONSES');
      }
      if (type === 'wordcloud') {
          this.prepareComparisonWordCloudData(comparisonData, asset.display_name, canvasId, 'AI RESPONSES');
      }
      if (type === 'table') {
          this.prepareComparisonTableData(comparisonData, asset.display_name, canvasId, 'AI RESPONSES');
      }
      }else{

      
      if(type == 'pie'){
        this.prepareComparisonSunburstData(comparisonData, asset.urls.thumb_small, asset.display_name, canvasId,'AI RESPONSES');

        this.prepareSunburstData(data, asset.urls.thumb_small, asset.display_name, canvasId, 'HUMAN RESPONSES');

        }
      if (type === 'histogram') {
          this.prepareComparisonHistogramData(comparisonData, asset.display_name, canvasId, 'AI RESPONSES');

          this.prepareHistogramData(data, asset.display_name, canvasId, 'HUMAN RESPONSES');
      }
      if (type === 'wordcloud') {
          this.prepareComparisonWordCloudData(comparisonData, asset.display_name, canvasId, 'AI RESPONSES');

          this.prepareWordCloudData(data, asset.display_name, canvasId, 'HUMAN RESPONSES');
      }
      if (type === 'table') {
          this.prepareComparisonTableData(comparisonData, asset.display_name, canvasId, 'AI RESPONSES');

          this.prepareTableData(data, asset.display_name, canvasId, 'HUMAN RESPONSES');
      }
    }
    },
    //SUNBURST PREPARATION AND RENDERING
    prepareSunburstData(data, imageUrl, conceptName, canvasId, chartTitle) {
      const sunburstData = [];

      // Add central node for the image at level 0
      const centralNode = {
        id: '0.0',
        parent: '',
        name: '',
        image: imageUrl, // Add image URL
        value: 1
      };
      sunburstData.push(centralNode);

      const processNode = (node, parentId) => {
        const sunburstNode = {
          id: node.word_id || `id-${Math.random().toString(36).substr(2, 9)}`,
          name: node.word_en || node.word,
          parent: parentId,
          value: node.fas || 0
        };
        sunburstData.push(sunburstNode);

        if (node.children && node.children.length) {
          node.children.forEach(child => processNode(child, sunburstNode.id));
        }
      };

      // Process the data starting from the central node
      data.children.forEach(child => processNode(child, '0.0'));
      this.sunburstData = sunburstData;


      // Render the Sunburst chart
      const chartId = `chart-${[canvasId]}-${conceptName.replace(/\s+/g, '-')}-${this.concepts[canvasId] ? this.concepts[canvasId].length : 0}-main`;
      if (!this.concepts[canvasId]) {
          this.concepts[canvasId] = [];
      }


      this.concepts[canvasId].push({
        name: conceptName,
        chartId: chartId,
        imageUrl: imageUrl
      });
      this.$nextTick(() => {
        this.renderSunburstChart(data,sunburstData, chartId, conceptName, imageUrl, canvasId, chartTitle);
      });
    },
    //Chatgpt sunburst
    prepareComparisonSunburstData(data, imageUrl, conceptName, canvasId, chartTitle) {
      const compSunburstData = [];

      // Add central node for the image at level 0
      const centralNode = {
          id: '0.0',  // Static ID for the root node
          parent: '',
          name: '', // Name of the concept
          image: imageUrl, // Add image URL
          value: 1
      };
      compSunburstData.push(centralNode);

      // Function to process each node and its children, generate unique ID
      const processNode = (node, parentId, index) => {
          // Generate a unique ID using parent ID and index
          const nodeId = `${parentId}-${index}`;
          const sunburstNode = {
              id: nodeId,
              name: node.word.toUpperCase(),
              parent: parentId,
              value: node.fas / 100
          };
          compSunburstData.push(sunburstNode);

          // Recursively process children
          if (node.children && node.children.length) {
              node.children.forEach((child, idx) => processNode(child, nodeId, idx + 1));
          }
      };

      // Process the top-level data starting from the central node
      data.chatgpt_responses.forEach((item, idx) => processNode(item, '0.0', idx + 1));
      const totalLength = data.chatgpt_responses.reduce((accumulator, entry) => {
                return accumulator + 1 + (entry.children ? entry.children.length : 0);
            }, 0);

      console.log(totalLength);

      this.compSunburstData = compSunburstData;

      // Render the Sunburst chart
      const chartId = `chart-${canvasId}-${conceptName.replace(/\s+/g, '-')}-${this.concepts[canvasId] ? this.concepts[canvasId].length : 0}-comp`;
      if (!this.concepts[canvasId]) {
          this.concepts[canvasId] = [];
      }

      this.concepts[canvasId].push({
          name: conceptName,
          chartId: chartId,
          imageUrl: imageUrl
      });
      this.$nextTick(() => {
          this.renderCompSunburstChart(data,totalLength,compSunburstData, chartId, conceptName, imageUrl, canvasId, chartTitle);
      });
    },
    renderSunburstChart(extraData, plotData, chartId, conceptName, imageUrl, canvasId, chartTitle) {
      
      const chart = Highcharts.chart(chartId, {
        chart: {
          height: '75%',
          events: {
            load: function() {
              if(extraData.date && extraData.date !== "2100-01-01"){
                var label = this.renderer.label(
                  `Stability: ${extraData.stability.toFixed(2)},
                  Number of Responses: ${extraData.nbResponses},
                  Date: ${extraData.date}`
                ).css({
                    width: '400px',
                    fontSize: '9px'
                  })
                  .attr({
                    'stroke': 'silver',
                    'stroke-width': 1,
                    'r': 2,
                    'padding': 5
                  })
                  .add();
              }
              if(!extraData.date || extraData.date == "2100-01-01"){
                var label = this.renderer.label(
                  `Stability: ${extraData.stability === 0 ? 'null' : extraData.stability.toFixed(2)},
                  Number of Responses: ${extraData.nbResponses}`
                ).css({
                    width: '1000px',
                    fontSize: '9px'
                  })
                  .attr({
                    'stroke': 'silver',
                    'stroke-width': 1,
                    'r': 2,
                    'padding': 5
                  })
                  .add();
              }

                label.align(Highcharts.extend(label.getBBox(), {
                  align: 'center',
                  x: 0,
                  verticalAlign: 'bottom',
                  y: 12
                }), null, 'spacingBox');

              },
              render: function () {
                    const chart = this;
                    const centerX = chart.plotLeft + chart.series[0].center[0];
                    const centerY = chart.plotTop + chart.series[0].center[1];
                    const imageSize = 80; // Adjust as necessary

                    if (chart.customImage) {
                        chart.customImage.destroy();
                        chart.customImage = null; // Ensure reference is cleared
                    }
                    if (chart.customText) {
                        chart.customText.destroy();
                        chart.customText = null; // Ensure reference is cleared
                    }

                    // Add the custom image or text to the center
                    if (imageUrl) {
                        chart.customImage = chart.renderer
                            .image(imageUrl, centerX - imageSize / 2, centerY - imageSize / 2, imageSize, imageSize)
                            .attr({
                                preserveAspectRatio: 'xMidYMid meet' // Maintain aspect ratio
                            })
                            .add();
                    } else {
                        chart.customText = chart.renderer.text(conceptName, centerX, centerY)
                            .css({
                                color: '#333333',
                                fontSize: '30px',
                                fontFamily: 'Times New Roman, serif'
                            })
                            .attr({
                                zIndex: 5
                            })
                            .add();

                        // Center the text after adding it to get accurate dimensions
                        let bbox = chart.customText.getBBox();
                        chart.customText.attr({
                            x: centerX - bbox.width / 2,
                            y: centerY - bbox.height / 2 + bbox.height  // Adjust Y to align by the top of the text
                        });
                    }
                }
            }
        },
        colors: ['transparent'].concat(Highcharts.getOptions().colors),
        title: {
          text: chartTitle, // Set the title to the concept name or any other text
          align: 'center',
          verticalAlign: 'top',
          margin: 10, // Adjust margin to control spacing between title and chart
          style: {
            fontSize: '15px', // Adjust the font size of the title
            fontWeight: 'bold', // Optional: make the title bold
            color: '#1177ba' // Optional: set title color
          }
        },
        series: [{
          type: 'sunburst',
          width: '100%',
          name:'Root',
          data: plotData,
          allowDrillToNode: true,
          cursor: 'pointer',
          dataLabels: {
            crop: false,
            overflow: 'allow',
            formatter: function () {
              if (this.point.id === '0.0') {
                return ''; 
              }
              return this.point.name; 
            },
            style: {
              fontSize: '10px',
              textoverflow: 'ellipsis',
            },
            minFontSize: 8, 
            maxFontSize: 20,
            filter: {
              property: 'innerArcLength',
              operator: '>',
              value: 0
            }
          },
          levels: [{
            level: 0, 
            colorByPoint: true,
            dataLabels: {
              enabled: false
            }
          }, {
            level: 1, 
            levelIsConstant: false,
            dataLabels: {
              filter: {
                property: 'outerArcLength',
                operator: '>',
                value: 1 
              }
            },style: {
              fontSize: '10px',
            },
          }, {
            level: 2,
            colorByPoint: true
          }, {
            level: 3,
            colorVariation: {
              key: 'brightness',
              to: -0.5
            }
          }, {
            level: 4,
            colorVariation: {
              key: 'brightness',
              to: 0.5
            }
          }]
        }],
        tooltip: {
          headerFormat: '',
          pointFormat: 'The data of <b>{point.name}</b> is <b>{point.value}</b>'
        }
      });
      this.chartInstances.push({ id: chartId, chart: chart});
    },

    renderCompSunburstChart(extraData,totalLength, plotData, chartId, conceptName, imageUrl, canvasId, chartTitle) {
      
      const chart = Highcharts.chart(chartId, {
        chart: {
          height: '75%',
          events: {
            load: function() {
              if(extraData.date && extraData.date !== "2100-01-01"){
                var label = this.renderer.label(
                  `Stability: 'null',
                  Number of Responses: ${totalLength},
                  Date: ${extraData.date}`
                ).css({
                    width: '400px',
                    fontSize: '9px'
                  })
                  .attr({
                    'stroke': 'silver',
                    'stroke-width': 1,
                    'r': 2,
                    'padding': 5
                  })
                  .add();
              }
              if(!extraData.date || extraData.date == "2100-01-01"){
                var label = this.renderer.label(
                  `Stability: null,
                  Number of Responses: ${totalLength}`
                ).css({
                    width: '1000px',
                    fontSize: '9px'
                  })
                  .attr({
                    'stroke': 'silver',
                    'stroke-width': 1,
                    'r': 2,
                    'padding': 5
                  })
                  .add();
              }

                label.align(Highcharts.extend(label.getBBox(), {
                  align: 'center',
                  x: 0,
                  verticalAlign: 'bottom',
                  y: 12
                }), null, 'spacingBox');

              },
              render: function () {
                    const chart = this;
                    const centerX = chart.plotLeft + chart.series[0].center[0];
                    const centerY = chart.plotTop + chart.series[0].center[1];
                    const imageSize = 80; // Adjust as necessary

                    if (chart.customImage) {
                        chart.customImage.destroy();
                        chart.customImage = null; // Ensure reference is cleared
                    }
                    if (chart.customText) {
                        chart.customText.destroy();
                        chart.customText = null; // Ensure reference is cleared
                    }

                    // Add the custom image or text to the center
                    if (imageUrl) {
                        chart.customImage = chart.renderer
                            .image(imageUrl, centerX - imageSize / 2, centerY - imageSize / 2, imageSize, imageSize)
                            .attr({
                                preserveAspectRatio: 'xMidYMid meet' // Maintain aspect ratio
                            })
                            .add();
                    } else {
                        chart.customText = chart.renderer.text(conceptName, centerX, centerY)
                            .css({
                                color: '#333333',
                                fontSize: '30px',
                                fontFamily: 'Times New Roman, serif'
                            })
                            .attr({
                                zIndex: 5
                            })
                            .add();

                        // Center the text after adding it to get accurate dimensions
                        let bbox = chart.customText.getBBox();
                        chart.customText.attr({
                            x: centerX - bbox.width / 2,
                            y: centerY - bbox.height / 2 + bbox.height  // Adjust Y to align by the top of the text
                        });
                    }
                }
            }
        },
        colors: ['transparent'].concat(Highcharts.getOptions().colors),
        title: {
          text: chartTitle, // Set the title to the concept name or any other text
          align: 'center',
          verticalAlign: 'top',
          margin: 10, // Adjust margin to control spacing between title and chart
          style: {
            fontSize: '15px', // Adjust the font size of the title
            fontWeight: 'bold', // Optional: make the title bold
            color: '#1177ba' // Optional: set title color
          }
        },
        series: [{
          type: 'sunburst',
          width: '100%',
          name:'Root',
          data: plotData,
          allowDrillToNode: true,
          cursor: 'pointer',
          dataLabels: {
            crop: false,
            overflow: 'allow',
            formatter: function () {
              if (this.point.id === '0.0') {
                return ''; 
              }
              return this.point.name; 
            },
            style: {
              fontSize: '10px',
              textoverflow: 'ellipsis',
            },
            minFontSize: 8, 
            maxFontSize: 20,
            filter: {
              property: 'innerArcLength',
              operator: '>',
              value: 0
            }
          },
          levels: [{
            level: 0, 
            colorByPoint: true,
            dataLabels: {
              enabled: false
            }
          }, {
            level: 1, 
            levelIsConstant: false,
            dataLabels: {
              filter: {
                property: 'outerArcLength',
                operator: '>',
                value: 1 
              }
            },style: {
              fontSize: '10px',
            },
          }, {
            level: 2,
            colorByPoint: true
          }, {
            level: 3,
            colorVariation: {
              key: 'brightness',
              to: -0.5
            }
          }, {
            level: 4,
            colorVariation: {
              key: 'brightness',
              to: 0.5
            }
          }]
        }],
        tooltip: {
          headerFormat: '',
          pointFormat: 'The data of <b>{point.name}</b> is <b>{point.value}</b>'
        }
      });
      this.chartInstances.push({ id: chartId, chart: chart});
    },

    

    
    //Histogram
    prepareHistogramData(data, conceptName, canvasId, chartTitle) {
      const histogramData = [];

      if (data.children) {
          data.children.forEach(node => {
              if (node.word && node.fas) {
                const fasValue = parseFloat(node.fas.toFixed(2));
                // Only include values greater than 0.001
                if (fasValue > 0.002) {
                    histogramData.push({
                        name: node.word_en || node.word,
                        y: fasValue
                    });
                }
      }});
      }

      this.originialHistogramData = histogramData;
        // Schedule the histogram to be rendered after the next update cycle
        const chartId = `chart-${canvasId}-${conceptName.replace(/\s+/g, '-')}-${this.concepts[canvasId] ? this.concepts[canvasId].length : 0}-main`;
        // Ensure concept list for canvasId
        if (!this.concepts[canvasId]) {
          this.concepts[canvasId] = [];
        }

        // Add new concept information
        this.concepts[canvasId].push({
          name: conceptName,
          chartId: chartId
        });

        this.$nextTick(() => {
            this.renderHistogramChart(histogramData, chartId, conceptName, canvasId, chartTitle);
        });

    },

    //Chatgpt histogram
    prepareComparisonHistogramData(comparisonData, conceptName, canvasId, chartTitle) {
        const compHistogramData = [];

        // Iterate through the chatgpt_responses array
        // Iterate through the chatgpt_responses array
        if (comparisonData.chatgpt_responses) {
            comparisonData.chatgpt_responses.forEach(response => {
                if (response.word && response.fas) {
                    // Divide FAS value by 100
                    const fasValue = parseFloat((response.fas / 100).toFixed(2));
                    
                    // Only include values greater than 0.15
                    if (fasValue > 0.01) {
                        compHistogramData.push({
                            name: response.word.toUpperCase(),  // Use the response word as the label
                            y: fasValue           // Use the FAS value as the data point
                        });
                    }
                }
            });
        }
        this.comparisonHistogramData = compHistogramData.slice(0, 50);

        // Schedule the comparison histogram to be rendered after the next update cycle
        const chartId = `chart-${canvasId}-${conceptName.replace(/\s+/g, '-')}-${this.concepts[canvasId] ? this.concepts[canvasId].length : 0}-comp`;
        // Ensure concept list for canvasId
        if (!this.concepts[canvasId]) {
            this.concepts[canvasId] = [];
        }

        // Add new concept information for comparison
        this.concepts[canvasId].push({
            name: conceptName,
            chartId: chartId
        });
        this.$nextTick(() => {
            this.renderHistogramChart(compHistogramData, chartId, conceptName, canvasId, chartTitle);
        });
    },

    renderHistogramChart(histogramData, chartId, conceptName, canvasId, chartTitle) {
        
        const chart = Highcharts.chart(chartId, {
        chart: {
          type: 'column',
          reflow: true,
          clip: true,
          zIndex: 1,
        },
        title: {
            text: chartTitle,  
            align: 'center',  
            style: {
                fontSize: '15px',  
                fontWeight: 'bold', 
                color: '#1177ba' 
            }
        },
        xAxis: {
          type: 'category',
          labels: {
            rotation: -90, 
            step: 1,

            style: {
              fontSize: '10px'
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Forward Association Strength (FAS) %'
          },
          labels: {
            step: 1,

          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><br/>',
          pointFormat: '<b>FAS: {point.y:.5f}</b>' 
        },
        plotOptions: {
          column: {
            pointPadding: 0.1, // Padding between columns
            borderWidth: 0,
            groupPadding: 0, // Spacing between groups of columns
            shadow: false
          }
        },
        series: [{
          name: 'FAS Value',
          data: histogramData,
          dataLabels: {
            step:1,
            rotation: -90,
            color: '#FFFFFF',
            align: 'center',
            format: '{point.y:.5f}',
            allowOverlap: false,
            style: {
              fontSize: '10px',
              fontFamily: 'Verdana, sans-serif'
            }
          }
        }]
      });
      // this.chartInstances.push({ chart: chart });
      this.chartInstances.push({ id: chartId, chart: chart});
    },

    //WORDCLOUD

    prepareWordCloudData(data, conceptName, canvasId, chartTitle) {
      const wordCloudData = [];

      if (data.children && data.children.length) {
          data.children.forEach(child => {
              if (child.word && child.fas) {
                  wordCloudData.push({
                      name: child.word_en || child.word,
                      weight: parseFloat(child.fas.toFixed(5))  // Use 'fas' as weight for word size
                  });
              }
          });
      }


      this.wordcloudChartData = wordCloudData;
        // Schedule the histogram to be rendered after the next update cycle
        const chartId = `chart-${[canvasId]}-${conceptName.replace(/\s+/g, '-')}-${this.concepts[canvasId] ? this.concepts[canvasId].length : 0}-main`;
  
        // Ensure concept list for canvasId
        if (!this.concepts[canvasId]) {
          this.concepts[canvasId] = [];
        }

        // Add new concept information
        this.concepts[canvasId].push({
          name: conceptName,
          chartId: chartId
        });
        this.$nextTick(() => {
            this.renderWordcloudChart(wordCloudData, chartId, conceptName, canvasId, chartTitle);
        });

    },

    //Chatgpt wordcloud

    prepareComparisonWordCloudData(comparisonData, conceptName, canvasId, chartTitle) {
      const compWordCloudData = [];

      // Extract words and their FAS from chatgpt_responses and their children
      if (comparisonData.chatgpt_responses && comparisonData.chatgpt_responses.length) {
        comparisonData.chatgpt_responses.forEach(response => {
          // Add the main word if FAS >= 0.15 after dividing by 100
          const mainFasValue = response.fas / 100;
          if (mainFasValue >= 0.01) {
            compWordCloudData.push({
              name: response.word.toUpperCase(),
              weight: mainFasValue
            });
          }
        });
      }

      this.comparisonWordcloudChartData = compWordCloudData;

      // Generate a unique chart ID for the comparison word cloud
      const chartId = `chart-${canvasId}-${conceptName.replace(/\s+/g, '-')}-${this.concepts[canvasId] ? this.concepts[canvasId].length : 0}-comp`;

      // Ensure concept list for canvasId
      if (!this.concepts[canvasId]) {
        this.concepts[canvasId] = [];
      }

      // Add new concept information
      this.concepts[canvasId].push({
        name: conceptName,
        chartId: chartId
      });

      // Render the word cloud chart
      this.$nextTick(() => {
        this.renderWordcloudChart(compWordCloudData, chartId, conceptName, canvasId, chartTitle);
      });
    },


    renderWordcloudChart(cloudData, chartId, conceptName, canvasId, chartTitle) {
      const chart = Highcharts.chart(chartId, {
            series: [{
                type: 'wordcloud',
                data: cloudData,
                minFontSize: 5,
                name: 'Frequency'
            }],
            title: {
            text: chartTitle,  
            align: 'center',  
            style: {
                fontSize: '15px',  
                fontWeight: 'bold', 
                color: '#1177ba' 
            }
            },
            tooltip: {
                pointFormat: 'Frequency: <b>{point.weight}</b>'
            }
        });
        this.chartInstances.push({ id: chartId, chart: chart });
    },

    // Table 
    prepareTableData(data, conceptName, canvasId, chartTitle) {
        const tableData = [];
        const nbResponses = data.nbResponses;

        data.children.forEach(node => {
            if (node.word && node.fas) {
                const responses = Math.round(node.fas * nbResponses);
                const percentage = (node.fas * 100).toFixed(2) + '%';

                tableData.push({
                    word: node.word_en || node.word,
                    responses: responses,
                    percentage: percentage
                });
            }
        });

        // Store the prepared table data in a state or a property for later use in rendering
        this.tableData = tableData;  // Assuming 'this.tableData' is where you keep it
        const chartId = `chart-${[canvasId]}-${conceptName.replace(/\s+/g, '-')}-${this.concepts[canvasId] ? this.concepts[canvasId].length : 0}-main`;
  
        // Ensure concept list for canvasId
        if (!this.concepts[canvasId]) {
          this.concepts[canvasId] = [];
        }

        // Add new concept information
        this.concepts[canvasId].push({
          name: conceptName,
          chartId: chartId
        });
        this.$nextTick(() => {
            this.renderTable(tableData, chartId, chartTitle);
        });

        // Optionally, you can invoke rendering right after data preparation if necessary
        
    },


    //chatgpt table

    prepareComparisonTableData(comparisonData, conceptName, canvasId, chartTitle) {
        const tableData = [];
        const nbResponses = comparisonData.nbResponses;  // Example value, replace it with actual data if needed

        // Iterate through the first-order words in the chatgpt_responses array
        if (comparisonData) {
            comparisonData.chatgpt_responses.forEach(node => {
                if (node.word && node.fas) {
                    const responses = Math.round((node.fas * nbResponses)/100);
                    const percentage = (node.fas).toFixed(2) + '%';

                    tableData.push({
                        word: node.word.toUpperCase(),
                        responses: responses,
                        percentage: percentage
                    });
                }
            });
        }

        // Limit to first 50 words if there are more than 50
        this.tableData = tableData;

        const chartId = `chart-${canvasId}-${conceptName.replace(/\s+/g, '-')}-${this.concepts[canvasId] ? this.concepts[canvasId].length : 0}-comp`;

        // Ensure concept list for canvasId
        if (!this.concepts[canvasId]) {
            this.concepts[canvasId] = [];
        }

        // Add new concept information
        this.concepts[canvasId].push({
            name: conceptName,
            chartId: chartId
        });

        this.$nextTick(() => {
            this.renderTable(tableData, chartId, chartTitle);
        });
    },


    renderTable(tableData, chartId, chartTitle) {
        const container = document.getElementById(chartId);

        // Clear the existing table content
        container.innerHTML = '';

        // Create the table structure
        let html = `
        <div style="width: 100%; margin-top:20px; margin-bottom:20px; border: 3px solid #ccc; box-sizing: border-box; display: flex; flex-direction: column; height: 100%;">
            <!-- Title Section -->
            <div style="text-align: center; padding: 10px; background-color: #f5f5f5; border-bottom: 1px solid #ccc; flex-shrink: 0;">
                <span style="font-size: 15px; font-weight: bold; color: #1177ba;">${chartTitle}</span>
            </div>
            <!-- Table Section -->
            <div style="overflow-y: auto; max-height: 500px; flex-grow: 1;">
                <table style="width: 100%; border-collapse: collapse;">
                    <thead style="position: sticky; top: 0; z-index: 1; background-color: #007bff;">
                        <tr>
                            <th style="font-size: 20px; padding: 15px; text-align: center; border-bottom: 1px solid #ddd; border-right: 1px solid #ddd; color: white">WORD</th>
                            <th style="font-size: 20px;padding: 15px; text-align: center; border-bottom: 1px solid #ddd; border-right: 1px solid #ddd; color: white">RESPONSES</th>
                            <th style="font-size: 20px;padding: 15px; text-align: center; border-bottom: 1px solid #ddd; color: white">PERCENTAGE</th>
                        </tr>
                    </thead>
                    <tbody>`;

        // Loop through tableData and populate rows
        tableData.forEach((item) => {
            html += `
                <tr>
                    <td style="padding: 12px; text-align: center; border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">${item.word}</td>
                    <td style="padding: 12px; text-align: center; border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">${item.responses}</td>
                    <td style="padding: 12px; text-align: center; border-bottom: 1px solid #ddd;">${item.percentage}</td>
                </tr>`;
        });

        html += `
                    </tbody>
                </table>
            </div>
        </div>`;

        // Set the container's innerHTML to the generated table
        container.innerHTML = html;
    },


    removeWheel: function ({asset_and_positioning, canvasId}) {
            this.assets_and_positionings.delete(asset_and_positioning);
            if (this.assets_and_positionings.size == 0) {
                this.empty = true;
            }
            this.removeConcept(asset_and_positioning.asset.display_name, canvasId);
    },
    removeConcept: function (conceptName, canvasId) {
        if (this.concepts[canvasId]) {
            // Filter out all concepts with the given name
            const conceptsToRemove = this.concepts[canvasId].filter(concept => concept.name === conceptName);

            // Iterate through the concepts that need to be removed
            conceptsToRemove.forEach(concept => {
                // Remove the chart associated with the concept
                this.removeChart(concept.chartId);
            });

            // Remove the concepts from the array
            this.concepts[canvasId] = this.concepts[canvasId].filter(concept => concept.name !== conceptName);

            // If no more concepts for this monitor, optionally clean up
            if (this.concepts[canvasId].length === 0) {
                delete this.concepts[canvasId];
            }
        }
    },

    removeChart: function (chartId) {
        // Implementation for removing the chart visualization
        // This might involve directly manipulating the DOM or using a chart library's API
        // let adjustedChartId = chartId;
    
        // if (chartId.endsWith('-main')) {
        //     adjustedChartId = `${chartId}-main`;
        // } else if (chartId.endsWith('-comp')) {
        //     adjustedChartId = `${chartId}-comp`;
        // }
        const idIndex = this.chartInstances.findIndex(c => c.id === chartId || c.id === chartId+'main' || c.id === chartId+'comp');

        if (idIndex !== -1) {
            // Check if the next element contains the chart object
            const chartObject = this.chartInstances[idIndex];

            if (chartObject && chartObject.chart) {
                // Destroy the chart
                chartObject.chart.destroy();
            }
            const chartObject_1 = this.chartInstances[idIndex+1];

            if (chartObject_1 && chartObject_1.chart) {
                // Destroy the chart
                chartObject_1.chart.destroy();
            }

            // Remove both the id and chart object from the array
            this.chartInstances.splice(idIndex, 2); // Remove id and chart
        } else {
            console.error("Chart not found: ", chartId);
        }

    },
  }
};
</script>



<style lang="scss" scoped>

.add-chart-btn {
  padding: 15px 20px;
  border-radius: 100%; 
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin: 20px auto; 
}

.canvas-grid {
  display: grid;
  grid-row-start: auto;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 3.3125rem;
  width: 100%;
  max-width: 100rem; 
  margin: auto;
}


.canvas-container {
  background-color: #ffffff77;

  width: 100%;
  height: 700px;
  border: 3px solid #ccc; 
  padding-top: 10px; 
  padding-left: 20px; 
  padding-right: 20px; 
  padding-bottom: 25px; 
  margin-left: 30px;
  display: flex;
  flex-direction: column; 
  
  margin: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
  transition: margin 0.25s;
}

.chart-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  grid-auto-rows: auto; /* Automatically adjust row height */
  gap: 1rem; /* Optional: space between columns */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.chart-container {
  background-color: #ffffff;
  border: 1px solid #ccc;
  overflow: hidden;
  height: 100%; 
  width: 100%; 
  box-sizing: border-box;
}

.status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.status-content {
    max-width: 80%;
    margin: auto;
}

.brand-status-text {
    font-size: 1.1em;
    line-height: 1.5;
    color: #333;
    margin-top: 20px;
    text-align: left;
}

.brand-status-text:first-child {
    text-align: center;
    font-weight: bold;
}

.status-path {
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
}

.status-path li {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 1.1em;
    color: #333;
}

.status-path li .fa-arrow-right {
    color: #007bff;
    margin-right: 10px;
}

.brand-status-text a {
    color: #007bff;
    text-decoration: none;
}

.brand-status-text a:hover {
    text-decoration: underline;
}
.delete-btn {

  display: flex;
  justify-content: end;
  padding-top: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 15px;
}

table {
    width: 50%;
    margin-left: 100px;
    max-height: 400px;
    overflow-y: auto;
    border-collapse: collapse;
}

th, td {
    padding: 28px;
    text-align: right;
    border: 1px solid #000000;
}

tr {
    background-color: #f2f2f2;
    text-align: center;
    border: 1px solid #000000;

}

tbody tr:hover {
    background-color: #f5f5f5;
}
.delete-dialog-card {
  min-width: 300px;
  max-width: 450px;  
  overflow-x: hidden;
  border-radius: 8px; 
}

.delete-dialog-content {
  padding: 16px; 
  display: flex;
  align-items: center;
  gap: 10px; 
}

.dialog-header {
  display: flex;
  align-items: center;
}

.dialog-text {
  color: #333; 
  font-size: 16px;  
  margin-left: 10px;  
}

.dialog-actions {
  justify-content: center;  
  padding: 8px 0px; 
}

.dialog-button {
  min-width: 50px;  
}
.q-icon {
  font-size: 24px;  
}
.chart-title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 2px;
}

</style>




