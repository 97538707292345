<template>
  <div class="subsc-pg-order">
    <div class="top-pg-hed">
      <h6>Payment Method</h6>
    </div>
    <div class="flx-tow-col">
      <div class="save-card-details-colm">
        <h5>Your saved card details</h5>
        <div class="tow-col-fx">
          <div class="flxlft">Card Details</div>
          <div class="flxrgt">Name</div>
        </div>

        <div class="tow-col-fx" v-for="payment_method in paymentMethodList" :key="payment_method">
          <div class="flxlft">
            <div class="border-box-bott" @click="togglePaymentMethod(payment_method.id)">
              <input class="form-check-input" type="radio" :v-model="selectedPaymentMethod" :value="payment_method.id"
                id="flexCheckDefault" />
              <label class="form-check-label" for="flexCheckDefault">
                {{ payment_method.card.brand }} Credit card
                <div class="lgt-color">
                  Credit card ending in ........{{ payment_method.card.last4 }}
                  <!-- <span class="card-img-span"
                    ><img src="img/card-img.png"
                  /></span> -->
                </div>
              </label>
            </div>
            <!-- <div class="evv-cod">
              <label>Enter CVV </label
              ><input type="password" placeholder="123" />
            </div> -->
            <label>Expiration Date </label>:
            {{ payment_method.card.exp_month }}/{{
              payment_method.card.exp_year
            }}
          </div>
          <div class="flxrgt">
            <div class="nametx">
              <span class="customer_name">{{ payment_method.customer }}</span>
              <div class="del_icon">
                <q-icon @click="deletePaymentMethod(payment_method.id)" class="trash-icon" size="20px"
                  name="eva-trash-2-outline" />
                <q-icon @click="updateFavoritePaymentMethod(payment_method.id)" class="star-icon" :class="{
                  'favorite-icon': payment_method.id == favoritePaymentMethod,
                }" size="20px" name="eva-star-outline" />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="img-crad-sec">
          <h5>Add another card</h5>
          <div class="botom-card-visa"><img src="img/cardsvisa-img.png" /></div>
        </div> -->
      </div>
      <div class="outer-bx-clm">
        <div class="gry-sml-rgt-bx">
          <div class="top-pg-hed">
            <h6>Order Summary</h6>
          </div>
          <div class="sub-head">
            <spna class="lft-tx-com">Subtotal ($)</spna>
            <spna class="rgt-tx-com">$ {{ selectedLicense.amount }}</spna>
          </div>
          <div class="sub-head">
            <spna class="lft-tx-com">Mindcoins</spna>
            <spna class="rgt-tx-com">{{ selectedLicense.mindcoins }}</spna>
          </div>
          <div class="ext-sml-txt">Subtotal includes all applicable taxes</div>
          <div class="mid-sml-txt">
            You’re getting the best price we have got
          </div>
          <div class="seq-pay">
            <img src="../../assets/lock-icon.png" />Secure Payment
          </div>
        </div>
        <div class="btn-col full-width">
          <button @click="handleSubmit" v-bind:class="getClass()" :disabled="!selectedPaymentMethod">
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { alert_error, alert_success } from '@/helpers/alert_helper';

export default {
  name: 'SavedCards',
  props: {
    price: {
      type: String,
    },
    selectedLicense: {
      type: String,
    },
    selectedPaymentMethod: {
      type: String,
    },
  },
  emits: ['paymentMethodListEmpty', 'paymentMethodChange'],
  data: function () {
    return {
      paymentMethodList: [],
      selectedPaymentMethod: null,
      favoritePaymentMethod: null,
      processingPayment: false,
    };
  },
  mounted: async function () {
    let default_payment_method = '';
    await axios
      .post('/api/cas/org/default_payment_method/get', {
        org_id: this.$store.state.current_org.id,
      })
      .then((response) => (default_payment_method = response.data));
    this.selectedPaymentMethod = default_payment_method;
    this.favoritePaymentMethod = default_payment_method;
    this.$emit('paymentMethodChange', default_payment_method);

    let payment_methods;
    await axios
      .post('/payment_methods/list', {
        customer_id: this.$store.state.current_org.stripe_customer_id,
      })
      .then((response) => (payment_methods = response.data));

    this.paymentMethodList = payment_methods.data;
    if (this.paymentMethodList.length == 0) {
      this.$emit('paymentMethodListEmpty');
    }
  },
  methods: {
    getClass: function () {
      return {
        btn3: this.selectedPaymentMethod,
        'disabled-btn': !this.selectedPaymentMethod,
      };
    },
    togglePaymentMethod: function (payment_method_id) {
      this.selectedPaymentMethod = payment_method_id;
      this.$emit('paymentMethodChange', payment_method_id);
    },
    deletePaymentMethod: async function (payment_method_id, vue_vm = this) {
      await axios.post('/payment_methods/delete', {
        customer_id: this.$store.state.current_org.stripe_customer_id,
        payment_method_id: payment_method_id,
        org_id: this.$store.state.current_org.id,
      });
      this.paymentMethodList = this.paymentMethodList.filter(
        (method) => method.id != payment_method_id
      );
      if (this.paymentMethodList.length == 0) {
        this.$emit('paymentMethodListEmpty');
      }
      await alert_success(
        'Success',
        vue_vm.$t('message.payment_method_deleted')
      );
    },
    updateFavoritePaymentMethod: async function (
      payment_method_id,
      vue_vm = this
    ) {
      try {
        let fav = await axios.post(
          '/api/cas/org/default_payment_method/update',
          {
            org_id: this.$store.state.current_org.id,
            payment_method_id: payment_method_id,
          }
        );
        this.favoritePaymentMethod = payment_method_id;
        await alert_success(
          'Success',
          vue_vm.$t('message.payment_method_favourited')
        );
      } catch (error) {
        console.log(error);
      }
    },

    handleSubmit: async function (e, vue_vm = this) {
      //   e.preventDefault();0
      this.processingPayment = true;
      // If paymentMethodListEmpty is false, then we assume that user checked out
      // using the already existing payment methods.
      try {
        var response = await axios.post('/payment/upgrade', {
          org_id: this.$store.state.current_org.id,
          license_id: {
            id: this.$props.selectedLicense.id,
          },
          payment_method: this.$props.selectedPaymentMethod,
          amount: this.selectedLicense.amount,
          mindcoins: this.selectedLicense.mindcoins,
          pay_as_you_go: this.selectedLicense.pay_as_you_go,
        });
        this.processingPayment = false;
        await this.$store.dispatch('load_user_data');
        this.$router.push({
          name: 'Payment Success',
          params: {
            mindcoins: this.$props.selectedLicense.mindcoins,
          },
        });
      } catch (err) {
        this.processingPayment = false;
        await alert_error(this.$t('message.payment_unexpected_fail'));
      }
    },
  },
};
</script>

<style scoped>
.form-check-label {
  text-transform: capitalize;
}

.btn-col.full-width button.disabled-btn {
  width: 100%;
}
</style>
