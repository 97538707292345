<template>
  <div>
    <main>
      <landing-page></landing-page>
      <section style="display: flex; flex-direction: column;" class="passions-header-box" v-if="showPage">
        <h3 style="display: flex; width: 100%;">
          <span class="info-tooltip" @click="showTip">
            Now select & rank the top 3 values you share with your brand ambassadors
            <font-awesome-icon :icon="['fas', 'info-circle']" style="color: #7878E9; margin-left: 5px; cursor: pointer;" @click="showTip"/>
            <div class="tooptip-text">
              {{ this.$t('message.values_tip_tooltip_content') }}
            </div>
          </span> 
        </h3>
        <div style="display: flex; justify-content: flex-start; width: 90%; margin-top: 15px; gap:20px;">
          <div style="width: 65%;"></div>
          <div style="width: 35%;">
            <div class="passions-search-bar">
              <div class="form-outline" style="width: 100%;">
                <input type="search" id="form1" class="form-control"
                  placeholder="Search a value" aria-label="Search" v-model="search_text"
                  @change="handleSearch" />
              </div>
              <span style="margin-top: 10px; margin-bottom: 10px;">Or click on an image to select it</span>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: flex-start; width: 90%; gap:20px;">
          <div style="width: 65%;">
            <div class="select-images-box">
              <div class="selectimg">
                <span><font-awesome-icon :icon="['fas', 'trophy']" style="color: #808080;" /></span>
                <div class="select-img-passion">
                  <span class="close-btncol" @click="handleClose('two')" style="z-index: 100;">X</span>
                  <img v-if="passions.second_passion" v-bind:src="`${all_passions.find((el) => el.id === passions.second_passion)
                    .urls.thumb_large
                    }`
                    " />
                  <div v-else>
                    <label class="upload-icon">
                      <q-icon v-if="!imageUrl2" name="cloud_upload" size="6vw" class="icon" />
                      <input :disabled="isUploadedBefore(imageUrl2)" class="file-input" type="file" ref="fileInput2" style="display: none"
                        @change="handleFileChange2">
                      <img v-if="imageUrl2" :src="imageUrl2" class="uploaded-image">
                    </label>
                  </div>
                </div>
              </div>
              <div class="selectimg">
                <span><font-awesome-icon :icon="['fas', 'trophy']" style="color: #FFD43B;" /></span>
                <div class="select-img-passion">
                  <span class="close-btncol" @click="handleClose('one')" style="z-index: 100;">X</span>
                  <img v-if="passions.first_passion" v-bind:src="`${all_passions.find((el) => el.id === passions.first_passion)
                    .urls.thumb_large
                    }`
                    " />
                  <div v-else>
                    <label class="upload-icon">
                      <q-icon v-if="!imageUrl1" name="cloud_upload" size="6vw" class="icon" />
                      <input  :disabled="isUploadedBefore(imageUrl1)" class="file-input" type="file" ref="fileInput1" style="display: none"
                        @change="handleFileChange1">
                      <img v-if="imageUrl1" :src="imageUrl1" class="uploaded-image">
                    </label>
                  </div>
                </div>
              </div>
              <div class="selectimg">
                <span><font-awesome-icon :icon="['fas', 'trophy']" style="color: #CD7F32;" /></span>
                <div class="select-img-passion">
                  <span class="close-btncol" @click="handleClose('three')" style="z-index: 100;">X</span>
                  <img v-if="passions.third_passion" v-bind:src="`${all_passions.find((el) => el.id === passions.third_passion)
                    .urls.thumb_large
                    }`
                    " />
                  <div v-else>
                    <label class="upload-icon">
                      <q-icon v-if="!imageUrl3" name="cloud_upload" size="6vw" class="icon" />
                      <input :disabled="isUploadedBefore(imageUrl3)" class="file-input" type="file" ref="fileInput3" style="display: none"
                        @change="handleFileChange3">
                      <img v-if="imageUrl3" :src="imageUrl3" class="uploaded-image">
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 35%;"> 
            <div class="images-ur-brand">
              <div class="select-images-box">
                <div class="images-gallery passions-gallery scroll" @scroll="handleScroll"
                  v-if="!loading && list.length">
                  <div class="brnd-img-bx cursor-p" v-for="items in list" v-bind:key="items">
                    <img v-bind:src="items.urls.thumb_medium" @click="handleSelectImg(items.id)" /><img class="bigimg"
                      src="../../assets/icon8.png" @click="handleZoomAsset(items)" />
                  </div>
                </div>
                <div class="images-gallery passions-gallery" v-else-if="loading">
                  <loading-spinner></loading-spinner>
                </div>
                <div v-if="!list.length && !loading" style="display: flex; flex-direction: column; justify-content: space-between; align-items: center; height: 100%; width: 100%; padding: 1%;">
                  <div class="nodata">
                    <strong>No image found!</strong>
                    <br>Upload you own value images by clicking on the tiles 
                    <br>Or have AI generate one
                  </div>
                  <div>
                    <button class="btngrey" @click="generateBrandValueImage">Generate</button>
                  </div>
                  <div style="display: flex; align-items: center; width: 50%; height: 50%;">
                    <img v-if="this.aiImage !== ''" :src="this.aiImageURL" style="width: 100%; height: 100%; padding: 2%; cursor: pointer;" @click="handleAiImageClick" ref="fileInputAI">
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="bottom-line">
        <div class="btn-col">
          <button class="btngrey" @click="handleBack" style="margin-right: 2vw">
            Back
          </button>
          <button v-bind:class="getClass()" :disabled="(!this.passions.first_passion && !this.imageUrl1) ||
            (!this.passions.second_passion && !this.imageUrl2) ||
            (!this.passions.third_passion && !this.imageUrl3)
            " @click="handleConfirm">
            To step 3
          </button>
        </div>
      </div>
      </section>
      <OnboardingTipPopup 
        v-model="tipVisible" 
        :hasAccordion="true"
        :hasFooter="true"
        :dialogTitle="this.$t('message.values_tip_title')"
        :dialogContent="this.$t('message.values_tip_content')"
        :dialogExtendedContent="this.$t('message.values_tip_extended')"
        :footerContent="this.$t('message.values_tip_footer')"
        :onboardingPhase="onboardingPhase"
        @update:tipVisible="tipVisible = $event">
    </OnboardingTipPopup>
    </main>
    <loading-app-wide v-if="display_loading_overlay" />
    <full-preview-passions :asset="asset" ref="fullscreen_preview">
    </full-preview-passions>
  </div>
</template>

<script>
import axios from 'axios';
import FullPreviewPassions from './common/FullPreviewPassions.vue';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import { alert_error, alert_reminding, genericDialog } from '@/helpers/alert_helper.js';
import LandingPage from './LandingPage.vue';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
import OnboardingTipPopup from './common/OnboardingTipPopup.vue';

export default {
  name: 'Passions',
  components: {
    FullPreviewPassions,
    LoadingSpinner,
    LandingPage,
    LoadingAppWide,
    OnboardingTipPopup
  },
  props: {
    value: String,
    pageText: String,
  },
  data() {
    return {
      tipVisible: false,
      onboardingPhase:"values",
      image: '',
      imageUrl1: null,
      imageUrl2: null,
      imageUrl3: null,
      show: false,
      passions: {
        first_passion: '',
        second_passion: '',
        third_passion: '',
      },
      list: {},
      asset: {},
      search_text: '',
      loading: false,
      all_passions: [],
      showPage: false,
      pageText: localStorage.getItem('pageText'),
      finish_status: localStorage.getItem('finish_status'),
      pageName: localStorage.getItem('pageName'),
      display_loading_overlay: false,
      aiText: '',
      aiImage: '',
      aiImageURL: '',
    };
  },
  mounted: async function () {
    const res = await axios.get('/api/cas/assets/s3_prefix');
    this.image = res.data.s3_bucket_url;

    document.getElementById('mindspeller').style.overflow = 'auto';

    const payload = {
      image: 'image',
    };
    this.loading = true;

    try {
      const result = await axios.get(
        '/api/cas/users/get_all_passions',
        payload
      );

      if (result.status === 200) {
        this.loading = false;
        this.list = result.data;
        this.all_passions = result.data;
      }
      const passions = await axios.get('/api/cas/users/get_passions');

      if (typeof (passions.data.first_passion) === 'object') {
        this.passions.first_passion = passions.data.first_passion.id;
      } else {
        this.imageUrl1 = this.image + passions.data.first_passion;
      }

      if (typeof (passions.data.second_passion) === 'object') {
        this.passions.second_passion = passions.data.second_passion.id;
      } else {
        this.imageUrl2 = this.image + passions.data.second_passion;
      }

      if (typeof (passions.data.third_passion) === 'object') {
        this.passions.third_passion = passions.data.third_passion.id;
      } else {
        this.imageUrl3 = this.image + passions.data.third_passion;
      }

    } catch (error) {
      console.log(error);
    }
    window.scrollTo(0, 0);
    this.showPage = true;
  },
  methods: {
    // to check if the asset is uploaded already once
    isUploadedBefore(imageUrl) {
      if(!imageUrl) {
        return false
      }else{
        return imageUrl.startsWith(this.image);
      }
    },
    generateBrandValueImage: async function () {
      if (this.search_text) {
        const dialog_options = {
        title: this.$t("message.generate_brand_story_title"),
        text: this.$t("message.generate_brand_value_image"),
        confirmButtonText: this.$t("message.yes"),
        cancelButtonText: this.$t("message.cancel"),
      };
      const dialog_result = await genericDialog(dialog_options);
      if (dialog_result.isConfirmed) {
        try {
          this.loading = true;
          const keywords = this.search_text.split(" ")
          let lowercaseKeywords = keywords.map(keyword => keyword.toLowerCase());
          const payload = {'keywords': lowercaseKeywords};
          const response = await axios.post('/api/cas/path_finder/generate_openai_brand_value_image', payload);
          this.aiText = response.data.story;
          this.aiImage = response.data.image;
          this.aiImageURL = response.data.imageURL;
          this.$store.dispatch('get_mindcoin_balance');
        } catch (error) {
          console.log(error)
          alert_error(this.$t('message.general_server_error'));
        }
        this.loading = false;
      }
    }
    },
    showTip(){
      this.tipVisible = true;
    },
    
    handleAiImageClick() {
      if (this.aiImage) {
        if (!this.imageUrl1 || this.imageUrl1== '')
          {
            this.imageUrl1 = this.aiImage;
          }
        else if (!this.imageUrl2 || this.imageUrl2 == ''){
          this.imageUrl2 = this.aiImage;
        }
        else if (!this.imageUrl3 || this.imageUrl3 == ''){
          this.imageUrl3 = this.aiImage;
        }
      }
    },

    // handle upload passion image
    handleFileChange1(event) {
      const file = event.target.files[0];
      if (file && this.isValidFileType(file.type)) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageUrl1 = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert_reminding("Invalid file type. Please select a JPEG, JPG, or PNG image.");
      }
    },

    handleFileChange2(event) {
      const file = event.target.files[0];
      if (file && this.isValidFileType(file.type)) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageUrl2 = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert_reminding("Invalid file type. Please select a JPEG, JPG, or PNG image.");
      }
    },

    handleFileChange3(event) {
      const file = event.target.files[0];
      if (file && this.isValidFileType(file.type)) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageUrl3 = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert_reminding("Invalid file type. Please select a JPEG, JPG, or PNG image.");
      }
    },

    isValidFileType(fileType) {
      return fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg';
    },

    handleBack() {
      localStorage.setItem('pageName', 'passions');
      return this.$router.push({
        name: 'Archetypes',
      });
    },

    handleNext() {
      window.addEventListener(
        'load',
        this.$router.push({
          name: 'Brand Value',
          params: {
            value: 'brand',
          },
        })
      );
    },

    handleZoomAsset(value) {
      this.asset = value;
      this.$refs['fullscreen_preview'].open();
    },

    getClass() {
      if (
        (this.passions.first_passion || this.imageUrl1) &&
        (this.passions.second_passion || this.imageUrl2) &&
        (this.passions.third_passion || this.imageUrl3)
      ) {
        return 'btn3';
      } else {
        return 'disabled-btn';
      }
    },

    handleConfirm: async function () {
      this.display_loading_overlay = true;
      try {
        const archetype = {
          first_passion: this.passions.first_passion || this.imageUrl1,
          second_passion: this.passions.second_passion || this.imageUrl2,
          third_passion: this.passions.third_passion || this.imageUrl3,
        };
        if (
          (this.passions.first_passion || this.imageUrl1) &&
          (this.passions.second_passion || this.imageUrl2) &&
          (this.passions.third_passion || this.imageUrl3)
        ) {

          const data = await axios.post(
            '/api/cas/users/update_passions',
            archetype
          );

          if (data.status === 200) {
            if ((this.finish_status && !this.finish_status["passion"]) || !this.pageText) {
              const step_update = await axios.post(
                `/api/cas/users/update_onboarding`,
                {
                  completed_step: 2,
                }
              );
            }
            await this.$store.dispatch('load_user_data')
            this.$router.push({name: 'Brand Value'});
          }
          this.display_loading_overlay = false;
        } else {
          this.display_loading_overlay = false;
          return;
        }
      } catch (error) {
        this.display_loading_overlay = false;
        alert_error(
          error.message
            ? error.message
            : this.$t('message.general_server_error')
        );
      }
    },

    clearList() {
      this.list = [];
    },

    handleSearch: async function () {
      this.loading = true;
      this.clearList();
      this.aiImage = '';
      try {
        const search_result = await axios.post(
          '/api/cas/assets/search_passions',
          {
            search_string: this.search_text,
          }
        );
        if (search_result.status === 200) {
          this.loading = false;
          this.list = search_result.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    handleSelectImg: function (value) {
      if (
        this.passions.first_passion !== value &&
        this.passions.second_passion !== value &&
        this.passions.third_passion !== value
      ) {
        if (!this.passions.first_passion && !this.imageUrl1) {
          this.passions.first_passion = value;
          this.imageUrl1 = '';
        } else if (!this.passions.second_passion && !this.imageUrl2) {
          this.passions.second_passion = value;
          this.imageUrl2 = '';
        } else if (!this.passions.third_passion && !this.imageUrl3) {
          this.passions.third_passion = value;
          this.imageUrl3 = '';
        }
      }
    },

    handleClose(value) {
      if (value === 'one') {
        this.passions.first_passion = '';
        this.imageUrl1 = '';
      } else if (value === 'two') {
        this.passions.second_passion = '';
        this.imageUrl2 = '';
      } else if (value === 'three') {
        this.passions.third_passion = '';
        this.imageUrl3 = '';
      }
    },

  },
};
</script>

<style scoped>
::placeholder {
  font-size: 18px;
}

.btn-col {
  text-align: center !important;
}

.images-gallery.passions-gallery {
  height: 378px;
  position: relative;
}

.nodata {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.select-img-passion {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 19%;
  display: flex;
  background-color: #F7F9FA;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 31%);
  border-radius: 1vw;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  border: 1px #ccc solid;
  position: relative;
}

.upload-icon {
  cursor: pointer;
}

.icon {
  cursor: pointer;
  transition: color 0.3s;
}

.icon:hover {
  color: #1F1FFF;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.passions-header-box {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.passions-header-box h3 {
    font-weight: 700;
    font-size: 1.2vw;
    line-height: 2vw;
    width: 90%;
    margin-top: 15px;
}

.passions-search-bar{
  width:100%;
  display: flex;
  flex-direction: column;
  gap:10px;
  justify-content: space-evenly;
  align-items: center;
}
.passions-search-bar .form-control {
    border-color: #0A00FF;
    background-image: url(../../assets/search-icon.png);
    background-position: 0.5vw center;
    background-repeat: no-repeat;
    padding: 0.5vw 2.2vw;
    font-size: 1.2vw;
    line-height: 1.6vw;
    background-size: 1.2vw;
    border: 0.1vw solid #0A00FF;
    border-radius: 0.6vw;
    width:100%;
}
.info-tooltip {
    transform: none;
    background: no-repeat;
    vertical-align: text-bottom;
    position: relative;
    cursor: pointer;
}

.info-tooltip:hover .tooptip-text {
    display: block
}
.tooptip-text {
    position: absolute;
    font-size: 1.1vw;
    line-height: 1.3vw;
    font-weight: normal;
    background: #7878E9;
    color: #fff;
    padding: 1vw;
    border-radius: 1vw;
    text-align: justify;
    left: calc(100% + 25px);
    top: -1vw;
    display: none;
    min-height: 150px;
    width: 400px;
    z-index: 999999;
}
</style>
