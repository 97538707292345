<template>
  <div>
    <h4 class="explheading">{{ $t('message.inspirational_themes') }}</h4>
    <div class="chekbx-text-p">{{ $t('message.theme_ranking') }}</div>
    <div class="checkbox-asid scroll">
      <word-checkbox v-for="d in data" :ref="refFn" :key="`${d[0]}-${counter}`" :word="d[0]"
        @wordSelected="onWordSelected" @wordUnselected="onWordUnselected">
      </word-checkbox>
    </div>
  </div>
</template>

<script>
import WordCheckbox from '@/components/common/WordCheckbox.vue';


export default {
  components: { WordCheckbox },
  emits: ['wordSelected', 'wordUnselected'],
  data: function () {
    return {
      counter: 0,
      data: {},
      wordCheckboxes: new Array(),
    };
  },
  methods: {
    setData: function (data) {
      this.counter = this.counter + 1;
      this.data = data;
    },
    clearData: function () {
      this.counter = 0;
      this.data = {};
      this.wordCheckboxes.length = 0;
    },
    toggleWord: function (word) {
      if (this.wordCheckboxes.includes(word)) {
        this.wordCheckboxes = this.wordCheckboxes.filter(item => item !== word);
      }
      else {
        this.$emit('wordSelected', word);
        this.wordCheckboxes.push(word)
      }
      // const wordCheckbox = this.wordCheckboxes.find((w) => w.word === word);
      // // if the word can't be found in the word list we should do nothing
      // if (!wordCheckbox) {
      //   return;
      // }
      // if (wordCheckbox.checked) {
      //   wordCheckbox.externalUnselect();
      // } else {
      //   wordCheckbox.externalSelect();
      // }
    },
    onWordSelected: function (word) {
      this.$emit('wordSelected', word);
    },
    onWordUnselected: function (word) {
      this.$emit('wordUnselected', word);
    },
    refFn: function (wordCheckbox) {
      this.wordCheckboxes.push(wordCheckbox);
    },
    getSelectedWords: function () {
      let words = [];
      this.wordCheckboxes.forEach(wordCheckbox => {
        if (wordCheckbox.checked) {
          words.push(wordCheckbox.word)
        }
      })
      return words;
    },
  },
};
</script>
<style lang="scss" scoped>
.main-sidebar-tmp {
  padding: 20px 50px 0 75px;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.scrollable-inner {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.scrollable-inner label {
  flex-basis: 100%;
  margin-top: 30px;
}

.scrollable-inner label span {
  padding-left: 10px;
}

.word-list {
  padding: 0;
}

.explheading {
  font-size: 1.1vw;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.5vw;
  line-height: 1.5vw;
}

.left-checkbx-div .checkbox-asid .form-check-inline {
  padding-left: 0.5vw;
}

.checkbox-asid.scroll {
  height: 280px;
}
</style>
