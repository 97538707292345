<template>
  <div class="containerName">
    <div :id="containerName" class="containerName"></div>
  </div>
</template>
  
  <script>
  export default {
    name: 'BellCurveChart',
    props: {
      containerName: {
        type: String,
        required: true
      },
      min: {
        type: Number,
        required: true
      },
      max: {
        type: Number,
        required: true
      },
      stdDeviation: {
        type: Number,
        required: true
      },
      mean: {
        type: Number,
        required: true
      },
      dataPoint: {
        type: Number,
        required: true
      }
    },
    methods: {
      renderBellCurveChart() {
        const containerName = this.containerName;
        const dataPoint = this.dataPoint;
        const min = this.min;
        const max = this.max;
        const mean = this.mean;
        const stdDeviation = this.stdDeviation;
  
        const generateData = () => {
          const data = [];
          for (let i = min; i <= max; i += 0.1) {
            data.push([i, this.normalDistribution(i)]);
          }
          return data;
        };
  
        Highcharts.chart(containerName, {
          title: {
            text: 'Clarity Score Distribution'
          },
          tooltip: {
            enabled: false
          },
          exporting: {enabled: false},
          xAxis: [{
            title: { visible: false },
            min: min,
            max: max
          }],
          yAxis: [{
            visible: false
          }],
          series: [
          {
            name: 'Distribution',
            type: 'scatter',
            data: generateData(),
            marker: {
              radius: 1.5
            }
          }, 
          {
            name: 'Concept Position',
            type: 'scatter',
            data: [[dataPoint, this.normalDistribution(dataPoint)]],
            marker: {
              fillColor: 'red',
              lineWidth: 2,
              lineColor: 'black',
              radius: 6
            }
          }]
        });
      },
      normalDistribution(x) {
        const mean = this.mean;
        const stdDev = this.stdDeviation;
        return (1 / (stdDev * Math.sqrt(2 * Math.PI))) * Math.exp(-((x - mean) ** 2) / (2 * stdDev ** 2));
      }
    },
    mounted() {
      this.renderBellCurveChart();
    }
  };
  </script>
  
  <style scoped>
  .containerName {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  </style>
  