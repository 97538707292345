<template>
  <div class="wt-box-col active-add-img">
    <div class="col-md-12 choose-libra">
      <multiple-ia-picker
        ref="ia-picker"
        :showFrameworkSelector="false"
        @wordSelected="onIaChanged"
        @wordUnselected="onIaChanged"
        @wordRemoved="onIaChanged"
        :isLabelRemoved="false"
      >
      </multiple-ia-picker>
    </div>
  </div>

  <div class="divdr-bx"  v-if="data.length && data['words'].length">or</div>

  <div class="assets-col-div" v-if="data.length && data['words'].length">
    <h5>Choose from recently used keywords</h5>
    <h6>Word</h6>
   <div class="checkbox-asid" v-if="data">
    <div
      class="form-check form-check-inline"
      v-for="items in data['words']"
      v-bind:key="items.id"
    >
      <input
        class="form-check-input"
        type="checkbox"
        id="inlineCheckbox1"
        v-model="items.id"
      />
      <label class="form-check-label" for="inlineCheckbox1"
        >{{ items.word.word }}
      </label>
    </div>
  </div>
  </div>
</template>

<script>
import MultipleIaPicker from '@/components/target_pickers/sidebar_pickers/MultipleIaPicker.vue';
import Tooltip from '@/components/common/Tooltip.vue';
import axios from 'axios';

export default {
  components: {
    MultipleIaPicker,
    Tooltip,
  },
  emits: ['generate', 'reset'],
  data: function() {
    return {
      selected_ia_count: 0,
      data: [],
      image: '',
      video: '',
      objectFit: 'cover',
    };
  },
   mounted: async function() {
    const data = await axios.get('/api/cas/assets/get_last_used');

    if (data.status === 200) {
      this.data = data.data;
    }
  },
  watch: {
    selected_ia_count() {
      this.checkGenerate();
    }
  },
  methods: {
    reset: function() {
      this.$refs['ia-picker'].reset();
      this.selected_ia_count = 0;
    },
    checkGenerate() {
      if (this.selected_ia_count >= 3) {
        this.generate();
      }
      else {
        this.$emit('reset');
      }
    },
    generate: function() {
      const selected_ias = this.$refs['ia-picker'].intended_associations;
      this.$emit('generate', selected_ias);
    },
    /*
        Whenever the list of selected IAs changes the sidebar needs to figure
        out if clicking the generate button is allowed.
        */
    onIaChanged: function() {
      this.selected_ia_count = this.$refs[
        'ia-picker'
      ].intended_associations.size;
    },
  },
};
</script>

<style scoped>
.choose-libra label {
    color: #000!important;
    text-align: center!important;
    
}
</style>