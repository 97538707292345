const association_wheels_translation = {
    en: {
        message: {
            aw_tooltip:
                "You can add multiple Association Wheels. Look for non-category" +
                " related associations in the long tail by hovering over the wheel " +
                "segments. They are the 'Points of Interest' surrounding your Asset. Color" +
                " the most emotionally differentiating associations your Asset already has" +
                " today. These represent your Brand DNA. Now look for semantic connections" +
                " in the Association Wheel of your Intended Association to see if you can " +
                " spot some Points of Interest surrounding this candidate " +
                "association for rebranding purposes.",
            aw_tip:
                "Tip: Color the non-category related associations (left mouse click) to " +
                "compare with the wheel of your Desired Association (e.g. 'Volvo' with " +
                "'Safe').",
            select_an_asset: 'Add concept from My Library',
            select_a_word_instead: 'Add a Mindspeller dictionary word',
            font_size: 'Font size',
            limit_wheel_size: 'Limit wheel size',
            apply_color_scheme: 'Apply color scheme',
            select_a_color: 'Please select a color',
            hide_x_percent_associations: 'Hide {percent}% of the associations',
            stability: "Stability",
            number_of_responses: "Number of responses",
            brand_experience: "Brand experience",
            aw_instructions: "Association Wheels instructions",
        },
    },
    nl: {
        message: {
            aw_tooltip: 
                "Je kan meerdere Association Wheels toevoegen. Zoek naar niet-" +
                "categorie gerelateerde associaties in de lange staart door over de " +
                "wielsegmenten te zweven. Dit zijn de 'Points of Interest' rond je asset. " +
                "Kleur de meest emotioneel onderscheidende associaties die uw Asset " +
                "vandaag al heeft. Deze maken deel uit van uw merk-DNA. Zoek nu naar " +
                "semantische verbindingen in het Associatiewiel van jouw Gewenste " +
                "Associatie om te zien of er al raakvlakken zijn of aanknopingspunten voor" +
                " je herpositionering strategie.",
            aw_tip:
                "Tip: Kleur de niet-categorie gerelateerde associaties (linker muisklik) " +
                "om te vergelijken met het wiel van uw Gewenste Associatie (bijv. 'Volvo' " +
                "met 'Veilig').",
            select_an_asset: 'Voeg concept toe',
            select_a_word_instead: 'Voeg een woord uit het Mindspeller woordenboek toe',
            font_size: 'Font grootte',
            limit_wheel_size: 'Beperk wiel grootte',
            apply_color_scheme: 'Pas kleur schema toe',
            select_a_color: 'Selecteer een kleur',
            hide_x_percent_associations: 'Verberg {percent}% van de associaties',
            stability: "Stabiliteit",
            number_of_responses: "Aantal responses",
            brand_experience: "Merk ervaring",
            aw_instructions: "Association Wheels instructies",
        },
    }
};

export { association_wheels_translation };
