<template>
<div id="tropicana-graph" class="application-graph"></div>
</template>

<script>
import axios from "axios";
import {
    alert_error
} from '@/helpers/alert_helper.js';
import TropicanaChart from './tropicana.js';

export default {
    methods: {
        load: async function (first_asset, second_asset) {
            const payload = {
                "asset_1": first_asset,
                "asset_2": second_asset,
            };
            try {
                const response = await axios.post('/competitive_profiler/analyze', payload);
                const chart = new TropicanaChart(
                    "tropicana-graph",
                    response.data.slice(0, 30)
                );
                return response;
            } catch (e) {
                if (e.response.status === 403) {
                    alert_error(this.$t('message.no_access_to_items'));
                } else {
                    alert_error(this.$t('message.general_server_error'));
                }
            }
        },
        exportChart: function () {
            const svg = document.querySelector("#tropicana-graph svg");
            this.$exportD3.saveSvgAsPng(svg, "competitive profiler.png", {
                scale: 2
            });
        },
    }
}
</script>

<style scoped>
text {
    font-size: 11px !important;
    font-family: inherit !important;
}

#tropicana-graph {
    width: 96%;
    height: 100%;
}

.application-graph svg {
    width: 101% !important;
}

svg {
    width: 62vw !important;
}

/* .tick text {
    font-size: 15px !important;
    font-family: inherit !important;
} */
</style>
