export function login(jwt_token, jwt_refresh_token) {
  localStorage.setItem('jwt_token', jwt_token);
  localStorage.setItem('jwt_refresh_token', jwt_refresh_token);
}

export function logout(jwt_token) {
  localStorage.removeItem('jwt_token');
  localStorage.removeItem('jwt_refresh_token');
  localStorage.removeItem('pageText');
  localStorage.removeItem('pageName');
}

export function isLoggedIn() {
  return localStorage.getItem("jwt_token") !== null;
}
