<template>
    <div v-bind:id="containerName" style="width: 100%; height: 100%;"></div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
export default {
  name: "ConceptLifecycle",
  props: {
    lifecycle: {
      type: Number,
      required: true
    },
    lightVersion: {
      type: Boolean,
      required: true
    },
    inverted: {
      type: Boolean,
      required: false
    },
    showTitle: {
      type: Boolean,
      required: false
    }
  },
  data: function () {
    return {
      colors: [],
      containerName: "container-"+uuidv4(),
    }
  },
  computed: {
    dataLabels() {
      if (this.lightVersion === true) {
        return {
          enabled: false,
        };
      } else {
        return {
          connectorWidth: 1,
          borderRadius: 3,
          position: 'center',
          shadow: true,
          distance: 100,
          useHTML: true,
          formatter: function () {
            return '<span style="display: inline-block; white-space: nowrap; word-wrap: break-word;"><b>' + this.point.name + '</b><br> ' + this.point.description+'</span>';
          }
        };
      }
    },
    chartTooltip() {
      if (this.lightVersion === true) {
        return {
          formatter: function () {
            return '<b>' + this.point.name + '</b><br>' +
              this.point.description;
          },
        };
      } else {
        return {
          enabled: false
        };
      }
    }
  },
  watch: {
    lifecycle(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.prepareChart();
        this.renderChart();
      }
    },
    lightVersion(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.prepareChart();
        this.renderChart();
      }
    },
  },
  mounted() {
    this.prepareChart();
    this.renderChart();
  },
  methods: {
    prepareChart(){
      switch (this.lifecycle) {
        case 0:
          this.colors = ["#7878E9","#D3D3D3","#D3D3D3","#D3D3D3","#D3D3D3"];
          break;
        case 1:
          this.colors = ["#D3D3D3","#7878E9","#D3D3D3","#D3D3D3","#D3D3D3"];
          break;
        case 2:
          this.colors = ["#D3D3D3","#D3D3D3","#7878E9","#D3D3D3","#D3D3D3"];
          break;
        case 3:
          this.colors = ["#D3D3D3","#D3D3D3","#D3D3D3","#7878E9","#D3D3D3"];
          break;
        default: 
          this.colors = ["#D3D3D3","#D3D3D3","#D3D3D3","#D3D3D3","#7878E9"];
      }
    },
    renderChart() {
      const name = this.containerName
      const colors = this.colors
      const tooltip = this.chartTooltip
      const dataLabels = this.dataLabels
      Highcharts.chart(name, {
        chart: {
          type: "timeline",
          margin: [20, 40, 20, 40],
          inverted: this.inverted
        },
        title: {
          text: this.showTitle === true? "Concept Lifecycle": "",
          align: "center"
        },
        xAxis: {
          visible: false
        },
        yAxis: {
          visible: false
        },
        legend: {
          enabled: false
        },
        colors: colors,
        exporting: {
          buttons: {
              contextButton: {
                  enabled: false
              }
          }
        },
        tooltip: tooltip,
        series: [{
          dataLabels: dataLabels,
          data: [
            { name: this.$t("message.queued"), label: this.$t("message.queued_description"), x: Date.UTC(2024, 3, 10), description: this.$t("message.queued_description"), connectorColor: colors[0]},
            { name: this.$t("message.in_survey"), label: this.$t("message.in_survey_description"), x: Date.UTC(2024, 3, 15), description: this.$t("message.in_survey_description"), connectorColor:colors[1] },
            { name: this.$t("message.in_cleaning"), label: this.$t("message.in_cleaning_description"), x: Date.UTC(2024, 3, 20), description: this.$t("message.in_cleaning_description"), connectorColor:colors[2] },
            { name: this.$t("message.in_ingestion"), label: this.$t("message.in_ingestion_description"), x: Date.UTC(2024, 3, 25), description: this.$t("message.in_ingestion_description"), connectorColor:colors[3] },
            { name: this.$t("message.done"), label: this.$t("message.done_description"), x: Date.UTC(2024, 3, 30), description: this.$t("message.done_description"), connectorColor:colors[4] },
          ]
        }]
      });
    }
  }
};
</script>