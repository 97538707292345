<template>
    <p>
        <font-awesome-icon
            icon="trash-alt"
            class="delete-icon"
            @click="$emit('deleteDocument', document)">
        </font-awesome-icon>
        <span class="co-document-name"
              @click="$emit('openDocument', document)"
              >{{ document.data.title }}
        </span>
    </p>
</template>

<script>
export default {
    emits: ['openDocument', 'deleteDocument'],
    props: {
        document: { required: true, type: Object },
    },
}
</script>

<style scoped>
.co-document-name {
    cursor: pointer;
}
.delete-icon {
    margin-right: 10px;
    cursor: pointer;
}
</style>
