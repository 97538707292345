<template>
    <div class="tracking-toggle" style="display: flex; justify-content:space-around;align-items: center;">
        <q-toggle  v-model="isMonitored" @click="changeMonitoring" />
    </div>
</template>

<script>
import axios from 'axios';
import {
    alert_error,
    toast_success,
    monitoringDialog
} from '@/helpers/alert_helper.js';
import Tooltip from '@/components/common/Tooltip.vue';

export default {
    components: {
        Tooltip,
    },
    props: {
        asset: {
            required: true,
            type: Object
        },
    },
    data: function () {
        return {
            isMonitored: false,
            price: 0,
        }
    },
    methods: {
        _determinePrice: function () {
            const type = this.asset.type_;
            let priceType = '';
            if (type.isImage) {
                priceType = 'private_image_asset';
            } else if (type.isAudio || type.isVideo) {
                priceType = 'private_av_asset';
            } else {
                priceType = 'private_word_asset';
            }
            this.price = this.$store.getters.get_price(priceType);
        },
        _fetchMonitoringStatus: async function () {
            try {
                const url = `/api/cas/assets/monitoring/status/${this.asset.id}`;
                const response = await axios.get(url);
                this.isMonitored = response.data.is_monitored;
            } catch (error) {
                if (error.response.status === 403) {
                    console.error('The user does not have access to this asset.');
                } else {
                    alert_error(this.$t('message.general_server_error'));
                }
            }
        },
        changeMonitoring: async function () {
            const nowMonitored = this.isMonitored;
            const previouslyMonitored = !nowMonitored;
            let dialog_result = null;
            // we only want to show the dialog if this action initiates
            // monitoring; if the user is trying to disable monitoring this 
            // should be allowed without a dialog
            if (!previouslyMonitored) {
                const dialog_options = {
                    title: this.$t("message.tracking_confirm"),
                    confirmButtonText: this.$t("message.track"),
                    cancelButtonText: this.$t("message.cancel"),
                    line1: this.$t("message.tracking_confirm_html1"),
                    line2: this.$t("message.tracking_confirm_html2"),
                    price: this.price,
                    line3: this.$t("message.tracking_confirm_html3"),
                };
                dialog_result = await monitoringDialog(dialog_options);
            }
            // if the asset is not being currently monitored (i.e. this action
            // will initiate monitoring) AND the dialog was confirmed we proceed
            if (previouslyMonitored || dialog_result.isConfirmed) {
                try {
                    const payload = {
                        id: this.asset.id
                    };
                    // this API endpoint will toggle monitoring, that's why we
                    // only need to send asset id
                    const response = await axios.post('/api/cas/assets/monitoring/toggle', payload);
                    let msg = null;
                    // the toast we show depends on whether the monitoring was
                    // initiated or disabled by this action
                    if (nowMonitored) {
                        msg = this.$t(
                            "message.asset_now_monitored", {
                            asset: this.asset.display_name
                        }
                        );
                    } else {
                        msg = this.$t(
                            "message.asset_no_longer_monitored", {
                            asset: this.asset.display_name
                        }
                        );
                    }
                    toast_success(msg);
                } catch (error) {
                    if (error.response.status === 403) {
                        console.error('The user does not have access to this asset.');
                        this.isMonitored = false;
                    } else if (error.response.status === 400) {
                        await alert_error(this.$t('message.track_monthly_insufficient_credits'));
                        this.isMonitored = false;
                    } else {
                        await alert_error(this.$t('message.general_server_error'));
                        this.isMonitored = false;
                    }
                }
            }
            // if the user reject monitoring in the dialog
            if (!dialog_result.isConfirmed) {
                this.isMonitored = !this.isMonitored;
            }
        },
    },
    watch: {
        // whenever the asset changes we need to ask the API if it's monitored
        asset: {
            deep: true,
            immediate: true,
            handler(val, oldVal) {
                this._fetchMonitoringStatus();
                this._determinePrice();
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.tracking-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tracking-toggle label {
    font-family: 'Open Sans';
    font-weight: bolder;
    text-transform: uppercase;
    margin: 0 10px 0 0;
}
</style>
