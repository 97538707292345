<template>
  <page-title
    title="Content Tuner"
    :desc="this.$t('message.ct')"
    :instructionsVideoTitle="$t('message.co_instructions')"
    instructionsName="co_instructions_main"
    :instructionsVideoUrl="
      $store.getters.getInstructionalVideoUrl('co_instructions_main')
    "
  >
  </page-title>
  <div class="document-info">
    <input class="title hidden-text-input" v-model="title" />
  </div>
  <div class="version-ai-col-sec">
    <co-editor-text
      ref="editor-A"
      version="A"
      versionText="A: Generic"
      generic="true"
      @suggestion="updateSuggestions"
      @editorContentChange="onEditorContentChange"
      @editorTextImageEmpty="onEditorTextImageEmpty"
      @tone_text="copy_tone_text"
      placeholderText="Add text"
    >
    </co-editor-text>
    <co-editor-text
      ref="editor-B"
      version="B"
      versionText="B: My Original"
      generic="false"
      :new_text="tone_text"
      @suggestion="updateSuggestions"
      @editorContentChange="onEditorContentChange"
      @editorTextImageEmpty="onEditorTextImageEmpty"
      placeholderText="Create a Version B of your brand story here"
    >
    </co-editor-text>
  </div>
  <co-workspace-footer
    @showDocumentPicker="showDocumentPicker"
    @saveDocument="saveDocument"
    @reset="reset"
  >
  </co-workspace-footer>
  <document-picker ref="document-picker" @openDocument="openDocument">
  </document-picker>
  <co-suggestions ref="suggestions-sidebar"></co-suggestions>
</template>

<script>
import axios from 'axios';
import CoEditorText from './CoEditorText';
import CoWorkspaceFooter from './CoWorkspaceFooter';
import DocumentPicker from './DocumentPicker.vue';
import CoSuggestions from './CoSuggestions';
import { toast_success } from '@/helpers/alert_helper.js';
import PageTitle from '../common/PageTitle';

export default {
  name: 'CoWorkspace.vue',
  components: {
    CoSuggestions,
    CoEditorText,
    CoWorkspaceFooter,
    DocumentPicker,
    PageTitle,
  },
  props:{
    jumpStartAsset: Object
  },
  emits: ['reset', 'onEditorContentChange', 'onEditorTextImageEmpty'],
  data: function() {
    return {
      tone_text:null,
      title: this.$t('message.new_document'),
      selected_ia: {},
      editorStatusData: {
        A: true,
        B: true,
      },
    };
  },
  computed: {
    translatedSuggestionCategory: function() {
      // Each localization has different names for its parts of speech, therefore
      // we need to consult translations.
      return this.$t('message.' + this.suggestionCategory);
    },
    // CO Editor Text and Image empty
    editorTextImageEmpty: function() {
      return this.editorStatusData.A && this.editorStatusData.B;
    },
  },
  methods: {
    copy_tone_text(new_story){
      this.tone_text = new_story;
    },
    reset: function() {
      this.$emit('reset');
      this.newDocument(false);
    },
    triggerEditorGenerate: async function(selected_ia) {
      this.selected_ia = selected_ia;
      // Trigger each editor and return their scores
      const editorA = await this.$refs['editor-A'].generateResults(selected_ia);
      const editorB = await this.$refs['editor-B'].generateResults(selected_ia);
      return [editorA, editorB];
    },
    updateSuggestions: function(suggestion_obj) {
      // Suggestion obj is a JSON returned by the text editor, contains currently
      // clicked object data.
      this.$refs['suggestions-sidebar'].open(suggestion_obj, this.selected_ia);
    },
    /*
        This method returns a JSON representation of all the relevant parts of the
        document (e.g. title, text and asset/image) for all versions.
        */
    getDocument: function() {
      return {
        title: this.title,
        version_a: {
          text: this.$refs['editor-A'].getEditorText(),
          asset: this.$refs['editor-A'].getAssetId(),
        },
        version_b: {
          text: this.$refs['editor-B'].getEditorText(),
          asset: this.$refs['editor-B'].getAssetId(),
        },
      };
    },
    /* Opens the modal in which the user can choose one of their documents.*/
    showDocumentPicker: async function() {
      this.$refs['document-picker'].show();
    },
    /* Loads the chosen document into the editors and other GUI elements. */
    openDocument: async function(document) {
      this.title = document.data.title;
      this.$refs['editor-A'].setEditorText(document.data.version_a.text);
      this.$refs['editor-B'].setEditorText(document.data.version_b.text);
      this.$refs['editor-A'].setImage(document.data.version_a.asset);
      this.$refs['editor-B'].setImage(document.data.version_b.asset);
      toast_success(this.$t('message.document_opened'));
    },
    saveDocument: async function() {
      const payload = this.getDocument();
      const response = await axios.post(
        '/api/cas/copy_opt/document/save',
        payload
      );
      toast_success(this.$t('message.document_saved'));
    },
    newDocument: function(showToast = true) {
      this.title = this.$t('message.new_document');
      this.$refs['editor-A'].reset();
      this.$refs['editor-B'].reset();
      if (showToast) {
        toast_success(this.$t('message.new_document_created'));
      }
    },
    onEditorContentChange: function() {
      this.$emit('editorContentChange');
    },
    onEditorTextImageEmpty: function(value) {
      this.editorStatusData[value.editor] = value.data;
    },
  },
  watch: {
    editorTextImageEmpty: function(value) {
      this.$emit('editorTextImageEmpty', value);
    },
    selected_ia:function(value){
      this.$refs["editor-A"].selected_ia = value;
    },
    jumpStartAsset: function(value) {
      if (value) {
        this.$refs["editor-A"].setImage(value.asset.id);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
input.title.hidden-text-input {
  border: 1px #ccc solid;
  background: #fff;
  padding: 0.3vw;
  border-radius: 4px;
  font-size: 1vw;
}

#result-state {
  display: initial;
}

.main {
  width: 100%;
}

.document-info {
  padding: 0px 1px;
}

.document-info input {
  border: 1px solid #c4c4c4;
  outline: none;
  background-color: #f7f7f7;
}

.editor {
  padding: 35px 50px !important;
  height: 100%;
}

.main-editor {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.result-state {
  width: 100%;
  height: calc(100% - #{$pagetitle});
  flex: 1;
}

.editors {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
}

.editors-grid {
  display: flex;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.document-editor {
  display: flex;
  height: 100%;
}

.main-footer div {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 35px;
  padding-left: 50px;
}
</style>
