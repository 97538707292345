import $ from 'jquery';
import * as d3 from 'd3'

let margin = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
};
let gutter = 100;
let svg;
let x;
let y;
let tick_duration = 500;

export default class TropicanaChart {

    constructor(dom_element, data) {
        let container = $("#" + dom_element);
        this.dom_element = container[0];
        this.data = this.transformData(data);
        this.height = container.height() - margin.bottom - margin.top;
        this.width = container.width() - margin.left - margin.right;
        this.cx = this.width / 2;
        this.formatter = d3.format(',d');
        this.initGraph();
    }

    update(data) {
        this.data = this.transformData(data);
        this.render();
    }

    initGraph() {
        let self = this;
        let colors = this.getColors();

        $(this.dom_element).empty();
        svg = d3.select(this.dom_element).append('svg')
            .attr("viewBox", [0, 0, self.width + margin.left + margin.right, self.height + margin.top + margin.bottom])
            .attr("preserveAspectRatio", "xMidYMid meet")
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);


        // words axis
        this.words_scale = d3.scaleBand()
            .domain(self.data.words)
            .range([0, self.height])
            .padding(0.5);

        this.word_axis_left = d3.axisRight(this.words_scale)
            .tickPadding(10);


        this.axis_words_svg = svg.append('g')
            .attr('class', 'axis words')
            .attr('transform', `translate(${self.cx + gutter / 2 + 10},0)`)
            .call(this.word_axis_left);

        this.word_axis_right = d3.axisLeft(this.words_scale)
            .tickFormat(d => '');

        svg.append('g')
            .attr('class', 'axis words')
            .attr('transform', `translate(${self.cx - gutter / 2 - 10},0)`)
            .call(this.word_axis_right);

        this.axis_words_svg.append('text')
            .attr('dy', '.32em')
            .text('Words');

        this.axis_words_svg.selectAll('text')
            .attr('x', -gutter / 2 - 10)
            .style('text-anchor', 'middle');


        // Asset Axes
        this.mas_scale_left = d3.scaleLinear()
            .domain([0, 10])
            .range([0, self.width / 4]);

        this.mas_scale_right = d3.scaleLinear()
            .domain([0, 10])
            .range([0, self.width / 4]);

        // left axis
        this.axis_left = d3.axisBottom(this.mas_scale_left)
            .ticks(5)
            .tickFormat(this.formatter);

        // svg.append('g')
        //     .attr('class', 'axis left')
        //     .attr('transform',
        //         `translate(${self.cx - 250 - gutter},${self.height + 5})`)
        //     .call(this.axis_left);

        // right axis
        this.axis_right = d3.axisBottom(this.mas_scale_right)
            .ticks(5)
            .tickFormat(this.formatter);

        // svg.append('g')
        //     .attr('class', 'axis right')
        //     .attr('transform',
        //         `translate(${self.cx + gutter},${self.height + 5})`)
        //     .call(this.axis_right);

        // population bars
        this.bars = svg.append('g')
            .attr('class', 'pyramid mas');

        // tooltip
        self.tooltip = d3.select("body").append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);

        this.render();
    }

    render() {
        this.renderBars('left', this.mas_scale_left);
        this.renderBars('right', this.mas_scale_right);
        this.render_asset_frame('left');
        this.render_asset_frame('right');
    }

    renderBars(direction, scale) {
        let self = this;
        let colors = this.getColors();
        let bars = this.bars.selectAll(`.bar .bar-${direction}`).data(this.data[direction]);

        let x_pos = d => {
            return direction === "left"
                ? self.cx - gutter - scale(d.delta)
                : self.cx + gutter;
        }

        bars.enter()
            .append('rect')
            .attr('class', `mas-bar bar-${direction}`)
            .style("display", d => {
                if (direction === "left") return d.delta < 0 ? "none" : null;
                else return d.delta > 0 ? "none" : null;
            })
            .style("fill", d => colors[direction])
            .attr("x", x_pos)
            .attr("width", 0)
            .attr("y", d => self.words_scale(d.association))
            .attr("height", self.words_scale.bandwidth())
            .on("mouseover", function (d, i) {
                self.tooltip.transition()
                    .duration(200)
                    .style("opacity", .9)
                let left_row = self.data['left'][i];
                let right_row = self.data['right'][i];
                let left_word = left_row['asset']['word'];
                let right_word = right_row['asset']['word'];
                self.tooltip.html(`<p>Delta: ${d.delta.toFixed(2)}</p>` +
                                  `<p>${left_word}: ${left_row.distance.toFixed(2)}</p>` +
                                  `<p>${right_word}: ${right_row.distance.toFixed(2)}</p>`)
                    .style("left", (d3.event.pageX) + "px")
                    .style("top", (d3.event.pageY - 28) + "px");
            })
            .on("mouseout", function (d) {
                self.tooltip.transition()
                    .duration(500)
                    .style("opacity", 0);
            })
            .transition()
            .duration(tick_duration)
            .delay(function (d, i) {
                return i * 150
            })
            .attr("width", d => scale(Math.abs(d.delta)))

        bars.transition()
            .duration(tick_duration)
            .ease(d3.easeLinear)
            .attr('width', d => scale(Math.abs(d.delta)))

        bars.exit()
            .transition()
            .duration(tick_duration)
            .ease(d3.easeLinear)
            .attr('width', d => 0)
            .remove();
    }

    render_asset_frame(direction) {
        let self = this;

        let frame = svg.append('g')
            .attr("id", `asset-frame-${direction}`)
            .attr("class", `asset-frame`)

        if (self.data[direction][0]['asset']['type'] > 1) {
            let image_url = self.data[direction][0]['asset']['url'];
            frame.append("svg:image")
                .attr('x', direction === 'left' ? 0 + 20 : self.width - (self.width / 6) - 20)
                .attr("y", 0)
                .attr('width', (self.width / 6) - 20)
                .attr('height', self.height)
                // .attr("preserveAspectRatio", 'xMinYMid meet')
                .attr("xlink:href", image_url)
        } else {
            svg.append("text")
                .attr('x', direction === 'left' ? 0 + 20 : self.width - self.width / 4)
                .attr("y", self.height / 2)
                .text(self.data[direction][0]['asset']['display_name'])
                .style("font-size", "30px")
                .attr("alignment-baseline", "bottom")
                .attr("text-anchor", "start")
        }
    }


    getColors() {
        let color_palette = {"left": "#CCDDFC", "right": "#FCCCE4"};
        return color_palette;
    }

    transformData(data) {
        let transformed_data = {
            "words": [],
            "left": [],
            "right": [],
        }
        transformed_data.words = data.map(d => d.word);
        transformed_data.left = data.map(d => d.scores[0]);
        transformed_data.right = data.map(d => d.scores[1]);
        return transformed_data;
    }

}
