<template>
<div class="link-bx-div">
  <div class=" rgt-link-col bottom-lnk">
      <ul>
        <li>
  <a class="btn btn-small reset-button" @click="$emit('showDocumentPicker')">{{ $t('message.open') }}</a>
        </li>
        <li>
  <a class="btn btn-small reset-button" @click="$emit('saveDocument')">{{ $t('message.save') }}</a>
  </li>
  <li>
  <!-- <a class="btn co-button btn-small" @click="$emit('reset')">
    <q-icon
        size="20px"
        name="eva-sync-outline"
      />
    reset</a> -->
     <a
            class="btn btn-small reset-button"
            @click="$emit('reset')"
            v-if="!empty"
            ><span><img src="../../assets/icon9.png"/></span>
            {{ $t('message.reset') }}</a
          >
    </li>
      </ul>
  </div>
</div>
</template>

<script>
export default {
    emits: ['showDocumentPicker', 'saveDocument', 'reset'],
}
</script>
<style scoped lang="scss">
.co-button {
  margin-right: 0 !important;
  margin-left: 50px !important;
}

.rgt-link-col.bottom-lnk {
    padding-right: 2vw;
    padding-top: 2vw;
}
</style>