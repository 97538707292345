<template>
  <page-title
    title="Connection Checker"
    :desc="this.$t('message.cc')"
    :instructionsVideoTitle="$t('message.neuropositioning_instructions')"
    instructionsName="np_instructions_main"
    :instructionsVideoUrl="
      $store.getters.getInstructionalVideoUrl('np_instructions_main')
    "
  >
  </page-title>
  <div class="explorer-text" v-if="empty">
      Select concepts from the right panel to get results here
    </div>
  <div class="inspir-img-box">
    <div class="inspir-img-div ">
      <workspace-empty
        v-if="empty"
        app_name="Connection Checker"
        background="connection-checker.png"
      >
      </workspace-empty>
      <radar-chart ref="radar-chart" :style="{ width: empty ? '0%' : '100%' }">
      </radar-chart>
    </div>
  </div>

  <div class="link-bx-div">
    <div class=" rgt-link-col bottom-lnk">
      <ul>
        <li>
          <a
            class="btn btn-small reset-button"
            @click="$emit('reset')"
            v-if="!empty"
            ><span><img src="../../assets/icon9.png"/></span>
            {{ $t('message.reset') }}</a
          >
        </li>
        <li>
          <a
            class="btn btn-small export-button"
            @click="exportChart"
            v-if="!empty"
            ><span><img src="../../assets/icon10.png"/></span
            >{{ $t('message.export') }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import RadarChart from './RadarChart.vue';
import Tooltip from '@/components/common/Tooltip.vue';
import WorkspaceEmpty from '@/components/common/WorkspaceEmpty.vue';
import PageTitle from '../common/PageTitle';

export default {
  components: {
    PageTitle,
    WorkspaceEmpty,
    RadarChart,
    Tooltip,
  },
  emits: ['reset'],
  data: function() {
    return {
      empty: true,
    };
  },
  methods: {
    reset: function() {
      this.empty = true;
    },
    generate: async function(
      selected_intended_associations,
      selected_assets_and_positionings
    ) {
      const load = await this.$refs['radar-chart'].load(
        selected_intended_associations,
        selected_assets_and_positionings
      );
      this.empty = false;
    },
    exportChart: function() {
      this.$refs['radar-chart'].exportChart();
    },
  },
};
</script>

<style scoped>
.np-editor {
  padding: 35px 50px;
  height: 100%;
  overflow: hidden;
}

main .main-editor .editor .title {
  color: #343a40;
  font-size: 1.3125rem;
  margin: 0 0 5px 0;
  display: block;
}

.main {
  width: 100%;
}

.editor {
  padding: 35px 50px !important;
  height: 100%;
  overflow: hidden;
}

.main-editor {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.main-footer {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}

.state {
  width: 100%;
  height: 100%;
}

.radar-panel {
  display: flex;
  width: 100%;
  height: 100%;
}

.main-footer {
  width: 200px;
}

.connect-check-pg .inspir-img-div {
  width: 58%;
}
</style>
