<template>
    <btn class="button_book_tour" @click="handleBookATour"><font-awesome-icon :icon="['fas', 'envelope']" class="icon" />Contact us</btn>
</template>
  
<script>
    export default 
    {
        name: 'Tour',
        methods: 
            {
                handleBookATour: function () 
                {
                    window.open("https://www.mindspeller.com/contact/", "_blank")
                },
            },
    };
</script>

<style scoped>
    .button_book_tour {
        padding: 0.5vw 1vw;
        background: #FFFFFF;
        border-radius: 2vw;
        font-weight: 600;
        font-style: normal;
        color: #000000;
        cursor: pointer;
        transition: all 1s;
    }

    .button_book_tour a {
        color: #ffffff !important;
    }
    .icon{
        width: 20px;
        margin-right: 10px;
        color: #74C0FC;
    }
</style>

  