<template>
  <section class="middle-section">
    <div class="container-fluid">
      <div class="dashboard-page">
        <div class="ac-info-div">
          <div class="container-account">
            <div class="ac-info-col-div">

              <div class="flex-container">

                <!-- account infomation -->
                <div class="column-account">
                  <h6>Account Details</h6>

                  <form class="row g-3 form-css-bx">
                    
                  <div class="prof-imgbx">
                    <div class="col-12">
                      <label class="form-label">Upload Profile Pic</label>
                    </div>
                    <div class="profimgsec" style="cursor: pointer;" :loading="isSelecting" @click="handleFileImport">
                      <img v-if="!image" src="../../assets/prof-icon.png" style="width: 24px;" />
                      <img v-else v-bind:src="image.data['url']" />
                    </div>
                    <input ref="uploader" class="d-none" type="file" :accept="acceptExtenstions" @change="onFileChanged" />
                  </div>


                    <div class="col-12">
                      <label for="inputEmail" class="form-label">Email</label>
                      <input v-model="user.email" type="email" class="form-control" id="inputEmail4"
                        placeholder="Enter Email" disabled />
                    </div>

                    <!-- password -->
                    <div class="col-12">
                      <label for="input-password">{{ $t("message.password") }}</label>
                      <input class="form-control" disabled="" type="text" value="**********">
                      <router-link :to="{ name: 'Change password' }" class="link">
                        {{ $t("message.change_password") }}
                      </router-link>
                    </div>

                    <div class="col-12">
                      <label class="form-label">Username</label>
                      <input v-model="user.user_name" type="text" class="form-control" id="username"
                        placeholder="Enter Username" />
                    </div>

                    <div class="col-12">
                      <label class="form-label">First Name</label>
                      <input v-model="user.first_name" type="text" class="form-control" id="firstname"
                        placeholder="Enter First Name" />
                    </div>
                    <div class="col-12">
                      <label class="form-label">Last Name</label>
                      <input v-model="user.last_name" type="text" class="form-control" id="firstname"
                        placeholder="Enter Last Name" />
                    </div>
                    <div class="col-12">
                      <label class="form-label">Brand Name</label>
                      <input v-model="user.brand_name" type="text" class="form-control" id="firstname"
                        placeholder="Enter Brand Name" />
                    </div>
                    <div class="col-12">
                      <label for="input" class="form-label">Phone no.</label>
                      <input v-model="user.phone_no" type="phone" class="form-control" id="inputphone4"
                        placeholder="Enter Phone No." @click="isPhoneNotValid = false" />
                      <span class="matchingPasswordText" v-if="isPhoneNotValid">Please enter valid phone no</span>
                    </div>
                    <div class="col-12">
                      <label for="input" class="form-label">Country</label>
                      <input v-model="user.country" type="country" class="form-control" id="inputCountry4"
                        placeholder="Enter Country" />
                    </div>
                    <div class="col-12">
                      <label class="form-label">Address</label>
                      <input v-model="user.address" type="text" class="form-control" id="firstname"
                        placeholder="Enter Address" />
                    </div>

                    <!-- asset update -->
                    <div class="col-12">
                      <input type="checkbox" id="positioning-emails" class="settings-checkbox"
                        :checked="$store.state.current_user.notify_asset_positioning"
                        @change="positioning_updates_change">
                      <label class="form-label-select small-font" for="positioning-emails">{{
                        $t("message.receive_asset_updates") }}
                      </label>
                    </div>

                    <!-- product update -->
                    <div class="col-12">
                      <input type="checkbox" id="product-updates" class="settings-checkbox"
                        :checked="$store.state.current_user.consent_general_communication"
                        @change="product_updates_change">
                      <label class="form-label-select small-font" for="product-updates">{{
                        $t("message.receive_product_updates") }}
                      </label>
                    </div>

                    <!-- upload -->
                    <div class="col-12">
                      <div class="btn-div">
                        <button type="button" class="btn3" @click="onSaveHandler">
                          Save Changes
                        </button>
                      </div>
                    </div>

                  </form>
                </div>
                <!-- end -- account infomation -->

                <!-- team -->
                <div class="column-team">
                  <div v-if="this.$store.state.current_user.category.name=='Mindspeller user'||this.$store.state.current_user.category.name=='Organisation admin'">
                  <Team></Team>
                  </div>
                </div>
                <!-- end team -->

                <!-- license -->
                <div class="column-license">
                  <License></License>
                </div>
                <!-- end license -->

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <loading-app-wide v-if="loading" />
</template>

<script>
import axios from 'axios';
import { alert_error, toast_success, alert_success } from '@/helpers/alert_helper.js';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import License from './license.vue';
import Team from './team.vue';



export default {
  name: 'MyAccount',
  components: {
    LoadingAppWide, LoadingSpinner,
    License, Team
  },

  data() {
    return {
      isSelecting: false,
      selectedFile: null,
      image: '',
      user: {
        first_name: '',
        last_name: '',
        brand_name: '',
        email: '',
        country: '',
        address: '',
        phone_no: '',
        user_name: '',
      },
      brandValue: [],
      total_asset: '',
      isPhoneNotValid: null,
      archetypes: {
        first_archetype: '',
        second_archetype: '',
        third_archetype: '',
      },
      passions: {
        first_passion: '',
        second_passion: '',
        third_passion: '',
      },
      loading: false,
    };
  },

  computed: {
    acceptExtenstions: function () {
      const extensions = ['png', 'jpg', 'jpeg'];
      return extensions.map((extension) => `.${extension}`).join(',');
    },
  },

  methods: {
    product_updates_change: function () {
      const checkbox = document.getElementById('product-updates');
      this.$store.dispatch('set_product_updates', checkbox.checked);
    },

    positioning_updates_change: function () {
      const checkbox = document.getElementById('positioning-emails');
      this.$store.dispatch('set_positioning_updates', checkbox.checked);
    },

    redirectToTeamPage() {
      this.$router.push('/team');
    },

    redirectToChangePasswordPage() {
      this.$router.push('/change_password');
    },

    handleEdit(value) {
      localStorage.setItem('pageText', 'account');
      if (value === 'passions') {
        this.$router.push({
          name: 'Passions',
        });
      } else if (value === 'archetypes') {
        this.$router.push({
          name: 'Archetypes',
        });
      } else if (value === 'brand') {
        this.$router.push({
          name: 'Brand Value',
        });
      }
    },

    handleFileImport() {
      this.isSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        {
          once: true,
        }
      );
      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },

    onFileChanged: async function (e) {
      this.selectedFile = e.target.files[0];
      let fd = new FormData();
      fd.append('file', this.selectedFile);
      const data = await axios.post('/api/cas/users/upload_picture', fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (data) {
        const result = await axios.get('/api/cas/users/get_picture');
        if (result) {
          this.image = result;
        }
      }
      location.reload();
    },

    onSaveHandler: async function () {
      if (
        this.user.phone_no &&
        !this.user.phone_no.match(
          /^((\+\d{1,2}|1)[\s.-]?)?\(?[2-9](?!11)\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{4}$|^$/
        )
      ) {
        this.isPhoneNotValid = true;
        return;
      }
      this.loading = true;
      try {
        // update the news/product receive part first
        const id = this.$store.state.current_user.id;
        const payload_news = {
          'consent_general_communication': this.$store.state.current_user.consent_general_communication,
          'notify_asset_positioning': this.$store.state.current_user.notify_asset_positioning,
        };
        axios.post(`/api/cas/users/update/${id}`, payload_news)
          .then(response => {
            // toast_success(this.$t("message.user_updated"));
          })
          .catch(error => {
            alert_error(this.$t("message.error_user_update"));
          });

        // then update the rest user's information
        const payload = {
          username: this.user.user_name,
          email: this.user.email,
          phone: this.user.phone_no,
          country: this.user.country,
          address: this.user.address,
          brand_name: this.user.brand_name,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
        };

        const result = await axios.post(
          '/api/cas/users/update_user_details',
          payload
        );

        if (result.status === 200) {
          await this.$store.dispatch('load_user_data');
          toast_success('Details updated successfully');
        } else {
          alert_error(this.$t('message.general_server_error'));
        }
      } catch (error) {
        alert_error(this.$t('message.general_server_error'));
      }
      this.loading = false;
    },

    handleAssets: function () {
      window.open(`${window.location.origin}/?#/library`);
    },

    return_my_acount: function () {
      this.$router.push({ name: 'My Account' });
    },
  },

  mounted: async function () {
    document.getElementById('mindspeller').style.overflow = 'auto';
    localStorage.removeItem('pageText');
    localStorage.removeItem('pageName');

    try {
      const result = await axios.get('/api/cas/users/get_picture');
      if (result.status === 200) {
        this.image = result;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const result = await axios.get('/api/cas/users/current_user');
      if (result.status === 200) {
        this.user.user_name = result.data.username;
        this.user.brand_name = result.data.brand_name;
        this.user.email = result.data.email;
        this.user.phone_no = result.data.phone;
        this.user.country = result.data.country;
        this.user.address = result.data.address;
        this.user.first_name = result.data.first_name;
        this.user.last_name = result.data.last_name;
        this.user.password = result.data.password;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const archetypes = await axios.get('/api/cas/users/get_archetypes');
      if (archetypes.status === 200) {
        this.archetypes.first_archetype = archetypes.data.first_archetype;
        this.archetypes.second_archetype = archetypes.data.second_archetype;
        this.archetypes.third_archetype = archetypes.data.third_archetype;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const passions = await axios.get('/api/cas/users/get_passions');
      if (passions.status === 200) {
        this.passions.first_passion = passions.data.first_passion;
        this.passions.second_passion = passions.data.second_passion;
        this.passions.third_passion = passions.data.third_passion;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const brandData = await axios.get('/api/cas/users/get_brand_description');
      if (brandData.status === 200) {
        this.brandValue.push(brandData.data);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const investData = await axios.get('/api/cas/users/get_investor');
      if (investData.status === 200) {
        this.brandValue.push(investData.data);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const assets = await axios.get('/api/cas/assets/get_assets_count');

      if (assets.status === 200) {
        this.total_asset = assets.data;
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
.small-font {
  font-size: 10px; /* Adjust the font size as needed */
}
img.uploadImg {
  height: 2vw;
  width: 2vw;
}

thead {
  grid-area: header;
}

tbody {
  grid-area: body;
}

tfoot {
  grid-area: footer;
}

.flex-container {
  display: flex;
}

.column-account {
  /* flex: 1; */
  width: 40%;
}

.column-team {
  /* flex: 1; */
  width:  55%;
}

.form-label-select{
  margin-left: 10px;
}

.form-label{
  margin-left: 5px;
}
.column-license {
  /* flex: 1; */
  width:  30%;
}

</style>
