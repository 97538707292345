<template>
  <div>
    <add-asset
      ref="add-multimedia-asset-modal"
      :fileType="fileType"
      @resetSearch="$emit('resetSearch')"
    >
    </add-asset>
    <add-text-asset
      ref="add-text-asset-modal"
      @resetSearch="$emit('resetSearch')"
    >
    </add-text-asset>
    <q-dialog v-model="visible">
      <q-card class="selector-card">
        <q-card-section class="row items-center q-pb-none">
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section>
          <div class="top-text">{{ $t('new_asset.add_new_item') }}</div>
        </q-card-section>

        <div class="panes">
          <div class="pane-wrapper left">
            <div class="pane">
              <div class="big-button">
                <q-btn size="48px" round dense @click="addTextAsset">
                  <span class="circular-icon">
                    <img src="@/assets/icons/upload.svg" />
                  </span>
                </q-btn>
              </div>
              <div class="bottom-info">
                <div class="icons">
                  <img class="sm-icon" src="@/assets/icons/align-left.svg" />
                  <img class="sm-icon" src="@/assets/icons/type.svg" />
                </div>
                <div style="text-align: center;">
                  {{ $t('new_asset.add_words') }}
                  <br>
                  {{word_price}} mindcoins
                </div>
              </div>
            </div>
          </div>
          <div class="pane-wrapper">
            <div class="pane">
              <div class="big-button">
                <q-btn
                  size="48px"
                  round
                  dense
                  @click="addMultimediaAsset('img')"
                >
                  <span class="circular-icon">
                    <img src="@/assets/icons/upload.svg" />
                  </span>
                </q-btn>
              </div>
              <div class="bottom-info">
                <div class="icons">
                  <img class="sm-icon" src="@/assets/icons/image-thick.svg" />
                </div>
                <div style="text-align: center;">
                  {{ $t('new_asset.upload_image') }}
                  <br>
                  {{image_price}} mindcoins
                </div>
              </div>
            </div>
          </div>
          <div class="pane-wrapper right">
            <div class="pane">
              <div class="big-button">
                <q-btn
                  size="48px"
                  round
                  dense
                  @click="addMultimediaAsset('file')"
                >
                  <span class="circular-icon">
                    <img src="@/assets/icons/upload.svg" />
                  </span>
                </q-btn>
              </div>
              <div class="bottom-info">
                <div class="icons">
                  <!-- <img class="sm-icon" src="@/assets/icons/image-thick.svg"/> -->
                  <img class="sm-icon" src="@/assets/icons/film.svg" />
                  <img class="sm-icon" src="@/assets/icons/music.svg" />
                </div>
                <div style="text-align: center;">
                  {{ $t('new_asset.upload_multimedia') }}
                  <br>
                  {{av_price}} mindcoins
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="supported-assets-message">
          <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
          Currently only Word or Image assets are supported in our 'MindBias' tool 
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>


<script>
import AddAsset from '@/components/add_asset/AddAsset.vue';
import AddTextAsset from '@/components/add_asset/AddTextAsset.vue';

export default {
  components: { AddAsset, AddTextAsset },

  emits: ['resetSearch'],

  data: function() {
    return {
      visible: false,
      fileType: '',
      word_price: null,
      image_price: null,
      av_price: null
    };
  },
  created(){
    this.word_price = this.$store.getters.get_price('private_word_asset')
    this.image_price = this.$store.getters.get_price('private_image_asset')
    this.av_price = this.$store.getters.get_price('private_av_asset')
  }, 
  methods: {
    open: function() {
      this.visible = true;
    },
    close: function() {
      this.visible = false;
    },
    addMultimediaAsset: function(value) {
      this.fileType = value;
      this.$refs['add-multimedia-asset-modal'].open();
      this.close();
    },
    addTextAsset: function() {
      this.$refs['add-text-asset-modal'].open();
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.q-dialog__inner--minimized > div {
  max-width: initial;
}
.selector-card {
  width: calc(100vw * 0.4);
  min-width: 600px;
  min-height: 350px;
  height: auto;
  overflow: unset;
  max-height: initial;
}
.top-text {
  font-size: 1.675rem;
  font-family: 'Open Sans';
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
}
.panes {
  display: flex;
  height: 60%;
      width: 95%;
    margin: 0 auto;
}
.panes .left {
  justify-content: flex-end;
}
.panes .right {
  justify-content: flex-start;
}
.pane-wrapper {
  display: flex;
  align-items: center;
}
.big-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-info div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circular-icon {
  width: 48px;
  height: 48px;
  background-color: #000aff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.icons {
  margin: 10px 0 0 0;
  flex-basis: 100%;
}
.description {
  text-transform: capitalize;
}
.sm-icon {
  margin: 0 5px 0 5px;
  height: 20px;
  width: 20px;
}

.q-card__section.q-card__section--vert.row.items-center.q-pb-none {
  position: absolute;
  right: 0;
  z-index: 1;
  top: -11px;
  display: block;
  text-align: revert;
}

.panes {
    display: flex;
    height: 60%;
    justify-content: space-between;
    align-items: flex-start;
}

.panes div {
    width: 100%;
}
.supported-assets-message {
  text-align: center;
  font-size: 13px;
  color: #e42d2d;
  margin-top: 20px;
  padding: 10px;
  border-top: 1px solid #eee;
  font-weight: 500;
}
.supported-assets-message .warning-icon {
  color: #ff9800; /* A warm orange color for the warning icon */
  margin-right: 8px;
}
</style>
