<template>
    <div class="brand-menu-item">
     <a @click="handleMyBrand"><font-awesome-icon class="logo" :icon="['fas', 'copyright']"/>My Brand</a>
    </div> 
 </template>
 
 <script>
 export default {
     name: "Library",
     methods:{
        handleMyBrand() {
      window.open(this.$router.resolve('/my-brand').href, '_blank');
    },
     }
 }
 </script>
 
 <style>
     .brand-menu-item{
         display: inline-block;
         vertical-align: middle;
         font-weight: 600;
         font-size: 1.1vw;
         line-height: 1.5vw;
         color: white;
         position: relative;
         cursor: pointer;
     }
     .logo{
         width: 20px;
         margin-right: 10px;
         color: white;
     }
 </style>
 