<template>
  <section class="settings-workspace  team-wrapper">

    <div class="settings-title">
      <h6>
        {{ $t("message.team_overview") }}
      </h6>
    </div>

    <div class="table-container">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("message.name") }}</th>
            <th>{{ $t("message.type") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody aria-live="polite">
          <template v-if="loading">
            <tr>
              <td colspan="3" class="loading-row">
                <loading-spinner></loading-spinner>
              </td>
            </tr>
          </template>
          <template v-else>
            <user-row v-for="u in users" :key="u.id" :user="u" @edit-click="edit_user" />
          </template>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="3">
              <a class="link" @click="invite_user">
                <font-awesome-icon icon="plus-circle" />
                <i class="fal fa-plus-circle"></i>
                <span>{{ $t("message.invite_a_team_member") }}</span>
              </a>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>

  </section>
</template>

<script>
import UserRow from './UserRow.vue';
import axios from "axios";
import LoadingSpinner from '../common/LoadingSpinner.vue';

export default {
  components: { UserRow, LoadingSpinner },

  data: function () {
    return {
      users: [],
      loading: false,
    }
  },

  methods: {
    return_my_acount: function () {
      this.$router.push({ name: 'My Account' });
    },
    get_users: async function () {
      this.loading = true;
      const response = await axios.get('/api/cas/users/list_by_org');
      this.users = response.data;
      this.loading = false;
    },
    invite_user: function () {
      this.$router.push({ name: 'Invite a team member' });
    },
    edit_user: function (user_id) {
      // find the user with the provided id
      const user = this.users.find(u => u.id === user_id);

      this.$router.push({
        name: 'Edit a team member',
        // we can't pass the whole user object as props to the route,
        // so we extract the useful attributes of the user
        params: {
          id: user.id,
          username: user.username,
          email: user.email,
          type: user.category.id,
          active: parseInt(user.active) === 1,
        }
      })
    },
  },

  beforeMount: function () {
    this.get_users();
  }
}
</script>

<style scoped  lang="scss">
.settings-workspace {
  display: block;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // height: 100vh;
}

.settings-title {
  font-size: 1.5rem;
  color: $mdspblue_ui;
  // margin-bottom: 10px;
}

thead {
  grid-area: header;
}

tbody {
  grid-area: body;
}

tfoot {
  grid-area: footer;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table-container {
  display: grid;
  grid-template-areas: "header" "body" "footer";
  width: 75%;
  overflow-y: auto;
  max-height: 100vh;
  padding: 0px 1.5vw 1.5vw;
  background: #F0F3F6;
  border-radius: 0.7vw;
  /* display: block; */
}

.team-wrapper {
  display: flex;
  flex-flow: column;
  overflow: hidden;

}

.link {
  cursor: pointer;
}

.loading-row {
  grid-column: 1/-1;
}
</style>
