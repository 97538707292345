<template>
  <section class="middle-section">
    <div class="container">
      <div class="inspiration-pg">
        <div class="inspir-lft-sec scroll">
          <div class="leftBar"> 
            <workspace ref="workspace" @reset="reset"></workspace>
          </div>
        </div>
        <div class="rightbox-sec scroll inspas">
          <div class="rightBar">
            <h6>
              Enter at least three brand values              <!-- <span>*Choose or add any words for your target association</span> -->
            </h6>

            <left-sidebar
              ref="left-sidebar"
              @generate="onGenerate"
              @reset="resetWorkspace"
            ></left-sidebar>
          </div>
        </div>
      </div>
    </div>
    <div class="black-bg-popup">
      <div class="credit-model-popup-div">
        <div class="close-btn1">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.2861 20.7142L20.7142 11.2861"
              stroke="black"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M11.2861 11.2861L20.7142 20.7142"
              stroke="black"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <h5>Mindspeller Mindcoins Model</h5>

        <table>
          <tr>
            <th>Type</th>
            <th>Mindspeller assets</th>
            <th>Own assets</th>
          </tr>

          <tr>
            <td>Words</td>
            <td>10</td>
            <td>100</td>
          </tr>

          <tr>
            <td>Images</td>
            <td>20</td>
            <td>200</td>
          </tr>
          <tr>
            <td>Media</td>
            <td>30</td>
            <td>300</td>
          </tr>
        </table>
      </div>
    </div>
  </section>
  <loading-app-wide v-if="display_loading_overlay" />
</template>

<script>
import Workspace from './Workspace.vue';
import LeftSidebar from './LeftSidebar.vue';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';

export default {
  name: 'InspirationExplorer',
  components: {
    Workspace,
    LeftSidebar,
    LoadingAppWide,
  },
  data: function() {
    return {
      display_loading_overlay: false,
    };
  },

  methods: {
    resetWorkspace() {
        this.$refs['workspace'].reset();
    },
    reset: function() {
      this.$refs['workspace'].reset();
      this.$refs['left-sidebar'].reset();
    },
    onGenerate: async function(ias) {
      this.display_loading_overlay = true;
      await this.$refs['workspace'].generate(ias);
      this.display_loading_overlay = false;
    },
  },
};
</script>

<style scoped>
.leftBar.scroll {
  max-height: 42vw;
}

.rightBar.scroll {
  padding: 2vw;
}

.inspir-lft-sec {
  /* width: 70% !; */
  padding: none !important;
}

.container {
  padding-left: 5vw;
}

/* .rightbox-sec {
  padding: 2vw 0.5vw 2vw !important;
} */

.left-checkbx-div {
  width: 30% !important;
}

.rightBar {
  padding: 1vw 2vw 7vw 2vw;
}

.inspiration-pg {
  display: flex;
  justify-content: center;
  align-items: stretch;
}
</style>
