<template>
    <page-title title="Association Wheel" :desc="this.$t('message.aw')"
        :instructionsVideoTitle="$t('message.aw_instructions')" instructionsName="aw_instructions_main"
        :instructionsVideoUrl="$store.getters.getInstructionalVideoUrl('aw_instructions_main')">
    </page-title>
    <div class="explorer-text" v-if="empty">Select concepts from the right panel to get results here </div>
    <div class="inspir-img-box">
        <workspace-empty v-if="empty" app_name="Association Wheels" background="empty-association-wheel.png">
        </workspace-empty>
        <div class="img-boxes" v-bind:style="{ width: empty ? '0%' : '100%' }">
            <div class="graph_box" v-for="ap in assets_and_positionings" :key="ap.key">
                <aw-chart :asset_and_positioning="ap" :options="options" :ref="setChartRef"
                    @wordColorPicked="onWordColorPicked" @chartError="onChartError">
                </aw-chart>
            </div>
        </div>
    </div>
    <div class="link-bx-div">
        <div class=" rgt-link-col bottom-lnk">
            <ul>
                <li>
                    <a class="btn btn-small reset-button" @click="$emit('reset')"
                        v-if="assets_and_positionings.size > 0"><span><img src="../../assets/icon9.png" /></span> {{
                            $t('message.reset') }}</a>
                </li>
                <li>
                    <a class="btn btn-small reset-button" @click="$emit('reset_color')"
                        v-if="assets_and_positionings.size > 0"><span><img src="../../assets/icon9.png" /></span> {{
                            $t('message.reset_color') }}</a>
                </li>
                <li>
                    <a class="btn btn-small export-button" @click="exportCharts"
                        v-if="assets_and_positionings.size > 0"><span><img src="../../assets/icon10.png" /></span>{{
                            $t('message.export') }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import AwChart from './AwChart.vue';
import Tooltip from '@/components/common/Tooltip.vue';
import WorkspaceEmpty from '@/components/common/WorkspaceEmpty.vue';
import PageTitle from '../common/PageTitle';

export default {
    components: {
        WorkspaceEmpty,
        Tooltip,
        AwChart,
        PageTitle
    },
    emits: ['reset', 'reset_color'],
    data: function () {
        return {
            empty: true,
            assets_and_positionings: new Set(),
            charts: new Array(),
            // these options apply to all wheels
            options: {
                font_size: 18,
                font_weight: 'bold',
                font_color: '#000',
                font_family: 'Open+Sans',
                color_scheme: 'default',
                display_image: true,
                fas_percentage: 1,
                color_selections: [],
            },
        };
    },
    computed: {
        aw_width() {
            return this.assets_and_positionings.size > 1 ?
                'flex-basis: 50%;' :
                'flex-basis: 100%;';
        },
    },
    methods: {
        reset: function () {
            this.assets_and_positionings.clear();
            this.empty = true;
        },
        addWheel: async function (asset_and_positioning) {
            this.assets_and_positionings.add(asset_and_positioning);
            this.empty = false;
        },
        removeWheel: function (asset_and_positioning) {
            this.assets_and_positionings.delete(asset_and_positioning);
            if (this.assets_and_positionings.size == 0) {
                this.empty = true;
            }
        },
        exportCharts: function () {
            for (const chart of this.charts) {
                chart.exportChart();
            }
        },
        setFontSize: function (size) {
            this.options.font_size = size;
        },
        setWheelSize: function (size) {
            const percentage_size = size / 100;
            this.options.fas_percentage = percentage_size;
        },
        setTopCollapse: function (size) {
            const percentage_size = size / 100;
            this.options.collapse_percentage = percentage_size;
        },
        setPercentage: function (value) {
            for (const chart of this.charts) {
                chart.setPercentage(value);
            }
        },
        setWheelInformation: function (value) {
            for (const chart of this.charts) {
                chart.setWheelInformation(value);
            }
        },
        /*
        If there's an error with the chart (e.g. 500 on the server) we need to
        remove the chart from the workspace since it will just take up space.
        */
        onChartError: function (asset_and_positioning) {
            this.assets_and_positionings.delete(asset_and_positioning);
            if (this.assets_and_positionings.size == 0) {
                this.empty = true;
            }
        },
        /*
        When the color of word is picked in one of the charts it should be
        changed in all of the charts.
        */
        onWordColorPicked: function (word, color, depth) {
            for (const chart of this.charts) {
                chart.setWordColor(word, color, depth);
            }
        },
        /*
        Apply the color scheme to the first chart. All other charts, call
        them target charts, will have some of their words colored - all the
        words in the target chart that also appear in the first chart will get
        the color they were assigned in the first chart. The words that only
        appear in the target charts (and not the first chart) will remain grey.
        */
        applyColorScheme: function (scheme) {
            // first, we apply the scheme to the first chart; the return value
            // of this procedure is the list of objects describing which word
            // had been assigned which color
            const firstChart = this.charts[0];
            const firstChartWordColors = firstChart.applyScheme(scheme);
            // override the colors of the target chart words that appear in the
            // first chart
            for (const [index, chart] of this.charts.entries()) {
                if (index === 0) continue;
                for (const pair of firstChartWordColors) {
                    chart.setWordColor(pair.word, pair.color, 1);
                }
            }
        },
        // Here we clear the charts 
        ClearColorScheme: function () {
            for (const chart of this.charts) {
                // remove the color for each chart
                chart.clearWordColor();
            }
        },

        setChartRef: function (element) {
            if (element) {
                this.charts.push(element);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.state {
    height: 100%;
}

.main {
    flex: 1;
}

.img-boxes {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 2vw;
}

.main-editor {
    width: 100%;
    height: calc(100% - #{$pagetitle});
}

.main-editor .editor {
    display: flex;
    flex-direction: column;
    padding: 35px 50px;
    height: 100%;
    overflow: auto;
}

.main-editor .editor .title {
    color: #343a40;
    font-size: 1.3125rem;
    margin: 0 0 5px 0;
    display: block;
}

.main-editor .editor {
    flex-basis: initial;
    padding: 35px 50px;
}

.has-sidebar .main-editor {
    width: 100% !important;
}

.has-sidebar .main-editor {
    width: 100%;
    float: none;
}

#wheel-containers {
    display: flex;
    width: 100%;
    height: 100%;
}

.assoc-wheel-grid {
    height: 100%;
    flex-wrap: wrap;
    display: flex;
}

.wheel-container {
    // padding: 1rem;
    height: 100%;
    width: 100%;
}

.graph_box {
    // height: 61vh;
    height: auto;
    width: 45%;
    margin-bottom: 4vw;
}

.rgt-link-col.bottom-lnk {
    width: 100%;
}

// i.q-icon.eva.eva-list-outline {
//     margin-right: 0.5vw !important;
// }

.inspir-img-box {
    height: auto;
}</style>
