<template>
  <q-chip removable @remove="removeIa" :class="chipColor" text-color="black">
    {{ word.word }}
  </q-chip>
</template>

<script>
export default {
  props: {
    word: { required: true, type: Object },
  },
  emits: ['removeIa'],
  methods: {
    removeIa: function(e) {
      this.$emit('removeIa', this.word);
    },
  },
  computed: {
    chipColor: function() {
      if (this.word.hasOwnProperty('isFrameworkWord') && this.word.isFrameworkWord) {
        return 'framework-chip';
      } else {
        return 'standard-chip';
      }
    },
  },
}
</script>
<style scoped lang="scss">
.framework-chip {
  background: #CDD2FF !important;
}
.standard-chip {
  background: 757575 !important;
}
</style>