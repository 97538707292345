<template>
    <q-select dense outlined use-input ref="select_widget" v-model="selectedWord" :options="options" :loading="loading" 
        :hint="hint" @filter="filter" placeholder="Search keyword">
        <template v-slot:no-option>
            <q-item>
                <q-item-section class="text-grey">
                    {{ $t('message.no_match') }}
                </q-item-section>
            </q-item>
        </template>
        <p style="margin-top:10px; font-size: 12px; text-align: center;">Checked items purchased</p>

        <template v-slot:loading>
            <q-item>
                <q-item-section class="loading-container">
                    <q-spinner size="30px" color="primary" />
                </q-item-section>
            </q-item>
        </template>
    </q-select>
</template>

<script>
import axios from 'axios';
import {
    alert_error,
    genericDialog
} from '@/helpers/alert_helper.js';

export default {
    emits: ['wordSelected', 'wordChanged'],
    props: {
        clearAfterSelection: {
            default: true,
            type: Boolean,
        },
        hint: {
            default: '',
            type: String,
        },
    },
    data() {
        return {
            selectedWord: null,
            options: new Array(),
            loading: false,
            last_search: new String(),

        };
    },
    methods: {
        reset: function () {
            this.selectedWord = null;
        },
        filter: async function (val, update, abort) {
            await update(this.fetchWords(val));
        },

        fetchWords: async function (val) {
            if (val === '') {
                this.options = [];
                this.last_search = val;
            } else {
                if (val !== this.last_search) {
                    this.loading = true;
                    const payload = {
                        draw: 1,
                        search: {
                            value: val.toLowerCase(),
                        },
                        start: 0,
                        length: 8,
                    };
                    
                    const response = await axios.post('/api/cas/words/search', payload);

                    const options = response.data.data.map((w) => ({
                        value: w,
                        label: w.has_access ? `✔ ${w.word}` : w.word,
                    }));
                    this.options = options;
                    this.last_search = val;
                    this.$refs.select_widget.showPopup();
                    this.loading = false;

                }
            }
        },

        iaSelect: async function (word_object) {
            const payload = {
                word_ids: [word_object.word_id],
            };
            // here we remove the charging part
            const access = await axios.post('/api/cas/words/check_access', payload);
            if (access.data[word_object.word_id]) {
                this.$emit('wordSelected', word_object);
            } else {
                const price = this.$store.getters.get_price('public_word_asset');
                const dialog_options = {
                    title: this.$t('message.purchase_ia', {
                        word: word_object.word,
                    }),
                    text: this.$t('message.ia_cost', {
                        price: price,
                    }),
                    confirmButtonText: this.$t('message.add'),
                    cancelButtonText: this.$t('message.cancel'),
                };
                const dialog_result = await genericDialog(dialog_options);
                if (dialog_result.isConfirmed) {
                    const url = `/api/cas/words/request_access_new`;
                    const response = await axios.post(url, {
                        word_ids: [word_object.word_id],
                    });
                    if (response.data.access_granted) {
                        this.$emit('wordSelected', word_object);
                        this.$store.dispatch('get_mindcoin_balance');
                    }
                    if (response.data.insufficient_credits) {
                        alert_error(this.$t('message.insufficient_credits_for_ia'));
                    }
                    if (response.data.read_only) {
                        alert_error(this.$t('message.read_only_user_no_ia'));
                    }
                }
            }
        },
    },
    watch: {
        selectedWord: function (new_value) {
            if (new_value !== null) {
                this.iaSelect(new_value.value);
                if (this.clearAfterSelection) {
                    this.reset();
                }
            } else {
                this.$emit('wordChanged', new_value);
            }
        },
    },
};
</script>

<style scoped>
.loading-container {
    display: flex;
    justify-content: center;
    margin-left: -18px;

}
</style>
