<template>    
    <div class="asset first-asset">
        <div class="asset_img">
            <img src="../../assets/plus-icon.png" /> Add Concept
        </div>
    </div>
    </template>
    
    <script>
    export default {
        name: 'AssetCreator.vue',
        props: {
            isModal: {
                type: String,
                default: false,
            }
        }
    };
    </script>
    
    <style lang="scss" scoped>
    .asset.first-asset::before {
        display: none;
    }
    
    .asset .asset_img img {
        width: 2vw;
        display: block;
        text-align: center;
    }
    
    .asset .asset_img {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: -1px 1px 6px rgba(0,0,0,.1607843137254902);
        border-radius: 20px;
        position: relative;
        overflow: hidden;
        z-index: 0;
        background-color: white;
        padding: 5px;
        flex-direction: column;
        font-size: 1vw;
        color: #000;
        height: 7vw;
        width: 7vw;
    }
    </style>
    