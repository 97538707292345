<template>
  <div class="radio-buttons" v-for="c in campaigns">
    <campaign-checkbox
      :campaign="c"
      @campaignSelected="onCampaignSelected"
      @campaignUnselected="onCampaignUnselected">
    </campaign-checkbox>
  </div>

  <a class="link" @click="openCampaignModal">
    + {{ $t('message.add_campaign') }}
  </a>
  
  <add-campaign-modal ref="campaignModal" @addCampaign="onAddCampaign">
  </add-campaign-modal>
</template>

<script>
import CampaignCheckbox from './CampaignCheckbox.vue';
import AddCampaignModal from './AddCampaignModal.vue';

export default {
  components: { CampaignCheckbox, AddCampaignModal },
  emits: ['campaignSelected', 'campaignUnselected'],
  data: function() {
    return {
      campaigns: new Set(),
      selected_campaigns: new Set(),
    }
  },
  methods: {
    reset: function() {
      this.campaigns.clear();
      this.selected_campaigns.clear();
    },
    openCampaignModal: function() {
      this.$refs['campaignModal'].open();
    },
    onAddCampaign: function(data) {
      const campaign = new Campaign(data.name, data.start_date, data.end_date);
      this.campaigns.add(campaign);
      this.selected_campaigns.add(campaign);
      this.$emit('campaignSelected', campaign);
    },
    onCampaignSelected: function(campaign) {
      if (!this.selected_campaigns.has(campaign)) {
        this.selected_campaigns.add(campaign);
        this.$emit('campaignSelected', campaign);
      }
    },
    onCampaignUnselected: function(campaign) {
      if (this.selected_campaigns.has(campaign)) {
        this.selected_campaigns.delete(campaign);
        this.$emit('campaignUnselected', campaign);
      }
    },
  },
}

class Campaign {
  constructor(name, start_date, end_date) {
    this.name = name;
    this.start_date = start_date;
    this.end_date = end_date;
  }
}
</script>
