import * as d3 from 'd3'
import moment from 'moment';
import $ from "jquery";
import axios from "axios";
import {
    alert_error,
    genericDialog,
    toast_success
} from '@/helpers/alert_helper.js';

export default class Mindtracker {

    constructor(dom_id, assets, ia, data, campaigns, loading, callback,callback_image,callback_imageURL,store,callback_link_word_id,callback_date) {
        this.colors = ["#78CDD7", "#FCCCE4", "#18c61a", "#9817ff", "#d31911", "#24b7f1", "#fa82ce", "#736c31", "#1263e2", "#18c199", "#ed990a", "#f2917f", "#7b637c", "#a8b311", "#a438c0", "#d00d5e", "#1e7b1d", "#05767b", "#aaa1f9", "#a5aea1", "#a75312", "#026eb8", "#94b665", "#91529e", "#caa74f", "#c90392", "#a84e5d", "#6a4cf1", "#1ac463", "#d89ab1", "#3c764d", "#2dbdc5", "#fb78fa", "#a6a9cd", "#c1383d", "#8b614e", "#73be38", "#ff8d52", "#cea37e", "#b53c7e", "#656d61", "#436f90", "#5e7304", "#82b792", "#fb88a3", "#dd8df2", "#6a5cc0", "#d098d5", "#ac15dc", "#a4543b", "#76b4cc", "#6963a4", "#8e620d", "#77adf8", "#18c61a", "#9817ff", "#d31911", "#24b7f1", "#fa82ce", "#736c31", "#1263e2", "#18c199", "#ed990a", "#f2917f", "#7b637c", "#a8b311", "#a438c0", "#d00d5e", "#1e7b1d", "#05767b", "#aaa1f9", "#a5aea1", "#a75312", "#026eb8", "#94b665", "#91529e", "#caa74f", "#c90392", "#a84e5d", "#6a4cf1", "#1ac463", "#d89ab1", "#3c764d", "#2dbdc5", "#fb78fa", "#a6a9cd", "#c1383d", "#8b614e", "#73be38", "#ff8d52", "#cea37e", "#b53c7e", "#656d61", "#436f90", "#5e7304", "#82b792", "#fb88a3", "#dd8df2", "#6a5cc0", "#d098d5", "#ac15dc", "#a4543b", "#76b4cc", "#6963a4", "#8e620d", "#77adf8"];
        this.render(dom_id, assets, ia, data, campaigns, loading, callback, callback_image, callback_imageURL, store, callback_link_word_id, callback_date);
    }

    render(dom_id, assets, ia, data, campaigns, loading, callback, callback_image, callback_imageURL, store, callback_link_word_id, callback_date) {
        let selected_data = this.getSelectedData(assets, ia, data);
        let selected_colors = this.colors.slice(0, assets.length);
        let selected_labels = assets.map(a => a.display_name);
        let selected_thumbnails = assets.map(a => a.urls.thumb_medium);
        let selected_types = assets.map(a => a.type_.id);
        this.renderChart(
            dom_id,
            ia,
            selected_data,
            selected_colors,
            selected_labels,
            selected_thumbnails,
            selected_types,
            Array.from(campaigns),
            assets,
            loading,
            callback,
            callback_image, 
            callback_imageURL,
            store,
            callback_link_word_id,
            callback_date
        );
    }

    renderChart(dom_id, ia, data, colors, labels, thumbnails, types, campaigns, assets, loading, callback, callback_image, callback_imageURL, store, callback_link_word_id, callback_date) {
        const chart = document.getElementById(dom_id);
        chart.innerHtml = '';
        if (data.length < 1) return;
        const options = {
            color: colors,
            labels: labels,
            thumbnails: thumbnails,
            axis_title: ia.word,
            campaigns: campaigns,
            types: types,
        };
        this.init(`#${dom_id}`, data, options, assets, loading, callback, callback_image, callback_imageURL, store, callback_link_word_id, callback_date);
    }

    getSelectedData(assets, ia, received_data) {
        let selected_data = [];
        let data = received_data[ia.word_id];
        let selected_ids = assets.map(a => a.word.word_id);
        selected_ids.forEach(function (key) {
            selected_data.push(data[key]);
        });
        return selected_data;
    }


    init(id, data, options, assets, loading, callback, callback_image, callback_imageURL, store, callback_link_word_id, callback_date) {
        let cfg = {
            width: 1070,
            // height: 500,
            // Height would be simply parent's height - however, 274 is substracted
            // since the height is somehow bigger than it should be, e.g.
            // when parent is 609px tall, the graph gets height set to ~800px.
            // Therefore we substract 274px to make up for that.
            height: $("#tracker-graph").height() - 274,
            margin: { "left": 25, "bottom": 25, "right": 110, "top": 25 },
            campaigns: []
        };

        //Put all of the options into a variable called cfg
        if ('undefined' !== typeof options) {
            for (var i in options) {
                if ('undefined' !== typeof options[i]) {
                    cfg[i] = options[i];
                }
            }//for i
        }//if

        let date_range = getDateRange(data);

        //Remove whatever chart with the same id/class was present before
        d3.select(id).select("svg").remove();

        //Initiate the radar chart SVG
        const svg = d3.select(id).append("svg")
            .attr("viewBox", [0, 0, cfg.width, cfg.height])
            .attr("preserveAspectRatio", "xMinYMid meet")
            .style('width', '100%')
            .style('height', '100%');

        let x = d3.scaleTime()
            .domain([date_range.start.toDate(), date_range.end.toDate()])
            .range([cfg.margin.left, cfg.width - cfg.margin.right])
            .nice();


        let x_axis = g => g
            .attr("transform", `translate(0,${cfg.height - cfg.margin.bottom})`)
            .call(d3.axisBottom(x)
                // .ticks(d3.timeMonth.every(1))
                .tickFormat(d3.timeFormat("%b/%Y")))

        let y = d3.scaleLinear()
            .domain([0, 10])
            .range([cfg.height - cfg.margin.bottom, cfg.margin.top])

        let y_axis = g => g
            .attr("transform", `translate(${cfg.margin.left},0)`)
            .call(d3.axisLeft(y))

        svg.append("g")
            .call(x_axis);

        svg.append("g")
            .call(y_axis);

        // Add Y axis label:
        svg.append("text")
            .attr("x", 0)
            .attr("y", 12)
            .text(cfg.axis_title)
            .attr("text-anchor", "start")
            .attr("font-size", "12px")
            .attr("transform", "translate(0, 5)");

        for (let [index, campaign] of cfg.campaigns.entries()) {
            const color = this.colors[this.colors.length - index - 1];
            addCampaignMarker(campaign, color);
        }

        data.forEach((data_set, idx) => renderDataSet(data_set, cfg.color[idx], 5));
        renderLegend();

        function renderDataSet(data_set, color, bar_width) {
            const groups = svg.append("g")
                .selectAll("g.lollipop")
                .data(data_set)
                .enter()
                .append("g")
                .attr("class", "lollipop")
                .attr('transform', `translate(0, 0)`);
            let scale_factor = 10;
            groups.append('rect')
                .attr("x", d => x(moment(d.date).toDate()))
                .attr("y", d => y(d.score))
                .attr("height", d => y(0) - y(d.score))
                .attr("width", bar_width / scale_factor)
                .attr("fill", d => {
                    if (d.forecasted) {
                        return "#CCCCCC"
                    } else {
                        return color;
                    }
                });

            let circleOffset = bar_width / (2 * scale_factor);
            groups.append("circle")
                .attr("cx", d => x(moment(d.date).toDate()) + circleOffset)
                .attr("cy", d => y(d.score))
                .attr("r", d => bar_width)
                .on("click", function (d) {
                    handleClick(d, assets, loading, callback, callback_image, callback_imageURL, store, callback_link_word_id, callback_date);
                })
                .attr("fill", d => {
                    if (d.forecasted) {
                        return "#CCCCCC"
                    } else {
                        return color;
                    }
                })
                .append("title")
                .text(d => d.score.toFixed(2) + ": " + moment(d.date).format("YYYY-MM-DD"));

            async function handleClick(d, asset, loading, callback, callback_image, callback_imageURL, store, callback_link_word_id, callback_date) {
                try {
                    const dialog_options = {
                        title: 'Add perceived image to your timeline',
                        text: "Image costs 10 mindcoins",
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                    };
                    const dialog_result = await genericDialog(dialog_options);
                    if (!dialog_result.isConfirmed) {
                        return;
                    }
                    if (dialog_result.isConfirmed) {
                        await store.dispatch('get_mindcoin_balance');
                        if (store.state.mindcoins < 10) {
                            alert_error(this.$t('new_asset.insufficient_credits'));
                            return;
                        }
                        // Show loading indicator
                        showLoading(loading);
                        await store.dispatch('get_mindcoin_balance');
                        // here we call the association wheel api
                        let payload = {
                            proportion: 0.0,
                            asset: asset[0],
                            filters: [],
                            from_date: d.fromDate,
                            to_date: d.date
                        };

                        const url = '/api/cas/association_wheels/analyze';
                        const response = await axios.post(url, payload);

                        const words = response.data[0].children
                            .slice(0, 20)
                            .map((child) => `${child.word}: ${child.percentViz}`)
                            .join(', ');
                        payload = { 'description': words };
                        const res = await axios.post('/api/cas/association_wheels/image_generator', payload);
                        let image = res.data.image;
                        let imageURL = res.data.imageURL;
                        callback_image(image);
                        callback_imageURL(imageURL);
                        callback_link_word_id(asset[0].id);
                        callback_date(d.date);
                        callback(true);
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    hideLoading(loading);
                }
            }

            function showLoading(loading) {
                if (loading) {
                    const loadingElement = document.getElementById(loading);
                    loadingElement.style.display = 'block';
                }
            }

            function hideLoading(loading) {
                if (loading) {
                    const loadingElement = document.getElementById(loading);
                    loadingElement.style.display = 'none';
                }
            }


        }

        function renderLegend() {

            let svg = d3.select("#tracker-graph svg")
            let x = 15;
            let y = 15;
            let dy = 25;

            let legend = svg.append('g')
                .attr("class", "legend")

            for (let i = 0; i < cfg.labels.length; i++) {
                let type = cfg.types[i];
                if (type === 2) {
                    legend.append("svg:image")
                        .attr('x', cfg.width - 50)
                        .attr("y", y + (dy * i))
                        .attr('width', 40)
                        .attr('height', 20)
                        // .attr("preserveAspectRatio", 'xMinYMid meet')
                        .attr("xlink:href", cfg.thumbnails[i]);
                } else {
                    svg.append("text")
                        .attr('x', cfg.width - 50)
                        .attr("y", 29 + (dy * i))
                        .text(cfg.labels[i])
                        .style("font-size", "10px")
                        .attr("alignment-baseline", "bottom")
                        .attr("text-anchor", "start")
                }
                legend.append("circle")
                    .attr("cx", cfg.width - 70)
                    .attr("cy", 25 + (25 * i))
                    .attr("r", 6)
                    .style("fill", cfg.color[i])
            }

        }

        function getDateRange(data) {
            let assets_dates = data.concat.apply([], data);
            let all_data = []
                .concat(assets_dates.map(d => d.date))
                .concat(cfg.campaigns.map(c => c.start))
                .concat(cfg.campaigns.map(c => c.end));
            let dates = all_data.map(val => moment(val));
            let start_date = moment.min(dates).subtract(1, 'months');
            let end_date = moment.max(dates).add(1, 'months');
            return { 'start': start_date, "end": end_date }
        }


        function addCampaignMarker(campaign, color) {
            let svg = d3.select("#tracker-graph svg")

            let x1 = x(campaign.start_date);
            let x2 = x(campaign.end_date);

            let campaign_marker = svg.append('g')
                .attr("class", "campaign-marker")
            //~ .attr("id", "marker-" + campaign.id)
            //~ .attr("data-id", campaign.id)

            campaign_marker.append('rect')
                .attr("x", x1)
                .attr("y", 0)
                .attr("height", cfg.height - cfg.margin.bottom)
                .attr("width", x2 - x1)
                .attr("fill", color);

            campaign_marker.append("text")
                .attr("x", x1 + (x2 - x1) / 2)
                .attr("y", 20)
                .text(campaign.name)
                .style("font-size", "10px")
                .attr("alignment-baseline", "middle")
                .attr("text-anchor", "middle")
        }

    }

   
};
