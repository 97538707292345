<template>
  <!-- <h4>
    Content
  </h4> -->
  <div class="wt-box-col">
    <div class="trg-assoc">
      <p>Step 2:</p>
      <p>Enter the memory, feeling or keyword you want to evoke with your concepts</p>
      <div class="col-md-12 choose-libra">
        <single-ia-picker ref="ia-picker" @iaSelected="onIaSelected" @iaChanged="onIaChanged">
        </single-ia-picker>
      </div>
    </div>
    <span style="font-weight: 700" v-if="renderChart">Content Tuner A/B</span>
  </div>

  <co-d3-chart ref="co-d3-chart" v-if="renderChart"> </co-d3-chart>
  <div class="emotions-col">
    <div class="btn-col" style="position: sticky; ">
      <button class="generatebtn" v-bind:class="getClass()" @click="generateChart" :disabled="buttonDisabled">
        <transition name="fade">
          <span class="btnText" v-if="!textComputed">Score</span>
          <img class="imgDiv" v-else src="@/assets/icons/checkbox.svg" />
        </transition>
      </button>
    </div>
    <div v-if="renderEmotions">
    <h6 v-if="renderEmotions">Emotions</h6>
    <div class="rand-emo-div">
      <div class="emo-rang-col">
        <emotional-analysis v-if="renderEmotions" :emotional_data="emotionValenceData" :showValueLabel="false" emotion="valence" ref="valence"
          :labels="{
            left: $t('message.valence_left'),
            right: $t('message.valence_right')
          }">
        </emotional-analysis>
      </div>

      <div class="emo-rang-col">
        <emotional-analysis v-if="renderEmotions" :emotional_data="emotionArousalData" :showValueLabel="false" emotion="arousal" ref="arousal"
          :labels="{
            left: $t('message.arousal_left'),
            right: $t('message.arousal_right')
          }">
        </emotional-analysis>
      </div>

      <div class="emo-rang-col">
        <emotional-analysis v-if="renderEmotions" :emotional_data="emotionDominanceData" :showValueLabel="false" emotion="dominance"
          ref="dominance" :labels="{
            left: $t('message.dominance_left'),
            right: $t('message.dominance_right')
          }">
        </emotional-analysis>
      </div>
    </div>
</div>
  </div>
</template>

<script>
import Tooltip from '@/components/common/Tooltip.vue';
import SingleIaPicker from '@/components/target_pickers/sidebar_pickers/SingleIaPicker.vue';
import CoD3Chart from './CoD3Chart';
import EmotionalAnalysis from '@/components/content_tuner/EmotionalAnalysis';

export default {
  components: { SingleIaPicker, CoD3Chart, Tooltip, EmotionalAnalysis },
  emits: ['generate', 'iaSelected'],
  props: {
    buttonTooltipString: { required: true, type: String },
    editorTextImageEmpty: { required: true, type: Boolean },
  },
  data: function () {
    return {
      // used to tell if we want to show chart or not.
      selected_ia: {},
      renderChart: false,
      renderEmotions: false,
      ia_is_selected: false,
      emotionValenceData: { A: 0, B: 10 },
      emotionArousalData: { A: 0, B: 10 },
      emotionDominanceData: { A: 0, B: 10 },
      textComputed: false,
      textChanged: true,
    };
  },
  computed: {
    buttonDisabled: function () {
      return (
        !this.ia_is_selected || this.editorTextImageEmpty || !this.textChanged
      );
    },
  },
  methods: {
    getClass() {
      return {
        'btn3': !this.buttonDisabled,
        'disabled-btn': this.buttonDisabled
      }
    },
    reset: function () {
      this.$refs['ia-picker'].reset();
      this.renderChart = false;
      this.renderEmotions = false;
    },
    updateD3Chart: function (data, config) {
      this.$refs['co-d3-chart'].updateChart(data, config);
    },
    updateEmotionalScores: function (scores) {
      this.emotionValenceData = {
        A: scores[0].emotional_score.valence,
        B: scores[1].emotional_score.valence,
      };
      this.emotionArousalData = {
        A: scores[0].emotional_score.arousal,
        B: scores[1].emotional_score.arousal,
      };
      this.emotionDominanceData = {
        A: scores[0].emotional_score.dominance,
        B: scores[1].emotional_score.dominance,
      };
    },
    generateChart: function () {
      const selected_ia = this.$refs['ia-picker'].selected_intended_association;
      this.$emit('generate', selected_ia);
      this.selected_ia = selected_ia
      this.renderChart = true;
      this.textChanged = false;
    },
    /*
    Whenever IAs change in some way the sidebar needs to figure out if
    clicking the generate button is allowed.
    */
    onIaSelected: function (selected_ia) {
      this.textComputed = false;
      this.$emit('iaSelected', selected_ia);
      this.selected_ia = selected_ia;
      this.ia_is_selected = Object.keys(selected_ia).length > 0;
      this.textChanged = true;
    },
    editorContentChange: function () {
      this.textComputed = false;
      this.textChanged = true;
    },
    onIaChanged: function (value) {
      this.textComputed = false;
      if (value === null) {
        this.ia_is_selected = false;
      }
      return;
    },
    changeButtonState: function () {
      this.textComputed = true;
    },
  },
  watch: {
    ia_is_selected: function (value) {
      this.$emit('editorCoIaEmpty', !value);
    },

    // buttonDisabled(newValue) {
    //   if (!newValue) {
    //     this.generateChart();
    //   }
    // },
  },
};
</script>
<style scoped lang="scss">
.imgDiv {
  height: 2vw;
}

.emotions-col {
  padding: 1vw 2.5vw;
  margin-top: 37px;
  border-top: none;
}

.rightbox-sec span.btnText {
  text-align: center;
  line-height: 1.9vw;
}

.rightbox-sec .btn-col span.btnText {
  text-align: center !important;
  display: block;
  margin: 0;
}

.rightbox-sec span {
  color: #000;
  font-weight: 500;
  font-size: 1.1vw;
  line-height: 1.5vw;
  display: block;
  margin-top: 0.2vw;
  margin-bottom: 0.2vw;
  text-align: center;
}

.trg-assoc p {
  text-align: center;
  margin-bottom: 2vw;
  line-height: 1.7vw;
}

.rightbox-sec .trg-assoc span {
  font-size: 1.2vw;
  font-weight: 600;
  margin-bottom: 1vw;
}
</style>
