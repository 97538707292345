<template>
  <div class="w-100">
    <p>{{ $t('message.create_brand_story') }} {{ $t('message.draft_story') }}:</p>

    <p>{{ words.join(', ') }}</p>
    <button class="btn btn-small ai-button" style="color: blue;" v-if="!empty" @click="generateStory">
      <q-icon size="20px" name="eva-flash-outline" />
      {{ $t("message.generate") }}
    </button>

    <div class="textarea-wrapper">
      <textarea id="pathfinder-textarea" />
      <div>
        <a @click="copyToClipboard">{{ $t('message.copy_to_clipboard') }}</a>
      </div>
    </div>

    <div v-if="imageURL" style="display: flex; justify-content: flex-end; margin-right: 2vw; margin-top: 1vw;">
      <button style="font-size: 0.8vw;" class='buy-credits' @click="exportImage">Download image</button>
    </div>

    <!-- display the images below -->
    <div style="display: flex; justify-content: center; margin-top: 2vw; margin-bottom: 2vw;">
      <img :src="imageURL" style="width:40vw" id="image">
    </div>
  </div>

  <div v-if="imageURL" class="bye-credits-blue">
    <span>Like what you see? Add this concept to your Library. This triggers a quick check to map and validate
      the concept in the network!</span>
    <button class="btn1" @click="addToLibrary(imageURL)">Add Concept</button>
  </div>
  <loading-app-wide v-if="loading" />
</template>

<script>
import {
  alert_error,
  genericDialog,
  toast_success
} from '@/helpers/alert_helper.js';
import axios from 'axios'
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';


export default {
  components: {
        LoadingAppWide,
    },
  emits: ['generateStory'],
  data: function () {
    return {
      words: new Array,
      imageURL: "",
      image:"",
      loading:false,
    }
  },
  methods: {
    async exportImage() {
      try {
        var a = document.createElement("a"); //Create <a>
        a.href = this.image //Image Base64 Goes here
        a.download = "Image.png"; //File name Here
        a.click(); //Downloaded file
      } catch (error) {
        console.error('Error downloading image:', error);
      }
    },

    clearData: function () {
      this.words = new Array;
      this.setText('');
      this.imageURL = "";
    },
    getWord: function () {
      return this.words
    },
    generateStory: function () {
      this.imageURL = '';
      this.$emit('generateStory');
    },
    toggleWord: function (word) {
      if (this.words.includes(word)) {
        const index = this.words.indexOf(word);
        this.words.splice(index, 1);
      } else {
        this.words.push(word);
      }
    },
    copyToClipboard: function () {
      let textArea = document.getElementById('pathfinder-textarea');
      textArea.select();
      document.execCommand('copy');
    },
    setText: function (text) {
      let textArea = document.getElementById('pathfinder-textarea');
      textArea.innerText = text;
    },
    setImage: function (image) {
      this.image = image;
    },
    setImageURL: function (imageURL) {
      this.imageURL = imageURL;
    },
    addToLibrary: async function (imageURL) {
      const dialog_options = {
        title: this.$t('new_asset.upload_selected_asset'),
        text: this.$t('new_asset.this_will_cost', {
          price: 200
        }),
        confirmButtonText: this.$t("message.asset_creator_add"),
        cancelButtonText: this.$t("message.cancel"),
      };

      const dialog_result = await genericDialog(dialog_options);
      if (!dialog_result.isConfirmed) {
        return;
      }
      if (dialog_result.isConfirmed) {
        // next, check if the user has enough credits
        await this.$store.dispatch('get_mindcoin_balance');
        if (this.$store.state.mindcoins < 200) {
          alert_error(this.$t('new_asset.insufficient_credits'));
          return;
        }
        try {
          this.loading = true;
          const response = await axios.post('/api/cas/assets/story_creator', { "imageURL": imageURL });
          if (response.status == 204) {
            this.loading = false;
            toast_success(`Concept ordered successfully, you will be notified when your concepts(s) is analysed`);
            this.$store.dispatch('get_mindcoin_balance');
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  },
}
</script>
<style scoped lang="scss">
.textarea-wrapper {
  width: 98% !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 1px 6px #00000029;
  border: 1px solid #EAEAEA;
  border-radius: 25px;
  padding: 10px;
  display: flex;
  flex-flow: column;
}

.textarea-wrapper div {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  cursor: pointer;
}

.textarea-wrapper textarea {
  height: 100%;
  width: 100%;
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
  /*remove the resize handle on the bottom right*/
}

.w-100 {
  padding-left: 1vw !important;
  margin-top: 1vw !important;
}

.wt-box-col {
  text-align: left !important;
}
</style>
