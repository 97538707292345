const library_translation = {
    en: {
        message: {
            buy_asset: 'Do you want to add the Concept {asset}?',
            asset_cost: 'This Concept order will deduct {price} mindcoins.',
            insufficient_credits_for_asset: 'You have insufficient mindcoins to add this Concept.',
            purchased_asset: 'Purchased concept.',
            tracking_tooltip:
                "Tracking this Concept means we will monitor the position of your Concept in " +
                "the network on a monthly basis. This allows you to visualize in the Mind " +
                "Tracker application how fast your brand is moving in the direction of " +
                "your Intended Association for the Concept. It also allows you to monitor " +
                "the impact of your campaigns by comparing the semantic distance from your" +
                " Concept to its Intended Association pre vs post campaign. Please note that" +
                " activating this switch will cause an automatic deduction of mindcoins at" +
                " the end of your billing cycle corresponding to the type of Concept",
            tracking_confirm: 'Track concept in Brand Tracker',
            tracking_once:"Concept repositioning",
            reposition:'Order the concept once again',
            tracking_confirm_html1: 'Map this item monthly to get',
            tracking_confirm_html2: 'results in Brand Tracker',
            tracking_confirm_html3: 'mindcoins/month',
            track_monthly: 'track monthly',
            track_monthly_insufficient_credits: 'You have insufficient monthly mindcoin budget to enable tracking. Upgrade your license via settings.',
            asset_now_monitored: 'Concept {asset} is now being tracked.',
            asset_no_longer_monitored: 'Concept {asset} is no longer being tracked.',
            track_this_asset: 'Track this concept',
            library_instructions: 'Library instructions',
            positioned_assets: "Mapped Concepts",
            assets_being_positioned: "Concepts Being Mapped",
            only_tracked: "Only Tracked",
            branded: "Branded",
            not_branded: "Not Branded",
            newest: "Newest",
            oldest: "Oldest",
            alphabetical: "A - Z",
            reverse_alphabetical: "Z - A",
            rating_asc: "Rating (Lowest)",
            rating_desc: "Rating (Highest)",
            load_concept_from_store1: "Add Public Concepts",
            // load_concept_from_store2: "from Store",
            store_asset_description1: "Mapped & ready",
            store_asset_description2: "for benchmarking",
            videos_and_sounds: "Videos And Sounds",
            mapped: "Mapped",
            positioned_n_times: "({ n } times)",
            choose_tool: "Choose tool",
            add_to_my_library: "Add to my library",
            select_asset_to_add: "Select concept to add",
        },
    },
    nl: {
        message: {
            buy_asset: 'Wil je deze Concept {asset} toevoegen?', 
            asset_cost: 'Deze Concept vergt {price} mindcoins.', 
            insufficient_credits_for_asset: 'Je hebt onvoldoende credits om deze Concept toe te voegen.',
            purchased_asset: 'Toegevoegde concept.',
            tracking_tooltip:
                "Door deze Concept te volgen, zullen we maandelijks de gemapte positie van jouw " +
                "Concept in het netwerk updaten. Hierdoor kunt u in de Brand Tracker-" +
                "applicatie visualiseren hoe snel jouw merk in de richting van jouw " +
                "Gewenste Associatie beweegt. Hiermee kunt u ook de impact van jouw " +
                "campagnes volgen door de onbewuste associatie tussen jouw Concept " +
                "en je Gewenste Associatie voor en na jouw campagnes te vergelijken. Bemerk " +
                "dat de activatie van deze switch een automatische deductie van Mindcoins " +
                "triggert aan het einde van uw billing cycle in overeenstemming met het " +
                "type van Concept",
            tracking_confirm: 'Track concept met Brand Tracker', 
            tracking_once:"Herpositionering van activa",
            reposition:'Volg het bezit opnieuw',
            tracking_confirm_html1: 'Map deze Concept maandelijks voor', 
            tracking_confirm_html2: 'monitoring in Brand Tracker', 
            tracking_confirm_html3: 'mindcoins/maand', 
            track_monthly: 'track maandelijks',
            track_monthly_insufficient_credits: 'Je hebt onvoldoende mindcoins binnen je huidig maandelijks budget voor tracking. Upgrade je licentie via Instellingen.',
            asset_now_monitored: 'Concept {asset} wordt nu getracked.', 
            asset_no_longer_monitored: 'Concept {asset} wordt niet langer getracked.', 
            track_this_asset: 'Track deze concept',
            library_instructions: 'Library instructies', 
            positioned_assets: "Gemapte Concepten", 
            assets_being_positioned: "Concepten in verwerking",
            only_tracked: "Enkel getracked", 
            branded: "Merk gerelateerd", 
            not_branded: "Niet merk gerelateerd", 
            newest: "Nieuwste", 
            oldest: "Oudste", 
            alphabetical: "A - Z", 
            reverse_alphabetical: "Z - A",
            rating_asc: "Beoordeling (laagste)",
            rating_desc: "Beoordeling (hoogste)",
            load_concept_from_store1: "Voeg Publieke Concepts toe",
            // load_concept_from_store2: "vanuit Store",
            store_asset_description1: "Gemapped & klaar",
            store_asset_description2: "om te benchmarken",
            videos_and_sounds: "Videos en jingles", 
            mapped: "Gemapped", 
            positioned_n_times: "({ n } keer)", 
            choose_tool: "Kies app", 
            add_to_my_library: "Voeg toe aan mijn Library", 
            select_asset_to_add: "Kies Concept om toe te voegen", 
        },
    }
};

export { library_translation };
