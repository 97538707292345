<template>
  <q-dialog v-model="visible">
    <q-card>
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">{{ data.word }}</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup size="16" />
      </q-card-section>
      <q-card-section>
        <q-table
          :rows="rows"
          :columns="columns"
          row-key="association"
          :rows-per-page-options="[0]"
          :rows-per-page-label="$t('message.rows_per_page')">
        </q-table>
      </q-card-section>
    </q-card> 
  </q-dialog>
</template>

<script>
export default {
  props: {
    data: { required: true, type: Object },
  },
  data: function () {
    return {
      visible: false,
      columns: [
        { field: 'association', label: this.$t('message.association'), align: 'left' },
        { field: 'responses', label: this.$t('message.responses'), align: 'left' },
        { field: 'percentage', label: this.$t('message.percentage'), align: 'left' },
      ],
    }
  },
  computed: {
    rows: function() {
      const result = [];
      for (const a of this.data.children) {
        result.push({
          association: a.word.toLowerCase(),
          responses: (a.fas * this.data.nbResponses).toFixed(0),
          percentage: (a.fas * 100).toFixed(2),
        });
      }
      return result;
    },
  },
  methods: {
    open: function() {
      this.visible = true;
    },
    close: function() {
      this.visible = false;
    },
  },
}
</script>

<style scoped>
  button.q-btn.q-btn-item.non-selectable.no-outline.q-btn--flat.q-btn--round.q-btn--actionable.q-focusable.q-hoverable.q-btn--dense {
  position: absolute;
  right: 1vw;
  top: 3px;
}

.q-card.suggestion-card {
  padding: 0 2vw 2vw 2vw;
  width: 360px;
}
.q-card__section.q-card__section--vert.row.items-center.q-pb-none {
  margin-left: 0;
  padding-top: 2.5vw;
  text-transform: capitalize;
}

.q-card__section.q-card__section--vert.row.items-center.q-pb-none .text-h4 {
  font-weight: 600;
  font-size: 1.9vw;
  padding: 0 0 0.5vw;
  border-bottom: 1px #ccc solid;
  margin-bottom: 1vw;
}

.q-card__section.q-card__section--vert {
  padding: 0;
}
</style>
