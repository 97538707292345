<template>
    <section class="middle-section">
        <div class="container">
            <div class="inspiration-pg library-pg">
                <div class="popup-select-asset">

                    <div class="lft-col-md-1">
                        <Histogram @word-updated="handleWordUpdate" @survey-updated="handleSurveyUpdate"></Histogram>
                    </div>

                    <div class="rgt-col-md-1 scroll">
                        <Survey_side_bar :word="word" :survey="survey">
                        </Survey_side_bar>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import Histogram from './histogram.vue'
import Survey_side_bar from './survey_side_bar.vue';

export default {
    components: {
        Histogram,
        Survey_side_bar,
    },
    data() {
        return {
            word: "",
            survey: null,
        };
    },

    methods: {
        handleWordUpdate(updatedWord) {
            this.word = updatedWord; // Update the parent component's value
        },

        handleSurveyUpdate(updatedSurvey) {
            this.survey = updatedSurvey; // Update the parent component's value
        },

    },
};
</script>
  
<style scoped>
.rightBar.scroll {
    max-height: 100vh !important;
    overflow-x: hidden;
    margin-bottom: 50px;
    padding-top: 8vw;
}

.lft-col-md-1 {
    height: 100vh;
    padding-top: 7vw !important;
    overflow-y: auto;
}

.library-pg .popup-select-asset .rgt-col-md-1 {
    height: 100vh;
}

.rgt-col-md-1 {
    padding-top: 8vw;
}
</style>
  