<template>
  <div class="aw-information">
    <span class="aw-info-text">
      {{ $t('message.stability') }}: {{ stability.toFixed(2) }} - 
      {{ $t('message.number_of_responses') }}: {{ respondentCount }} -
      {{ $t('message.date') }}: <strong>{{ date }}</strong>
    </span>
    <br>
<!--    <span v-if="brandExperienceShown">-->
<!--      {{ $t('message.brand_experience') }}: {{ brandExperience }}-->
<!--    </span>-->
  </div>
</template>

<script>
export default {
  props: {
    stability: { required: true, type: Number },
    respondentCount: { required: true, type: Number },
    brandExperiencePercentage: { required: true, type: Number },
    date: { required: true, type: String },
  },
  computed: {
    brandExperience: function() {
      if (this.brandExperiencePercentage > 0.0) {
          return `${this.brandExperiencePercentage.toFixed(2)}%`;
      } else {
          return 'N/A';
      }
    },
    brandExperienceShown: function() {
      return this.brandExperiencePercentage > 0.0
    }
  },
}
</script>
<style scoped>
.aw-information {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.aw-info-text {
  font-size: 12px;
}
</style>
