<template>
    <div class="coupons">
      <div class="selected-license-info">
        <span class="license-name"
              :key="selectedLicense">{{ selectedLicense.name }}</span>
        <span v-if="itemPrice && !priceComparison" class="license-fee" :key="selectedLicense">{{
            itemPrice
          }}€</span>
        <span v-else>
          <s>{{ oldPrice }}€</s>
          {{ itemPrice }}€
        </span>
      </div>
      <input :disabled="priceComparison" :v-model="couponCode" placeholder="Enter optional discount coupon..."
             class="login-input" type="text"/>
      <button :disabled="priceComparison" class="btn btn-outline" @click="validateCoupon">APPLY
      </button>
      <transition name="fade" mode="out-in">
        <div class="coupon-error" v-if="!couponValid">Given code is
          invalid or has expired.
        </div>
      </transition>
      <transition name="fade" mode="out-in">
        <div class="coupon-success" v-if="couponApplied">
          Coupon code {{ couponCode }}<br> has been applied!
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "DiscountCodes",
    props: {
      selectedLicense: { required: true, type: Object },
      itemPrice: { required: true, type: Boolean, default: false },
      oldPrice: { required: true, type: Number, default: 0 },
      priceComparison: { required: true, type: Boolean, default: false },
      couponValid: { required: true, type: Boolean },
      couponApplied: { required: true, type: Boolean, default: false },
      couponCode: { required: true, type: String, default: '' },
    },
    emits: ['changePlan'],
    methods: {
      validateCoupon: async function () {
        var code_exists = false;
        var coupon = this.couponCode;
        var current_org = this.$store.state.current_org;
        const response = await axios.post("/api/cas/discount_code/exists", {'coupon': coupon, 'current_org': current_org}).then(response => {
          code_exists = response.data;
        });
        if (code_exists) {
          this.couponValid = true;
          this.couponApplied = true;
          this.$emit('changePlan', this.selectedLicense)
        } else {
          this.couponValid = false;
          this.couponApplied = false;
        }
      },
    },
    computed: {},
    data: function() {
      return {
      }
    },
  }
  </script>
  
  <style scoped lang="scss">
  .coupons {
    display: flex;
    flex-flow: column;
  }
  
  .coupon-error {
    width: 100%;
    color: red;
    display: flex;
    justify-content: center;
  }
  
  .coupon-success {
    width: 100%;
    color: green;
    display: flex;
    justify-content: center;
  }
  
  .login-input {
    width: 100%;
    border-radius: 50px;
    line-height: 50px;
    border-color: #e2e2e2;
    border-style: solid;
    padding-left: 15px;
    font-family: 'Open Sans';
    font-size: 0.8rem;
  }
  
  .login-input:focus {
    outline: none !important;
    box-shadow: 0 0 5px $mdspblue_ui;
    border-color: transparent;
  }
  
  .login-label {
    font-family: 'Open Sans';
    padding: 0 0 30px 10px;
    color: #8b8484;
    font-size: 0.8rem;
  }
  
  .selected-license-info {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: end;
    margin: 10px auto 5px auto;
  }
  
  .license-name {
    font-size: 1.2rem;
  }
  
  .license-fee {
    font-size: 1rem;
  }
  </style>