<template>
  <page-title
    title="Inspiration Explorer"
    :desc="this.$t('message.ie')"
    :instructionsVideoTitle="$t('message.explorer_instructions')"
    instructionsName="e_instructions_main"
    :instructionsVideoUrl="
      $store.getters.getInstructionalVideoUrl('e_instructions_main')
    "
  >
  </page-title>
  <div class="explorer-text" v-if="empty">
    Your Inspiration Explorer results will appear here
  </div>
  <div
    class="inspir-img-box"
    v-bind:style="{
      paddingRight: empty ? '' : '2vw',
      width: empty ? '100%' : '65%',
    }"
  >
    <div v-bind:style="{ display: empty ? 'block' : 'none' }">
      <workspace-empty
        v-if="empty"
        app_name="Inspiration Explorer"
        background="img2.png"
      >
      </workspace-empty>
    </div>
    <div
      class="exchart"
      v-bind:style="{
        width: empty ? '0%' : '100%',
        height: empty ? '0px' : '110px',
        visibility: empty ? 'hidden' : 'visible',
      }"
    >
      <explorer-chart ref="chart"> </explorer-chart>
    </div>
  </div>

  <div class="link-bx-div" v-if="!empty">
    <div class="col-md-8 rgt-link-col bottom-lnk">
      <ul>
        <li>
          <a @click="$emit('reset')"
            ><span><img src="../../assets/icon9.png"/></span>
            {{ $t('message.reset') }}</a
          >
        </li>
        <li>
          <a @click="exportChart"
            ><span><img src="../../assets/icon10.png"/></span
            >{{ $t('message.export') }}</a
          >
        </li>
        <!-- <li>
            <a href="#"
              ><span><img src="img/icon11.png"/></span
            ></a>
          </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import ExplorerChart from './ExplorerChart.vue';
import Tooltip from '@/components/common/Tooltip.vue';
import WorkspaceEmpty from '@/components/common/WorkspaceEmpty.vue';
import PageTitle from '@/components/common/PageTitle.vue';

export default {
  components: {
    WorkspaceEmpty,
    Tooltip,
    ExplorerChart,
    PageTitle,
  },
  emits: ['reset'],
  data: function() {
    return {
      empty: true,
    };
  },
  methods: {
    reset: function() {
      this.empty = true;
    },
    generate: async function(selected_ias) {
      const load = await this.$refs['chart'].load(selected_ias);
      this.empty = false;
    },
    exportChart: function() {
      const svg = document.querySelector('#explorer-graph svg');
      // using our custom D3export plugin to export the PNG
      this.$exportD3.saveSvgAsPng(svg, 'explorer_graph.png', {
        scale: 2,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.np-editor {
  padding: 35px 50px;
  height: 100%;
  overflow: hidden;
}

.main {
  display: flex;
  flex-flow: column;
  z-index: 1;
  -moz-transition: 400ms width ease;
  -o-transition: 400ms width ease;
  -webkit-transition: 400ms width ease;
  transition: 400ms width ease;
  // @media (min-width: 1024px) {
  //   width: calc(100vw - #{$sidebar-small});
  // }

  @media (min-width: 1600px) {
    width: calc(100vw - #{$sidebar-medium});
  }

  @media (min-width: 1920px) {
    width: calc(100vw - #{$sidebar-big});
  }
}

.main-editor {
  width: 100%;
}

.main .main-editor .editor .title {
  color: #343a40;
  font-size: 1.3125rem;
  margin: 0 0 5px 0;
  display: block;
}

.main .main-editor .editor {
  flex-basis: initial;
  padding: 10px 50px 0 50px;
}

.state {
  width: 100%;
  display: flex;

  @media (max-width: 1280px) {
    height: calc(100% - #{$navbar-small});
  }

  @media (min-width: 1281px) {
    height: calc(100% - #{$navbar-big});
  }
}

.main-footer {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding: 0 15px 35px 0px;
  width: 200px;
}

li {
  cursor: pointer;
}

.exchart {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 27vw;
  width: 100%;
  margin-top: 4vw;
  // margin-bottom: 2vw;
}

.inspir-img-box {
  margin-top: 4vw;
}
</style>
