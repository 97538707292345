<template>
<div class="left-checkbx-div" v-if="!empty" style="width: 24% !important">
    <word-list :data="data" ref="word-list" @wordSelected="onWordSelected" @wordUnselected="onWordUnselected">
    </word-list>
</div>
<div class="inspir-img-div" v-bind:style="{display: empty ? 'none' : 'block'}">
    <div id="explorer-graph"></div>
</div>
</template>

<script>
import axios from 'axios';
import ExplorerChart from './explorer.js';
import {
    alert_error
} from '@/helpers/alert_helper.js';
import WordList from './WordList.vue';

export default {
    components: {
        WordList
    },
    emits: ['wordAdded', 'wordRemoved'],
    data: function () {
        return {
            data: {},
            d3_chart: {
                data: {}
            },
            empty: true,
        };
    },
    methods: {
        load: async function (ias, amount = 50) {
            const ias_ids = Array.from(ias).map((ia) => ia.word_id);
            ias_ids.sort((a, b) => a - b);
            const payload = {
                amount: amount,
                words: ias_ids
            };
            try {
                const response = await axios.post('/api/cas/explorer/analyze', payload);
                let data = response.data.Results;
                data = data.slice(0, 25);
                this.data = data;
                this.d3_chart = new ExplorerChart('explorer-graph', data, this);
                this.empty = false;
            } catch (error) {
                if (error.response.status === 403) {
                    alert_error(this.$t('message.no_access_to_items'));
                } else {
                    alert_error(this.$t('message.general_server_error'));
                }
            }
        },
        onWordSelected: function (word) {
            this.d3_chart.clicked(word);
        },
        onWordUnselected: function (word) {
            this.d3_chart.clicked(word);
        },
        /* Called from D3 */
        addWordToList: function (word) {
            this.$refs['word-list'].externalSelectWord(word);
        },
        /* Called from D3 */
        removeWordFromList: function (word) {
            this.$refs['word-list'].externalUnselectWord(word);
        },
        exportChart: function () {
            const svg = document.querySelector('#explorer-graph svg');
            this.$exportD3.saveSvgAsPng(svg, 'Inspiration Explorer.png', {
                scale: 2,
            });
        },
    },
};
</script>

<style>
.explorer-result-wrapper {
    display: flex;
    visibility: visible;
    width: 100%;
    height: 100%;
}

#explorer-graph {
    margin-top: 30px;
    flex: 1;
    padding: 5px;
    height: calc(100% - 30px);
}

#explorer-graph svg {
    width: 800px;
    height: 100%;
}

#sub-graph-originality .area {
    fill: url(#color-gradient-originality);
    stroke-width: 5px;
}

#sub-graph-popularity .area {
    fill: url(#color-gradient-popularity);
    stroke-width: 5px;
}

.main.has-footer .main-sidebar,
#main.has-footer .main-editor {}

.main .main-sidebar-new {
    float: right;
    padding: 5px 5px 5px 5px;
}

/* .sidebar-title {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    color: #343a40;
    font-weight: 700;
    margin-bottom: 13px;
    margin-left: 10px;
}

.sidebar-desc {
    color: #868e96;
    font-size: 0.75rem;
    line-height: normal;
    margin-bottom: 30px;
    margin-left: 10px;
} */

/* .main-sidebar-new {
    width: 280px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
} */

/* .inspir-img-div {
    width: 78% !important;
        height: 28vw !important;
} */

/* .left-checkbx-div {
    width: 24% !important;
} */
</style>
