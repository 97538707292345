<template>
    <div class="maintenance">
        <div>
            <h2>We are currently performing maintenance work...</h2>
            <div style="display: flex; justify-content: center;">
                <p> preventing temporary access to your account </p>
            </div>
            <div style="display: flex; justify-content: center;">
                <p>If you don't have access within the next 30 minutes, please contact your <a
                        href="mailto:contact@mindspeller.com">account manager</a> </p>
            </div>

        </div>
    </div>
</template>
  
<style>
.maintenance {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
</style>
  