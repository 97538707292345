<template>
  <q-dialog v-model="visible">
      <q-card class="instruction-card">
        <q-card-section class="row">
          <div class="text-h5">{{ $t('message.your_documents') }}</div>
          <q-space />
          <q-btn icon="close" size="16px" flat round dense v-close-popup/>
        </q-card-section>
        <q-card-section>
          <document
            v-if="documents.length>0"
            v-for="doc in sorted_documents"
            :key="doc.id"
            :document="doc"
            @openDocument="onOpenDocument"
            @deleteDocument="onDeleteDocument">
          </document>
          <span v-if="documents.length===0">
            {{ $t('message.no_documents') }}
          </span>
        </q-card-section>
        <q-card-section class="row">
          <a class="btn3" @click="hide">
            {{ $t('message.cancel') }}
          </a>
        </q-card-section>
      </q-card>
  </q-dialog>
</template>

<script>
import axios from "axios";
import Document from "./Document.vue";
import { toast_success, genericDialog } from '@/helpers/alert_helper.js';

export default {
  components: { Document },
  emits: ['openDocument'],
  data: function() {
    return {
      visible: false,
      documents: [],
    }
  },
  computed: {
    // sort documents by updated_at DESC
    sorted_documents: function () {
      return this.documents.sort(
        (a, b) => 0 - a.updated_at.localeCompare(b.updated_at)
      );
    }
  },
  methods: {
    show: function() {
      this.fetchDocuments();
      this.visible = true;
    },
    hide: function() {
      this.visible = false;
    },
    fetchDocuments: async function() {
      const response = await axios.get('/api/cas/copy_opt/document/all');
      this.documents = response.data;
    },
    onOpenDocument: async function(document) {
      this.$emit('openDocument', document);
      this.hide();
    },
    onDeleteDocument: async function(document) {
      const dialog_options = {
        title: this.$t("message.document_delete_confirm"),
        confirmButtonText: this.$t("message.delete"),
        cancelButtonText: this.$t("message.cancel"),
      };
      const dialog_result = await genericDialog(dialog_options);
      
      if (dialog_result.isConfirmed) {
        const response = await axios.delete(
          '/api/cas/copy_opt/document/delete',
          { data: document }
        );
        toast_success(this.$t("message.document_deleted"));
        this.fetchDocuments();
      }
    },
  },
}
</script>

<style scoped>
.document_list {
    overflow-y: auto;
    height:400px;
}

button.q-btn.q-btn-item.non-selectable.no-outline.q-btn--flat.q-btn--round.q-btn--actionable.q-focusable.q-hoverable.q-btn--dense {
    position: absolute;
    right: -5px;
    top: -9px;
    z-index: 1;
}


.q-card.instruction-card {
    width: 406px;
    overflow: hidden;
    padding: 1vw;
}

.q-card__section.q-card__section--vert {
    font-size: 1.2vw;
    display: block;
}

.text-h5 {
font-weight: 600;
    padding: 1.2vw 0 0 0;
    text-align: left;
    font-size: 1.5vw;
}

.q-card__section.q-card__section--vert.row {
    display: block;
    width: 100%;
    margin: 0;
    text-align: right;
    padding: 0;
}

.q-card__section.q-card__section--vert {
    padding-left: 0;
    padding-bottom: 2vw;
text-align: left;
}


.q-card__section.q-card__section--vert.row a.btn3 {
    width: auto;
}
</style>
