<template>
  <div class="btn-col" v-if="showButton">
    <button class="btn3" :title="asset_empty ? $t('message.select_asset_before_adding') : null"
      :disabled="selected_asset.state == 1" @click="$emit('add')">
      <div v-if="selected_asset.state === 1">
        Asset not ready
      </div>
      <div v-else>
        {{ $t('message.add') }}
      </div>
    </button>
  </div>
</template>

<script>
export default {
  emits: ['add', 'close'],
  props: {
    selected_asset: { required: true, type: Object },
  },
  computed: {
    // asset_empty: function() {
    //     return Object.keys(this.selected_asset).length === 0;
    // },
    showButton: function () {
      return Object.keys(this.selected_asset).length > 0;
    },
  },
  methods: {
    //    getClass(){
    //     return {
    //         'btn3': !this.asset_empty,
    //         'disabled-btn': this.asset_empty
    //         }
    // },
  },
};
</script>
<style scoped lang="scss">
.asset-picker-button {
  position: absolute;
  bottom: 0;
  right: 0;
  overflow-y: scroll;

  @media (min-width: 1024px) {
    right: ($sidebar-small - 200px) / 2;
  }

  @media (min-width: 1600px) {
    right: ($sidebar-medium - 200px) / 2;
  }

  @media (min-width: 1920px) {
    right: ($sidebar-big - 200px) / 2;
  }
}

.search-bar {
  margin: 55px 0 0 1px;
}
</style>
