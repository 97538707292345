<template>
  <div class="tip" @click="openInstructions">
    <q-tooltip>{{ tooltipText }}</q-tooltip>
    <img class="cursor-p"  src="@/assets/icons/video.svg" style="width:30px " />
    <instructions-video
      ref="instructions-video"
      :videoUrl="instructionsVideoUrl"
      :videoTitle="instructionsVideoTitle"
      :videoName="instructionsName"
      :showSupressFooter="showSupressFooter">
    </instructions-video>
  </div>
</template>

<script>
import InstructionsVideo from '@/components/common/InstructionsVideo.vue';

export default {
  components: { InstructionsVideo },
  props: {
    tooltipText: { required: true, type: String },
    instructionsVideoUrl: { required: true, type: String },
    instructionsVideoTitle: { required: true, type: String },
    instructionsName: { required: true, type: String },
    // Cancels instructional video
    suppressVideo: { type: Boolean, default: false },
    showSupressFooter: { type: Boolean, default: true }
  },
  methods: {
    openInstructions: function() {
      this.$refs['instructions-video'].open();
    },
  },
  mounted: function() {
    if (this.instructionsVideoUrl === "" ) { return }
    const dontShowInstructions = localStorage.getItem(this.instructionsName);
    if (dontShowInstructions === null && !this.suppressVideo) {
        this.openInstructions();
    }
  },
}
</script>
