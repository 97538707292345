<template>
    <div class="taskbar">
        <div v-for="ap in selected_assets_and_positionings" :key="ap.asset.id" class="asset-display-btn">
            <img v-if="ap.asset.type_.isImage" :src="ap.asset.urls.thumb_small" class="thumbnail" alt='Click to enlarge' @click="openFullScreenPreview(ap.asset)"/>
            <span>{{ ap.asset.display_name }}</span>
            <button class="delete-btn" @click="onRemoveAsset(ap)"><font-awesome-icon :icon="['fas', 'trash-alt']" style="color: #e05252;" /></button>
        </div>
    
        <full-screen-preview :asset="selectedAssetForPreview" v-if="selectedAssetForPreview" ref="fullscreen_preview"></full-screen-preview>
    
        <asset-picker ref="asset-picker" :show_positionings="show_positionings" :allowed_types="allowed_types" @addAsset="onAddAsset" @wordSelected="handleWordSelected">
        </asset-picker>
    
        <select class="visualization-select" v-model="selectedVisualization" @change="emitSelectedVisualization" :disabled="selected_assets_and_positionings.size === 0">
            <option disabled value="">Select Visualization</option>
            <option value="pie">Sunburst</option>
            <option value="histogram">Histogram</option>
            <option value="wordcloud">Word cloud</option>
            <option value="table">Association List</option>
        </select>
    
        <loading-app-wide v-if="loading" />
        <div v-if="selected_assets_and_positionings.size > 0">
            <button class="reveal-btn" @click="openImageModal(data)">
                Reveal image
            </button>
        </div>
        <q-dialog v-model="imageModalVisible" ref="myDialog" @show="onShow" @hide="onHide">
            <q-card>
                <!-- Draggable bar -->
                <q-bar class="bg-primary text-white" :class="draggable ? 'cursor-move' : ''">
                <q-space />
                </q-bar>

                <!-- Card Section with instructions -->
                <q-card-section>
                <p style="text-align: center; font-size: larger; font-weight: bolder;">Choose an image to add to your library</p>
                <p style="font-weight: lighter; font-size: small;">
                    (<b>Select the image</b> to add as a concept in the upcoming mapping survey. You will be notified when your concept is ready for testing)
                </p>
                </q-card-section>

                <!-- Display logic for clickable images -->
                <div v-if="dataImageURL && comparisonImageURL" style="display: flex; justify-content: space-around; margin: 0 auto; margin-bottom: 2vw;">
                <!-- AI response image -->
                <div :class="{'selected-image': selectedImageURL === comparisonImageURL}" style="text-align: center; cursor: pointer;" @click="selectImage(comparisonImageURL)">
                    <p style="font-weight: bold;"> From AI Responses</p>
                    <a class="download-link" @click="exportImage(comparisonImageURL)" style="text-align: end;margin-right: 65px;">Download</a>
                    <img :src="comparisonImageURL" style="width: 280px;" id="comparison-image" />
                </div>
                <!-- Human response image -->
                <div :class="{'selected-image': selectedImageURL === dataImageURL}" style="text-align: center; cursor: pointer;" @click="selectImage(dataImageURL)">
                    <p style="font-weight: bold;"> From Human Responses</p>
                    <a class="download-link" @click="exportImage(dataImageURL)" style="text-align: end;margin-right: 65px;">Download</a>
                    <img :src="dataImageURL" style="width: 280px;" id="data-image" />
                </div>


                </div>

                <!-- Single image display when only one image is available -->
                <div v-else-if="dataImageURL" :class="{'selected-image': selectedImageURL === dataImageURL}" style="text-align: center; margin: 0 auto; margin-bottom: 2vw;" @click="selectImage(dataImageURL)">
                <p style="font-weight: bold;"> From Human Responses</p>
                <a class="download-link" @click="exportImage(dataImageURL)" style="text-align: end;margin-right: 65px;">Download</a>
                <img :src="dataImageURL" style="width: 280px;" id="data-image" />
                </div>

                <div v-else-if="comparisonImageURL" :class="{'selected-image': selectedImageURL === comparisonImageURL}" style="text-align: center; margin: 0 auto; margin-bottom: 2vw;" @click="selectImage(comparisonImageURL)">
                <p style="font-weight: bold;"> From AI Responses</p>
                <a class="download-link" @click="exportImage(comparisonImageURL)" style="text-align: end;margin-right: 65px;">Download</a>
                <img :src="comparisonImageURL" style="width: 280px;" id="comparison-image" />
                </div>

                <!-- Message when no images are available -->
                <div v-else style="text-align: center; margin: 0 auto; margin-bottom: 2vw;">
                <p>No images available to display.</p>
                </div>

                <!-- Card actions -->
                <q-card-actions>
                <div style="display: flex; justify-content: flex-end; margin-right: 1.3vw;">
                    <btn style="background-color: #92a0b3 !important; margin-right: 1vw;" class="button_pay" @click="closeImageModal">Cancel</btn>
                    <btn class="button_pay" @click="addToLibrary">Add Concept</btn>
                </div>
                </q-card-actions>
            </q-card>
            </q-dialog>
    
        <div class="search-and-add">
            <button class="library-btn" @click="openAssetPicker(canvasId)" :disabled="selected_assets_and_positionings.size > 0">
                <font-awesome-icon :icon="['fas', 'plus']" style="color: #ffffff; margin-right: 5px;" /> Add Concept
            </button>
        </div>
    </div>
    </template>
    
    <script>
    import axios from 'axios';
    import AssetPicker from './AssetPicker.vue';
    import AssetCheckbox from '@/components/target_pickers/assets/AssetCheckbox.vue';
    import WordPicker from './WordPicker.vue';
    import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
    import FullScreenPreview from '@/components/common/FullScreenPreview.vue';
    import { toast_success, genericDialog, alert_error } from '@/helpers/alert_helper.js';
    
    export default {
        components: {
            AssetCheckbox,
            AssetPicker,
            LoadingAppWide,
            WordPicker,
            FullScreenPreview,
        },
        emits: ['assetAdded', 'assetRemoved', 'onTotalAssetsCount'],
        computed: {
            isDisabled() {
                return this.wordAdded;  // Return true if a word has been added, disabling the component
            }
        },
        props: {
            canvasId: {
                type: String,
                required: true
            },
            data: {
                type: Object,
                default: () => ({})
            },
            comparisonData: {
                type: Object,
                default: () => ({})
            },
            show_positionings: {
                default: false,
                type: Boolean,
            },
            allowed_types: {
                default: ['images', 'words', 'multimedia'],
                type: Array,
            },
        },
        data: function () {
            return {
                selected_assets_and_positionings: new Set(),
                selectedVisualization: '',
                dummy: null,
                swDialog: false,
                target: null,
                nodeDragg: null,
                imageURL: '',
                image: '',
                afterloading: false,
                imageModalVisible: false,
                proportion: null,
                loading: false,
                showInformation: true,
                wordAdded: false,
                selectedAssetForPreview: null,
                dataImageURL: '',
                comparisonImageURL: '',
                selectedImageURL: '',
            };
        },
    
        methods: {
            reset: function () {
                this.selected_assets_and_positionings.clear();
            },
            emitSelectedVisualization() {

    
                if (this.currentAssetDetails) {
                    this.$emit('visualizationTypeSelected', {
                    type: this.selectedVisualization,
                    asset_and_positioning: this.currentAssetDetails.asset_and_positioning || 0,
                    canvasId: this.canvasId
                    });
                } else {
                    console.error("No asset details available to add with visualization type");
                }
            },
            onRemoveAsset(asset_and_positioning) {
            
                this.selected_assets_and_positionings.delete(asset_and_positioning);
                this.$emit('assetRemoved', {asset_and_positioning, canvasId: this.canvasId});
                this.$emit('onTotalAssetsCount', this.selected_assets_and_positionings);
                this.selectedVisualization = '';
            },
            openAssetPicker: function (canvasId) {
                this.$refs['asset-picker'].openAssetPicker(canvasId);
            },
            onAddAsset(asset_and_positioning, canvasId) {
                if (asset_and_positioning && asset_and_positioning.asset) {
                    this.selected_assets_and_positionings.add(asset_and_positioning);
                    this.currentAssetDetails = { asset_and_positioning } || 0; // Store details correctly
                    this.selectedVisualization = 'pie';
                    this.emitSelectedVisualization();
                    this.$emit('assetAdded', { asset_and_positioning, canvasId });
                    const msg = this.$t('message.added', { words: asset_and_positioning.asset.display_name });
                    toast_success(msg);
                } else {
                    console.error("Attempted to add invalid asset positioning", asset_and_positioning);
                }
    
            },
    
            openFullScreenPreview(asset) {
                this.selectedAssetForPreview = asset;
                this.$nextTick(() => {
                this.$refs.fullscreen_preview.open();
                });
            },
            /*
                Since the rest of the component (and it's children) only expect assets,
                we have to create a "mock" asset before adding it to the list.
                */
            handleWordSelected(word) {
                if (this.selected_assets_and_positionings.size >= 1) {
                    // Show error message if more than one asset exists
                    alert('Cannot add more than one asset. Please open another monitor.');
                    return; // Abort the function to prevent adding new assets
                }
                // we look through the mocked objects in selected_assets_and_positionings
                // to see whether the mock of this word has already been added to the
                // list
                const match = Array.from(this.selected_assets_and_positionings).find(
                    (ap) => ap.mock && ap.asset.word.word_id == word.word_id
                );
                if (typeof match === 'undefined') {
                    // we now mock an asset based on the selected word
                    const word_type = {
                        id: 1,
                        title: 'word',
                        isMultimedia: false,
                        isImage: false,
                        isAudio: false,
                        isVideo: false,
                    };
                    const mock_asset = {
                        id: word.word_id,
                        state: 2,
                        user: {},
                        display_name: word.word,
                        word: {
                            word: word.word,
                            brand: 0,
                            asset_mime_type: '',
                            file_identifier: '',
                            external: 0,
                            word_id: word.word_id,
                            type_: word_type,
                            image_url: '',
                        },
                        candidate_word: [],
                        asset_mime_type: '',
                        file_identifier: '',
                        type_: word_type,
                        urls: {},
                    };
                    const payload = {
                        mock: true, // we mark this object as a mock
                        asset: mock_asset,
                        positioning: {},
                    };
                    this.onAddAsset(payload);
                    this.wordAdded = true;
                } else {
                    alert_error(this.$t('message.duplicate_ia'));
                    this.wordAdded = false;
                }
            },
    
//AwChart components:

        onShow() {
            let dialogElements = document.getElementsByClassName("q-dialog");
            this.target = dialogElements[0].querySelector(".q-card");
            this.nodeDragg = this.target.querySelector(".q-bar");
            if (this.draggable) {
                this.nodeDragg.addEventListener("mousedown", this.onGrab);
            }

            this.$emit("onShow");
        },
        onHide() {
            if (this.draggable) {
                document.removeEventListener("mousemove", this.onDrag);
                document.removeEventListener("mouseup", this.onLetGo);
                this.nodeDragg.removeEventListener("mousedown", this.onGrab);
            }
            this.$emit("onHide");
        },
        onDrag(e) {
            let originalStyles = window.getComputedStyle(this.target);
            this.target.style.left =
                parseInt(originalStyles.left) + e.movementX + "px";
            this.target.style.top = parseInt(originalStyles.top) + e.movementY + "px";
        },

        onLetGo() {
            document.removeEventListener("mousemove", this.onDrag);
            document.removeEventListener("mouseup", this.onLetGo);
        },

        onGrab() {
            document.addEventListener("mousemove", this.onDrag);
            document.addEventListener("mouseup", this.onLetGo);
        },
        selectImage(imageURL) {
            this.selectedImageURL = imageURL;
        },

        async addToLibrary(imageURL) {
            if (!this.selectedImageURL) {
                alert_error("Please select an image to add.");
                return;
            }
            const dialog_options = {
                title: "Upload this image to Your Library",
                text: this.$t('new_asset.this_will_cost', {
                    price: 200
                }),
                confirmButtonText: this.$t("message.asset_creator_add"),
                cancelButtonText: this.$t("message.cancel"),
            };

            const dialog_result = await genericDialog(dialog_options);
            if (!dialog_result.isConfirmed) {
                return;
            }
            if (dialog_result.isConfirmed) {
                // next, check if the user has enough credits
                await this.$store.dispatch('get_mindcoin_balance');
                if (this.$store.state.mindcoins < 200) {
                    alert_error(this.$t('new_asset.insufficient_credits'));
                    return;
                }
                try {
                    this.loading = true;
                    this.imageModalVisible = false;
                    console.log(this.selectedImageURL);
                    const response = await axios.post('/api/cas/assets/story_creator', { "imageURL": this.selectedImageURL });
                    this.loading = false;
                    if (response.status == 204) {
                        toast_success(`Concept ordered successfully, you will be notified when your concept(s) is analysed`);
                        this.$store.dispatch('get_mindcoin_balance');
                    }
                } catch (error) {
                    if (error.response.status === 500) {
                        alert_error("Our generative AI was unable to return an image due to one or more associations not passing our content moderation filters");
                        
                    } else {
                        alert_error(this.$t('message.general_server_error'));
                    }
                }
            }
        },


        exportImage(imageURL) {
            try {
            const a = document.createElement("a"); // Create <a> element
            a.href = imageURL; // Use the image URL passed as a parameter
            a.download = "Image.png"; // Set the file name for the downloaded image
            a.click(); // Trigger the download
            } catch (error) {
            console.error("Error downloading image:", error);
            }
        },

        async openImageModal(data) {
            const canvasData = this.data;  // Retrieve data from cache
            const canvasComparisonData = this.comparisonData;
            if (!canvasData && !canvasComparisonData) {
                console.error("Neither data nor comparison data available.");
                return;
            }
            console.log(canvasData);
            console.log("comp", canvasComparisonData);
            const dialog_options = {
                title: 'Generate images',
                text: "Image generation costs 10 mindcoins per response (AI / Human)",
                confirmButtonText: "Yes",
                cancelButtonText: "Cancel",
            };
            const dialog_result = await genericDialog(dialog_options);

            if (!dialog_result.isConfirmed) {
                return;
            }

            try {
                // Ensure enough mindcoins
                await this.$store.dispatch('get_mindcoin_balance');
                const cost = (canvasData && canvasComparisonData) ? 20 : 10;
                if (this.$store.state.mindcoins < cost) {
                    alert_error(this.$t('new_asset.insufficient_credits'));
                    return;
                }

                this.loading = true;

                let dataImageURL = null;
                let comparisonImageURL = null;

                // Handle Human response (data)
                if (canvasData && canvasData.children && canvasData.children.length !== 0) {
                    await this.$store.dispatch('get_mindcoin_balance');
                    const cost = 10;
                    if (this.$store.state.mindcoins < cost) {
                        alert_error(this.$t('new_asset.insufficient_credits'));
                        return;
                    }
                    const words = canvasData.children
                        ? canvasData.children.slice(0, 20).map(child => `${child.word}: ${child.percentViz}`).join(', ')
                        : '';
                    const payloadData = { description: words };
                    console.log("data payload", payloadData);
                    
                    // First API call for Human response
                    const response = await axios.post('/api/cas/association_wheels/image_generator', payloadData);
                    dataImageURL = response.data.image;
                }

                // Handle AI response (comparisonData)
                if (canvasComparisonData && canvasComparisonData.chatgpt_responses && canvasComparisonData.chatgpt_responses.length !== 0) {
                    await this.$store.dispatch('get_mindcoin_balance');
                    const cost = 10;
                    if (this.$store.state.mindcoins < cost) {
                        alert_error(this.$t('new_asset.insufficient_credits'));
                        return;
                    }
                    const comparisonWords = canvasComparisonData.chatgpt_responses
                        ? canvasComparisonData.chatgpt_responses.slice(0, 20).map(child => `${child.word}: ${child.fas}`).join(', ')
                        : '';
                    const payloadComparison = { description: comparisonWords };
                    console.log("comparison payload", payloadComparison);
                    
                    // Second API call for AI response (after the first call is completed)
                    const response = await axios.post('/api/cas/association_wheels/image_generator', payloadComparison);
                    comparisonImageURL = response.data.image;
                }

                // Store the URLs
                this.dataImageURL = dataImageURL;
                this.comparisonImageURL = comparisonImageURL;
                this.selectedImageURL = '';

                this.loading = false;
                this.imageModalVisible = true;
            } catch (error) {
                console.error(error);
                alert_error(this.$t('message.general_server_error'));
                this.loading = false;
            }
        },


        closeImageModal() {
            this.imageModalVisible = false;
        },
        },
    };
    </script>
    
    <style lang="scss" scoped>
    /* Styles are retained as provided to ensure consistent appearance and behavior. */
    .taskbar {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        padding: 10px;
        background-color: transparent;
    }
    
    .asset-display-btn {
        display: flex;
        align-items: center;
        padding: 4px 5px;
        background-color: #ffffff;
        border: 2px solid #007bff;
        color: black;
        border-radius: 5px;
        cursor: pointer;
        font-size: 15px;
    }
    
    .thumbnail {
        width: 40px;
        min-height: 20px;
        max-height: 40px;
        margin-right: 5px;
        border-radius: 5px;
        cursor: pointer;
    }
    
    button {
        margin-left: auto;
        padding: 5px 10px;
        background-color: red;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
    
    .visualization-select {
        padding: 12px 15px;
        margin-right: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: white;
        color: #333;
        font-size: 20px;
        &:disabled {
            background-color: #eee;
            color: #aaa;
            cursor: not-allowed;
        }
    }
    
    .library-btn {
        top: 5px;
        right: 5px;
        padding: 9px 10px;
        border: none;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        border-radius: 5px;
        font-size: 20px;
    }
    
    .search-and-add {
        display: flex;
        align-items: center;
        margin-left: auto;
    }
    
    .compact-search {
        padding: 4px 8px;
        margin-right: 5px;
        font-size: 14px;
    }
    
    .reveal-btn {
        top: 5px;
        right: 5px;
        padding: 11px 10px;
        border: none;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        border-radius: 5px;
        font-size: 20px;
    }
    
    .delete-btn {
        position: relative;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 20px;
    }
    
    ::v-deep .q-field--auto-height.q-field--dense .q-field__control {
        min-height: 50px;
        width: 300px;
    }
    .selected-image {
        border: 3px solid #007bff;
        border-radius: 10px;
    }
    </style>
    