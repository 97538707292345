<template>
    <div class="rank-sec">
        <div class="container">
            <ul>
                <li v-bind:class="step > 0 && step <= 3 && this.$route.path !== '/archetypes' ? 'green-colo' : 'seclet-point'">
                    <span>{{ step > 0 && step <= 3 ? '&#10003;' : 1 }}</span>Rank archetypes
                </li>
                <li v-bind:class="step > 1 && step <= 3 && this.$route.path !== '/passions' ? 'green-colo' : 'seclet-point'">
                    <span>{{ step > 1 && step <= 3 ? '&#10003;' : 2 }}</span>Rank values
                </li>
                <li v-bind:class="step > 2 && step <= 3  && this.$route.path !== '/brand_value' ? 'green-colo' : 'seclet-point'">
                    <span>{{ step > 2 && step <= 3 ? '&#10003;' : 3 }}</span>Brand info
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LandingPage',
    data() {
        return {
            show: '/archetypes',
            step: this.$store.state.current_user.onboarding_step,
        };
    },
};
</script>
