<template>
<q-dialog v-model="visible">
    <q-card class="upload-item-card">
        <q-card-section class="row items-center q-pb-none">
            <q-space />
            <q-btn icon="close" flat round dense @click="close" />
        </q-card-section>

        <q-card-section class="upload-info">
            <div class="top-text">{{ $t('new_asset.add_new_item') }}</div>
        </q-card-section>

        <div class="wrapper">
            <div class="input-form">
                <input v-model="displayName" :placeholder="$t('new_asset.type_to_create_asset')" />
                <q-btn @click="textAssetAdd" :disabled="displayName.length==0" class="search-button">
                    <img src="@/assets/icons/plus-white.svg" />
                </q-btn>
            </div>
        </div>

        <q-list bordered class="rounded-borders upload-list">
            <candidate-asset v-for="file in files" :key="file.uuid" :file="file" :ref="setCandidateAssetRef" @remove="onFileRemove">
            </candidate-asset>
        </q-list>

        <div class="dialogs" v-if="files.size>0">
            <p v-if="insufficientMindcoins">
                {{ $t("new_asset.insufficient_credits") }}
            </p>
            <p class="font-weight-bold">
                {{ $t("new_asset.total_mindcoins", { total: mindcoinTotal }) }}
            </p>
        </div>
        <div class="buttons" v-if="files.size>0">
            <button class="btngrey" @click="close">
                {{ $t("message.cancel") }}
            </button>
            <button class="btn3" :disabled="!uploadAllowed" @click="createAssets">
                {{ $t("message.asset_creator_add") }}
            </button>
        </div>
    </q-card>
</q-dialog>
</template>

<script>
import {
    v4 as uuidv4
} from 'uuid';
import axios from "axios";
import { alert_error, toast_success, genericDialog,alert_reminding } from '@/helpers/alert_helper.js';
import CandidateAsset from "./CandidateAsset.vue";

export default {
    components: {
        CandidateAsset,
    },

    emits: ['resetSearch'],

    data: function () {
        return {
            displayName: '',
            files: new Set(),
            candidateAssets: new Set(),
            visible: false,
        }
    },
    computed: {
        mindcoinTotal: function () {
            // the total only includes acceptable files (i.e. it excludes the
            // files with an unacceptable extension)
            return this.acceptableAssets
                .map(ca => ca.info.price)
                .reduce((a, b) => a + b, 0);
        },
        insufficientMindcoins: function () {
            return this.mindcoinTotal > this.$store.state.mindcoins;
        },
        uploadAllowed: function () {
            return !this.insufficientMindcoins &&
                this.acceptableAssets.length > 0;
        },
        acceptableAssets: function () {
            return Array.from(this.candidateAssets).filter(ca => ca.info.acceptable);
        },
    },
    methods: {
        open: function () {
            this.displayName = '';
            this.files.clear();
            this.candidateAssets.clear();
            this.visible = true;
        },
        close: function () {
            this.visible = false;
        },
        textAssetAdd: async function () {
            for (const ca of this.candidateAssets) {
                if (ca.info.displayName === this.displayName) {
                    alert_error(this.$t('message.duplicate_asset'));
                    return;
                }
            }
            // we don't allow dictionary words to be used as assets
            const payload = {
                'word': this.displayName
            };
            try {
                const response_exists = await axios.post('/api/cas/words/exists', payload);
                if (response_exists.data.exists) {
                    alert_reminding(this.$t('new_asset.word_exists_in_network'));
                    return;
                }
                const response_duplicate = await axios.post('/api/cas/assets/asset_exists_for_word', payload);
                if (response_duplicate.data.asset_exists_for_word) {
                    alert_error(this.$t('new_asset.duplicate_order_attempt'));
                    return;
                }
            } catch (error) {
                alert_error(this.$t('message.general_server_error'));
                return;
            }

            const mockFile = new File([""], `${this.displayName}.txt`, {
                type: 'text/plain'
            });
            mockFile.uuid = uuidv4();
            this.files.add(mockFile);
            this.displayName = '';
        },
        onFileRemove: function (file) {
            this.candidateAssets.clear();
            this.files.delete(file);
        },
        setCandidateAssetRef: function (ca) {
            if (ca) {
                this.candidateAssets.add(ca);
            }
        },
        createAssets: async function () {
            // first, we ask the user to confirm the upload
            const dialog_options = {
                title: this.$t('new_asset.create_selected_asset'),
                text: this.$t('new_asset.this_will_cost', {
                    price: this.mindcoinTotal
                }) + '\n' + this.$t('new_asset.short_notification_text'),
                confirmButtonText: this.$t("message.asset_creator_add"),
                cancelButtonText: this.$t("message.cancel"),
            };
            const dialog_result = await genericDialog(dialog_options);
            if (!dialog_result.isConfirmed) {
                return;
            }

            // next, check if the user has enough credits
            await this.$store.dispatch('get_mindcoin_balance');
            if (this.$store.state.mindcoins < this.mindcoinTotal) {
                alert_error(this.$t('new_asset.insufficient_credits'));
                return;
            }

            // if the user has enough credits, we upload the files one by one
            for (const ca of this.candidateAssets) {
                ca.info.uploading = true;
                const payload = {
                    'word': ca.info.displayName,
                    'confidential':ca.questions.isConfidential
                };
                
                try {
                    const response = await axios.post('/api/cas/assets/create_text_asset', payload);
                } catch (error) {
                    const er = error.response;
                    if (er.status === 403 && er.data.insufficient_credits) {
                        alert_error(this.$t('new_asset.insufficient_credits'));
                        ca.info.uploading = false;
                        // since the user no longer has enough credits, we shouldn't try
                        // to upload the remaining files either
                        break;
                    } else {
                        alert_error(this.$t('message.general_server_error'));
                    }
                }
            }
            const assetDisplayNames = Array.from(this.candidateAssets)
                .map(ca => ca.info.displayName) // get asset display names first
                .map(dn => `"${dn}"`) // surround them with double quotes
                .join(', '); // create a comma-separated string
            toast_success(this.$t('new_asset.assets_created', {
                assets: assetDisplayNames
            }));
            this.files.clear();
            this.candidateAssets.clear();
            this.$store.dispatch('get_mindcoin_balance');
            this.$emit("resetSearch");
        },
    },
}
</script>

<style lang="scss" scoped>
.upload-item-card {
    width: 1000px;
    max-width: 80vw;
    height: 800px;
    overflow: hidden;
    display: flex;
    flex-flow: column;
}

.upload-info .bottom-text {
    font-size: 1rem;
    font-family: 'Open Sans';
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
}

.upload-info .top-text {
    font-size: 1.675rem;
    font-family: 'Open Sans';
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;

}

.dialogs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

.dialogs p {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 5px;
    font-weight: bolder;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.buttons button {
    margin: 20px 5px !important;
}

.button-blue {
    color: $mdspwhite_ui;
    background-color: $mdspblue_ui;
    min-width: 150px;
    margin-left: 10px;
}

.button-white {
    color: $mdspblue_ui;
    background-color: $mdspwhite_ui;
    min-width: 150px;
    margin-right: 10px;
}

.upload-list {
    height: 45%;
    overflow-y: auto;
    overflow-x: clip;
    margin-bottom: 10px;
}

.input-form {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 6px #0000007c;
    border: 1px solid #FFFFFF;
    border-radius: 41px;
    width: 60%;
}

.input-form input {
    width: calc(100% - 80px);
    padding-left: 32px;
    font-size: 1.5rem;
    font-family: "Open Sans";
    font-weight: 300;
    color: #000AFF;
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
}

::placeholder {
    text-overflow: ellipsis;
}

.search-button {
    border-radius: 50%;
    background-color: blue;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
}

@media (min-width: 1024px) {
    .wrapper .input-form input {
        min-height: 50px;
    }

    .search-button {
        width: 50px;
        height: 50px;
    }
}

@media (min-width: 1600px) {
    .wrapper .input-form input {
        min-height: 60px;
    }

    .search-button {
        width: 60px;
        height: 60px;
    }
}

@media (min-width: 1920px) {
    .wrapper .input-form input {
        min-height: 80px;
    }

    .search-button {
        width: 80px;
        height: 80px;
    }
}
</style>
