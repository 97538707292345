<template>
    <section class="settings-workspace">
        <h6 class="form-section-title">
            {{ $t("message.your_mindcoins") }}
        </h6>

        <div class="settings-content-block">

            <p>{{ $t("message.mindcoin_use") }}</p>

            <div class="credits">
                <span class="settings-secondary"><strong>{{ mindcoin_count }}</strong> mindcoins</span>
            </div>
        </div>
        <div class="settings-content-block main-block">
            <div class="form-section-title">{{
                $t("message.your_current_plan")
            }}
            </div>
            <p class="settings-secondary"><strong>{{ license }}</strong></p>

            <div class="settings-content-block">
                <!-- <transition name="fade" mode="out-in"> -->
                <a href="mailto:contact@mindspeller.com" class="btn3" style="margin: 0 !important">
                    {{ $t("login.contact_us") }}
                </a>
                <!-- </transition> -->
            </div>


            <!-- <div class="settings-content-block">
          <transition name="fade" mode="out-in">
            <a
                v-if="licenseModificationAllowed && !purchaseDialogDisplayed"
                id="btnChangePlan"
                class="btn btn-primary btn-sm pull-left"
                @click="purchaseDialogDisplayed = true"
            >{{ $t("message.change_plan") }}
            </a>
          </transition>
          <transition name="fade" mode="out-in" class="settings-content-block">
            <a href="mailto:contact@mindspeller.com"
               class="btn btn-primary" v-if="!licenseModificationAllowed"
               style="margin: 0 !important">
              {{ $t("login.contact_us") }}
            </a>
          </transition>
        </div> -->
            <!-- <div class="settings-content-block override-top-margin" v-if="licenseModificationAllowed">
          <div class="tracking-toggle">
            <q-toggle
                :disable="!licenseToggleAllowed"
                left-label
                v-model="license_extension_active"
                :label="this.$t('message.automatically_extend_license')"
                @click="licenseExtensionChange">
            </q-toggle>
          </div>
          <small v-if="displayLastRefresh && license_extension_active">{{ this.$t('message.next_renewal', {'renewal_date': last_refresh}) }}
            <span v-if="licenseIsTrial">{{ this.$t('message.your_license_will_extend_into', {'license_name': extensionLicenseName}) }}</span>
          </small>
          <small v-else-if="displayLastRefresh && !license_extension_active">{{ this.$t('message.license_will_expire_on', {'renewal_date': last_refresh}) }}
          </small>
        </div> -->
            <!-- <transition name="fade" mode="out-in">
                <div class="license-upgrade-ui main-block" v-if="purchaseDialogDisplayed">
                    <transition name="fade" mode="out-in">
                        <loading-app-embedded v-if="processingPayment"></loading-app-embedded>
                    </transition>
                    <LicenseListTable :all-licenses="allLicenses" @changePlan="changePlan">
                    </LicenseListTable>
                    <transition name="quickfade" mode="out-in">
                        <div :key="selectedLicense" class="purchase-summary">
                            <div class="dialog" v-if="selectedLicense && selectedLicense.name != 'Demo'">
                                <discount-codes ref="discount-codes" @changePlan="changePlan"
                                    :selected-license="selectedLicense" :item-price="itemPrice" :old-price="oldPrice"
                                    :price-comparison="priceComparison" :coupon-valid="couponValid"
                                    :coupon-applied="couponApplied" :coupon-code="couponCode">
                                </discount-codes>
                            </div>
                            <div class="payment-ui">
                                <form class="payment-form" v-if="selectedLicense && selectedLicense.name != 'Demo'">
                                    <payment-methods v-if="!paymentMethodListEmpty"
                                        @paymentMethodListEmpty="onPaymentMethodListEmpty"
                                        @paymentMethodChange="onPaymentMethodChange">
                                    </payment-methods>
                                    <div v-else id="payment-element">
                                    </div>
                                    <button class="btn btn-outline" id="submit" :disabled="paymentButtonDisabled"
                                        @click="handleSubmit">
                                        <div class="spinner hidden" id="spinner"></div>
                                        <span id="button-text">{{ this.$t('message.pay_now') }}</span>
                                    </button>
                                    <div id="payment-message" class="hidden"></div>
                                </form>
                            </div>
                        </div>
                    </transition>
                </div>
            </transition> -->
        </div>
    </section>
</template>
  
<script>
import axios from "axios";
import { alert_error, alert_success } from "@/helpers/alert_helper";
import PaymentMethods from "./paymentMethods";
import LicenseListTable from "./licenseListTable";
import DiscountCodes from "./discountCodes";
import loadingAppEmbedded from "@/components/common/loadingAppEmbedded";

export default {
    components: {
        DiscountCodes, loadingAppEmbedded, PaymentMethods,
        LicenseListTable
    },
    data: function () {
        return {
            allLicenses: [],
            selectedLicense: false,
            // State variables for coupons
            couponValid: Boolean,
            couponApplied: false,
            couponCode: '',
            // Showing button allowing for plan change
            purchaseDialogDisplayed: false,
            processingPayment: false,
            itemPrice: false,
            oldPrice: 0,
            priceComparison: false,
            selectedPaymentMethod: null,
            paymentMethodListEmpty: false,
            // Stripe variables
            client_secret: String,
            stripe: {},
            elements: {},
            // Variables related to license renewal
            license_extension_active: Boolean,
            last_refresh: String,
            licenseIsTrial: false,
            extensionLicenseName: '',
        }
    },
    computed: {
        mindcoin_count: function () {
            return this.$store.state.mindcoins;
        },
        license: function () {
            return this.$store.state.current_license.name;
        },
        licenseModificationAllowed: function () {
            let user = this.$store.state.current_user;
            let org = this.$store.state.current_org;

            // id 3 is organisation admin and id 5 is mindspeller admin
            return (user.category.id == 3 || user.category.id == 5) && org.payment_type == 'CARD';
        },
        displayLastRefresh: function () {
            if (this.last_refresh === null) {
                return false;
            } else {
                return this.last_refresh.length > 0;
            }
        },
        paymentButtonDisabled: function () {
            if (this.paymentMethodListEmpty) {
                return false;
            } else if (!this.paymentMethodListEmpty) {
                if (this.selectedPaymentMethod === null) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        licenseToggleAllowed: function () {
            if (this.paymentMethodListEmpty) {
                return false;
            } else {
                return true;
            };
        }
    },
    methods: {
        changePlan: async function (license) {
            this.selectedLicense = license;
            var discount_code = this.couponCode;
            // STRIPE STUFF
            const items = [{
                id: this.selectedLicense,
                coupon: discount_code,
                org_id: this.$store.state.current_org.id,
                license_id: this.selectedLicense.id,
                customer_id: this.$store.state.current_org.stripe_customer_id
            }];

            var clientSecret = '';
            var calculatedPrice = '';
            var oldPrice = '';

            const response = await axios.post("/payment/create_payment_intent", items).then(response => {
                clientSecret = response.data.clientSecret;
                calculatedPrice = response.data.calculatedPrice;
                oldPrice = response.data.oldPrice;
            });

            this.oldPrice = oldPrice;
            this.itemPrice = calculatedPrice;
            if (oldPrice != calculatedPrice) {
                this.priceComparison = true;
            }

            var options = {
                clientSecret: clientSecret,
                // Fully customizable with appearance API.
                appearance: {
                    theme: 'stripe',
                }
            };

            this.elements = this.stripe.elements(options);
            const paymentElement = this.elements.create("payment");
            paymentElement.mount("#payment-element");
            return calculatedPrice;
        },
        licenseExtensionChange: async function () {
            let new_toggle_value = this.license_extension_active == false ? 0 : 1;
            try {
                await axios.post("/api/cas/org/license/extension/update", { 'toggle_value': new_toggle_value });
            } catch (e) {
                return;
            }
        },
        validateCoupon: async function () {
            var code_exists = false;
            var coupon = this.couponCode;
            var current_org = this.$store.state.current_org;
            const response = await axios.post("/api/cas/discount_code/exists", { 'coupon': coupon, 'current_org': current_org }).then(response => {
                code_exists = response.data;
            });
            if (code_exists) {
                this.couponValid = true;
                this.couponApplied = true;
                this.changePlan(this.selectedLicense);
            } else {
                this.couponValid = false;
                this.couponApplied = false;
            }
        },
        handleSubmit: async function (e, vue_vm = this) {
            e.preventDefault();
            this.processingPayment = true;

            if (this.paymentMethodListEmpty) {
                // If paymentMethodListEmpty is true, then we assume that user checked out
                // using the Stripe iframe.
                var elements = vue_vm.elements;

                await vue_vm.stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        return_url: window.location.origin,
                    },
                    redirect: 'if_required'
                }).then(async function (response) {
                    if (response.error) {
                        if (response.error.type === "card_error" || response.error.type === "validation_error") {
                            vue_vm.processingPayment = false;
                            alert_error(response.error.message);
                        } else {
                            vue_vm.processingPayment = false;
                            alert_error(vue_vm.$t('message.payment_failed') + response.error.type);
                        }
                    } else if (response.paymentIntent && response.paymentIntent.status === 'succeeded') {
                        vue_vm.selectedLicense = false;
                        vue_vm.purchaseDialogDisplayed = false;
                        vue_vm.processingPayment = false;
                        await alert_success(vue_vm.$t('message.payment_succeeded'));
                        location.reload();
                    }
                });
            } else {
                // If paymentMethodListEmpty is false, then we assume that user checked out
                // using the already existing payment methods.
                try {
                    var response = await axios.post("/payment/pay",
                        {
                            'org_id': this.$store.state.current_org.id,
                            'license_id': this.selectedLicense,
                            'payment_method': this.selectedPaymentMethod,
                        })
                    this.processingPayment = false;
                    await alert_success(vue_vm.$t('message.payment_succeeded'))
                    location.reload();
                } catch (err) {
                    this.processingPayment = false;
                    await alert_error('Error', vue_vm.$t('message.payment_unexpected_fail'));
                }
            }
        },
        onPaymentMethodChange: function (payment_method_id) {
            this.selectedPaymentMethod = payment_method_id;
        },
        onPaymentMethodListEmpty: async function () {
            this.paymentMethodListEmpty = true;
            this.license_extension_active = false;
            await this.licenseExtensionChange();
            await this.changePlan(this.selectedLicense);
        }
    },
    mounted: async function () {
        axios.get('/api/cas/org/license/all').then(response =>
            this.allLicenses = response.data.filter(license => !license.is_legacy)
                .filter(license => license.id !== this.$store.state.current_license.id)
                .filter(license => license.is_purchasable == 1));

        let license_data = {};
        await axios.get('/api/cas/org/license/extendable').then(response =>
            license_data = response.data);

        let discount_code = '';
        await axios.post('/api/cas/discount_code/get', { 'org_id': this.$store.state.current_org.id }).then(response =>
            discount_code = response.data.code);
        if (discount_code != undefined && discount_code.length > 0) {
            this.couponCode = discount_code;
        }

        let current_user_license = this.$store.state.current_license;
        let all_default_license_data = '';
        await axios.get('/api/cas/org/license/default_license/get_all').then(response =>
            all_default_license_data = response
        );

        // let payment_methods;
        // await axios.post('/payment_methods/list',
        //     { 'customer_id': this.$store.state.current_org.stripe_customer_id }).then(response =>
        //         payment_methods = response.data
        //     );

        // if (payment_methods.data.length > 0) {
        //     this.paymentMethodListEmpty = false;
        // } else if (payment_methods.data.length == 0) {
        //     this.paymentMethodListEmpty = true;
        // }

        this.license_extension_active = license_data.automatic_extension;
        this.last_refresh = license_data.last_refresh;
        this.stripe = Stripe(this.$store.getters.getEnvVar('VUE_APP_STRIPE_PUBLISHABLE_KEY'));
        this.elements = {};
    }
}
</script>
<style scoped lang="scss">

.settings-workspace {
    display: flex;
    flex-flow: column;
}

.settings-content-block {
    display: flex;
    flex-flow: column;
    align-items: baseline;
    margin-top: 15px;
    width: 100%;
    position: relative;
}

.settings-content-block p {
    margin: 0;
}

.settings-secondary {
    font-size: 1rem;
    color: $mdspblue_ui;
}

.purchase-summary {
    display: flex;
    margin-top: 15px;
    width: 100%;
    justify-content: center;
    flex: 1;
}

.purchase-summary div,
form {
    margin: 0px 5px;
}

.purchase-summary .dialog {
    justify-content: flex-end;
    display: flex;
    flex-flow: column;
    align-items: center;
    flex: 1;
}

.purchase-summary .dialog div {
    width: 100%;
}

.payment-ui {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.license-upgrade-ui {
    display: flex;
    width: 100%;
}

.payment-form {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
}

.payment-form #payment-element {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    flex: 1;
}

.main-block {
    flex: 1;
}

.btn {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
}

.btn:hover {
    opacity: 0.8;
}

.settings-secondary {
  font-size: 1rem;
  color: $mdspblue_ui;
}

</style>
  