<template>
    <div class="flexible-layout">
        <!-- Main Content -->
        <main class="main-content">
            <div class="three-column-layout">
                <!-- First Column -->
                <div class="column">
                    <h2> Tools</h2>
                    <Tools></Tools>
                </div>

                <!-- Second Column -->
                <div class="column">
                    <h2> Library </h2>
                    <div>
                        <div class="top">
                            <div style="display: flex; flex-direction: column; align-items: center;">
                                <div class="title">My Assets</div>
                                <div class="button-container">
                                    <button class="button">Menu</button>
                                    <button class="button">Search</button>
                                </div>
                                <div style="display: flex;  justify-content: space-between;">
                                    <LibraryCreator></LibraryCreator>
                                    <LibraryCreator></LibraryCreator>
                                    <LibraryCreator></LibraryCreator>
                                </div>
                            </div>

                        </div>


                        <div class="middle">
                            Public Assets
                        </div>

                        <div class="bottom">
                            Tracked Assets
                        </div>
                    </div>
                </div>

                <!-- Third Column -->
                <div class="column">
                    <Appointment></Appointment>
                </div>
            </div>
        </main>
    </div>
</template>
  
<script>
import Tools from './Tools.vue'
import Appointment from './Appointment.vue';
import LibraryCreator from './LibraryCreator.vue';
export default {
    components: {
        Tools,
        Appointment,
        LibraryCreator
    },
    data() {
        return {
            libraryKey: 'mindspeller'
        }

    }
}
</script>
  
<style scoped>
.flexible-layout {
    display: flex;
}

h2 {
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-weight: bold;
            font-size: 24px;
            color: #333; /* Adjust the color as needed */
            text-transform: uppercase;
            letter-spacing: 1px;
            margin: 0;
            padding: 10px 0; /* Add padding for spacing */
            text-align: center; /* Center the text */
        }



.title {
    font-size: 24px;
    margin-bottom: 20px;
}

.button-container {
    display: flex;
    justify-content: center;
}

.button {
    padding: 10px 20px;
    margin: 0 10px;
    font-size: 16px;
    background-color: #ffffff;
    color: black;
    border: 3px solid black;
    border-radius: 4px;
    cursor: pointer;
}

.button:hover {
    background-color: #cecece;
}

.top,
.middle,
.bottom {
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #000; */
}

.main-content {
    flex: 1;
    margin-left: 4vw;
}

.header {
    background-color: #333;
    color: #fff;
    height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
}

.three-column-layout {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    margin-top: calc(5vw);
}

.column {
    flex: 0 0 calc(30% - 20px);
    padding: 20px;
    text-align: center;
    width: 30vw;
}

.column h2 {
    font-size: 24px;
    margin-top: 0;
}

.column p {
    font-size: 18px;
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .flexible-layout {
        flex-direction: column;
    }

    .main-content {
        order: -1;
        margin-left: 0;
    }
}

.container {
    display: flex;
    justify-content: space-between;
}

.item {
    width: 100px;
    height: 100px;
    background-color: lightblue;
}
</style>
  