<template>
    <main>
        <div class="bg-trans" v-bind:style="{
            display:
                show === 'welcome'
                ? 'block'
                : 'none',
            }">
            <div class="hello-msg" style="display: block;">
                <strong>Welcome!</strong>
                In order to optimize your user experience, we invite you to upload your first concept!
                <div style="display: flex; justify-content: space-between;">
                    <span><a class="cursor-p" @click="show = ''">OK</a></span>
                </div>
            </div>
        </div>
        <div class="bg-trans" v-bind:style="{display: show === 'next' ? 'block' : 'none'}">
            <div class="hello-msg next-pg-msg" style="display: block;">
                <div style="position: relative;  top: -50px; font-size: 0.9vw; text-align: left; text-transform: none;">
                    <strong>Congrats !</strong>
                    You've uploaded your first concept! Keep an eye on your e-mailbox, you will get notified
                    when we've reviewed it.
                    <span style="margin-top: -10px;"><a class="cursor-p" @click="handleNext">Next</a></span>
                </div>
            </div>
        </div>
        <h4 style="margin-top: 125px; margin-bottom: 75px; text-align: center;">In order to optimize your user experience, we invite you to upload your first concept!</h4>
        <section style="display: flex; justify-content: center; align-items: flex-start; gap:25px;">
            <div class="sub-container">
                <div>
                    <span style="font-weight: bolder; font-size: 1.6vw;">
                        Populate your library with your first concept!
                    </span>
                    <div>
                        <span style="font-size: 1.3vw;">
                            Copy paste your main website URL in the box below
                            <span class="tooltip-icon" @mouseenter="showTooltip = true" @mouseleave="showTooltip = false">
                                <img src="../../assets/info-tooltip.png" alt="Tooltip" id="tooltip-image"/>
                            </span>
                            <span v-if="showTooltip" class="tooltip-content">
                                Use our web scraping tool to find concepts <br>
                                on your website URL!
                            </span>
                        </span>
                    </div>
                </div>
                <div style="width: 50%; display: flex;justify-content: center;">
                    <input style="border-color: #0A00FF;" type="search" id="form1" class="form-control"
                        placeholder="Paste your website URL here"
                        aria-label="Search" v-model="search_text" @input="handleInput"
                    />
                </div>
                <div v-if="loading" style="margin-bottom: 10vw;">
                    <loading-spinner></loading-spinner>
                </div>
                <div v-if="!loading" 
                    style="display:flex;justify-content:center;">
                        <img :src="images" class="cropped-image" :class="{ 'hidden-image': !images }">
                </div>
            </div>
            <div class="sub-container">
                <div>
                    <span style="font-weight: bolder; font-size: 1.6vw;">
                        No luck with the scraping tool?
                    </span>
                    <div>
                        <span v-if="!upload" style="font-size: 1.3vw;">
                            Upload an image of your concept instead
                        </span>
                    </div>
                </div>
                <div class="upload-container">
                    <div class="drop-area" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop.prevent="handleDrop">
                        <p v-if="!selectedFile">Drag and drop your file here</p>
                        <label for="file-input" class="custom-file-upload">
                            <span class="upload-icon">&#8679;</span> Choose File
                        </label>
                        <input id="file-input" type="file" @change="handleFileUpload" ref="fileInput" style="display: none" />
                    </div>
                    <div v-if="selectedFile" class="file-info">
                        <span @click="handleClear">X</span>
                        <img :src="imagePreview" style="margin-top:25px; width: 50%;height:50%; object-fit: cover;">
                    </div>
                </div>
                <div style="display: flex; justify-content: center; align-items: center; margin: 5%; gap: 25px;">
                    <div class="confirm-selection" @click="handleConfirm">
                        Upload
                    </div>
                </div>
            </div>
        </section>
        <div style="display: flex; justify-content: center; align-items: center; margin: 5%; gap: 25px;">
                    <div class="skip-button" @click="handleSkip">
                        Upload concept later
                    </div>
        </div>
        <loading-app-wide v-if="display_loading_overlay" />
        <full-preview-archetypes :asset="asset" ref="fullscreen_preview"/>
    </main>
  <!-- here we display the scraping assets in a modal -->
  <q-dialog v-model="dialogVisible">
    <q-card style="width: 700px;">
      <q-card-section>
        <div class="image-container">
          <div v-for="(image, index) in images" :key="index" class="image-item">
            <q-img :src="image.src"
              :style="{ width: '80px', cursor: 'pointer', position: 'relative', filter: isSelected(image.src) ? 'grayscale(100%)' : 'none' }"
              @click="selectImage(image.src)">
            </q-img>
            <q-icon v-if="isSelected(image.src)" name="done" size="md" color="green" style="position: absolute; top: 0; left: 0;"/>
          </div>
        </div>
      </q-card-section>

      <q-card-actions>
        <div style="display: flex; justify-content: space-between; font-size: large;">
          <div style="font-size: small;">
            Selected concepts: {{selectedImages.length}}
          </div>
          <div style="font-size: small;">
            Total mindcoins: {{mindcoinTotal}}
          </div>
          <div>
            <button style="background-color:#92a0b3 !important; margin-right: 1vw;" class="button_pay" color="primary"
              @click="closeDialog">Cancel</button>
            <button class="button_pay" color="primary" @click="addAsset">Add Concept</button>
          </div>
        </div>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
    import axios from 'axios';
    import LoadingSpinner from '../common/LoadingSpinner.vue';
    import { alert_error, alert_reminding, genericDialog, toast_success } from '@/helpers/alert_helper';
    import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
    import FullPreviewArchetypes from './common/FullPreviewArchetypes.vue';

    export default {
        name: 'ConceptOnboarding',
        data() {
            return {
                show: 'welcome',
                showTooltip: false,
                upload:false,
                assets: null,
                images: '',
                loading: false,
                imagePreview: null,
                selectedFile: null,
                display_loading_overlay: false,
                search_text: '',
                dialogVisible: false,
                selectedImages: [],
                mindcoinTotal: 0,
            };
        },
        components:{
            LoadingSpinner,
        },
        methods:{
            handleNext() {
                this.$router.push({
                    name: 'Library',
                    params: {
                    value: '/library',
                    },
                });
            },
            handleInput() {
                if (this.isValidUrl(this.search_text)) {
                    this.scraping_img(this.search_text);
                }
            },
            isValidUrl(url) {
                const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
                return urlPattern.test(url);
            },
            async scraping_img(url) {
                // encode the url for the api call
                let encode_url = encodeURIComponent(url)
                this.loading = true;
                // extract the images from the website
                const res = await axios.get(
                    `https://opengraph.io/api/1.1/site/${encode_url}?app_id=a69a097d-6927-4a51-9d6a-aab17c263e23`
                );
                this.images = res.data.htmlInferred.images
                //first we remove the svg images
                this.images = this.images.filter(image => !image.endsWith('.svg'));
                //then we format the images
                this.images = this.images.map(image => {
                    return { src: image };
                });

                this.openDialog()
                this.loading = false;
            },
            openDialog() {
                this.dialogVisible = true;
            },
            closeDialog() {
                this.selectedImages = [];
                this.mindcoinTotal = 0;
                this.dialogVisible = false;
            },
            isSelected(image) {
                return this.selectedImages.includes(image);
            },
            selectImage(image) {
                const selectedImageIndex = this.selectedImages.indexOf(image);
                if (selectedImageIndex === -1) {
                    this.selectedImages.push(image);
                } else {
                    this.selectedImages.splice(selectedImageIndex, 1);
                }
                this.mindcoinTotal = 200 * this.selectedImages.length;
            },
            async addAsset() {
                //we need to upload selectedimages to the libary
                try {
                    const dialog_options = {
                    title: "Upload selected concepts?",
                    text: this.$t('new_asset.this_will_cost', {
                        price:  this.mindcoinTotal
                    }),
                    confirmButtonText: this.$t("message.asset_creator_add"),
                    cancelButtonText: this.$t("message.cancel"),
                    };
                    const dialog_result = await genericDialog(dialog_options);
                    if (!dialog_result.isConfirmed) {
                    return;
                    }

                    // next, check if the user has enough credits
                    await this.$store.dispatch('get_mindcoin_balance');
                    if (this.$store.state.mindcoins < this.mindcoinTotal) {
                    alert_error(this.$t('new_asset.insufficient_credits'));
                    return;
                    }
                    try {
                    this.loading = true;
                    const response = await axios.post('/api/cas/assets/scraping', { "images": this.selectedImages, "unit_price": 200 });
                    this.loading = false;
                    await this.$store.dispatch('get_mindcoin_balance');
                    this.$emit("resetSearch");
                    toast_success(`Concept uploaded, you will be notified when your concept is analysed`);
                    } catch (error) {
                    console.log(error)
                    }
                } catch (error) {
                    console.log(error)
                }
                this.$router.push({name:'Library'})
            },
            handleDrop(event) {
                this.selectedFile = event.dataTransfer.files[0];
                this.readImagePreview(this.selectedFile);
            },
            readImagePreview(file) {
                const reader = new FileReader();
                reader.onload = () => {
                    this.imagePreview = reader.result;
                    this.assets = {"auto":false,"image":this.imagePreview}
                    this.upload = true;
                };
                reader.readAsDataURL(file);
            },
            handleFileUpload(event) {
                const file = event.target.files[0];
                if (file && this.isValidFileType(file)) {
                    this.selectedFile = file;
                    this.readImagePreview(this.selectedFile);
                } else {
                    alert_reminding("Invalid file type. Please select a JPEG, JPG, or PNG image.");
                    this.resetFileInput();
                    this.assets = null;
                }
            },
            isValidFileType(file) {
                const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
                return allowedTypes.includes(file.type);
            },
            resetFileInput() {
                this.$refs.fileInput.value = "";
                this.selectedFile = null;
            },
            handleConfirm: async function () {
                this.display_loading_overlay = true;
                try {
                    //upload the user status in the database
                    await axios.put('/api/cas/users/update_free_archetype');
                    //upload the selected assets to My Library
                    await axios.post('/api/cas/assets/archetype', { 'selectedAssets': this.assets });
                    //we will reload the user data to make sure the extract website function can only be done once
                    this.$store.dispatch('get_mindcoin_balance');
                    this.$store.dispatch('load_user_data');
                    this.show = 'next';
                    this.images = '';
                    this.display_loading_overlay = false;
                } catch (error) {
                    this.display_loading_overlay = false;
                    alert_error(this.$t('message.general_server_error'));
                }
            },
            handleClear(){
                this.resetFileInput();
                this.images = '';
            },
            handleSkip(){
                this.$router.push({name:'Dashboard'})
            }
        },
    }
</script>

<style scoped>
    .tooltip-icon {
        position: relative;
        display: inline-block;
        cursor: pointer;
    }

    .tooltip-icon img {
        width: 20px;
        margin-left: 0.5vw;
    }

    .tooltip-content {
        white-space: nowrap;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 0.9vw;
        color: white;
        position: absolute;
        background-color: rgb(120, 120, 226);
        border: 1px solid #ccc;
        padding: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 100;
        text-transform: none;
        transition: opacity 0.3s, visibility 0.3s;
        margin-left: 0.5vw;
    }

    .cropped-image {
        width: 50%;
        transition: border 0.3s ease;
        overflow: scroll;
    }

    .hidden-image {
        display: none;
    }

    .cropped-image:hover {
        border: 2px solid #3498db;
    }
    
    .upload-container {
        text-align: center;
    }

    .drop-area {
        border: 2px dashed #ccc;
        cursor: pointer;
        display: inline-block;
        padding:10px;
    }

    .custom-file-upload {
        display: inline-block;
        padding: 10px 20px;
        background-color: #1F1FFF;
        color: white;
        cursor: pointer;
        border-radius: 4px;
    }

    .skip-button {
        display: inline-block;
        padding: 10px 20px;
        background-color: #7878E9;
        color: white;
        cursor: pointer;
        border-radius: 4px;
    }

    .confirm-selection {
        display: inline-block;
        padding: 10px 20px;
        background-color: forestgreen;
        color: white;
        cursor: pointer;
        border-radius: 4px;
    }

    .upload-icon {
        font-size: 1.3vw;
    }

    .file-info {
        display: flex;
        justify-content: center;
        /* position: relative;
        display: inline-block; */
    }

    .file-info img{
        display: block;
        max-width: 100%;
        border: 1px solid #ccc;
    }

    .close-button{
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 5px 8px;
        cursor: pointer;
        border: none;
        font-size: 18px;
        line-height: 1;
        z-index: 1;
    }

    .btn-col {
        text-align: center !important;
    }
    
    .sub-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 10px;
        width:45%;
    }

    .image-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: flex-start;
    }

    .image-item {
        position: relative;
        margin-bottom: 10px;
    }

    .button_pay {
        padding: 0.4vw 0.7vw;
        background: #0A00FF;
        border-radius: 2vw;
        font-style: normal;
        font-weight: 1000;
        font-size: 0.9vw;
        color: #ffffff !important;
        cursor: pointer;
        text-transform: capitalize;
        transition: all 1s;
    }

    .button_pay a {
        color: #ffffff !important;
    }

    .button_pay:hover {
        background-color: #92a0b3;
    }
</style>