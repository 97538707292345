<template>
<div class="lft-sec-bar">
    <div class="banner-img-box">
        <img src="../../../assets/banner-img1.jpg">
    </div>
    <div class="testimo-box">
        <div class="textmo-text">
            <p>We use Mindspeller to test our creative campaign concepts. Mindspeller's brand equity autopilot helps keep our brand on track.</p>
        <p>~ Wendy M.  Head of Marketing Communication Research, City of Antwerp</p>
        </div>

    </div>
</div>
</template>

<script>
export default {
    name: "MapBrand"
}
</script>
