<template>
  <div class="tool-container" style="width: 275px;">
    <div style="display: flex; justify-content: flex-start; align-items: center; margin-left: 5px;">
      <font-awesome-icon :icon="fetchIcon()" class="tool-icon"/>
      <h6>{{name}}</h6>
    </div>
    <span class="desc-text">{{desc}}</span>
  </div>
</template>

<script>

export default {
  name: 'UnusedApp',
  data() {
      return  {
          name: this.$props.name,
          desc: this.$props.desc,
          icon: []
      }
  },
  props: {
    image: String,
    name: String,
    desc: String,
    icon: Array,
    url: String,
    showTickBtn: Boolean
  },
  methods:{
    fetchIcon: function () {
      switch (this.name) {
        case 'Association wheel':
          return this.icon = ['fas', 'chart-pie']
        case 'MindBias':
          return this.icon = ['fas', 'brain'] 
        case 'Connection Checker':
          return this.icon = ['fas', 'draw-polygon']
        case 'Competitive profiler':
          return this.icon = ['fas', 'medal']
        case 'Story creator':
          return this.icon = ['fas', 'book-open']
        case 'Inspiration explorer':
          return this.icon = ['fas', 'lightbulb']
        case 'Content Tuner':
          return this.icon = ['fas', 'chart-pie']
        case 'Brand tracker':
          return this.icon = ['fas', 'copyright']
        default:
          return this.icon = ['fas', 'cog']
      }
    }
  }
};
</script>

<style scoped>
.tool-container {
    border: 0.1vw solid #34A853;
    border-radius: 0.5vw;
    cursor: pointer;
    height: 100px;
    border: 0.1vw solid #D1DAE7;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    transition: 0.5s;
    gap: 5px;
}
.tool-container:hover {
    box-shadow: 0px 0px 5px #b3b2b2;
}
.desc-text {
    color: #405261;
    font-size: 14px;
    text-align: left;
    margin-left: 5px;
}
.tool-icon{
  color: #7878E9;
  width: 20px; 
  height: 20px; 
  margin-right: 10px;
}
.tool-container h6 {
    font-weight: 700;
    height: 20px; 
    text-transform: capitalize;
    color: #1B2F3F;
}
</style>
