const mind_tracker_translation = {
    en: {
        message: {
            mind_tracker_tooltip:
                "The Mindspeller Association Score provides an indication of " +
                "how often your concept and your Intended Association " +
                "are thought of in the same context.",
            add_campaigns: 'Add Campaign(s) (optional)',
            add_campaign: 'Add Campaign',
            add_campaign_marker: 'Add a campaign label',
            campaign_name: 'Campaign name',
            mt_video_instructions: 'Brand Tracker instructions',
        },
    },
    nl: {
        message: {
            mind_tracker_tooltip: 
                "De Mindspeller Associatie Score geeft een indicatie van" +
                " hoe vaak mensen jouw concept en je Gewenste Associatie spontaan associëren",
            add_campaigns: 'Voeg Campagne(s) toe (optioneel)',
            add_campaign: 'Voeg Campagne toe',
            add_campaign_marker: 'Voeg een campaign label toe',
            campaign_name: 'Campagne naam',
            mt_video_instructions: 'Brand Tracker instructies',
        },
    }
};

export { mind_tracker_translation };
