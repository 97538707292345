const self_registration_translation = {
    en: {
        message: {
            // First page
            put_your_brand: 'Put your brand',
            on_the_map: 'on the map',
            to_create: 'to start',
            your_account: 'your trial',
            your_work_email: 'Your WORK email',
            start_trial: 'Start Trial',
            enter_valid_email_address: 'Please enter valid e-mail address.',
            user_with_given_email_exists: 'User with the given e-mail address exists.',
            already_have_an_account: 'Already have an account?',
            login_here: ' Login here.',
            // Password setup
            set_the_password: 'Set the password for your account',
            characters_requirement: 'At least 8 characters',
            uppercase_requirement: 'One uppercase character',
            lowercase_requirement: 'One lowercase character',
            number_or_whitespace_requirement: 'One number or whitespace character',
            set_password: 'Set password',
            repeat_password: 'Repeat password',
            i_accept: 'I accept',
            terms_and_conditions: 'Terms and conditions',
            passwords_dont_match: 'Passwords do not match.',
            // Onboarding step 1
            how_many_brands: 'How many brands do you manage?',
            one_brand: 'One',
            many_brands: 'Multiple',
            previous_button: 'previous',
            next_button: 'next',
            // Onboarding step 2
            upload_your_brand_logo: 'Upload your brand logo',
            choose_file_from_computer: 'Choose file from your computer',
            choose_brand_sector: 'Choose your brand sector',
            // Onboarding step 3
            almost_there: 'Almost there!',
            lets_make_it_more_personal: 'Let\'s make your experience more personal.',
            account_username: 'Account username',
            user_account_already_exists: 'User account with given name already exists.',
            organisation_name: 'Organisation name',
            organisation_account_already_exists: 'Organisation with given name already exists.',
            // Recaptcha
            this_site_is_protected: 'This site is protected by reCAPTCHA and the Google',
            privacy_policy: 'Privacy Policy',
            and: 'and',
            tos: 'Terms of Service',
            apply: 'apply.',
            captcha_error: 'Please try another name.',
            card_reassurance: 'No charge will be made for the first month. Unless you cancel your account during the free demo, your account will be automatically upgraded to the entry level “{ license_name }” license the next month, allowing you to map and analyze your own creative assets.',
            recommend_agency: 'Please enter the url of the agency that recommended Mindspeller to you.',
            enter_card_details: 'Please enter your credit card details.',
            not_applicable: 'Not applicable',
            // User billing details
            enter_billing_details: 'Enter billing details',
            address_1: 'Address line 1',
            address_2: 'Address line 2',
            vat_number: 'VAT number',
            company_number: 'Company number',
            country: 'Country',
            zipcode: 'Zipcode',
        },
    },
    nl: {
        message: {
            // First page
            put_your_brand: 'Zet je merk',
            on_the_map: 'op de kaart',
            to_create: 'om je trial',
            your_account: 'te starten',
            your_work_email: 'Jouw WERK email',
            start_trial: 'start trial',
            enter_valid_email_address: 'Geef een geldig e-mail address.',
            user_with_given_email_exists: 'User met opgegeven e-mail adres bestaat al.',
            already_have_an_account: 'Heb je al een account?',
            login_here: ' Hier inloggen.',
            // Password setup
            set_the_password: 'Maak een paswoord aan voor je account',
            characters_requirement: 'Minstens 8 karakters',
            uppercase_requirement: 'Eén uppercase karakter',
            lowercase_requirement: 'Eén lowercase karakter',
            number_or_whitespace_requirement: 'Eén nummer of spatie',
            set_password: 'Creëer paswoord',
            repeat_password: 'Herhaal paswoord',
            i_accept: 'Ik ga akkoord',
            terms_and_conditions: 'Algemene voorwaarden',
            passwords_dont_match: 'Paswoorden komen niet overeen.',
            // Onboarding step 1
            how_many_brands: 'Hoeveel merken beheer je?',
            one_brand: 'Eén',
            many_brands: 'Meerdere',
            previous_button: 'Vorige',
            next_button: 'Volgende',
            // Onboarding step 2
            upload_your_brand_logo: 'Upload je merk logo',
            choose_file_from_computer: 'Kies bestand op mijn computer',
            choose_brand_sector: 'Kies een sector voor je merk',
            // Onboarding step 3
            almost_there: 'Bijna klaar!',
            lets_make_it_more_personal: 'Laten we je ervaring wat persoonlijker maken.',
            account_username: 'Account gebruikersnaam',
            user_account_already_exists: 'Gebruiks account met opgegeven naam bestaat al.',
            organisation_name: 'Organisatie naam',
            organisation_account_already_exists: 'Organisatie met opgegeven naam bestaat al.',
            // Recaptcha
            this_site_is_protected: 'This site is protected by reCAPTCHA and the Google',
            privacy_policy: 'Privacy Policy',
            and: 'and',
            tos: 'Terms of Service',
            apply: 'apply.',
            captcha_error: 'Probeer aub een andere naam.',
            card_reassurance: 'Er worden geen kosten aangerekend gedurende de eerste maand. Tenzij u voorafgaand uw account opzegt, zal het automatisch omgezet worden in een basis "{ license_name }" licentie op basis waarvan u uw eigen creative concepten kan mappen en analyseren.',
            recommend_agency: 'Gelieve de url in te vullen van het agentschap dat u Mindspeller heeft aanbevolen (indien van toepassing).',
            enter_card_details: 'Voer uw creditcardgegevens in.',
            not_applicable: 'Niet van toepassing',
            // User billing details
            enter_billing_details: 'Enter billing details',
            address_1: 'Adres regel 1',
            address_2: 'Adres regel 2',
            vat_number: 'BTW nummer',
            company_number: 'Bedrijfsnummer',
            country: 'Land',
            zipcode: 'Postcode',
        },
    },
};

export { self_registration_translation };
