<template>
  <div class="library scroll" @scroll="handleScroll" v-bind:style="{ height: isModal ? '45vh' : '41vh' }">
    <!-- <div class="library scroll" @scroll="handleScroll" v-bind:style="{height: isModal ? '40vh' : '70vh' }"> -->
    <div class="asset-workspace mdspscrollbar">
      <div class="text-center" v-if="assets.length === 0 && !loading">
        {{ $t('message.library_no_match') }}
      </div>
      <div class="asset-grid">
        <asset-creator v-if="this.libraryKey === 'my_library'" @click="onAddNewAsset" :isModal="isModal">
        </asset-creator>
        <asset-tile v-for="a in assets" :key="a.id" :asset="a" :ref="tilesRef" @assetSelected="onAssetSelected"
          :isModal="isModal" :isMyLibrary="this.libraryKey === 'my_library'">
        </asset-tile>
        <div class="loaders" v-if="loading && assets.length">
          <span>
            <loading-spinner></loading-spinner>
          </span>
        </div>
        <span v-if="loading && !assets.length">
          <loading-spinner className="centered"></loading-spinner>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AssetTile from './AssetTile.vue';
import AssetCreator from './AssetCreator';
import LoadingSpinner from '../common/LoadingSpinner.vue';

export default {
  components: {
    AssetTile,
    AssetCreator,
    LoadingSpinner,
  },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    showAddAssetButton: {
      default: true,
      type: Boolean,
    },
    value: {
      type: String,
      default: false,
    },
    libraryKey: {
      type: String,
      default: false,
    },
    onCountTotalAssets: Function,
    onAddNewAsset: Function,
    isModal: String,
  },
  emits: ['assetClick', 'addNewAsset'],

  data: function () {
    return {
      assets: [],
      page: 0,
      loading: true,
      // this array contains refs to all the tile components; it is
      // filled out by the tilesRef() method
      tile_elements: [],
      // we use this to send a ping value along with the payload which
      // the API endpoint is expected to ping back; based on the ping
      // we can disregard all data that is not the result of the latest
      // API request
      requests_sent: 0,
      //assets number
      asset_num: null,
    };
  },


  methods: {
    /*
                    This method is used to keep an array of asset tiles by using the :ref
                    attibute during v-for of the <asset-tile> component (see
                    https://v3.vuejs.org/guide/migration/array-refs.html#migration-strategy
                    */
    tilesRef: function (element) {
      this.tile_elements.push(element);
    },
    /*
                    This method goes through the list of all asset tiles and asks each one
                    to unselect itself.
                    */
    resetSelection: function () {
      for (const tile of this.tile_elements) {
        tile.unselect();
      }
    },
    onAssetSelected: function (asset) {
      // when an asset is selected we ask all asset tiles to unselect
      // themselves (the newly selected asset will select itself after
      // emitting this event)
      this.resetSelection();
      this.$emit('assetClick', asset);
    },
    handleScroll: function (el) {
      const target_position = el.target.offsetHeight + el.target.scrollTop;
      // if (target_position + 3 >= el.target.scrollHeight) {
      if (el.target.scrollHeight - target_position < 20) {
        if (this.loading) {
          return
        }
        this.loadNextPage();
      }

    },
    loadNextPage: function () {
      this.page++;
      this._fetchAssets();
    },
    _fetchAssets: async function () {
      // if the filter is not yet set (i.e. is empty), we skip fetching
      if (Object.keys(this.filter).length === 0) {
        return;
      }
      // otherwise, we proceed with fetching the assets
      this.requests_sent++;
      // we will send this ping along with the request and compare it
      // to this.requests_sent after we receive a response
      this.loading = true;
      const ping = this.requests_sent;
      const payload = {
        page_size: 50,
        page: this.page,
        filter: this.filter,
        ping: ping,
        include_positionings: true,
      };

      const response = await axios.post(
        `${this.libraryKey === 'my_library'
          ? '/api/cas/assets/search'
          : this.libraryKey === 'mindspeller'
            ? '/api/cas/assets/store/search_new'
            : ''
        }`,
        payload
      );
      // we only want to process the latest query so we disregard all
      // responses that are not a result of the latest request (i.e. the
      // request whose ping is this.requests_sent)
      if (response.data.ping === this.requests_sent) {
        // get the total number of the assets of A user or mindspeller library
        if (response.data.assets.length > 0 && this.assets.length==0) {
          if (this.libraryKey === 'my_library'){
            this.asset_num = response.data.assets.length;
          }
          if (this.libraryKey === 'mindspeller'){
            this.asset_num = response.data.assets[0].total_count;
          }
        } 
        if (response.data.assets.length == 0 && this.assets.length==0) {
          this.asset_num = 0;
        } 

        // here we filter out the assets that state = 5
        let concatenate_assets = response.data.assets.filter(asset => asset.state !== 5);
        this.assets = [...this.assets, ...concatenate_assets];

        this.loading = false;
      }



    },
    /*
                    Add an asset to AssetGrid. In principle, AssetGrid assets should only
                    be fetched using the API, but when an asset is purchased we want to
                    manually add an asset without calling the API (since that would reset
                    the view and might confuse the user)
                    */
    addAsset: function (asset) {
      this.assets.unshift(asset);
    },
    /*
                    Resets the asset grid back to the initial asset draw while
                    */
    resetSearch: function () {
      this.page = 0;
      this.requests_sent = 0;
      this.assets = [];
      this._fetchAssets();
    },
  },

  watch: {
    // whenever filter changes we need to initiate a new search
    filter: {
      deep: true,
      immediate: true,
      handler() {
        this.resetSearch();
      },
    },
    assets: {
      deep: true,
      immediate: true,
      handler() {
        this.onCountTotalAssets(this.asset_num);
      },
    },

  },

  /* We need this for the <asset-tile> ref array (see this.tilesRef())*/
  beforeUpdate() {
    this.tile_elements = [];
  },
};
</script>

<style lang="scss" scoped>
.asset-workspace {
  // padding: 0 5% 0 5%;
  width: 100%;
  overflow-y: hidden;
}

.asset-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: left;
  position: relative;
  min-height: 150px;
  padding: 1.5%;
}

.loaders {
  width: 121px;
  height: 121px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>
