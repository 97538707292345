<template>
  <div class="landing-page" ref="landingPage">
    <div class="landing-content">
      <div style="display: flex; justify-content:center;">
        <Logo style="pointer-events:none;transform:scale(1.75); margin-bottom: 3vw;" />
      </div>
      <p style="text-align: center; margin-bottom: 5vw;" class="landing-subtitle">Welcome to your onboarding!</p>
      <div style="display: flex; align-items: center; justify-content: center; gap: 30px; width: 100%;">
        <button class="btn-secondary" @click="redirectToLibrary">Test your concept</button>
        <button class="btn-primary" @click="redirectToOnBoarding">Position your brand</button>
      </div>
    </div>
  </div>
</template>
  
<script>
import Logo from "../header/Logo.vue";

export default {
  components: {
    Logo,
  },
  mounted() {
    setTimeout(() => {
      this.$refs.landingPage.classList.add('slide-in');
    }, 100);
  },
  methods: {
    redirectToOnBoarding() {
      this.$router.push("/archetypes");
    },
    redirectToLibrary() {
      this.$router.push("/concept-onboarding");
    },
  },
};
</script>
  
<style scoped>
.landing-page {
  background: #fff;
  color: #1F1FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  font-weight: bolder;
  flex-direction: column;
  padding: 20px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.2s, transform 0.3s;
}

.slide-in {
  opacity: 1;
  transform: translateY(0);
}

.landing-content {
  padding: 20px;
  width: 90%;
  
}

.landing-title {
  font-size: 64px;
  font-weight: 900;
  margin-bottom: 20px;
  color: #1F1FFF;
  letter-spacing: -1px;
}

.landing-subtitle {
  font-size: 28px;
  margin-bottom: 40px;
  color: #555;
}

.btn-primary,
.btn-secondary {
  font-size: 24px;
  padding: 16px 32px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  letter-spacing: 1px;
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
  margin-bottom: 20px;
}

.btn-primary {
  background-color: #1F1FFF;
  color: #fff;
  width: 30%;
  flex-wrap: wrap;
}

.btn-secondary {
  background-color: #1F1FFF;
  width: 30%;
  flex-wrap: wrap;
  color: #fff;
}

.btn-primary:hover {
  background-color: #fff;
  color: #1F1FFF;
  border: 2px solid #1F1FFF;
}

.btn-secondary:hover {
  background-color: #fff;
  color: #1F1FFF;
  border: 2px solid #1F1FFF;
}

@media (max-width: 600px) {
  .landing-title {
    font-size: 48px;
  }

  .landing-subtitle {
    font-size: 24px;
  }

  .btn-primary,
  .btn-secondary {
    font-size: 20px;
    padding: 12px 24px;
  }
}
</style>
  