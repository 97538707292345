import { createI18n } from 'vue-i18n';
import { co_translation } from './content_optimizer.js';
import { shared_translation } from './shared.js';
import { settings_translation } from './settings.js';
import { target_picker_translation } from './target_pickers.js';
import { asset_picker_translation } from './asset_picker.js';
import { neuropositioning_translation } from './neuropositioning.js';
import { mind_tracker_translation } from './mind_tracker.js';
import { pos_translation } from './pos_translation';
import { path_finder_translation } from './path_finder.js';
import { explorer_translation } from './explorer.js';
import { association_wheels_translation } from './association_wheels.js';
import { library_translation } from './library.js';
import { add_new_asset_translation } from './add_new_asset.js';
import { competitive_profiler_translation } from './competitive_profiler.js';
import { login_translation } from './login.js';
import { emotional_analysis_translation } from "@/translations/emotional_analysis";
import { text_statistics_translation } from "@/translations/text_statistics";
import { dashboard_translation } from "@/translations/dashboard";
import {self_registration_translation} from "@/translations/self_registration";
import {onboarding_tips} from  "./onboarding_tips.js"

const messages = {
    en: {
        message: {
            ...co_translation.en.message,
            ...pos_translation.en.message,
            ...shared_translation.en.message,
            ...settings_translation.en.message,
            ...target_picker_translation.en.message,
            ...asset_picker_translation.en.message,
            ...neuropositioning_translation.en.message,
            ...mind_tracker_translation.en.message,
            ...path_finder_translation.en.message,
            ...explorer_translation.en.message,
            ...association_wheels_translation.en.message,
            ...library_translation.en.message,
            ...emotional_analysis_translation.en.message,
            ...text_statistics_translation.en.message,
            ...dashboard_translation.en.message,
            ...self_registration_translation.en.message,
            ...onboarding_tips.en.message
        },
        ...add_new_asset_translation.en,
        ...competitive_profiler_translation.en,
        ...login_translation.en,
    },
    nl: {
        message: {
            ...co_translation.nl.message,
            ...pos_translation.nl.message,
            ...shared_translation.nl.message,
            ...settings_translation.nl.message,
            ...target_picker_translation.nl.message,
            ...asset_picker_translation.nl.message,
            ...neuropositioning_translation.nl.message,
            ...mind_tracker_translation.nl.message,
            ...path_finder_translation.nl.message,
            ...explorer_translation.nl.message,
            ...association_wheels_translation.nl.message,
            ...library_translation.nl.message,
            ...emotional_analysis_translation.nl.message,
            ...text_statistics_translation.nl.message,
            ...dashboard_translation.nl.message,
            ...self_registration_translation.nl.message,
            ...onboarding_tips.nl.message
        },
        ...add_new_asset_translation.nl,
        ...competitive_profiler_translation.nl,
        ...login_translation.nl,
    }
};

const i18n = createI18n({
    // the default language is English; it will be changed on application init
    // based on the user's prefered language (stored in the DB)
    locale: 'en',
    messages,
});


export { i18n };
