<template>
  <div id="copy-score-chart">
  </div>
</template>

<script>
import * as d3 from 'd3'
import CopyScoreChart from './CoChart.js'

export default {
  name: "CoD3Chart",

    data: function() {
        return {
            d3_chart: { data: {} },
        }
    },

  methods: {
    updateChart: function(data, config) {
      this.d3_chart.update(data, config);
    },
  },

  mounted() {
    // Creating graph.
    this.d3_chart =
        new CopyScoreChart("copy-score-chart",
  [
            {
                "name": "A",
                "score": 0,
                "text_score": 0,
                "image_score": 0
            },
            {
                "name": "B",
                "score": 0,
                "text_score": 0,
                "image_score": 0
            }
        ],
        {
          config: {
              advanced_mode: false,
              show_scores: false,
              show_inspiration: false
          },
        }
      );
    },

}
</script>
