export function escapeScriptTags(text) {
        text = text.replace('<', '&lt;');
        text = text.replace('>', '&gt;');
        return text;
    }

export function restoreScriptTags(text) {
        text = text.replace('&lt;', '< ');
        text = text.replace('&gt;', ' >');
        return text;
    }

export function setEditorHTML(editor_id, text) {
        document.getElementById(editor_id).innerHTML = text;
    }

export function mutationObserverCallback(mutationList, observer) {
        mutationList.forEach((mutation) => {
            switch (mutation.type) {
                case 'characterData':
                    mutation.target.parentNode.style.boxShadow = 'none';
                    break;
            }
        });
    };

export let mutationObserverOptions = {
        characterData: true,
        subtree: true,
    };