<template>
    <word-picker
      ref="word-picker"
      :clearAfterSelection="false"
      @wordSelected="onWordSelected"
      @wordChanged="onWordChanged"
      hint="Enter your Target Association">
    </word-picker>
</template>

<script>
import WordPicker from '@/components/target_pickers/ias/WordPicker.vue';

export default {
  components: { WordPicker },
  emits: ['iaSelected'],
  data: function() {
    return {
      selected_intended_association: {},
    }
  },
  methods: {
    reset: function() {
      this.selected_intended_association = {};
      this.$refs['word-picker'].reset();
    },
    onWordSelected: function(word) {
      // we need to assign the word like this to preserve reactivity
      Object.assign(this.selected_intended_association, word);
      this.$emit('iaSelected', this.selected_intended_association);
    },
    onWordChanged: function(value) {
      this.$emit('iaChanged', value);
    },
  },
}
</script>
