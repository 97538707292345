<template>
  <div class="dropdown-menu-ul">
    <ul>
      <li class="user-container" @mouseenter="showSubmenu" @mouseleave="hideSubmenu">
        <font-awesome-icon :icon="['fas', 'user']" class="logo" />
        <font-awesome-icon :icon="['fas', 'angle-down']" style="color: #FFFFFF; margin-left: 10px;" />
        <ul class="user-dropdown" v-if="isSubmenuVisible">
          <li class="login-name">
            <a @click="showFileInputMethod">
              <img v-if="image" :src="image"/>
              <span v-else="!image || !this.$store.state.current_user.first_name" class="latter">
                {{
                  this.$store.state.current_user
                  ? this.$store.state.current_user.first_name
                  ? this.$store.state.current_user.first_name[0].toUpperCase()
                  : ''
                  : ''
                }}
              </span>
              <div class="nameli">
                {{
                  this.$store.state.current_user
                  ? this.$store.state.current_user.first_name
                  : ''
                }}
                <span class="email" :title="this.$store.state.current_user.email">
                  {{
                    this.$store.state.current_user
                    ? this.$store.state.current_user.email
                    : ''
                  }}
                </span>
            </div>
          </a>
          <input v-if="showFileInput" type="file" ref="fileInput" @change="uploadProfilePic">
          </li>
          <li @click="handleMyAccount">My Account</li>
          <li @click="logout"><a>Logout</a></li>
        </ul>
      </li>
    </ul>
  </div>
  <logout-confirm-box
    v-if="confirm"
    :show="confirm ? true : false"
    msg="Are you sure you want to logout the session?"
    heading="Logout Confirmation"
    btnText1="Cancel"
    btnText2="Logout"
    :handleClose="handleClose"
  ></logout-confirm-box>
  <logout-confirm-box v-if="confirm" :show="confirm ? true : false" msg="Are you sure you want to logout the session?"
    heading="Logout Confirmation" btnText1="Cancel" btnText2="Logout" :handleClose="handleClose"></logout-confirm-box>
</template>

<script>
import axios from 'axios';
import LogoutConfirmBox from '../common/LogoutConfirmBox.vue';

export default {
  data() {
    return {
      isSubmenuVisible: false,
      image: '',
      confirm: null,
      showFileInput: false
    };
  },
  components: {
    LogoutConfirmBox,
  },
  mounted: async function () {
    try {
      const result = await axios.get('/api/cas/users/get_picture');
      if (result) {
        this.image = result.data['url'];
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    showFileInputMethod(){
      this.showFileInput = true;
    },
    uploadProfilePic: async function (e) {
      let files = e.target.files || this.$refs.fileInput.files;
      if (!files || !files.length) {
        this.showFileInput = false;
        return;
      }

      const file = files[0];
      let fd = new FormData();
      fd.append('file', file);
      
      try {
        const data = await axios.post('/api/cas/users/upload_picture', fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (data) {
          const result = await axios.get('/api/cas/users/get_picture');
          if (result) {
            this.image = result.data.url;
          }
        }
      } catch (error) {
        console.error('Error uploading profile picture:', error);
      }
      this.showFileInput = false;
      location.reload();
    },
    showSubmenu() {
      this.isSubmenuVisible = true;
    },
    hideSubmenu() {
      this.isSubmenuVisible = false;
    },
    handleMyAccount() {
      window.open(this.$router.resolve('/my-account').href, '_blank');
    },
    logout() {
      this.confirm = 'logout';
    },
    handleClose() {
      this.confirm = null;
    },
    handleDashboard: function () {
      if (this.$store.state.current_user.onboarding_step >= 3) {
        this.$router.push({ name: 'Dashboard' });
      } else {
        if (this.$store.state.current_user.onboarding_step <= 0) {
          this.$router.push({ name: 'Archetypes' });
        } else if ((this.$store.state.current_user.onboarding_step = 1)) {
          this.$router.push({ name: 'Passions' });
        } else if ((this.$store.state.current_user.onboarding_step = 2)) {
          this.$router.push({ name: 'Brand Value' });
        }
      }
    },
  }
};
</script>

<style scoped>

.user-container:hover .submenu {
  display: block;
}
.logo{
  width:25px;
  height:25px;
  color:"#74C0FC";
}

.user-container{
  border: 0.1vw solid #FFFFFF;
  border-radius: 3vw;
  padding: 0.25vw 0.25vw;
  width:75px;
  height:40px;
}
.user-container ul{
  top: 40px;
}
.user-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
}
.login-name {
  display: flex;
  align-items: center;
}
.nameli {
  display: flex;
  flex-direction: column;
}
.email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
</style>
