<template>
    <div class="spinner-border" v-bind:class="className" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
</template>

<script>
export default {
    name: 'LoaderSpinner',
    props: {
      className: String
    }
}
</script>

<style scoped>
.centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
