<template>
  <div class="error-alert-box-bg logout-popup" v-bind:style="{display: show ? 'block' : 'none'}">
    <div class="alert-box-col">
      <div class="close-btn-blk" @click="handleClose">X</div>
      <h3>{{ heading }}</h3>
      <p>{{ msg }}</p>

      <div class="bottom-btn-col">
        <button class="btngrey"  @click="this.$props.handleClose">{{ btnText1 }}</button
        ><button class="btn3" @click="handleConfirm">{{ btnText2 }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogoutConfirmBox',
  props: {
    msg: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    btnText1: {
      type: String,
    },
    btnText2: {
      type: String,
      required: true,
    },
    show: {
      type: String,
      required: true,
    },
    handleClose: {
      type: Function,
    },
  },
  methods: {
     handleConfirm: function () {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'Login' });
    },
    handleClose: function () {
      this.$props.handleClose();
    }
  },
};
</script>
