const onboarding_tips = {
    en: {
        message: {
            archetypes_tip_tooltip_content: "A brand's essence lies beyond superficial elements; it's about identity and customer perception, built on high-quality products and service, resonating with customers and distinguishing industry leaders from the rest.",
            archetypes_tip_title: "The importance of a Brand Archetype: Picking your Brand Personality",
            archetypes_tip_content: "A brand encompasses more than superficial elements like color schemes and mascots; it is the essence of your identity and how your customers perceive you. Essential to establishing a strong brand are delivering high-quality products and top-notch service. A compelling brand that resonates with customers distinguishes industry leaders from the rest. Begin building your brand from the outset of your company, considering the twelve distinct brand archetypes to find one that aligns with your message. While overlap may occur, commit to a single archetype as your foundation, avoiding hybridization that can dilute your brand. Consistent messaging rooted in your chosen archetype will cultivate a unique and impactful brand identity.",
            archetypes_tip_extended: "A brand is more than just a color scheme and a mascot. A brand is your identity and how your customers perceive you. Creating a high quality product matters. Providing top-notch service matters. Those are the basics you need to get right. A compelling brand that connects easily with customers makes the difference between which companies dominate their industry and which are the “also rans”. Building a strong brand starts at the earliest stages of your company. Most companies fit into one of twelve brand archetypes and each has a distinctive personality. Take a look at the descriptions of the 12 brand archetypes by clicking on them and decide which one best reflects your message. Think carefully about your company and which archetype best reflects your message. Odds are one of these twelve is a good framework for your story. You may find yourself with some overlap (Hero versus Explorer, Magician versus the Creator), but you need to select one as a starting point. Even though it can be difficult and the desire to say you’re a hybrid can be strong, you should focus on only one. Once you identify your archetype you can start creating consistent messaging to develop your own unique brand. Hybridizing makes that very difficult and dilutes your message and brand.",
            values_tip_tooltip_content: "Crafting the company's story as a brand director involves highlighting its mission, purpose, and founders' values transparently, fostering connections, trust, and confidence, while emphasizing the ability to execute a shared vision and accommodating potential pivots for future success.",
            values_tip_title: "Communicating your Story & Weaving it into your Mission",
            values_tip_content: "Crafting the company's story is crucial for a brand director, following industry context. The narrative must explain the company's founding, its purpose, and the driving force behind it, with passion at its core. Sharing challenges and failures fosters connections, while transparency about founders and values builds credibility. The narrative should focus on the brand director as a leader, highlighting the ability to execute a shared vision. Frameworks like archetypes can aid in effectively conveying passion. A strong mission, exemplified by companies like Google and Patagonia, should accommodate potential pivots and balance viability with profitability for future success.",
            values_tip_extended:"Crafting a captivating company narrative is paramount for engaging both investors and audiences. Entrepreneurs are advised to share personal experiences, including setbacks, to foster trust and authenticity. This involves emphasizing emotional resonance in storytelling by addressing both personal and leadership aspects of their journey. Being relatable and accessible is crucial, particularly during fundraising endeavors. While condensing one's passion into a succinct narrative can be challenging, leveraging various storytelling frameworks can aid in this endeavor. Moreover, adaptability to evolving market demands is crucial. A well-defined mission should strike a balance between viability and profitability, ensuring long-term success. By weaving together personal anecdotes, emotional appeal, and strategic vision, entrepreneurs can craft a narrative that not only communicates their company's mission but also resonates deeply with their target audience. This approach not only strengthens the brand's authenticity but also fosters a sense of connection and trust. Ultimately, a compelling company story serves as a powerful tool for inspiring confidence, attracting investment, and driving sustainable growth. It encapsulates the essence of the brand, encapsulating its purpose, values, and aspirations, while also highlighting its ability to adapt and thrive in an ever-changing business landscape.",
            values_tip_footer: "Does your company mission cover potential pivots? Take some time to think about how your current mission has impacted the overall performance and outcomes of your business, because the groundwork you lay down now will have a big impact on the future direction of your business.",
            brand_info_tip_tooltip_content: "A brand's mission acts as a vital compass, providing clarity, focus, and adaptability amid challenges, fostering innovation, resilience, and strategic direction for sustained progress and future success.",
            brand_info_tip_title: "Choosing a Mission that covers your Pivots",
            brand_info_tip_content: "A new brand's mission serves as a vital compass, guiding its purpose, values, and goals, and ensuring clarity and focus amid evolving challenges. Unlike clichés, a genuine mission is specific, achievable, and aligned with the brand's existential narrative. It provides a roadmap for strategic direction, aiding decision-making and adaptation during pivots. Memorable, manageable, measurable, and motivational, a mission offers clear direction and answers existential questions, enabling sustained progress. Tactical and adaptable, it drives continuous innovation and prepares for future pivots. Embracing a culture of iteration and learning fosters resilience and engagement, strengthening the team's commitment to the mission and strategy. Crucially, a mission must anticipate and accommodate future pivots, ensuring readiness to adapt while maintaining focus on the ultimate goal, akin to navigating a dynamic game with evolving strategies but a consistent aim to win.",
            brand_info_tip_extended: "Crafting a meaningful mission is crucial for any burgeoning brand. It serves as more than a mere corporate mantra; it embodies the essence, values, and objectives that define the brand's existence. A genuine mission transcends clichés, avoiding vagueness or unattainable aspirations. Rather than aiming to save the world or tackle every societal woe, a brand should focus on a specific subset of issues aligned with its purpose and goals. A well-defined mission acts as a guiding light, providing a clear roadmap toward the brand's ultimate objectives. It offers direction, strategy, and cohesion for the team, even amid pivots or industry shifts. A mission should be memorable, manageable, measurable, and motivational, furnishing the brand with a sense of purpose and direction. Especially in the early stages, when specific goals may be elusive, a mission provides answers to existential questions and fosters progress. It's a tactical tool focused on the present, guiding decisions to maintain momentum toward long-term goals. Embracing a culture of iteration and innovation is vital for driving the mission forward and preparing for growth. While setbacks are inevitable, they offer opportunities for learning and growth. A clear mission enhances employee engagement, aligning the team with the brand's vision and fostering a sense of contribution and impact. Crucially, a mission must anticipate future pivots, as change is inherent in business. Whether due to market shifts, regulations, or technological advancements, brands must be prepared to adapt smoothly. A mission that accommodates future changes while remaining actionable ensures continuity amid transformation, akin to adjusting strategies in a game while aiming for the same victory. In essence, a well-crafted mission serves as a beacon, guiding the brand through uncertainty, evolution, and growth, ensuring alignment with its purpose and vision."
        },
    },
    nl: {
        message: {
            archetypes_tip_tooltip_content: "A brand's essence lies beyond superficial elements; it's about identity and customer perception, built on high-quality products and service, resonating with customers and distinguishing industry leaders from the rest.",
            archetypes_tip_title: "The importance of a Brand Archetype: Picking your Brand Personality",
            archetypes_tip_content: "A brand encompasses more than superficial elements like color schemes and mascots; it is the essence of your identity and how your customers perceive you. Essential to establishing a strong brand are delivering high-quality products and top-notch service. A compelling brand that resonates with customers distinguishes industry leaders from the rest. Begin building your brand from the outset of your company, considering the twelve distinct brand archetypes to find one that aligns with your message. While overlap may occur, commit to a single archetype as your foundation, avoiding hybridization that can dilute your brand. Consistent messaging rooted in your chosen archetype will cultivate a unique and impactful brand identity.",
            archetypes_tip_extended: "A brand is more than just a color scheme and a mascot. A brand is your identity and how your customers perceive you. Creating a high quality product matters. Providing top-notch service matters. Those are the basics you need to get right. A compelling brand that connects easily with customers makes the difference between which companies dominate their industry and which are the “also rans”. Building a strong brand starts at the earliest stages of your company. Most companies fit into one of twelve brand archetypes and each has a distinctive personality. Take a look at the descriptions of the 12 brand archetypes by clicking on them and decide which one best reflects your message. Think carefully about your company and which archetype best reflects your message. Odds are one of these twelve is a good framework for your story. You may find yourself with some overlap (Hero versus Explorer, Magician versus the Creator), but you need to select one as a starting point. Even though it can be difficult and the desire to say you’re a hybrid can be strong, you should focus on only one. Once you identify your archetype you can start creating consistent messaging to develop your own unique brand. Hybridizing makes that very difficult and dilutes your message and brand.",
            values_tip_tooltip_content: "Crafting the company's story as a brand director involves highlighting its mission, purpose, and founders' values transparently, fostering connections, trust, and confidence, while emphasizing the ability to execute a shared vision and accommodating potential pivots for future success.",
            values_tip_title: "Communicating your Story & Weaving it into your Mission",
            values_tip_content: "Crafting the company's story is crucial for a brand director, following industry context. The narrative must explain the company's founding, its purpose, and the driving force behind it, with passion at its core. Sharing challenges and failures fosters connections, while transparency about founders and values builds credibility. The narrative should focus on the brand director as a leader, highlighting the ability to execute a shared vision. Frameworks like archetypes can aid in effectively conveying passion. A strong mission, exemplified by companies like Google and Patagonia, should accommodate potential pivots and balance viability with profitability for future success.",
            values_tip_extended:"Crafting a captivating company narrative is paramount for engaging both investors and audiences. Entrepreneurs are advised to share personal experiences, including setbacks, to foster trust and authenticity. This involves emphasizing emotional resonance in storytelling by addressing both personal and leadership aspects of their journey. Being relatable and accessible is crucial, particularly during fundraising endeavors. While condensing one's passion into a succinct narrative can be challenging, leveraging various storytelling frameworks can aid in this endeavor. Moreover, adaptability to evolving market demands is crucial. A well-defined mission should strike a balance between viability and profitability, ensuring long-term success. By weaving together personal anecdotes, emotional appeal, and strategic vision, entrepreneurs can craft a narrative that not only communicates their company's mission but also resonates deeply with their target audience. This approach not only strengthens the brand's authenticity but also fosters a sense of connection and trust. Ultimately, a compelling company story serves as a powerful tool for inspiring confidence, attracting investment, and driving sustainable growth. It encapsulates the essence of the brand, encapsulating its purpose, values, and aspirations, while also highlighting its ability to adapt and thrive in an ever-changing business landscape.",
            values_tip_footer: "Does your company mission cover potential pivots? Take some time to think about how your current mission has impacted the overall performance and outcomes of your business, because the groundwork you lay down now will have a big impact on the future direction of your business.",
            brand_info_tip_tooltip_content: "A brand's mission acts as a vital compass, providing clarity, focus, and adaptability amid challenges, fostering innovation, resilience, and strategic direction for sustained progress and future success.",
            brand_info_tip_title: "Choosing a Mission that covers your Pivots",
            brand_info_tip_content: "A new brand's mission serves as a vital compass, guiding its purpose, values, and goals, and ensuring clarity and focus amid evolving challenges. Unlike clichés, a genuine mission is specific, achievable, and aligned with the brand's existential narrative. It provides a roadmap for strategic direction, aiding decision-making and adaptation during pivots. Memorable, manageable, measurable, and motivational, a mission offers clear direction and answers existential questions, enabling sustained progress. Tactical and adaptable, it drives continuous innovation and prepares for future pivots. Embracing a culture of iteration and learning fosters resilience and engagement, strengthening the team's commitment to the mission and strategy. Crucially, a mission must anticipate and accommodate future pivots, ensuring readiness to adapt while maintaining focus on the ultimate goal, akin to navigating a dynamic game with evolving strategies but a consistent aim to win.",
            brand_info_tip_extended: "Crafting a meaningful mission is crucial for any burgeoning brand. It serves as more than a mere corporate mantra; it embodies the essence, values, and objectives that define the brand's existence. A genuine mission transcends clichés, avoiding vagueness or unattainable aspirations. Rather than aiming to save the world or tackle every societal woe, a brand should focus on a specific subset of issues aligned with its purpose and goals. A well-defined mission acts as a guiding light, providing a clear roadmap toward the brand's ultimate objectives. It offers direction, strategy, and cohesion for the team, even amid pivots or industry shifts. A mission should be memorable, manageable, measurable, and motivational, furnishing the brand with a sense of purpose and direction. Especially in the early stages, when specific goals may be elusive, a mission provides answers to existential questions and fosters progress. It's a tactical tool focused on the present, guiding decisions to maintain momentum toward long-term goals. Embracing a culture of iteration and innovation is vital for driving the mission forward and preparing for growth. While setbacks are inevitable, they offer opportunities for learning and growth. A clear mission enhances employee engagement, aligning the team with the brand's vision and fostering a sense of contribution and impact. Crucially, a mission must anticipate future pivots, as change is inherent in business. Whether due to market shifts, regulations, or technological advancements, brands must be prepared to adapt smoothly. A mission that accommodates future changes while remaining actionable ensures continuity amid transformation, akin to adjusting strategies in a game while aiming for the same victory. In essence, a well-crafted mission serves as a beacon, guiding the brand through uncertainty, evolution, and growth, ensuring alignment with its purpose and vision."
        },
    }
};

export { onboarding_tips };
