<template>
  <section class="middle-section">
    <div class="container">
      <div class="inspiration-pg association-wheel-pg">
        <div class="inspir-lft-sec scroll">
          <div class="leftBar">
            <co-workspace
              ref="co-workspace"
              :jumpStartAsset="assetAndPositioning"
              @reset="reset"
              @editorContentChange="editorContentChange"
              @editorTextImageEmpty="setEditorStatus"
            ></co-workspace>
        </div>
      </div>
        <div class="rightbox-sec scroll">
          <div class="rightBar">
          <co-sidebar
            ref="co-sidebar"
            @generate="triggerContentOptimizer"
            @iaSelected="onIaSelected"
            @editorCoIaEmpty="setIaStatus"
            :button-tooltip-string="buttonTooltipString"
            :editor-text-image-empty="editorTextImageEmpty"
          >
          </co-sidebar>
          </div>
        </div>
      </div>
    </div>
    </section>
   <loading-app-wide v-if="display_loading_overlay" />
</template>

<script>
import AssetPicker from "../asset_picker/AssetPicker.vue";
import CoSidebar from "./CoSidebar.vue";
import CoWorkspace from "./CoWorkspace";
import LoadingAppWide from "@/components/common/LoadingAppWide.vue";

export default {
  components: { CoWorkspace, CoSidebar, AssetPicker, LoadingAppWide },

  computed: {
    buttonTooltipString: function () {
      if (this.editorTextImageEmpty) {
        return this.$t('message.co_tooltip_fill_editor')
      } else if (this.editorCoIaEmpty) {
        return this.$t('message.co_tooltip_select_ia')
      } else {
        return this.$t('message.generate')
      };
    },
  },

  data: function(){
    return {
      display_loading_overlay: false,
      assetAndPositioning: null,
      D3ChartData: [
        // Array of objects required for generating D3 chart.
        {
          "name": "A",
          "score": new Number,
          "text_score": new Number,
          "image_score": new Number
        },
        {
          "name": "B",
          "score": new Number,
          "text_score": new Number,
          "image_score": new Number
        }
      ],
      editorTextImageEmpty: true,
      editorCoIaEmpty: true,
      // D3 chart options - currently not working.
      D3ChartConfig: {
        advanced_mode: false,
        show_scores: false,
        show_inspiration: false
      },
    }
  },
  methods: {
    reset: function() {
      this.$refs['co-sidebar'].reset();
    },
    /*
    This method triggers CO operation (analysis, text decoration and
    chart) and is fired upon clicking "Generate" button in the
    sidebar.
    */
    triggerContentOptimizer: async function(selected_ia) {
      this.display_loading_overlay = true;
      let scores = [];
    
      scores = await this.$refs["co-workspace"].triggerEditorGenerate(selected_ia);
      // Scores - array of two objects containing text_score and image_score.

      scores.forEach((element, index) => {
        // Updating D3ChartData variable with collected scores.
        this.D3ChartData[index].text_score = element.text_score;
        this.D3ChartData[index].image_score = element.image_score;
        if (((element.text_score + element.image_score) / 2) == 0){
        this.D3ChartData[index].score = null;
        } else {
        this.D3ChartData[index].score =
            (element.text_score + element.image_score) / 2;
        }
      });
      // Updating D3 chart with data and config.
      this.$refs["co-sidebar"].updateD3Chart(
        this.D3ChartData,
        this.D3ChartConfig
      );

      // Updating emotional scoring
      this.$refs["co-sidebar"].updateEmotionalScores(scores);
      this.display_loading_overlay = false;
      this.$refs["co-sidebar"].changeButtonState();
      this.$refs["co-sidebar"].renderEmotions = true;
    },
    onIaSelected: function(selected_ia) {
      this.$refs["co-workspace"].selected_ia = selected_ia;
    },
    editorContentChange: function() {
      this.$refs['co-sidebar'].editorContentChange();
    },
    setEditorStatus: function(value) {
      this.editorTextImageEmpty = value;
    },
    setIaStatus: function(value) {
      this.editorCoIaEmpty = value;
    },
    jumpstart: async function () {
      const jumpstartEmpty = await this.$store.dispatch('jumpstart_empty');
      if (!jumpstartEmpty) {
          this.assetAndPositioning = await this.$store.dispatch('pop_jumpstart');
      }
    }
  },
  mounted: async function () {
        this.jumpstart();
  },
}
</script>

<style scoped>
.rightbox-sec span {
    color: #fff;
    font-weight: 500;
    font-size: 1.1vw;
    line-height: 1.5vw;
    display: block;
    margin-top: 0.2vw;
    margin-bottom: 0.2vw;
    text-align: left;
}

.rightbox-sec .trg-assoc span {
   font-size: 1.2vw;
    font-weight: 600;
    margin-bottom: 1vw;
}

.trg-assoc p{text-align: left; margin-bottom: 2vw; line-height: 1.7vw;}
</style>

