<template>
  <div class="text-stats shadow">
    <div
      class="notch right"
      @mouseenter="displayReadability = true"
      @mouseleave="displayReadability = false"
    >
      <section class="data">
        <section class="number">
          <span class="big">{{ Math.round(text_stats.reading_time) }}</span> s
        </section>
        <section class="text">
          {{ $t('message.text_info_reading_time') }}
        </section>
      </section>
      <transition name="fade">
        <div class="more-info right shadow" v-if="displayReadability">
          <table>
            <tr>
              <td>{{ text_stats.characters }}</td>
              <td>{{ $t('message.text_info_characters') }}</td>
            </tr>
            <tr>
              <td>{{ text_stats.words }}</td>
              <td>{{ $t('message.text_info_words') }}</td>
            </tr>
            <tr>
              <td>{{ text_stats.sentences }}</td>
              <td>{{ $t('message.text_info_sentences') }}</td>
            </tr>
            <tr class="no-underline">
              <td>{{ text_stats.average_sentence_length }}</td>
              <td>{{ $t('message.text_info_avg_sent_length') }}</td>
            </tr>
          </table>
        </div>
      </transition>
    </div>
    <div
      class="notch right"
      @mouseenter="displayInformation = true"
      @mouseleave="displayInformation = false"
    >
      <section class="data">
        <section class="number">
          <span class="big">{{ mappedReadabilityScore }}</span>
          <span class="small"> / 7</span>
        </section>
        <section class="text">
          {{ $t('message.text_info_readability') }}
        </section>
      </section>
      <transition name="fade">
        <div class="more-info left shadow" v-if="displayInformation">
          <table align="left">
            <tr class="no-underline">
              <th>{{ $t('message.text_info_score').toUpperCase() }}</th>
              <th>{{ $t('message.text_info_meaning').toUpperCase() }}</th>
            </tr>
            <tr>
              <td>7</td>
              <td>{{ $t('message.score_7') }}</td>
            </tr>
            <tr>
              <td>6</td>
              <td>{{ $t('message.score_6') }}</td>
            </tr>
            <tr>
              <td>5</td>
              <td>{{ $t('message.score_5') }}</td>
            </tr>
            <tr>
              <td>4</td>
              <td>{{ $t('message.score_4') }}</td>
            </tr>
            <tr>
              <td>3</td>
              <td>{{ $t('message.score_3') }}</td>
            </tr>
            <tr>
              <td>2</td>
              <td>{{ $t('message.score_2') }}</td>
            </tr>
            <tr class="no-underline">
              <td>1</td>
              <td>{{ $t('message.score_1') }}</td>
            </tr>
          </table>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextStatistics.vue',
  data: function() {
    return {
      displayReadability: false,
      displayInformation: false,
    };
  },
  computed: {
    mappedReadabilityScore: function() {
      let value = this.$props.text_stats.readability_score;
      if (value < 30) {
        return 1;
      } else if (value >= 30 && value < 50) {
        return 2;
      } else if (value >= 50 && value < 60) {
        return 3;
      } else if (value >= 60 && value < 70) {
        return 4;
      } else if (value >= 70 && value < 80) {
        return 5;
      } else if (value >= 80 && value < 90) {
        return 6;
      } else if (value >= 90) {
        return 7;
      }
    },
  },
  props: {
    text_stats: { required: true, type: Object },
  },
};
</script>

<style scoped lang="scss">
.text-stats {
  position: absolute;
  left: 14vw;
  bottom: -62px;
  display: flex;
  border-radius: 0 0 25px 0;
}
.notch {
  height: 60px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 16px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  cursor: pointer;
  @media (min-width: 800px) {
    padding: 0;
  }
  @media (min-width: 1000px) {
    padding: 8;
  }
}

.notch .data {
  color: $mdspblue_ui;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.data section {
  flex-basis: 100%;
  text-align: center;
}

.number .big {
  font-weight: bolder;
  @media (min-width: 800px) {
    font-size: 1rem;
  }
  @media (min-width: 1000px) {
    font-size: 1.5rem;
  }
}

.number .small {
  font-weight: bolder;
  @media (min-width: 800px) {
    font-size: 0.8rem;
  }
  @media (min-width: 1000px) {
    font-size: 1.2rem;
  }
}

.data .text {
  font-size: 0.8rem;
}

.notch.right {
  border-radius: 0 0 20px 0;
}

.shadow {
  -webkit-box-shadow: 2px 0px 17px 0px rgba(66, 68, 90, 0.29);
  -moz-box-shadow: 2px 0px 17px 0px rgba(66, 68, 90, 0.29);
  box-shadow: 2px 0px 17px 0px rgba(66, 68, 90, 0.29);
}
.more-info {
  padding: 50px 20px;
  position: absolute;
  background-color: white;
  z-index: 10;
  width: 450px;
  height: 500px;
  border-radius: 25px;
}

.more-info.right {
  top: -500px;
  //left: 150px;
}

.more-info.left {
  top: -500px;
  //left: 150px;
}

.more-info table {
  width: 100%;
  border-collapse: collapse;
}

tr {
  border-bottom: 1px solid lightgray;
}

.no-underline {
  border-bottom: none;
}

td {
  padding: 10px 15px;
}
th {
  text-align: left;
  padding: 0 15px;
  border-bottom: none;
}
</style>
