<template>
  <q-dialog class="video-overlay" v-model="visible">
    <div class="instruction-card-wrapper">
      <div class="instruction-card">
        <q-card>
          <div>
            <video
              controls
              :src="videoUrl"
              playsinline autoplay loop
              class="instructions-video">
            </video>
            <div v-if="showSupressFooter" class="checkbox-control">
              <label>
                <input type="checkbox" v-model="stopShowing">
                {{ $t('message.dont_show_again') }}
              </label>
            </div>
          </div>
        </q-card>
        <section class="text-h5">{{ videoTitle }}</section>
      </div>
      <div class="card-button">
        <q-btn
          outline dense round v-close-popup
          icon="close"
          size="20"
          color="white">
        </q-btn>
      </div>
    </div>
  </q-dialog>
</template>

<script>
import {ref, watch} from 'vue';
import {useDialog} from '@/composables/dialog.js';
import {useDialogDismiss} from '@/composables/dismiss.js';


export default {
  props: {
    videoUrl: {required: true, type: String},
    videoTitle: {required: true, type: String},
    videoName: {required: true, type: String},
    showSupressFooter: {required: false, type: Boolean, default: true},
  },
  setup: function (props) {
    const {stopShowing} = useDialogDismiss(props.videoName);
    const {visible, open, close} = useDialog();
    return {visible, open, close, stopShowing};
  },
}
</script>

<style scoped>
.q-dialog__inner > div {
  box-shadow: none;
}

.q-card {
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px rgb(0 0 0 / 14%), 0 1px 10px rgb(0 0 0 / 12%);
}

.instruction-card {
  width: 100%;
  max-width: 100%;
}

.instruction-card section {
  display: flex;
  justify-content: center;
  color: white;
  font-family: "Open Sans";
  padding-top: 10px;
}

.instruction-card-wrapper {
  width: 900px;
  max-width: 75vw;
  display: flex;
}

.card-button {
  padding-left: 10px;
}

.checkbox-control {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}
.checkbox-control label {
  display: flex;
  align-items: center;
}
.checkbox-control label input{
  margin: 0 5px 0 0;
}
.instructions-video {
  width: 100% !important;
  height: auto !important;
}

.q-dialog__inner.flex.no-pointer-events.q-dialog__inner--minimized.q-dialog__inner--standard.fixed-full.flex-center .instruction-card-wrapper {
    overflow: hidden;
}
</style>
