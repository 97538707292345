<template>
  <q-dialog v-model="visible">
    <div class="instruction-card-wrapper">
      <div class="instruction-card">
        <section class="text-h5">{{ this.asset.file_name }}</section> -->
        <q-card>
          <div>
            <img class="preview-media" v-if="this.asset"
              :src="`https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_${this.asset.file_url}`">
            <!-- <img
              class="preview-media"
              v-if="this.asset"
              :src="`https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_${this.asset.file_url}`"
            > -->
          </div>
        </q-card>
      </div>
      <!-- <div class="card-button">
        <q-btn
          outline dense round v-close-popup
          icon="close"
          size="20"
          color="white">
        </q-btn>
      </div> -->
    </div>
  </q-dialog>
</template>

<script>
import { useDialog } from '@/composables/dialog.js';

export default {
  props: {
    asset: { required: true, type: Object },
  },
  setup: function () {
    const { visible, open, close } = useDialog();
    return { visible, open, close };
  },
}
</script>

<style scoped>
.q-dialog__inner>div {
  box-shadow: none;
  /* overflow: hidden; */
}

.q-card {
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px rgb(0 0 0 / 14%), 0 1px 10px rgb(0 0 0 / 12%);
  ;
}

.q-card div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.instruction-card {
  width: 100%;
  max-width: 100%;
}

.instruction-card section {
  display: flex;
  justify-content: center;
  color: white;
  font-family: "Open Sans";
  padding-top: 10px;
}

.instruction-card-wrapper {
  width: 900px;
  max-width: 75vw;
  display: flex;
}

.card-button {
  padding-left: 10px;
}

.instructions-video {
  width: 100% !important;
  height: auto !important;
}

img {
  max-width: 100%;
}

.q-dialog {
  max-width: 80%;
  width: auto;
}

@media screen and (max-width: 600px) {
  .q-dialog {
    max-width: 90%;
  }
}
</style>
