<template>
<q-select dense outlined bg-color="#CDD2FF" v-model="selectedFramework" :options="frameworks" :hint="hint" placeholder="Search Keyword">
    <span class="placeholderTxt" v-if="!selectedFramework">Select</span>
    <template v-slot:option="scope">
        <q-item v-bind="scope.itemProps">
            <q-item-section>
                <q-item-label>{{ scope.opt.label }}</q-item-label>
                <q-item-label caption>{{ scope.opt.description }}</q-item-label>
            </q-item-section>
        </q-item>
    </template>
</q-select>
</template>

<script>
import axios from 'axios';
import {
    genericDialog,
    alert_error
} from '@/helpers/alert_helper.js';

export default {
    emits: ['frameworkSelected'],
    props: {
        hint: {
            default: '',
            type: String,
        },
    },
    data() {
        return {
            selectedFramework: null,
            frameworks: [],
        };
    },
    methods: {
        reset: function () {
            this.selectedFramework = null;
        },
        emitFrameworkSelected: function (selectedFramework) {
            this.$emit('frameworkSelected', selectedFramework);
        },
        frameworkSelect: async function (selected_framework) {
            // we check if the user has access to all of the words that belong
            // to the selected framework
            const framework_words = selected_framework.words;
            const missing_access = await this._missingAccess(framework_words);

            let price = this.$store.getters.get_price('public_word_asset');
            let total = price * missing_access.length;
            if (this.$store.state.mindcoins < total) {
                await alert_error(
                    this.$t('message.marketing_framework_insufficient_funds')
                );
                return;
            }

            // if there are words that the user doesn't have access to we
            // need to ask the user if they want to buy these words
            if (missing_access.length > 0) {
                const price = this.$store.getters.get_price('public_word_asset');
                const total = price * missing_access.length;
                const word_strings = missing_access.map((w) => w.word);
                const word_list = word_strings.join(', ');

                const dialog_options = {
                    title: this.$t('message.buy_multiple_words', {
                        word_list: word_list,
                    }),
                    text: this.$t('message.each_word_costs', {
                        price: price,
                        total: total,
                    }),
                    confirmButtonText: this.$t('message.add'),
                    cancelButtonText: this.$t('message.cancel'),
                };
                const dialog_result = await genericDialog(dialog_options);

                if (dialog_result.isConfirmed) {
                    await axios
                        .post(`/api/cas/words/request_access`, {
                            word_ids: missing_access.map((w) => w.word_id),
                        })
                        .then(this.emitFrameworkSelected(selected_framework));
                }
            } else {
                // if the user has access to all words from the framework
                // add them all to the IA list
                this.emitFrameworkSelected(selected_framework);
            }
        },

        /*
            Given a list of word objects, find words that the current user doesn't
            already have access to. Returns a list of word objects.
            */
        _missingAccess: async function (words) {
            const word_ids = words.map((w) => w.word_id);
            const payload = {
                word_ids: word_ids,
            };
            const response = await axios.post('/api/cas/words/check_access', payload);
            let missing_access = [];
            for (const word_id of Object.keys(response.data)) {
                const word_access = response.data[word_id];
                if (!word_access) {
                    // since the API only returns the word id as the key we need to find
                    // the whole word object
                    const wordObject = words.find((w) => w.word_id == word_id);
                    missing_access.push(wordObject);
                }
            }
            return missing_access;
        },
    },
    beforeCreate: async function () {
        // first we fetch the frameworks that the current user has access to
        const framework_response = await axios.get('/api/cas/frameworks/by_org');
        let frameworks = framework_response.data;
        // we also need to get the words that belong to those frameworks so
        // that we can display them in a tooltip
        const framework_ids = frameworks.map((f) => f.id);
        const payload = {
            framework_ids: framework_ids,
        };
        const word_response = await axios.post(
            '/api/cas/frameworks/words',
            payload
        );
        // we inject words lists into framework objects
        for (const framework of frameworks) {
            framework.words = word_response.data[framework.id];
        }
        // finally, we set the "frameworks" data variable
        this.frameworks = frameworks.map((f) => ({
            value: f,
            label: f.name,
            description: f.words.map((w) => w.word).join(', '),
        }));
    },
    watch: {
        selectedFramework: function (new_value) {
            if (new_value !== null) {
                this.frameworkSelect(new_value.value);
            }
        },
    },
};
</script>
