const path_finder_translation = {
    en: {
        message: {
            your_pathway: 'Connect the dots',
            inspirational_themes: 'Inspirational themes',
            theme_ranking: 
                "Theme ranking: select the themes that go together to create your " +
                "storyline.",
            path_finder_tooltip:
                "Candidate campaign themes currently associated only with your asset" +
                "are shown in the lower right hand quadrant. Candidate themes associated " +
                "with your Intended Association, you will find in the upper left hand " +
                "quadrant. Themes explicitly connecting both your asset and your Intended " +
                "Association, you will find in the upper right hand quadrant, on the " +
                "diagonal line between the two. Since we are not censoring our semantic " +
                "network, the results will reflect actual population bias, which may " +
                "include discriminatory and otherwise insensitive concepts. Please apply " +
                "professional prudence when crafting your brand story using these " +
                "concepts.",
            path_finder_instructions: "Story Creator instructions",
            create_brand_story: "Develop your brand story",
            draft_story: "based on the selected associations",
            generate_brand_story_title: "AI generation",
            generate_brand_story: "Generate an original brand story based on your selected keywords for 10 Mindcoins",
            generate_brand_value_image: "Generate an original brand value image based on your search text for 10 Mindcoins",

        },
    },
    nl: {
        message: {
            your_pathway: 'Connect the dots',
            inspirational_themes: "Inspirerende thema's",
            theme_ranking: 
                "Thema rangschikking: selecteer de thema's die samen passen om jouw " +
                "storyline te creëren.",
            path_finder_tooltip:
                "Kandidaat-campagnethema's die verband houden met de huidige positie van " +
                "jouw Asset worden in het kwadrant rechtsonder weergegeven. Kandidaat-" +
                "thema's die verband houden met jouw beoogde Gewenste Associatie vindt u " +
                "in het kwadrant linksboven. Thema's die expliciet verband houden met jouw" +
                " Asset en jouw Gewenste Associatie vindt u in het kwadrant rechtsboven, " +
                "op de diagonale lijn tussen beide. Aangezien we ons semantische netwerk " +
                "niet censureren, zullen de resultaten een afspiegeling zijn van de " +
                "werkelijke bevooroordeeldheid van de populatie. Deze kan discriminerende " +
                "en anderszins ongevoelige concepten bevatten. Gelieve professionele " +
                "voorzichtigheid te hanteren bij het maken van je merkverhaal aan de hand " +
                "van deze concepten.",
            path_finder_instructions: "Story Creator instructies",
            create_brand_story: "Ontwikkel uw brand story",
            draft_story: "op basis van de geselecteerde associaties",
            generate_brand_story_title: "AI generatie",
            generate_brand_story: "Genereer een originele brandstory op basis van uw geselecteerde keywords voor 10 Mindcoins",
            generate_brand_value_image: "Genereer een originele brand value image van uw geselecteerde keywords voor 10 Mindcoins",

        },
    }
};

export { path_finder_translation };
