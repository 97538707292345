<template>
<div v-if="sloganSelector" class="picker-opener" >
<!-- <q-select dense outlined v-model="dummy"/> -->
<div class="btn-div"><a class="btn3" @click="openAssetPicker">Go to Library</a></div>
</div>
<label class="marketLabel" v-if="showFrameworkSelector"></label>
<marketing-framework-picker v-if="showFrameworkSelector" hint="Select a Framework (Optional)" ref="marketing_picker" @frameworkSelected="onFrameworkSelected">
</marketing-framework-picker>
<label v-if="showWordSelector && isLabelRemoved"></label>
<word-picker v-if="showWordSelector" hint="Add a dictionary word" @wordSelected="onWordSelected">
</word-picker>

<asset-picker v-if="sloganSelector" ref="asset-picker" :allowed_types="['words']" @addAsset="onAssetSelected">
</asset-picker>
<br v-if="sloganSelector">
<ia-chip v-for="ia in intended_associations" :key="ia.word_id" :word="ia" @removeIa="onRemoveIa">
</ia-chip>
</template>

<script>
import IaChip from '@/components/target_pickers/ias/IaChip.vue';
import MarketingFrameworkPicker
from '@/components/target_pickers/ias/MarketingFrameworkPicker.vue';
import WordPicker from '@/components/target_pickers/ias/WordPicker.vue';
import {
    alert_error,
    toast_success
} from '@/helpers/alert_helper.js';
import AssetPicker from "@/components/asset_picker/AssetPicker";

export default {
    components: {
        MarketingFrameworkPicker,
        WordPicker,
        IaChip,
        AssetPicker
    },
    emits: ['wordSelected', 'wordUnselected', 'wordRemoved'],
    props: {
        showFrameworkSelector: {
            default: true,
            type: Boolean
        },
        showWordSelector: {
            default: true,
            type: Boolean
        },
        marketingFrameworkHint: {
            default: '',
            type: String
        },
        iaHint: {
            default: '',
            type: String
        },
        sloganSelector: {
            default: false,
            type: Boolean
        },
        isLabelRemoved: {
            default: true,
            type: Boolean
        },
    },
    data: function () {
        return {
            intended_associations: new Set(),
        }
    },
    methods: {
        reset: function () {
            this.intended_associations.clear();
            this.$refs['marketing_picker'].reset();
        },
        /* Add selected word to IAs unless it's a duplicate */
        onWordSelected: function (word) {
            // we need to match on word id here because it's not the same
            // instance (even though their contents are identical)
            const match = Array.from(this.intended_associations).find(
                ia => ia.word_id == word.word_id
            );
            // if the word is not already in the intended_associations
            if (typeof match === "undefined") {
                this.intended_associations.add(word);
                this.$emit('wordSelected', word);
                toast_success(this.$t("message.added", {
                    words: word.word
                }));
                this.$store.dispatch('get_mindcoin_balance');
            } else {
                alert_error(this.$t("message.duplicate_ia"));
            }
        },
        onAssetSelected: function (asset) {
            this.onWordSelected(asset.asset.word);
            return;
        },
        /* Add all words from the selected framework. */
        onFrameworkSelected: function (framework) {
            this.intended_associations.clear();
            framework.words.forEach(word => {
                // we add a flag to mark this word as a framework word (a word
                // is a framework word if it was added as a result of framework
                // selection, while words that have been added individually are
                // not framework words)
                word.isFrameworkWord = true;
                this.intended_associations.add(word);
            });
            this.$emit('wordSelected', framework.words[0]);
            const msg = this.$t("message.added", {
                words: framework.name
            });
            toast_success(msg);
            this.$store.dispatch('get_mindcoin_balance');
        },
        /* Handle IA removal */
        onRemoveIa: function (word) {
            this.intended_associations.delete(word);
            this.$emit('wordRemoved', word);
        },
        openAssetPicker: function () {
            this.$refs["asset-picker"].openAssetPicker();
        },
    },
}
</script>

<style scoped>
.picker-opener {
    margin-bottom: 2vw;
}
</style>
