const emotional_analysis_translation = {
    en: {
        message: {
            valence_left: "sad",
            valence_right: "happy",
            arousal_left: "calm",
            arousal_right: "excited",
            dominance_left: "following",
            dominance_right: "influencing"
        },
    },
    nl: {
        message: {
            valence_left: "triest",
            valence_right: "blij",
            arousal_left: "kalm",
            arousal_right: "opgewekt",
            dominance_left: "volgend",
            dominance_right: "beïnvloedend"
        },
    }
};

export { emotional_analysis_translation };
