<template>
<div class="divdr-bx" v-if="data.length">or</div>
<div v-if="data.length">
    <h5>Choose from recently used assets</h5>
    <h6 v-if="data['words'].length">Word</h6>
    <div class="checkbox-asid" v-if="data['words'].length">
        <div class="form-check form-check-inline" v-for="items in data['words']" v-bind:key="items.id">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" v-model="items.id" />
            <label class="form-check-label" for="inlineCheckbox1">{{ items.word.word }}
            </label>
        </div>
    </div>

    <h6 v-if="data['images'].length">Images</h6>
    <div class="imag-col-md" v-if="data['images'].length">
        <div class="img-col" v-for="items in data['images']" v-bind:key="items">
            <img v-bind:src="`${items.urls.thumb_medium}`" /><img src="../../assets/icon8.png" class="bigimg" @click="handleImage(items)" />
        </div>
    </div>

    <h6 v-if="data['videos'].length">Media</h6>
    <div class="imag-col-md video-col-md" v-if="data['videos'].length">
        <div class="img-col" v-for="items in data['videos']" v-bind:key="items">
            <img class="videoImg" v-bind:src="items.urls.gif" @click="handleVideos(items)">
        </div>
    </div>

</div>

<full-screen-preview v-if="image" :asset="image" ref="fullscreen_preview">
</full-screen-preview>
<instructions-video v-if="video" :videoUrl="video.urls.file" :videoTitle="video.display_name" :videoName="video.type_.title" ref="instructions-video"></instructions-video>
</template>

<script>
import axios from 'axios';
import FullScreenPreview from '@/components/common/FullScreenPreview.vue';
import InstructionsVideo from './InstructionsVideo.vue';

export default {
    name: 'RightSideBar',
    components: {
        FullScreenPreview,
        InstructionsVideo
    },
    data() {
        return {
            data: [],
            image: '',
            video: '',
            objectFit: 'cover',
        };
    },
    mounted: async function () {
        const data = await axios.get('/api/cas/assets/get_last_used');

        if (data.status === 200) {
            this.data = data.data;
        }
    },
    methods: {
        handleImage: function (value) {
            this.image = value;
            this.$refs['fullscreen_preview'].open();
        },
        handleVideos: function (value) {
            this.video = value;
            this.$refs['instructions-video'].open();
        },
    },
};
</script>

<style scoped>
.checkbox-asid {
    height: 7vw;
}

h5 {
    margin-top: 2vw;
    font-size: 15px;
    font-weight: 700;
}
</style>
