const login_translation = {
    en: {
        login: {
            username: 'Login',
            password: 'Password',
            log_in: 'Log in',
            remember_me: "Remember me",
            contact_us: "Contact us now",
            sign_up: "Sign up",
            not_a_user_yet: "Not a member of the Mindspeller community yet?",
            wrong_credentials: "Invalid login or password",
            show_password: "Show password",
        },
    },
    nl: {
        login: {
            username: 'Gebruikersnaam',
            password: 'Wachtwoord',
            log_in: 'Log in',
            remember_me: "Onthoud mij",
            contact_us: "Contacteer ons",
            sign_up: "",
            not_a_user_yet: "Nog geen lid van de Mindspeller community?",
            wrong_credentials: "De gebruikersnaam of het wachtwoord is ongeldig",
            show_password: "Show password", // TODONL
        },
    }
};

export { login_translation };
