<template>
    <span @click="showTooltip" class="np-tooltip">
        <font-awesome-icon icon="info-circle" />
        <i class="fal fa-info-circle"></i>
    </span>
</template>

<script>
import { alert_info } from '@/helpers/alert_helper.js';

export default {
    props: {
        title: String,
        message: String,
    },
    methods: {
        showTooltip: function() {
            alert_info(this.title, this.$t(`message.${this.message}`), 'justify');
        },
    }
}
</script>

<style scoped>
.np-tooltip {
    cursor: pointer
}
</style>
