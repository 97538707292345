const add_new_asset_translation = {
  en: {
    new_asset: {
      add_new_item: 'Add new concept to survey',
      upload_image: 'upload image/illustrations',
      upload_multimedia: 'upload videos/audio',
      add_words: 'Add keywords/slogans',
      type_to_create_asset: 'Type to create keyword / slogan concept',
      word_exists_in_network:
        'This word has already been mapped before and is available for analysis in the apps!',
      duplicate_order_attempt: 'You have already ordered this specific word.',
      mapping_required: 'mapping required',
      total_mindcoins: 'Total: {total} mindcoins',
      insufficient_credits:
        'You do not have enough mindcoins to upload ' +
        'your uploaded concept(s). You can try removing some from the list.',
      upload_from_computer:
        'Please select the concepts you want to map. Our proprietary mapping process involves real people and their spontaneous associations with your concepts. The mapping process will be completed over the weekend and you will receive an email notification as soon as your concpets have been mapped.',
      short_notification_text:
        'You will receive an automatic e-mail notification as soon as your concept has been mapped.',
      answer_image_questions: 'Please complete all image classification tags',
      accepted_types: 'Accepted file types are: { types }.',
      drop_here: 'You can also drag and drop files here.',
      unacceptable_type: 'UNACCEPTABLE FILE TYPE. ONLY { types } ARE ALLOWED.',
      image_too_small: 'IMAGE IS TOO SMALL',
      is_item_branded: 'Is this showing a brand?',
      type_of_item: 'Type of item',
      upload_selected_asset: 'Upload selected concepts?',
      create_selected_asset: 'Order selected concepts?',
      this_will_cost: 'This will cost {price} mindcoins.',
      assets_created: 'Concept(s) {assets} uploaded, you will be notified when your ordered concepts are ready for analysis',
    },
  },
  nl: {
    new_asset: {
      add_new_item: 'Voeg nieuw Concept toe',
      upload_multimedia: 'upload multimedia',
      add_words: 'voeg woord(en) toe',
      type_to_create_asset: 'Type om keyword/slogan concept te creeren',
      word_exists_in_network:
        'Dit woord werd al eerder gemapped en is beschikbaar voor analyse in de apps',
      duplicate_order_attempt: 'Je hebt dit woord al besteld.',
      mapping_required: 'mapping survey nodig',
      total_mindcoins: 'Totaal: {total} Mindcoins',
      insufficient_credits:
        'Je hebt onvoldoende Mindcoins om al deze' +
        'Concepten toe te voegen. Probeer om enkele concepten te verwijderen van de lijst.',
      upload_from_computer:
        'Gelieve uw Concept te selecteren voor mapping. Gezien we uw Concept mappen door bevraging van spontane associaties bij echte mensen, kan het mappen even duren.\n U ontvangt een automatische e-mail notificatie zodra uw Concept gemapt werd.',
      short_notification_text:
        'U ontvangt een automatische e-mail notificatie zodra uw concept gemapt werd.',
      answer_image_questions:
        'Gelieve alle gevraagde labels m.b.t. de afbeeldingen te vervolledigen',
      accepted_types: 'Aanvaarde bestand types zijn: { types }.',
      drop_here: 'Je kan ook bestanden naar hier slepen.',
      unacceptable_type:
        'ONAANVAARDBAARE BESTAND TYPE. ENKEL { types } TOEGESTAAN.',
      image_too_small: 'Afbeelding te klein',
      is_item_branded: 'Toont dit een merk?',
      type_of_item: 'Item type',
      upload_selected_asset: 'Geselecteerde concepten opladen?',
      create_selected_asset: 'Geselecteerde concepten bestellen?',
      this_will_cost: 'Dit bedraagt {price} Mindcoins.',
      assets_created: 'Gecreëerde concepten: {assets}.',
    },
  },
};

export { add_new_asset_translation };
