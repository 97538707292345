<template>
   <div class="library-menu-item">
    <a @click="handleLibrary"><font-awesome-icon class="logo" :icon="['fas', 'folder']"/>Library</a>
   </div> 
</template>

<script>
export default {
    name: "Library",
    methods:{
        handleLibrary: function () {
            window.open(this.$router.resolve('/library').href, '_blank');
    },
    }
}
</script>

<style>
    .library-menu-item{
        display: inline-block;
        vertical-align: middle;
        font-weight: 600;
        font-size: 1.1vw;
        line-height: 1.5vw;
        color: white;
        position: relative;
        cursor: pointer;
    }
    .logo{
        width: 20px;
        margin-right: 10px;
        color: white;
    }
</style>
