<template>
  <div class="logo">
    <a @click="handleClick"><img src="../../assets/logo-no-text.png" /></a>
  </div>
</template>

<script>
export default {
  name: "Logo",
  methods: {
    handleClick: function () {
      this.$router.push({ name: "Dashboard" });
    },
  },
};
</script>

<style scoped>
.logo {
  width: 75px;
  height: 75px;
  background-color: white;
}
</style>
