<template>
<section class="middle-section">
    <div class="container">
        <div class="inspiration-pg association-wheel-pg">
            <div class="inspir-lft-sec scroll">
                <div class="leftBar ">
                    <workspace ref="workspace" @reset="reset" @reset_color="reset_color"></workspace>
                </div>
            </div>
            <div class="rightbox-sec scroll">
                <div class="rightBar ">
                    <left-sidebar ref="left-sidebar" @percentageChange="onPercentageChange" @wheelInformationChange="onWheelInformationChange" @assetAdded="onAssetAdded" @assetRemoved="onAssetRemoved" @fontSizeChange="onFontSizeChange" @wheelSizeChange="onWheelSizeChange" @topCollapseChange="onTopCollapseChange" @applyColorScheme="onApplyColorScheme">
                    </left-sidebar>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import LeftSidebar from './LeftSidebar.vue';
import Workspace from './Workspace.vue';

export default {
    name: 'AssociationWheel',
    components: {
        LeftSidebar,
        Workspace,
    },
    data() {
        return {
            show: 'hide',
            display_loading_overlay: false,
        };
    },
    methods: {
        getClass(value) {
            this.show = value;
        },
        reset: function () {
            this.$refs['workspace'].reset();
            this.$refs['left-sidebar'].reset();
        },
        reset_color:function(){
            // here to reset the color
            this.$refs['workspace'].ClearColorScheme();
        },
        onPercentageChange: function (value) {
            this.$refs.workspace.setPercentage(value);
        },
        onWheelInformationChange: function (value) {
            this.$refs.workspace.setWheelInformation(value);
        },
        onAssetAdded: async function (asset_and_positioning) {
            await this.$refs.workspace.addWheel(asset_and_positioning);
        },
        onAssetRemoved: function (asset_and_positioning) {
            this.$refs.workspace.removeWheel(asset_and_positioning);
        },
        onFontSizeChange: function (size) {
            this.$refs.workspace.setFontSize(size);
        },
        onWheelSizeChange: function (size) {
            this.$refs.workspace.setWheelSize(size);
        },
        onTopCollapseChange: function (size) {
            this.$refs.workspace.setTopCollapse(size);
        },
        onApplyColorScheme: function (scheme) {
            this.$refs.workspace.applyColorScheme(scheme);
        },
        jumpstart: async function () {
            const jumpstartEmpty = await this.$store.dispatch('jumpstart_empty');
            if (!jumpstartEmpty) {
                const assetAndPositioning = await this.$store.dispatch('pop_jumpstart');
                this.$refs['left-sidebar'].manuallyAddAssetAndPositioning(
                    assetAndPositioning
                );
            }
        },
    },
    mounted: async function () {
        this.jumpstart();
    },
};
</script>

<style scoped>
.rightBar {
    padding: 1vw 2vw 1vw 2vw;
}
</style>
