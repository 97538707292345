<template>
  <main>
    <div class="container-fluid full-width-pg forget-pass-pg">
      <div class="free-trial-pg">
        <MapBrand />
        <div class="rgt-sec-bar">
          <div class="free-login-box forget-password">
            <div class="logo-imgbx">
              <img src="../../assets/logo-no-text.png" alt="" />
            </div>
            <div class="textbx">Forget Password</div>
            <div class="formbx">
              <form>
                <div class="form-group">
                  <label for="exampleInputEmail1">Email:</label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Your Professional Email Address"
                    v-model="email"
                  />
                </div>
                <button
                  type="button"
                  class="btn btn-primary btn-round"
                  @click="handleForgotPwd"
                >
                  Send
                </button>
                <button
                  type="button"
                  class="btn btn-primary btn-round"
                  style="margin-left: 5px; background-color: white; border-color: blue; border-style: solid; color:black"
                  @click="handleLoginRedirection"
                >
                  Back
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <loading-app-wide v-if="loading" />
</template>

<script>
import LoadingAppWide from "@/components/common/LoadingAppWide.vue";
import MapBrand from "./Common/MapBrand.vue";

import { alert_error } from "@/helpers/alert_helper.js";

export default {
  name: "ForgotPassword",
  components: {
    MapBrand,
    LoadingAppWide,
  },
  data() {
    return {
      email: "",
      loading: false,
      showPopupFlag: false,
    };
  },
  methods: {
    showNotif() {
      setTimeout(() => {
        this.$router.push("/login");
      }, 3000);
      this.$q.notify({
        progress: true,
        message: "Email sent successfully!",
        timeout: 3000,
        icon: "mail",
        color: "white",
        textColor: "primary",
        position: "top",
        actions: [{ label: "Dismiss", handler: () => {} }],
        classes: "my-custom-notification",
      });
    },

    handleForgotPwd: async function () {
      try {
        this.loading = true;
        await this.$store.dispatch("handleForgotPwd", this.email);
        this.showNotif();
      } catch (error) {
        alert_error(this.$t("message.general_server_error"));
      }
      this.loading = false;
    },
    handleLoginRedirection: function(){
      this.$router.push({ name: 'Login' });
    }
  },
};
</script>

<style>
.my-custom-notification .q-notification__progress {
  background-color: #002cf0;
  border-color: #2730ae;
}
</style>
