<template>
  <div class="upload-wrapper">
    <div
      @drop.prevent="dropFiles($event)"
      @dragenter.prevent
      @dragover.prevent
      class="upload-area q-mb-sm"
    >
      <div>
        <label class="custom-file-upload q-mb-sm">
          <img class="upload-icon" src="@/assets/icons/upload-blue.svg" />
          <input
            type="file"
            multiple="multiple"
            :accept="acceptExtenstions"
            @change="filesSelected($event)"
          >
        </label>
        <p class="upload-help-text">
          {{ $t("new_asset.accepted_types", { types: extensionsHumanReadable }) }}
        </p>
        <p class="upload-help-text">
          {{ $t("new_asset.drop_here") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['fileAdd'],
  props: {
      extensions: { default: ['png', 'jpg', 'jpeg', 'mp3', 'mp4'], type: Array },
  },
  computed: {
      acceptExtenstions: function() {
          return this.extensions.map(extension => `.${extension}`).join(',');
      },
      extensionsHumanReadable: function() {
          return this.extensions.map(e => `*.${e.toUpperCase()}`).join(', ');
      },
  },
  methods: {
    filesSelected: function(event) {
      this.addFiles(event.target.files);
    },
    dropFiles: function(event) {
      this.addFiles(event.dataTransfer.files);
    },
    addFiles: function(files) {
      for (const file of files) {
        this.$emit('fileAdd', file);
      }
    },
  },
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.custom-file-upload {
    display: inline-block;
    padding: 7px 12px;
    cursor: pointer;
    border: 2px #ccc solid;
    background: #fff;
    border-radius: 50%;
    width: 55px;
    height: 55px;
}

.upload-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-area {
  background-color: #e5e8ff;
  height: 140px;
  display: flex;
  align-items: center; 
  justify-content: center;
  text-align: center;
  width: 90%;
  border-radius: 30px;
}
.custom-file-upload label {
  margin-bottom: 0;
}

.upload-icon {
  width: 32px;
  height: 32px;
}
.upload-help-text {
  color: black;
  margin: 0;
}

.q-item__section.column.q-item__section--side.justify-center.q-item__section--avatar.q-mr-md {
    width: 100px;
    margin-right: 0;
}
</style>
